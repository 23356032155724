import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store/index";
import * as OxyStore from "../../store/OxyStore";
import NavMenuShop from "./NavMenuShop";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import { AddressDto, CartItemDto } from "../../models";
import { CartType } from "../../models/shop";
import { Constants } from "../../utils";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { CartPayload, OrderPayload } from "../../payloads";

type CheckoutProps = OxyStore.OxyState &
  typeof OxyStore.actionCreators &
  RouteComponentProps<{}>;

interface CheckoutState {
  validationErrors: string[];
  name: string;
  email: string;
  billingName: string;
  billingAddress: AddressDto;
  couponCode: string;
  couponDiscountAmount: number;
  couponErrorModalOpen: boolean;
}

class Checkout extends React.PureComponent<CheckoutProps, CheckoutState> {
  constructor(props: CheckoutProps) {
    super(props);

    this.state = {
      validationErrors: [],
      name: props.loggedInUser == null ? "" : props.loggedInUser.name,
      email: props.loggedInUser == null ? "" : props.loggedInUser.email,
      billingName:
        props.loggedInUser == null ? "" : props.loggedInUser.billingName,
      billingAddress:
        props.loggedInUser == null || props.loggedInUser.billingAddress == null
          ? {
              id: 0,
              zipCode: 0,
              city: "",
              streetAddress: "",
            }
          : { ...props.loggedInUser.billingAddress },
      couponCode: "",
      couponDiscountAmount: 0,
      couponErrorModalOpen: false,
    };
  }

  componentDidMount() {
    this.validate(true);
  }

  componentWillReceiveProps(nextProps: CheckoutProps) {
    if (
      JSON.stringify(nextProps.myCart) !== JSON.stringify(this.props.myCart)
    ) {
      this.validateAfterNewProps(nextProps);
    }

    if (this.props.barionPayment == null && nextProps.barionPayment != null) {
      localStorage.removeItem(Constants.webshopClientCartLocalStorageKey);
      this.props.setCartItemsRaw([]);
      window.location.href = nextProps.barionPayment.gatewayUrl;
    }

    if (this.props.loggedInUser == null && nextProps.loggedInUser != null) {
      this.setState({
        name: nextProps.loggedInUser.name,
        email: nextProps.loggedInUser.email,
        billingName: nextProps.loggedInUser.billingName,
        billingAddress: { ...nextProps.loggedInUser.billingAddress },
      });
    }

    if (
      this.props.couponValidationResult == null &&
      nextProps.couponValidationResult != null
    ) {
      if (
        nextProps.couponValidationResult.isValid &&
        nextProps.couponValidationResult.discountedAmount != null
      ) {
        this.setState(
          {
            couponDiscountAmount:
              nextProps.couponValidationResult.discountedAmount,
          },
          () => this.validate(true)
        );
      } else {
        this.setState(
          {
            couponDiscountAmount: 0,
            couponErrorModalOpen: true,
            couponCode: "",
          },
          () => this.validate(true)
        );
      }
    }
  }

  private currencyFormatter = new Intl.NumberFormat("hu-HU", {
    style: "currency",
    currency: "HUF",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  public render() {
    require("../../shop.css");

    const total =
      this.props.myCart.reduce((prev, curr) => {
        const currPrice =
          curr.product != null
            ? curr.product.price!!
            : curr.productOption!!.price;
        return prev + currPrice * curr.quantity;
      }, 0) - this.state.couponDiscountAmount;

    return (
      <React.Fragment>
        <NavMenuShop />
        <ScrollToTop />
        <div className="container checkout-main-container">
          <h1>Rendelés leadása</h1>
          <h2 style={{ color: "#747474" }}>Kosár</h2>
          {this.props.myCart.length > 0 && (
            <div className="scrollbar-container ps">
              {this.props.myCart.map((cartItem, index) => {
                const product =
                  cartItem.product != null
                    ? cartItem.product
                    : this.props.productCategories
                        .find((x) =>
                          x.products.find(
                            (y) => y.id === cartItem.productOption!!.productId
                          )
                        )!!
                        .products.find(
                          (x) => x.id === cartItem.productOption!!.productId
                        )!!;
                return (
                  <div key={index} className="cart-sidebar-product-container">
                    <Link
                      to={`/shop/product/${product.id}`}
                      className="cart-sidebar-product-image-container"
                    >
                      <div
                        style={{
                          display: "inline-block",
                          maxWidth: "100%",
                          overflow: "hidden",
                          position: "relative",
                          boxSizing: "border-box",
                          margin: "0px",
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            display: "block",
                            maxWidth: "100%",
                          }}
                        >
                          <img
                            aria-hidden="true"
                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEwIiBoZWlnaHQ9IjEyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
                            style={{
                              maxWidth: "100%",
                              display: "block",
                              margin: "0px",
                              border: "none",
                              padding: "0px",
                            }}
                          />
                        </div>
                        <img
                          src={`/wp-content/uploads/new/${product.image.name}`}
                          className="cart-sidebar-product-image"
                        />
                      </div>
                    </Link>
                    <div className="cart-sidebar-product-details-container">
                      <div>
                        <Link
                          to={`/shop/product/${product.id}`}
                          className="cart-sidebar-product-details-name"
                        >
                          {product.name}
                          {cartItem.productOption != null &&
                            ` - ${cartItem.productOption.name}`}
                        </Link>
                        <div className="cart-sidebar-product-amount">
                          {cartItem.quantity} x{" "}
                          <span className="cart-sidebar-product-price">
                            {this.currencyFormatter.format(
                              cartItem.productOption != null
                                ? cartItem.productOption.price
                                : cartItem.product!!.price!!
                            )}
                          </span>
                        </div>
                        <div className="cart-sidebar-product-amount-buttons-container">
                          <span
                            className={
                              cartItem.quantity > 1
                                ? "cursor-pointer"
                                : "cursor-not-allowed"
                            }
                            onClick={() =>
                              this.decreaseMyCartItemQuantity(cartItem)
                            }
                          >
                            <FontAwesomeIcon icon={["fas", "minus"]} />
                          </span>
                          <input
                            className="cart-sidebar-product-amount-input"
                            type="text"
                            size={9}
                            readOnly
                            value={cartItem.quantity}
                          />
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              this.increaseMyCartItemQuantity(cartItem)
                            }
                          >
                            <FontAwesomeIcon icon={["fas", "plus"]} />
                          </span>
                        </div>
                      </div>
                      <span
                        className="cursor-pointer"
                        onClick={() => this.deleteItemFromMyCart(cartItem)}
                      >
                        <FontAwesomeIcon icon={["fas", "times"]} />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {this.props.myCart.length === 0 && (
            <div className="empty-cart-message-container">
              <div className="empty-cart-icon">
                <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
              </div>
              <div className="empty-cart-text">
                <h2>A kosár üres.</h2>
              </div>
            </div>
          )}
          <h2 style={{ color: "#747474" }} className="mt-4">
            Rendelés adatai
          </h2>
          <div>
            <div className="form-group">
              <label htmlFor="inputCheckoutName">Név</label>
              <input
                type="text"
                className="form-control"
                id="inputCheckoutName"
                autoComplete="off"
                placeholder="Írd be a nevet."
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCheckoutEmail">E-mail cím</label>
              <input
                type="text"
                className="form-control"
                id="inputCheckoutEmail"
                autoComplete="off"
                placeholder="Írd be az e-mail címet."
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCheckoutBillingName">Számlázási név</label>
              <input
                type="text"
                className="form-control"
                id="inputCheckoutBillingName"
                autoComplete="off"
                placeholder="Írd be a számlázási nevet."
                value={this.state.billingName}
                onChange={this.handleBillingNameChange}
              />
            </div>
            <h3 style={{ color: "#747474" }} className="mt-3">
              Számlázási cím
            </h3>
            <div className="form-group">
              <label htmlFor="inputCheckoutZipCode">Irányítószám</label>
              <input
                type="text"
                className="form-control"
                id="inputCheckoutZipCode"
                autoComplete="off"
                placeholder="Írd be az irányítószámot."
                value={this.state.billingAddress.zipCode || ""}
                onChange={(e: any) =>
                  this.handleBillingAddressChange({
                    ...this.state.billingAddress,
                    zipCode: !isNaN(parseInt(e.target.value.replace(/\D/, "")))
                      ? parseInt(e.target.value.replace(/\D/, ""))
                      : 0,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCheckoutCity">Település</label>
              <input
                type="text"
                className="form-control"
                id="inputCheckoutCity"
                autoComplete="off"
                placeholder="Írd be a település nevét."
                value={this.state.billingAddress.city}
                onChange={(e: any) =>
                  this.handleBillingAddressChange({
                    ...this.state.billingAddress,
                    city: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCheckoutStreetAddress">Cím</label>
              <input
                type="text"
                className="form-control"
                id="inputCheckoutStreetAddress"
                autoComplete="off"
                placeholder="Írd be a címet."
                value={this.state.billingAddress.streetAddress}
                onChange={(e: any) =>
                  this.handleBillingAddressChange({
                    ...this.state.billingAddress,
                    streetAddress: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCheckoutCouponCode">Kuponkód</label>
            </div>
            <div className="form-group form-inline">
              <input
                type="text"
                className="form-control"
                id="inputCheckoutCouponCode"
                autoComplete="off"
                placeholder="Írd be a kuponkódot."
                value={this.state.couponCode}
                onChange={this.handleCouponCodeChange}
              />
              <Button
                color="primary"
                onClick={this.handleApplyCouponClick}
                disabled={this.state.couponCode.length === 0}
              >
                Kupon alkalmazása
              </Button>
            </div>
          </div>
          {this.state.couponDiscountAmount > 0 && (
            <div className="mt-4 mb-4 d-flex justify-content-between">
              <div>Kupon: {this.state.couponCode}</div>
              <div>
                -{" "}
                {this.currencyFormatter.format(this.state.couponDiscountAmount)}
              </div>
            </div>
          )}
          <div className="mt-4 mb-4">
            <div id="checkout-order-button-container">
              <Button
                id="checkout-order-button"
                color="success"
                className="d-flex"
                onClick={this.handleOrderClick}
                disabled={!this.isValid()}
              >
                <div className="mr-3">Megrendelés</div>
                <div className="cart-sidebar-footer-checkout-price">
                  {this.currencyFormatter.format(total)}
                </div>
              </Button>
            </div>
            {this.state.validationErrors.length > 0 && (
              <UncontrolledTooltip
                placement="top"
                target="checkout-order-button-container"
              >
                {this.state.validationErrors.map((error, index) => {
                  return (
                    <React.Fragment>
                      {error}
                      {index < this.state.validationErrors.length - 1 && <br />}
                    </React.Fragment>
                  );
                })}
              </UncontrolledTooltip>
            )}
          </div>
        </div>
        {this.getCouponErrorModal()}
        <Footer />
      </React.Fragment>
    );
  }

  private getCouponErrorModal = () => {
    return (
      <Modal
        isOpen={this.state.couponErrorModalOpen}
        toggle={this.toggleCouponErrorModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.toggleCouponErrorModal}>
          Kupon hiba
        </ModalHeader>
        <ModalBody>
          <p className="mb-2">
            {this.props.couponValidationResult != null &&
              this.props.couponValidationResult.rejectReason}
          </p>

          <div className="d-flex justify-content-end align-items-center mt-3">
            <button
              className="btn btn-primary"
              onClick={this.toggleCouponErrorModal}
            >
              OK
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private handleApplyCouponClick = () => {
    const cartPayload: CartPayload = {
      cartItems: this.props.myCart.map((cartItem) => {
        return {
          id: cartItem.id,
          productId: cartItem.product == null ? null : cartItem.product.id,
          productOptionId:
            cartItem.productOption == null ? null : cartItem.productOption.id,
          gifteeName: cartItem.gifteeName,
          quantity: cartItem.quantity,
        };
      }),
    };

    this.props.validateCoupon(this.state.couponCode, cartPayload);
  };

  private toggleCouponErrorModal = () => {
    if (this.state.couponErrorModalOpen) {
      this.props.clearCouponValidationResult();
    }

    this.setState({
      couponErrorModalOpen: !this.state.couponErrorModalOpen,
    });
  };

  private handleNameChange = (e: any) => {
    this.setState(
      {
        name: e.target.value,
      },
      () => this.validate(true)
    );
  };

  private handleBillingNameChange = (e: any) => {
    this.setState(
      {
        billingName: e.target.value,
      },
      () => this.validate(true)
    );
  };

  private handleBillingAddressChange = (billingAddress: AddressDto) => {
    this.setState(
      {
        billingAddress: {
          ...billingAddress,
        },
      },
      () => this.validate(true)
    );
  };

  private handleEmailChange = (e: any) => {
    this.setState(
      {
        email: e.target.value,
      },
      () => this.validate(true)
    );
  };

  private handleCouponCodeChange = (e: any) => {
    this.setState(
      {
        couponCode: e.target.value,
        couponDiscountAmount: 0,
      },
      () => this.validate(true)
    );

    if (this.props.couponValidationResult != null) {
      this.props.clearCouponValidationResult();
    }
  };

  private handleOrderClick = () => {
    const payload: OrderPayload = {
      name: this.state.name,
      billingName: this.state.billingName,
      email: this.state.email,
      billingAddress: {
        ...this.state.billingAddress,
      },
      couponCode: this.state.couponCode,
      cart: {
        cartItems: this.props.myCart.map((cartItem) => {
          return {
            id: cartItem.id,
            productId: cartItem.product == null ? null : cartItem.product.id,
            productOptionId:
              cartItem.productOption == null ? null : cartItem.productOption.id,
            gifteeName: cartItem.gifteeName,
            quantity: cartItem.quantity,
          };
        }),
      },
    };

    this.props.submitOrder(payload);
  };

  private isValid = () => {
    return this.validate();
  };

  private validate = (
    shouldSetState: boolean = false,
    props: CheckoutProps = this.props
  ) => {
    const validationErrors: string[] = [];

    if (props.myCart.length === 0) {
      validationErrors.push("A kosár üres.");
    }

    if (this.state.name.length === 0) {
      validationErrors.push("A név kitöltése kötelező.");
    }

    if (this.state.email.length === 0) {
      validationErrors.push("Az e-mail cím kitöltése kötelező.");
    }

    if (!Constants.emailValidationRegex.test(this.state.email)) {
      validationErrors.push("Az e-mail cím érvénytelen.");
    }

    if (this.state.billingName.length === 0) {
      validationErrors.push("A számlázási név kitöltése kötelező.");
    }

    if (
      this.state.billingAddress.zipCode < 1000 ||
      this.state.billingAddress.zipCode > 9999
    ) {
      validationErrors.push("Az irányítószám érvénytelen.");
    }

    if (this.state.billingAddress.city.length === 0) {
      validationErrors.push("A település kitöltése kötelező.");
    }

    if (this.state.billingAddress.streetAddress.length === 0) {
      validationErrors.push("A cím kitöltése kötelező.");
    }

    if (
      this.state.couponCode.length > 0 &&
      this.props.couponValidationResult == null
    ) {
      validationErrors.push(
        "Alkalmazd a megadott kupont a rendelés leadása előtt!"
      );
    }

    if (shouldSetState) {
      this.setState({
        validationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private validateAfterNewProps = (nextProps: CheckoutProps) => {
    return this.validate(true, nextProps);
  };

  private increaseMyCartItemQuantity = (cartItem: CartItemDto) => {
    if (this.props.cartType === CartType.Server) {
      this.props.increaseMyCartItemQuantity(cartItem.id);
    } else if (this.props.cartType === CartType.Client) {
      const cartFromLocalStorage = localStorage.getItem(
        Constants.webshopClientCartLocalStorageKey
      );
      const cartItemsParsed = (
        cartFromLocalStorage == null
          ? []
          : (JSON.parse(cartFromLocalStorage) as CartItemDto[])
      ).map((item) => {
        if (
          item.product != null &&
          cartItem.product != null &&
          item.product.id === cartItem.product.id
        ) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        } else if (
          item.productOption != null &&
          cartItem.productOption != null &&
          item.productOption.id === cartItem.productOption.id
        ) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        } else {
          return item;
        }
      });

      localStorage.setItem(
        Constants.webshopClientCartLocalStorageKey,
        JSON.stringify(cartItemsParsed)
      );
      this.props.setCartItemsRaw(cartItemsParsed);
    }
  };

  private decreaseMyCartItemQuantity = (cartItem: CartItemDto) => {
    if (cartItem.quantity > 1) {
      if (this.props.cartType === CartType.Server) {
        this.props.decreaseMyCartItemQuantity(cartItem.id);
      } else if (this.props.cartType === CartType.Client) {
        const cartFromLocalStorage = localStorage.getItem(
          Constants.webshopClientCartLocalStorageKey
        );
        const cartItemsParsed = (
          cartFromLocalStorage == null
            ? []
            : (JSON.parse(cartFromLocalStorage) as CartItemDto[])
        ).map((item) => {
          if (
            item.product != null &&
            cartItem.product != null &&
            item.product.id === cartItem.product.id
          ) {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          } else if (
            item.productOption != null &&
            cartItem.productOption != null &&
            item.productOption.id === cartItem.productOption.id
          ) {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          } else {
            return item;
          }
        });

        localStorage.setItem(
          Constants.webshopClientCartLocalStorageKey,
          JSON.stringify(cartItemsParsed)
        );
        this.props.setCartItemsRaw(cartItemsParsed);
      }
    }
  };

  private deleteItemFromMyCart = (cartItem: CartItemDto) => {
    if (this.props.cartType === CartType.Server) {
      this.props.deleteItemFromMyCart(cartItem.id);
    } else if (this.props.cartType === CartType.Client) {
      const cartFromLocalStorage = localStorage.getItem(
        Constants.webshopClientCartLocalStorageKey
      );
      const cartItemsParsed = (
        cartFromLocalStorage == null
          ? []
          : (JSON.parse(cartFromLocalStorage) as CartItemDto[])
      ).filter((item) => {
        if (
          item.product != null &&
          cartItem.product != null &&
          item.product.id === cartItem.product.id
        ) {
          return false;
        } else if (
          item.productOption != null &&
          cartItem.productOption != null &&
          item.productOption.id === cartItem.productOption.id
        ) {
          return false;
        } else {
          return true;
        }
      });

      localStorage.setItem(
        Constants.webshopClientCartLocalStorageKey,
        JSON.stringify(cartItemsParsed)
      );
      this.props.setCartItemsRaw(cartItemsParsed);
    }
  };
}

export default connect(
  (state: ApplicationState) => state.oxy,
  OxyStore.actionCreators
)(Checkout as any);
