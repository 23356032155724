import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CartSidebar from "./CartSidebar";
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Constants } from '../../utils';
import { CartItemDto } from '../../models';
import { CartPayload } from '../../payloads';
import { CartType, ProductDto } from '../../models/shop';

type NavMenuShopProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuShopState {
    isCartOpen: boolean;
    productSearchModalOpen: boolean;
    productSearch: string;
    filteredProducts: ProductDto[];
    mobileExpandedIndex: number;
    mobileMenuOpen: boolean;
}

enum MobileShopMenuCategory {
    Categories,
    Account
}

export class NavMenuShop extends React.PureComponent<NavMenuShopProps, NavMenuShopState> {
    constructor(props: NavMenuShopProps) {
        super(props);

        this.state = {
            isCartOpen: false,
            productSearchModalOpen: false,
            productSearch: "",
            filteredProducts: [],
            mobileExpandedIndex: NaN,
            mobileMenuOpen: false
        }
    }

    componentDidMount() {
        if (!this.props.userReceived) {
            this.props.getLoggedInUser();
            this.props.getAllProductCategories();
        } else if (this.props.loggedInUser != null) {
            this.props.updateCartType(CartType.Server);
        }
    }

    componentWillReceiveProps(nextProps: NavMenuShopProps) {
        if (!this.props.userReceived && nextProps.userReceived) {
            const cartFromLocalStorage = localStorage.getItem(Constants.webshopClientCartLocalStorageKey);
            const cartItemsParsed = cartFromLocalStorage == null ? [] : JSON.parse(cartFromLocalStorage) as CartItemDto[];
            if (nextProps.loggedInUser == null) {
                if (cartFromLocalStorage == null) {
                    localStorage.setItem(Constants.webshopClientCartLocalStorageKey, JSON.stringify([]));
                } else {
                    if (cartItemsParsed.length > 0) {
                        const payload: CartPayload = {
                            cartItems: cartItemsParsed.map(cartItem => {
                                return {
                                    id: null,
                                    productId: cartItem.product == null ? null : cartItem.product.id,
                                    productOptionId: cartItem.productOption == null ? null : cartItem.productOption.id,
                                    gifteeName: cartItem.gifteeName,
                                    quantity: cartItem.quantity
                                }
                            })
                        };

                        this.props.validateClientCart(payload);
                    }
                }
            } else {
                if (cartFromLocalStorage != null && cartItemsParsed.length > 0) {
                    const payload: CartPayload = {
                        cartItems: cartItemsParsed.map(cartItem => {
                            return {
                                id: null,
                                productId: cartItem.product == null ? null : cartItem.product.id,
                                productOptionId: cartItem.productOption == null ? null : cartItem.productOption.id,
                                gifteeName: cartItem.gifteeName,
                                quantity: cartItem.quantity
                            }
                        })
                    };

                    this.props.convertClientCartToServerCart(payload);
                    localStorage.removeItem(Constants.webshopClientCartLocalStorageKey);
                } else {
                    this.props.getMyCart();

                    if (cartFromLocalStorage != null) {
                        localStorage.removeItem(Constants.webshopClientCartLocalStorageKey);
                    }
                }

                this.props.updateCartType(CartType.Server);
            }
        }

        if (this.props.myCart.length !== nextProps.myCart.length) {
            if (nextProps.loggedInUser == null) {
                localStorage.setItem(Constants.webshopClientCartLocalStorageKey, JSON.stringify(nextProps.myCart));
            }
        }
    }

    public render() {
        const isLoggedIn = this.props.loggedInUser != null;

        return (
            <React.Fragment>
                <header className="fusion-header-wrapper shop-header">
                    <div className="fusion-header-v3 fusion-logo-alignment fusion-logo-left fusion-sticky-menu-1 fusion-sticky-logo-1 fusion-mobile-logo-  fusion-mobile-menu-design-modern">
                        <div className="fusion-secondary-header">
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '1%', alignItems: 'center', margin: '0 auto' }}>
                                <div>
                                    <div className="fusion-contact-info">
                                        <span className="fusion-contact-info-phone-number">
                                            Várjuk hívásodat: &nbsp;<span className="fusion-contact-info-email-address">
                                            </span>
                                            <span className="fusion-contact-info-email-address">
                                                <a className="white-link" href="tel:06304642297">06&nbsp;30&nbsp;464&nbsp;2297</a>
                                            </span>
                                        </span>
                                        <span className="fusion-header-separator">|</span>
                                        <span className="fusion-contact-info-email-address">
                                            <a className="white-link" href="mailto:oxyesztetika@gmail.com">E‑mail:&nbsp;oxyesztetika@gmail.com</a>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center nav-menu-shop-desktop">
                                    <div className="shop-nav-link">
                                        <a href="/">Kezdőlap</a>
                                    </div>
                                    <div className="shop-nav-link">
                                        <Link to="/shop">Webshop</Link>
                                    </div>
                                    <div className="shop-nav-link">
                                        <Link to="/shop/aszf">ÁSZF</Link>
                                    </div>
                                    <div className="shop-nav-link">
                                        <a href="/kapcsolat">Kapcsolat</a>
                                    </div>
                                    <div className="shop-nav-button-container d-flex justify-content-between align-items-center">
                                        <div className="shop-nav-button" onClick={this.toggleProductSearchModal}>
                                            <FontAwesomeIcon icon={["fas", "search"]} />
                                        </div>
                                        <div className="shop-nav-button shop-nav-link right dropdown">
                                            <div>
                                                <FontAwesomeIcon icon={["fas", "user"]} />
                                            </div>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                {!isLoggedIn && <a href="/authentication/login?returnUrl=/shop" className="dropdown-item">
                                                    <span>Bejelentkezés</span>
                                                </a>}
                                                {!isLoggedIn && <a href="/authentication/register" className="dropdown-item">
                                                    <span>Regisztráció</span>
                                                </a>}
                                                {isLoggedIn && <Link to="/shop/profile" className="dropdown-item">
                                                    <span>Profil</span>
                                                </Link>}
                                                {isLoggedIn && <Link to="/shop/my-orders" className="dropdown-item">
                                                    <span>Rendeléseim</span>
                                                </Link>}
                                                {isLoggedIn && <a href="/authentication/logout?returnUrl=/shop" className="dropdown-item">
                                                    <span>Kijelentkezés</span>
                                                </a>}
                                            </div>
                                        </div>
                                        <div className="shop-nav-button" onClick={this.toggleCartOpen}>
                                            <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                                            {this.props.myCart.length > 0 && <span className="cart-icon-badge-container">
                                                <Badge bg="danger" color="danger">{this.props.myCart.length}</Badge>
                                            </span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="nav-menu-shop-mobile-wrapper" onClick={e => { if (this.state.mobileMenuOpen) this.toggleMobileMenu(e, false); }} style={{ pointerEvents: this.state.mobileMenuOpen ? "all" : "none" }}>
                                    <div className="nav-menu-shop-mobile w-100" style={{ position: "absolute" }}>
                                        <nav className="navbar navbar-default oxy-navbar-mobile w-100" role="navigation" style={{ position: "absolute", zIndex: 2 }}>
                                            <div className="container-fluid d-flex justify-content-end">
                                                <div className="navbar-header">
                                                    <div className="navbar-toggle collapsed" onClick={e => this.toggleMobileMenu(e)} style={{ pointerEvents: "all", marginTop: "10px" }}>
                                                        <div>
                                                            <FontAwesomeIcon icon={["fas", "bars"]} />
                                                            {this.props.myCart.length > 0 && <span className="cart-icon-badge-container navbar-cart-badge">
                                                                <Badge bg="danger" color="danger">{this.props.myCart.length}</Badge>
                                                            </span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`collapse navbar-collapse ${this.state.mobileMenuOpen ? "show in" : ""}`} id="oxy-mobile-navbar-collapse">
                                                    <ul className="nav navbar-nav">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                            <a href="/" className="fusion-bar-highlight">
                                                                <span className="menu-text" style={{ fontSize: "1rem" }}>
                                                                    Kezdőlap
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                            <Link to="/shop" className="fusion-bar-highlight">
                                                                <span className="menu-text" style={{ fontSize: "1rem" }}>
                                                                    Webshop
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                            <Link to="/shop/aszf" className="fusion-bar-highlight">
                                                                <span className="menu-text" style={{ fontSize: "1rem" }}>
                                                                    ÁSZF
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                            <a href="/kapcsolat" className="fusion-bar-highlight">
                                                                <span className="menu-text" style={{ fontSize: "1rem" }}>
                                                                    Kapcsolat
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                            <div className="fusion-bar-highlight" onClick={this.toggleProductSearchModal}>
                                                                <span className="menu-text" style={{ fontSize: "1rem" }}>
                                                                    Keresés
                                                                </span>
                                                            </div>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu dropdown text-center">
                                                            <a href="#" onClick={e => this.toggleMobileExpandedIndex(e, this.state.mobileExpandedIndex === MobileShopMenuCategory.Account ? NaN : MobileShopMenuCategory.Account)} style={{ fontSize: "1rem" }}>
                                                                Fiók
                                                                <FontAwesomeIcon icon={["fas", this.state.mobileExpandedIndex === MobileShopMenuCategory.Account ? "angle-up" : "angle-down"]} className="ml-2" />
                                                            </a>
                                                            <ul className={`dropdown-menu ${this.state.mobileExpandedIndex === MobileShopMenuCategory.Account ? "show" : ""}`} role="menu">
                                                                {!isLoggedIn && <li className="dropdown-item text-center">
                                                                    <a href="/authentication/login?returnUrl=/shop">Bejelentkezés</a>
                                                                </li>}
                                                                {!isLoggedIn && <li className="dropdown-item text-center">
                                                                    <a href="/authentication/login?returnUrl=/shop">Regisztráció</a>
                                                                </li>}
                                                                {isLoggedIn && <li className="dropdown-item text-center">
                                                                    <Link to="/shop/profile" onClick={() => this.toggleMobileMenu(false)}>
                                                                        Profil
                                                                    </Link>
                                                                </li>}
                                                                {isLoggedIn && <li className="dropdown-item text-center">
                                                                    <Link to="/shop/my-orders" onClick={() => this.toggleMobileMenu(false)}>
                                                                        Rendeléseim
                                                                    </Link>
                                                                </li>}
                                                                {isLoggedIn && <li className="dropdown-item text-center">
                                                                    <a href="/authentication/logout?returnUrl=/shop">Kijelentkezés</a>
                                                                </li>}
                                                            </ul>
                                                        </li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                            <div className="fusion-bar-highlight d-flex justify-content-center align-items-center" onClick={this.toggleCartOpen}>
                                                                <span className="menu-text" style={{ fontSize: "1rem" }}>
                                                                    Kosár
                                                                </span>
                                                                {this.props.myCart.length > 0 && <span className="ml-2" style={{ marginTop: "-2px" }}>
                                                                    <Badge bg="danger" color="danger">{this.props.myCart.length}</Badge>
                                                                </span>}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fusion-clearfix" />
                </header>
                {this.props.userReceived && <CartSidebar
                    {...this.props}
                    isOpen={this.state.isCartOpen}
                    toggleOpen={this.toggleCartOpen}
                    userAuthenticated={this.props.loggedInUser != null}
                />}
                {this.getProductSearchModal()}
            </React.Fragment>
        );
    }

    private getProductSearchModal = () => {
        return (
            <Modal isOpen={this.state.productSearchModalOpen} toggle={this.toggleProductSearchModal} className="modal-dialog-centered" autoFocus={false}>
                <ModalHeader toggle={this.toggleProductSearchModal}>Termék keresése</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputProductSearch">Terméknév</label>
                        <input type="text" className="form-control" id="inputProductSearch" autoComplete="off" placeholder="Keresés..." value={this.state.productSearch} onChange={this.handleProductSearchChange} autoFocus={true} />
                    </div>
                    <div className="list-group mt-4 product-search-results-container">
                        {this.state.filteredProducts.map((product, index) => {
                            return (
                                <Link to={`/shop/product/${product.id}`} key={index} className="list-group-item list-group-item-action d-flex align-items-center" onClick={this.toggleProductSearchModal}>
                                    <div className="product-search-image-container">
                                        <img src={`/wp-content/uploads/new/${product.image.name}`} />
                                    </div>
                                    <div className="ml-3">
                                        {product.name}
                                    </div>
                                </Link>
                            );
                        })}
                        {this.state.productSearch.length > 0 && this.state.filteredProducts.length === 0 && <div>
                            <h3>Nem található ilyen termék.</h3>
                        </div>}
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private toggleMobileMenu = (e: any, value: boolean = !this.state.mobileMenuOpen) => {
        if (e != null && e.target != null && e.target.className != null && e.target.className.indexOf != null && e.target.className.indexOf("menu-text") !== -1) {
            return;
        }

        if (e.nativeEvent != null) {
            e.nativeEvent.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            e.stopPropagation();
        }

        this.setState({
            mobileMenuOpen: value
        });
    }

    private toggleMobileExpandedIndex = (e: any, index: number) => {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();

        this.setState({
            mobileExpandedIndex: index
        });
    }

    private toggleProductSearchModal = () => {
        this.setState({
            productSearchModalOpen: !this.state.productSearchModalOpen,
            productSearch: this.state.productSearchModalOpen ? "" : this.state.productSearch,
            filteredProducts: this.state.productSearchModalOpen ? [] : this.state.filteredProducts,
            mobileMenuOpen: false
        });
    }

    private handleProductSearchChange = (e: any) => {
        const search = e.target.value;
        const filteredProducts = search.length === 0 ? [] : this.props.productCategories.flatMap(category => category.products).filter(product => product.name.toLowerCase().indexOf(search.toLowerCase()) > -1);

        this.setState({
            productSearch: search,
            filteredProducts: filteredProducts
        });
    }

    private toggleCartOpen = () => {
        this.setState({
            isCartOpen: !this.state.isCartOpen,
            mobileMenuOpen: false
        });
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(NavMenuShop as any);
