import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button } from 'reactstrap';
import { OxyUser } from '../../../models';

interface UserCardProps {
    user: OxyUser;
    delete: (id: number) => void;
}

interface UserCardState {
}

class UserCard extends React.PureComponent<UserCardProps, UserCardState> {
    constructor(props: UserCardProps) {
        super(props);
    }

    public render() {
        return (
            <React.Fragment>
                <div className="list-group-item d-flex justify-content-between align-items-center">
                    <div>{this.props.user.name == null || this.props.user.name.length === 0 ? "(nincs név beállítva)" : this.props.user.name}</div>
                    <div>{this.props.user.email}</div>
                    <div>
                        <Button color="danger" onClick={() => this.props.delete(this.props.user.id)} data-toggle="tooltip" data-placement="bottom" title="Törlés">
                            <FontAwesomeIcon id={`user-delete-icon-${this.props.user.id}`} icon={["fas", "trash-alt"]} className="fa-fw" />
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserCard;
