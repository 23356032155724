import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as OxyStore from '../store/OxyStore'
import { Footer } from "./Footer";
import { isMobileOnly } from 'react-device-detect';
import NavMenu from './NavMenu';
import OxyScrollingPageItemImageRight from "./OxyScrollingPageItemImageRight";
import OxyScrollingPageItemImageLeft from "./OxyScrollingPageItemImageLeft";

interface OxyScrollingPageComponentOwnProps {
    path?: string;
}

type OxyScrollingPageComponentProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ path: string }>
    & OxyScrollingPageComponentOwnProps;

interface OxyScrollingPageComponentState {

}

class OxyScrollingPageComponent extends React.PureComponent<OxyScrollingPageComponentProps, OxyScrollingPageComponentState> {
    constructor(props: OxyScrollingPageComponentProps) {
        super(props);

        this.state = {
        }
    }

    public componentDidMount() {
        const scrollingPagePath: string = this.props.path || this.props.match.params.path;

        if (scrollingPagePath != null && scrollingPagePath.length > 0) {
            this.props.getScrollingPageByPath(scrollingPagePath);
        }

        (window as any).avadaLightBox && (window as any).avadaLightBox.initialize_lightbox();
    }

    public render() {
        if (this.props.currentScrollingPage !== undefined && this.props.currentScrollingPage == null) {
            window.location.href = "/";
            return null;
        }

        const section2ImageStyle = this.props.currentScrollingPage == null ? {} : (this.props.currentScrollingPage!!.isSection2ImageFullWidth ? { minWidth: "100vw" } : { maxWidth: "100%", maxHeight: "600px", marginRight: this.props.currentScrollingPage.isSection2ImageAlignedLeft ? "auto" : "unset" });

        return (
            <div id="boxed-wrapper">
                <div className="fusion-sides-frame"></div>
                <div id="wrapper" className="fusion-wrapper">
                    <div id="home" style={{ position: "relative", top: "-1px" }}></div>
                    <div id="sliders-container">
                    </div>

                    <NavMenu {...this.props} />

                    <div className={isMobileOnly ? "mobile" : ""}>
                        {this.props.currentScrollingPage != null &&
                            <main id="main" className={`clearfix width-100 elementor ${(isMobileOnly ? "mobile" : "")}`} style={{ marginBottom: "481px" }}>
                                <div className="fusion-row" style={{ maxWidth: '100%' }}>
                                    <section id="content" className="full-width">
                                        <div id="post-5" className="post-5 page type-page status-publish hentry">
                                            <div className="post-content">
                                                <React.Fragment>
                                                    <div className="image-with-gradient-border" />
                                                    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{
                                                        backgroundColor: 'rgba(204,204,204,0.05)',
                                                        backgroundImage: `url("/wp-content/uploads/new/${this.props.currentScrollingPage.mainImage.name}")`,
                                                        backgroundPosition: 'center 0px',
                                                        backgroundRepeat: 'no-repeat',
                                                        padding: "0",
                                                        marginBottom: '0px',
                                                        borderWidth: '0px 0px 0px 0px',
                                                        borderColor: '#f7f7f7',
                                                        borderStyle: 'solid',
                                                        WebkitBackgroundSize: isMobileOnly ? "cover" : 'cover',
                                                        MozBackgroundSize: isMobileOnly ? "cover" : 'cover',
                                                        OBackgroundSize: isMobileOnly ? "cover" : 'cover',
                                                        backgroundSize: isMobileOnly ? "cover" : 'cover',
                                                        height: isMobileOnly ? "122px" : "540px"
                                                    }}>
                                                    </div>
                                                    <div className="image-with-gradient-border" />
                                                </React.Fragment>
                                                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(188,151,120,0.1)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                                                    <div className="fusion-builder-row fusion-row">
                                                        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-25 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: isMobileOnly ? "unset" : '50px', marginBottom: this.props.currentScrollingPage.section2Visible ? '30px' : '24px' }}>
                                                            <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                                                <div className="fusion-text fusion-text-19" style={{ textAlign: this.props.currentScrollingPage.isSection1TitleAlignedCenter ? "center" : "start" }}>
                                                                    <h1>
                                                                        <strong style={{ wordBreak: "break-word" }}>
                                                                            {this.props.currentScrollingPage.section1Title.split("\n").map((row, index) => <React.Fragment key={index}>{row}{index < this.props.currentScrollingPage!!.section1Title.split("\n").length - 1 ? <br /> : ""}</React.Fragment>)}
                                                                        </strong>
                                                                    </h1>
                                                                </div>
                                                                {this.props.currentScrollingPage.section1SubTitle.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "").length > 0 && <div className="fusion-text fusion-text-36 oxy-text-justified" style={{ display: "block" }}>
                                                                    <p className="m-5481381658492837685p3">
                                                                        <span className="m-5481381658492837685s2 standard-text" dangerouslySetInnerHTML={{ __html: this.props.currentScrollingPage.section1SubTitle }} />
                                                                    </p>
                                                                </div>}
                                                                {this.props.currentScrollingPage.section1SubText.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "").length > 0 && <div className="fusion-text fusion-text-34 standard-text oxy-text-justified">
                                                                    <h4 style={{ textAlignLast: "center" }} dangerouslySetInnerHTML={{ __html: this.props.currentScrollingPage.section1SubText }} />
                                                                </div>}
                                                                <div className="fusion-clearfix" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.props.currentScrollingPage.section2Visible && <div className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(188,151,120,0.1)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid', minHeight: "unset" }}>
                                                    <div className="fusion-builder-row fusion-row" style={{ display: "flex", justifyContent: "center" }}>
                                                        <img className={!this.props.currentScrollingPage.section2Visible ? "admin-editing-blurred" : ""} alt="" style={section2ImageStyle} src={`/wp-content/uploads/new/${this.props.currentScrollingPage.section2Image.name}`} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="elementor-section-wrap" style={{ marginTop: isMobileOnly ? undefined : "100px" }}>
                                    {this.props.currentScrollingPage!!.items.map((item, index) => {
                                        return index % 2 === 0
                                            ? <OxyScrollingPageItemImageRight item={item} key={index} />
                                            : <OxyScrollingPageItemImageLeft item={item} key={index} />;
                                    })}
                                </div>

                                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundImage: 'url("/wp-content/uploads/new/hifu-img-2-test.png")', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '450px', paddingRight: '30px', paddingBottom: '100px', paddingLeft: '30px', marginBottom: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', marginTop: "0" }}>
                                    <div className="fusion-builder-row fusion-row" style={{ marginTop: "-190px" }}>
                                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ padding: '0px 0px 0px 0px' }}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <div className="fusion-animated shake" style={{ display: "flex", justifyContent: "space-between", flexDirection: isMobileOnly ? "column" : "row", backgroundColor: "rgba(215, 188, 178, 0.47)", padding: "16px", animationDuration: '1.6s' }} data-animationoffset="100%" data-animationtype="shake" data-animationduration="1.6">
                                                    <div className="fusion-button-wrapper" style={{ marginRight: isMobileOnly ? "0" : "60px", marginBottom: isMobileOnly ? "20px" : "0" }}>
                                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t" }} />
                                                        <a className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no " target="_blank" rel="noopener noreferrer" href="/shop" style={{ width: isMobileOnly ? "100%" : "unset" }}>
                                                            <span className="fusion-button-text">
                                                                Vásárláshoz kattints ide!
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div className="fusion-button-wrapper">
                                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t" }} />
                                                        <a className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no " href={this.props.currentScrollingPage.pricingPage == null ? "/" : `/arak/${this.props.currentScrollingPage.pricingPage.path}`}>
                                                            <span className="fusion-button-text">
                                                                Árak megtekintéséhez kattints ide!
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>}
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(OxyScrollingPageComponent as any);
