import * as React from "react";
import { ImageDto, PageDto, ScrollingPageDto } from "../../models";
import { PromotedServicePayload } from "../../payloads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ImagePickerModal } from "./ImagePickerModal";
import { PagePickerModal } from "./PagePickerModal";
import { ScrollingPagePickerModal } from "./ScrollingPagePickerModal";

interface PromotedServiceEditorProps {
  images: ImageDto[];
  pages: PageDto[];
  scrollingPages: PageDto[];
  isOpen: boolean;
  toggle: () => void;
  isCreate: boolean;
  payload: PromotedServicePayload;
  save: (payload: PromotedServicePayload) => void;
  onImageClick: (name: string) => void;
  uploadImage: (file: File) => void;
}

interface PromotedServiceEditorState {
  imagePickerOpen: boolean;
  pagePickerOpen: boolean;
  scrollingPagePickerOpen: boolean;
  image: ImageDto | null;
  page: PageDto | null;
  scrollingPage: ScrollingPageDto | null;
  payload: PromotedServicePayload;
  validationErrors: string[];
}

export class PromotedServiceEditor extends React.PureComponent<
  PromotedServiceEditorProps,
  PromotedServiceEditorState
> {
  constructor(props: PromotedServiceEditorProps) {
    super(props);

    this.state = {
      imagePickerOpen: false,
      pagePickerOpen: false,
      scrollingPagePickerOpen: false,
      image: props.images.find((x) => x.id === props.payload.imageId) || null,
      page: props.pages.find((x) => x.id === props.payload.targetId) || null,
      scrollingPage:
        props.scrollingPages.find(
          (x) => x.id === props.payload.targetScrollingId
        ) || null,
      payload: {
        ...props.payload,
      },
      validationErrors: [],
    };
  }

  componentWillReceiveProps(nextProps: PromotedServiceEditorProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({
        payload: {
          ...nextProps.payload,
        },
        image:
          nextProps.images.find((x) => x.id === nextProps.payload.imageId) ||
          null,
        page:
          nextProps.pages.find((x) => x.id === nextProps.payload.targetId) ||
          null,
        scrollingPage:
          nextProps.scrollingPages.find(
            (x) => x.id == nextProps.payload.targetScrollingId
          ) || null,
      });
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className="modal-dialog-centered modal-lg"
        >
          <ModalHeader toggle={this.props.toggle}>
            Kiemelt szolgáltatás{" "}
            {this.props.isCreate ? "hozzáadása" : "szerkesztése"}
          </ModalHeader>
          <ModalBody>
            <div className="d-flex mt-2">
              <div
                style={{
                  height: "200px",
                  minHeight: "200px",
                  maxHeight: "200px",
                }}
              >
                {this.state.image != null && (
                  <img
                    alt=""
                    onClick={() =>
                      this.props.onImageClick != null &&
                      this.props.onImageClick(this.state.image!!.name)
                    }
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      overflow: "hidden",
                    }}
                    src={`/wp-content/uploads/new/${this.state.image!!.name}`}
                  />
                )}
              </div>
              <div>
                <Button color="primary" onClick={this.toggleImagePicker}>
                  <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                  Kép kiválasztása
                </Button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputPromotedServiceTitle">Cím</label>
              <textarea
                rows={3}
                className="form-control"
                id={"inputPromotedServiceTitle"}
                value={this.state.payload.title}
                onChange={this.handleTitleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPromotedServiceTitle">Alcím</label>
              <textarea
                rows={3}
                className="form-control"
                id={"inputPromotedServiceImageSubTitle"}
                value={this.state.payload.subTitle}
                onChange={this.handleSubTitleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPromotedServiceImageSubTitle">
                Kép alatti szöveg
              </label>
              <input
                type="text"
                className="form-control"
                id={"inputPromotedServiceImageSubTitle"}
                value={this.state.payload.imageSubTitle}
                onChange={this.handleImageSubTitleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPromotedServiceDetails">
                Részletes leírás
              </label>
              <textarea
                rows={10}
                className="form-control"
                id="inputPromotedServiceDetails"
                value={this.state.payload.details}
                onChange={this.handleDetailsChange}
              />
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                Céloldal:{" "}
                {this.state.scrollingPage == null
                  ? " - "
                  : this.state.scrollingPage.name}
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={this.toggleScrollingPagePicker}
                >
                  <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                  Hivatkozás kiválasztása
                </Button>
              </div>
            </div>

            {this.state.validationErrors.length > 0 && (
              <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                {this.state.validationErrors.map((error, index) => {
                  return (
                    <div key={index} className="form-invalid">
                      {error}
                    </div>
                  );
                })}
              </Card>
            )}

            <div className="d-flex justify-content-between align-items-center mt-3">
              <button
                onClick={this.handleSaveClick}
                type="button"
                className="btn btn-primary"
                disabled={!this.isValid()}
              >
                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                Mentés
              </button>

              <button className="btn btn-secondary" onClick={this.props.toggle}>
                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
              </button>
            </div>
          </ModalBody>
        </Modal>
        <ImagePickerModal
          isOpen={this.state.imagePickerOpen}
          toggle={this.toggleImagePicker}
          images={this.props.images}
          onImageSelected={this.handleImageSelected}
          aspectRatio={350 / 191}
          uploadImage={this.props.uploadImage}
        />
        <PagePickerModal
          isOpen={this.state.pagePickerOpen}
          toggle={this.togglePagePicker}
          pages={this.props.pages}
          onPageSelected={this.handlePageSelected}
        />
        <ScrollingPagePickerModal
          isOpen={this.state.scrollingPagePickerOpen}
          toggle={this.toggleScrollingPagePicker}
          scrollingPages={this.props.scrollingPages}
          onScrollingPageSelected={this.handleScrollingPageSelected}
        />
      </React.Fragment>
    );
  }

  private handleSaveClick = () => {
    this.props.save(this.state.payload);
  };

  private toggleImagePicker = () => {
    this.setState({
      imagePickerOpen: !this.state.imagePickerOpen,
    });
  };

  private togglePagePicker = () => {
    this.setState({
      pagePickerOpen: !this.state.pagePickerOpen,
    });
  };

  private toggleScrollingPagePicker = () => {
    this.setState({
      scrollingPagePickerOpen: !this.state.scrollingPagePickerOpen,
    });
  };

  private handleImageSelected = (
    id: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          imageId: id,
          crop: {
            x,
            y,
            width,
            height,
          },
        },
        image: this.props.images.find((x) => x.id === id) || null,
      },
      () => this.validate(true)
    );

    this.toggleImagePicker();
  };

  private handlePageSelected = (id: number) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          targetId: id,
          targetScrollingId: null,
        },
        page: this.props.pages.find((x) => x.id === id) || null,
        scrollingPage: null,
      },
      () => this.validate(true)
    );

    this.togglePagePicker();
  };

  private handleScrollingPageSelected = (id: number) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          targetScrollingId: id,
          targetId: null,
        },
        scrollingPage:
          this.props.scrollingPages.find((x) => x.id === id) || null,
        page: null,
      },
      () => this.validate(true)
    );

    this.toggleScrollingPagePicker();
  };

  private handleTitleChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          title: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private handleSubTitleChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          subTitle: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private handleImageSubTitleChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          imageSubTitle: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private handleDetailsChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          details: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private isValid = () => {
    return this.validate();
  };

  private validate = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (
      this.state.payload.targetId == null &&
      this.state.payload.targetScrollingId == null
    ) {
      validationErrors.push(
        "A hivatkozni kívánt céloldal kötelezően választandó!"
      );
    }

    if (
      this.state.payload.targetId != null &&
      this.state.payload.targetScrollingId != null
    ) {
      validationErrors.push(
        "A hivatkozni kívánt céloldal kötelezően választandó!"
      );
    }

    if (isNaN(this.state.payload.imageId) || this.state.payload.imageId === 0) {
      validationErrors.push("A kép kiválasztása megadása kötelező.");
    }

    if (this.state.payload.title.length === 0) {
      validationErrors.push("A cím megadása kötelező.");
    }

    if (this.state.payload.title.length > 31) {
      validationErrors.push("A cím túl hosszú.");
    }

    if (this.state.payload.title.length < 2) {
      validationErrors.push("A cím túl rövid.");
    }

    if (this.state.payload.subTitle.length === 0) {
      validationErrors.push("Az alcím megadása kötelező.");
    }

    if (this.state.payload.subTitle.length > 31) {
      validationErrors.push("Az alcím túl hosszú.");
    }

    if (this.state.payload.subTitle.length < 2) {
      validationErrors.push("Az alcím túl rövid.");
    }

    if (this.state.payload.imageSubTitle.length === 0) {
      validationErrors.push("A kép alatti szöveg megadása kötelező.");
    }

    if (this.state.payload.imageSubTitle.length > 70) {
      validationErrors.push("A kép alatti szöveg túl hosszú.");
    }

    if (this.state.payload.imageSubTitle.length < 30) {
      validationErrors.push("A kép alatti szöveg túl rövid.");
    }

    if (this.state.payload.details.length === 0) {
      validationErrors.push("A részletes leírás megadása kötelező.");
    }

    if (this.state.payload.details.length > 200) {
      validationErrors.push("A részletes leírás túl hosszú.");
    }

    if (this.state.payload.details.length < 40) {
      validationErrors.push("A részletes leírás túl rövid.");
    }

    if (shouldSetState) {
      this.setState({
        validationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };
}

export default PromotedServiceEditor;
