import * as React from 'react';
import { CurrentOfferDto } from '../models/CurrentOfferDto';
import CurrentOfferRow from './CurrentOfferRow';
import { Helmet } from "react-helmet";
import { AdminEditorBadgePosition } from './admin/AdminEditorBadge';
import AdminEditorBadge from './admin/AdminEditorBadge';
import { isMobileOnly } from 'react-device-detect';

interface CurrentOffersProps {
    currentOffers: CurrentOfferDto[];
    isAdminAndEditing: boolean;
    toggleEditor: () => void;
}

class CurrentOffers extends React.PureComponent<CurrentOffersProps> {
    public render() {
        const offers = this.props.currentOffers;

        let threeColumnView: boolean = false;
        if (offers.length % 4 === 0) {
            threeColumnView = false;
        } else if (offers.length % 3 === 0) {
            threeColumnView = true;
        } else if (offers.length % 4 === 3) {
            threeColumnView = false;
        } else if (offers.length % 3 === 2) {
            threeColumnView = true;
        } else if (offers.length % 4 === 2) {
            threeColumnView = false;
        } else {
            threeColumnView = false;
        }

        const offersPerRow = threeColumnView ? 3 : 4;

        const groupedOffers = offers.reduce((resultArray: any[], item, index) => {
            const chunkIndex = Math.floor(index / offersPerRow)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []);

        if (offers.length !== 10 || isMobileOnly) {
            if (offers.length % 4 === 0) {
            } else if (offers.length % 3 === 0) {
            } else if (offers.length % 4 === 3) {
                groupedOffers[groupedOffers.length - 1].push(null);
            } else if (offers.length % 3 === 2) {
                groupedOffers[groupedOffers.length - 1].push(null);
            } else if (offers.length % 4 === 2) {
                groupedOffers[groupedOffers.length - 1].push(null);
                groupedOffers[groupedOffers.length - 1].push(null);
            }
        }

        if (offers.length === 10 && !isMobileOnly) {
            groupedOffers[1].splice(1, 0, undefined);
            groupedOffers[1].splice(1, 0, undefined);

            groupedOffers[2].splice(0, 0, groupedOffers[1][5]);
            groupedOffers[2].splice(0, 0, groupedOffers[1][4]);

            groupedOffers[1] = groupedOffers[1].slice(0, 4);
        }

        return (
            <React.Fragment>
                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(232,232,232,0.2)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                    <div className="fusion-builder-row fusion-row">
                        <div className="fusion-fullwidth fullwidth-box fusion-builder-row-13 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '50px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                            <div className="fusion-builder-row fusion-row">
                                <div className="fusion-layout-column fusion_builder_column fusion-builder-column-39 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: '0px', marginBottom: '30px', paddingTop: '40px' }}>
                                    <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                        {this.props.isAdminAndEditing && <AdminEditorBadge position={AdminEditorBadgePosition.TopRight} onClick={this.props.toggleEditor} />}
                                        <div className="fusion-text fusion-text-19">
                                            <h1 style={{ textAlign: 'center' }}>Bőrproblémák kezelése</h1>
                                        </div>
                                        <div className="fusion-sep-clear" />
                                        <div className="fusion-separator" style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', maxWidth: '500px' }}>
                                        </div>
                                        <div className="fusion-sep-clear" />
                                        {groupedOffers.map((offerGroup, index) => {
                                            return (
                                                <CurrentOfferRow
                                                    key={index}
                                                    currentOffers={offerGroup}
                                                    threeColumnView={threeColumnView}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Helmet>
                    <script type='text/javascript'
                        src='/wp-content/plugins/fusion-builder/assets/js/min/general/fusion-content-boxes.js?ver=1'
                        id='fusion-content-boxes-js'></script>
                </Helmet>
            </React.Fragment>
        );
    }
}

export default CurrentOffers;
