import * as React from 'react';
import { Link } from 'react-router-dom';
import { CouponDto } from '../../../models';

interface CouponCardProps {
    coupon: CouponDto;
}

interface CouponCardState {
}

class CouponCard extends React.PureComponent<CouponCardProps, CouponCardState> {
    constructor(props: CouponCardProps) {
        super(props);
    }

    private dateFormatter = new Intl.DateTimeFormat("hu-HU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: undefined,
        minute: undefined
    });

    public render() {
        return (
            <React.Fragment>
                <div className="list-group-item d-flex justify-content-between">
                    <div className="coupon-admin-email-column">{this.props.coupon.sentTo}</div>
                    <div className="coupon-admin-code-column">{this.props.coupon.code}</div>
                    <div className="coupon-admin-expiration-column">{this.dateFormatter.format(this.props.coupon.expiresAt)}</div>
                    <div className="coupon-admin-order-column">{this.props.coupon.orderId == null ? "(még nem használta fel)" : <Link to={`/shop/order/${this.props.coupon.orderId}`}>Rendelés #{this.props.coupon.orderId}</Link>}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default CouponCard;
