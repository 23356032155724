import * as React from 'react';
import { PromotedServiceDto } from '../models/PromotedServiceDto';
import PromotedServiceCard from './PromotedServiceCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './PromotedServices.css';
import { isMobileOnly } from "react-device-detect";

interface PromotedServiceRowProps {
    promotedServices: PromotedServiceDto[];
}

interface PromotedServiceRowState {
    currentIndex: number;
    splideTransitionInProgress: boolean;
}

const splideTransitionDuration = 1500; // this only goes for the sliding transition, for height & background animation see css

const splideOptions: any = {
    type: "slide",
    perPage: isMobileOnly ? 1 : 3,
    perMove: 1,
    start: isMobileOnly ? 0 : 1,
    focus: "center",
    updateOnMove: true,
    drag: false,
    speed: splideTransitionDuration
};

class PromotedServiceRow extends React.PureComponent<PromotedServiceRowProps, PromotedServiceRowState> {
    constructor(props: PromotedServiceRowProps) {
        super(props);

        this.state = {
            currentIndex: isMobileOnly ? 0 : 1,
            splideTransitionInProgress: false
        };
    }

    private splide: Splide | null = null;

    private minIndex = isMobileOnly ? 0 : 1;
    private maxIndex = isMobileOnly ? this.props.promotedServices.length - 1 : this.props.promotedServices.length - 2;

    public render() {

        return (
            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(232,232,232,0.2)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                <div className="fusion-builder-row fusion-row">
                    <span style={{ position: "absolute", left: isMobileOnly ? "-25px" : "-50px", top: "calc(50% - 50px)", fontSize: "40px", fontWeight: 900, cursor: this.state.currentIndex > this.minIndex ? "pointer" : "not-allowed", color: this.state.currentIndex > this.minIndex ? "inherit" : "lightgray" }} onClick={this.handlePreviousClick}>
                        <FontAwesomeIcon icon={["fas", "angle-left"]} className="mr-2" />
                    </span>
                    <Splide ref={node => this.splide = node} options={splideOptions}>
                        {this.props.promotedServices.map((promotedService, index) => {
                            return (
                                <SplideSlide key={index}>
                                    <PromotedServiceCard
                                        promotedService={promotedService}
                                    />
                                </SplideSlide>
                            );
                        })}
                    </Splide>
                    <span style={{ position: "absolute", right: isMobileOnly ? "-35px" : "-50px", top: "calc(50% - 50px)", fontSize: "40px", fontWeight: 900, cursor: this.state.currentIndex < this.maxIndex ? "pointer" : "not-allowed", color: this.state.currentIndex < this.maxIndex ? "inherit" : "lightgray" }} onClick={this.handleNextClick}>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} className="mr-2" />
                    </span>
                    {this.props.promotedServices.map((promotedService, index) => {
                        return (
                            <link key={index} rel="preload" href={`/wp-content/uploads/new/${promotedService.image.name}`} as="image" />
                        );
                    })}
                </div>
            </div>
        );
    }

    private handlePreviousClick = () => {
        if (this.state.currentIndex > this.minIndex && !this.state.splideTransitionInProgress) {
            this.setState({
                currentIndex: this.state.currentIndex - 1,
                splideTransitionInProgress: true
            }, () => {
                if (this.splide != null) this.splide.go(this.state.currentIndex);
                window.setTimeout(() => {
                    this.setState({
                        splideTransitionInProgress: false
                    });
                }, splideTransitionDuration);
            });
        }
    }

    private handleNextClick = () => {
        if (this.state.currentIndex < this.maxIndex && !this.state.splideTransitionInProgress) {
            this.setState({
                currentIndex: this.state.currentIndex + 1,
                splideTransitionInProgress: true
            }, () => {
                if (this.splide != null) this.splide.go(this.state.currentIndex);
                window.setTimeout(() => {
                    this.setState({
                        splideTransitionInProgress: false
                    });
                }, splideTransitionDuration);
            });
        }
    }
}

export default PromotedServiceRow;
