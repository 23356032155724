import * as React from 'react';
import { CurrentOfferDto } from "../../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface CurrentOfferEditorCardProps {
    item: CurrentOfferDto;
    sharedProps?: {
        onImageClick?: (name: string) => void;
        onDeleteClick?: (id: number) => void;
        onEditClick?: (id: number) => void;
    }
}

interface CurrentOfferEditorCardState {
    deleteModalOpen: boolean;
}

export class CurrentOfferEditorCard extends React.PureComponent<CurrentOfferEditorCardProps, CurrentOfferEditorCardState> {
    constructor(props: CurrentOfferEditorCardProps) {
        super(props);

        this.state = {
            deleteModalOpen: false
        };
    }

    public render() {
        return (
            <div style={{ maxWidth: "250px", minWidth: "250px", margin: "12px" }}>
                <div style={{ height: "200px", minHeight: "200px", maxHeight: "200px" }}>
                    <img alt="" onClick={() => this.props.sharedProps != null && this.props.sharedProps.onImageClick != null && this.props.sharedProps.onImageClick(this.props.item.image.name)} style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "contain", overflow: "hidden" }} src={`/wp-content/uploads/new/${this.props.item.image.name}`} />
                </div>
                <div>
                    {this.props.item.title}
                </div>
                <div className="d-flex justify-content-between">
                    {this.props.sharedProps != null && this.props.sharedProps.onEditClick != null && <div>
                        <Button onClick={() => this.props.sharedProps!!.onEditClick!!(this.props.item.id)} color="primary">
                            <FontAwesomeIcon icon={["fas", "pencil-alt"]} className="mr-1" /> Szerkesztés
                        </Button>
                    </div>}
                    {this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null && <div>
                        <Button onClick={this.handleDeleteClick} color="danger">
                            <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                        </Button>
                    </div>}
                </div>
                <Modal isOpen={this.state.deleteModalOpen} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                    <ModalHeader toggle={this.handleCancelDeleteClick}>Bőrproblémák kezelése törlése</ModalHeader>
                    <ModalBody>
                        <div>
                            <p>
                                Biztosan törölni szeretnéd a bőrproblémák kezelését?
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-primary">
                                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                            </button>

                            <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    private handleDeleteClick = () => {
        this.setState({
            deleteModalOpen: true
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteModalOpen: false
        });
    }

    private handleConfirmDeleteClick = () => {
        if (this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null) {
            this.props.sharedProps.onDeleteClick(this.props.item.id);
            this.setState({
                deleteModalOpen: false
            });
        }
    }
}

export default CurrentOfferEditorCard;
