import * as React from 'react';
import MainComponent from '../MainComponent';

export class MainComponentEditor extends React.PureComponent {
    public render() {
        return (
            <MainComponent editing={true} />
        );
    }
}

export default MainComponentEditor;
