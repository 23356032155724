import * as React from 'react';
import { Card, Modal, ModalHeader, ModalBody } from "reactstrap";
import { CustomerReviewDto } from "../../models";
import { CustomerReviewUpdatePayload } from "../../payloads";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomerReviewEditorModalProps {
    customerReviews: CustomerReviewDto[];
    isOpen: boolean;
    toggle: () => void;
    save: (id: number, payload: CustomerReviewUpdatePayload) => void;
}

interface CustomerReviewEditorModalState {
    customerReviews: CustomerReviewDto[];
    validationErrors: string[];
}

export class CustomerReviewEditorModal extends React.PureComponent<CustomerReviewEditorModalProps, CustomerReviewEditorModalState> {
    constructor(props: CustomerReviewEditorModalProps) {
        super(props);

        this.state = {
            customerReviews: props.customerReviews.slice(),
            validationErrors: []
        };
    }

    componentWillReceiveProps(nextProps: CustomerReviewEditorModalProps) {
        this.setState({
            customerReviews: nextProps.customerReviews.slice()
        });
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={this.props.toggle}>Értékelések szerkesztése</ModalHeader>
                    <ModalBody>
                        <div>
                            {this.state.customerReviews.map((customerReview, index) => {
                                return (
                                    <div key={index}>
                                        <div className="form-group">
                                            <label htmlFor={`inputCustomerReviewAuthor${index}`}>Szerző</label>
                                            <input type="text" className="form-control" id={`inputCustomerReviewAuthor${index}`} value={customerReview.author} onChange={(e: any) => this.handleCustomerReviewAuthorChange(index, e)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor={`inputCustomerReviewText${index}`}>Értékelés</label>
                                            <textarea rows={5} className="form-control" id={`inputCustomerReviewText${index}`} value={customerReview.text} onChange={(e: any) => this.handleCustomerReviewTextChange(index, e)} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {this.state.validationErrors.length > 0 &&
                            <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                                {this.state.validationErrors.map((error, index) => {
                                    return (
                                        <div key={index} className="form-invalid">
                                            {error}
                                        </div>
                                    );
                                })}
                            </Card>
                        }

                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleSaveCustomerReviewsClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                            </button>

                            <button className="btn btn-secondary" onClick={this.props.toggle}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    private handleSaveCustomerReviewsClick = () => {
        for (const customerReview of this.state.customerReviews) {
            const payload: CustomerReviewUpdatePayload = {
                author: customerReview.author,
                text: customerReview.text
            };

            this.props.save(customerReview.id, payload);
        }

        this.props.toggle();
    }

    private handleCustomerReviewAuthorChange = (index: number, e: any) => {
        this.setState({
            customerReviews: this.state.customerReviews.map((customerReview, i) => {
                return index === i
                    ? { ...this.state.customerReviews[i], author: e.target.value }
                    : customerReview
                    ;
            })
        }, () => this.validate(true));
    }

    private handleCustomerReviewTextChange = (index: number, e: any) => {
        this.setState({
            customerReviews: this.state.customerReviews.map((customerReview, i) => {
                return index === i
                    ? { ...this.state.customerReviews[i], text: e.target.value }
                    : customerReview
                    ;
            })
        }, () => this.validate(true));
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.customerReviews.find(x => x.text.length === 0) != null) {
            validationErrors.push("Az értékelés megadása kötelező.");
        }

        if (this.state.customerReviews.find(x => x.text.length < 6) != null) {
            validationErrors.push("Az értékelés túl rövid.");
        }

        if (this.state.customerReviews.find(x => x.text.length > 187) != null) {
            validationErrors.push("Az értékelés túl hosszú.");
        }

        if (this.state.customerReviews.find(x => x.author.length === 0) != null) {
            validationErrors.push("A szerző megadása kötelező.");
        }

        if (this.state.customerReviews.find(x => x.author.length < 7) != null) {
            validationErrors.push("A szerző túl rövid.");
        }

        if (this.state.customerReviews.find(x => x.author.length > 32) != null) {
            validationErrors.push("A szerző túl hosszú.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default CustomerReviewEditorModal;
