import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductDto } from '../../models/shop/ProductDto';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import NavMenuShop from './NavMenuShop';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';
import { Constants } from '../../utils';
import { CartType, ProductOptionDto } from '../../models/shop';
import { CartItemDto } from '../../models';
import { isMobileOnly } from 'react-device-detect';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

type ProductDetailsProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ id?: string }>;

interface ProductDetailsState {
    product: ProductDto | null;
    option: ProductOptionDto | null;
    gifteeName: string;
    cartType: CartType;
    addToCartResult: boolean | null;
    errorMessage: string;
}

class ProductDetails extends React.PureComponent<ProductDetailsProps, ProductDetailsState> {
    private longDescriptionDivRef: HTMLDivElement | null = null;

    constructor(props: ProductDetailsProps) {
        super(props);

        this.state = {
            product: null,
            option: null,
            gifteeName: "",
            cartType: props.userReceived && props.loggedInUser != null ? CartType.Server : CartType.Client,
            addToCartResult: null,
            errorMessage: ""
        }
    }

    public componentDidMount() {
        if (this.props.productCategories.length > 0) {
            const id = this.props.match.params.id;
            const idAsNumber = Number(id);
            if (!isNaN(idAsNumber) && idAsNumber > 0) {
                const category = this.props.productCategories.find(x => x.products.find(y => y.id === idAsNumber) != null && x.isVisible);

                if (category == null) {
                    window.location.href = "/shop";
                }
                else {
                    const product = category.products.find(x => x.id === idAsNumber && x.isVisible);

                    if (product == null) {
                        window.location.href = "/shop";
                    }
                    else {
                        this.setState({
                            product: product,
                            option: product.options.length > 0 ? product.options[0] : null
                        });
                    }
                }
            }
        }
    }

    componentWillReceiveProps(nextProps: ProductDetailsProps) {
        if (this.props.productCategories.length !== nextProps.productCategories.length || this.props.match.params.id !== nextProps.match.params.id) {
            const id = nextProps.match.params.id;
            const idAsNumber = Number(id);
            if (!isNaN(idAsNumber) && idAsNumber > 0) {
                const category = nextProps.productCategories.find(x => x.products.find(y => y.id === idAsNumber) != null && x.isVisible);

                if (category == null) {
                    window.location.href = "/shop";
                }
                else {
                    const product = category.products.find(x => x.id === idAsNumber && x.isVisible);

                    if (product == null) {
                        window.location.href = "/shop";
                    }
                    else {
                        this.setState({
                            product: product,
                            option: product.options.length > 0 ? product.options[0] : null
                        });
                    }
                }
            }
        }

        if (!this.props.userReceived && nextProps.userReceived) {
            if (nextProps.loggedInUser != null) {
                this.setState({
                    cartType: CartType.Server
                });
            }
        }
    }

    private currencyFormatter = new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    public render() {
        require("../../shop.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                {this.state.product != null && <div className="product-details">
                    <div className="product-details-header">
                        <div className="product-details-image-wrapper">
                            <img src={`/wp-content/uploads/new/${this.state.product.headerImage.name}`} />
                        </div>
                        <div className="product-name">
                            <h1 className="d-flex align-items-cemter">
                                <Link to="/shop">
                                    <FontAwesomeIcon icon={["fas", "angle-left"]} className="mr-3" />
                                </Link>
                                {this.state.product.name}
                            </h1>
                        </div>
                    </div>

                    <div className="product-details-info">
                        <div className="product-details-left">
                            <div className="product-image-wrapper">
                                <div className="product-image-slider">
                                    <div className="product-image-item product-image-slider-item">
                                        <div className="product-image-item-container">
                                            <img src={`/wp-content/uploads/new/${this.state.product.image.name}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-details-right">
                            <div className="product-details-data">
                                <div className="product-description" style={{ paddingBottom: isMobileOnly && this.state.product.shortDescription.length >= Constants.productShortDescriptionMaxLength ? "35px" : "10px" }}>
                                    <h2 className="description-header">Leírás</h2>
                                    <div className="description">
                                        {this.state.product.shortDescription.split("\n").map((row, index) => <React.Fragment key={index}>{row}{index < this.state.product!!.shortDescription.split("\n").length - 1 ? <br /> : ""}</React.Fragment>)}
                                        {this.state.product.shortDescription.length >= Constants.productShortDescriptionMaxLength && "..."}
                                    </div>
                                    {this.state.product.longDescription.length > 0 &&
                                        <div className="product-more-info-action-button-container" onClick={this.handleExpandDetailsClick}>
                                            Részletes leírás &gt;&gt;&gt;
                                        </div>
                                    }
                                </div>
                                <div className="buy-info">
                                    <h2 className="buy-info-header">Megrendelés </h2>
                                    <div className="buy-form">
                                        <table>
                                            {this.state.product.hasOptions && <tr>
                                                <td>
                                                    Hossz:
                                                </td>
                                                <td>
                                                    <select value={this.state.option != null ? this.state.option.id : 0} onChange={this.handleOptionChange}>
                                                        {this.state.product.options.filter(x => x.isVisible).map((option, index) => {
                                                            return (
                                                                <option key={index} value={option.id}>{option.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>}
                                            <tr>
                                                <td>
                                                    Ajándékozott neve:
                                                </td>
                                                <td>
                                                    <input type="text" value={this.state.gifteeName} onChange={this.handleGifteeNameChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Ár:
                                                </td>
                                                <td>
                                                    {this.state.product.hasOptions && this.state.option != null && this.currencyFormatter.format(this.state.option.price)}
                                                    {!this.state.product.hasOptions && this.currencyFormatter.format(this.state.product.price!!)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className="action">
                                                    <button className="buy-button" onClick={this.handleAddToCartClick}>
                                                        <FontAwesomeIcon icon={["fas", "shopping-cart"]} className="mr-1" />
                                                        <span>Kosárba</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.product.longDescription.length > 0 && <div className="product-expanded-details-container mt-3 p-3 mb-3 text-justify" ref={node => this.longDescriptionDivRef = node}>
                        {this.state.product.longDescription.split("\n").map((row, index) => <React.Fragment key={index}>{row}{index < this.state.product!!.longDescription.split("\n").length - 1 ? <br /> : ""}</React.Fragment>)}
                    </div>}
                </div>}
                {this.getAddToCartResultModal()}
                <Footer />
            </React.Fragment>
        );
    }

    private getAddToCartResultModal = () => {
        return (
            <Modal isOpen={this.state.addToCartResult != null} toggle={this.toggleAddToCartResultModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.toggleAddToCartResultModal}>Termék hozzáadása kosárhoz</ModalHeader>
                <ModalBody>
                    {this.state.addToCartResult === true && <p>
                        A termék bekerült a kosárba.
                    </p>}
                    {this.state.addToCartResult === false && <p>
                        Hiba: {this.state.errorMessage}
                    </p>}
                    <div className="d-flex justify-content-end align-items-center mt-4">
                        <button className="btn btn-primary" onClick={this.toggleAddToCartResultModal}>
                            <FontAwesomeIcon icon={["fas", "check"]} /> OK
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private toggleAddToCartResultModal = () => {
        this.setState({
            addToCartResult: null,
            errorMessage: ""
        });
    }

    private handleAddToCartClick = () => {
        if (this.state.gifteeName.length === 0) {
            this.setState({
                addToCartResult: false,
                errorMessage: "Az ajándékozott nevét kötelező megadni!"
            });
        } else if (this.state.gifteeName.length > 20) {
            this.setState({
                addToCartResult: false,
                errorMessage: "Az ajándékozott neve túl hosszú (maximum 20 karakter)!"
            });
        } else {
            this.setState({
                addToCartResult: true,
                errorMessage: ""
            });

            if (this.state.cartType === CartType.Server) {
                this.props.addProductToMyCart(this.state.option == null ? this.state.product!!.id : null, this.state.option != null ? this.state.option!!.id : null, this.state.gifteeName);
            } else if (this.state.cartType === CartType.Client) {
                const newCartItem: CartItemDto = {
                    id: 0,
                    product: this.state.product == null ? null : (this.state.product.hasOptions ? null : this.state.product),
                    productOption: this.state.option,
                    gifteeName: this.state.gifteeName,
                    quantity: 1
                };

                const cartFromLocalStorage = localStorage.getItem(Constants.webshopClientCartLocalStorageKey);
                const cartItemsParsed = (cartFromLocalStorage == null ? [] : JSON.parse(cartFromLocalStorage) as CartItemDto[]).concat([newCartItem]);

                localStorage.setItem(Constants.webshopClientCartLocalStorageKey, JSON.stringify(cartItemsParsed));
                this.props.setCartItemsRaw(cartItemsParsed);
            }

            this.setState({
                gifteeName: ""
            });
        }
    }

    private handleOptionChange = (e: any) => {
        this.setState({
            option: this.state.product!!.options.find(x => x.id == e.target.value)!!
        });
    }

    private handleExpandDetailsClick = () => {
        if (this.longDescriptionDivRef != null) {
            this.longDescriptionDivRef.scrollIntoView({ behavior: "smooth", block: isMobileOnly ? "start" : "end", inline: "nearest" });
        }
    }

    private handleGifteeNameChange = (e: any) => {
        this.setState({
            gifteeName: e.target.value
        });
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(ProductDetails as any);

