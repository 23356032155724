import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ImageWithTextSideBySideDto } from '../models';
import AdminEditorBadge, { AdminEditorBadgePosition } from "./admin/AdminEditorBadge";

export enum ImagePosition {
    Left,
    Right
}

interface ImageWithTextSideBySideItemProps {
    item: ImageWithTextSideBySideDto;
    imagePosition: ImagePosition;
    isVisible: boolean;
    marginTop?: string;
    marginBottom?: string;
    isAdminAndEditing?: boolean;
    toggleEditor?: () => void;
}

export class ImageWithTextSideBySideItem extends React.PureComponent<ImageWithTextSideBySideItemProps> {
    public render() {
        return isMobileOnly || this.props.imagePosition === ImagePosition.Left ?
            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-5 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid', marginTop: this.props.marginTop || "", marginBottom: this.props.marginBottom || "" }}>
                <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start" style={{ maxWidth: '1216.8px', marginLeft: 'calc(-4% / 2 )', marginRight: 'calc(-4% / 2 )' }}>
                    {this.props.isAdminAndEditing && this.props.toggleEditor && <AdminEditorBadge position={AdminEditorBadgePosition.TopRight} onClick={this.props.toggleEditor} />}
                    <div className={`fusion-layout-column fusion_builder_column fusion_builder_column_1_3 1_3 fusion-flex-column ${!this.props.isVisible && this.props.isAdminAndEditing ? "admin-editing-blurred" : ""}`}>
                        <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "24px", marginRight: "24px", flexDirection: isMobileOnly ? "column" : "row" }}>
                                <div style={{ marginRight: "24px", minWidth: isMobileOnly ? "80%" : "40%", maxWidth: isMobileOnly ? "80%" : "40%", marginBottom: isMobileOnly ? "16px" : 0 }}>
                                    <span className=" fusion-imageframe imageframe-dropshadow imageframe-2 hover-type-zoomin" style={{ WebkitBoxShadow: '3px 3px 7px rgba(0,0,0,0.3)', boxShadow: '3px 3px 7px rgba(0,0,0,0.3)' }}>
                                        <a href={`/wp-content/uploads/new/${this.props.item.image.name}`} className="fusion-lightbox" data-rel={`iLightbox[9dcf9ae6c2d42b0001d${this.props.item.image.id}]`}>
                                            <img width={2560} height={1760} alt="" src={`/wp-content/uploads/new/${this.props.item.image.name}`} className="img-responsive" srcSet={`/wp-content/uploads/new/${this.props.item.image.name} 200w, /wp-content/uploads/new/${this.props.item.image.name} 400w, /wp-content/uploads/new/${this.props.item.image.name} 600w, /wp-content/uploads/new/${this.props.item.image.name} 800w, /wp-content/uploads/new/${this.props.item.image.name} 1200w, /wp-content/uploads/new/${this.props.item.image.name} 2560w`} sizes="(max-width: 1024px) 100vw, (max-width: 640px) 100vw, 400px" />
                                        </a>
                                    </span>
                                </div>
                                <div className="standard-text oxy-text-justified">
                                    <h4 dangerouslySetInnerHTML={{ __html: this.props.item.text }} />
                                    {this.props.item.scrollingPage != null &&
                                        <React.Fragment>
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t" }} />
                                            <a href={`/oldal/${this.props.item.scrollingPage.path}`} style={{ marginInline: '70px' }} className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no">
                                                <span className="fusion-button-text">
                                                    Az ajánlott kezelés megtekintéséhez kattints ide!
                                                </span>
                                            </a>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\twidth: 33.333333333333% !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 30px;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-right: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-left: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:1024px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:640px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t" }} />
                </div>
                <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-flex-container.fusion-builder-row-5 {\n\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-right: 30px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-left: 30px;\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t" }} />
            </div>
            :
            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-5 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid', marginTop: this.props.marginTop || "" }}>
                <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start" style={{ maxWidth: '1216.8px', marginLeft: 'calc(-4% / 2 )', marginRight: 'calc(-4% / 2 )' }}>
                    <div className={`fusion-layout-column fusion_builder_column fusion_builder_column_1_3 1_3 fusion-flex-column ${!this.props.isVisible && this.props.isAdminAndEditing ? "admin-editing-blurred" : ""}`}>
                        <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "24px", marginRight: "24px" }}>
                                <div className="standard-text oxy-text-justified">
                                    <h4 dangerouslySetInnerHTML={{ __html: this.props.item.text }} />
                                    {this.props.item.scrollingPage != null &&
                                        <React.Fragment>
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t" }} />
                                            <a href={`/oldal/${this.props.item.scrollingPage.path}`} style={{ marginInline: '70px' }} className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no">
                                                <span className="fusion-button-text">
                                                    Az ajánlott kezelés megtekintéséhez kattints ide!
                                                </span>
                                            </a>
                                        </React.Fragment>
                                    }
                                </div>
                                <div style={{ marginLeft: "24px", minWidth: "40%", maxWidth: "40%" }}>
                                    <span className=" fusion-imageframe imageframe-dropshadow imageframe-3 hover-type-zoomin" style={{ WebkitBoxShadow: '3px 3px 7px rgba(0,0,0,0.3)', boxShadow: '3px 3px 7px rgba(0,0,0,0.3)' }}>
                                        <a href={`/wp-content/uploads/new/${this.props.item.image.name}`} className="fusion-lightbox" data-rel={`iLightbox[5ad9ddcb83e4c2f8a6c]${this.props.item.image.id}`}>
                                            <img width={2000} height={1202} alt="" src={`/wp-content/uploads/new/${this.props.item.image.name}`} className="img-responsive" srcSet={`/wp-content/uploads/new/${this.props.item.image.name} 200w, /wp-content/uploads/new/${this.props.item.image.name} 400w, /wp-content/uploads/new/${this.props.item.image.name} 600w, /wp-content/uploads/new/${this.props.item.image.name} 800w, /wp-content/uploads/new/${this.props.item.image.name} 1200w, /wp-content/new/${this.props.item.image.name} 2000w`} sizes="(max-width: 1024px) 100vw, (max-width: 640px) 100vw, 400px" />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\twidth: 33.333333333333% !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 30px;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-right: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-left: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:1024px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:640px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t" }} />
                </div>
                <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-flex-container.fusion-builder-row-5 {\n\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-right: 30px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-left: 30px;\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t" }} />
            </div>
            ;
    }
}

export default ImageWithTextSideBySideItem;
