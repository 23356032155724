import { ProtectedRestApi } from "./";
import { ServiceResult, MenuItemDto } from "../models";
import { PricingMenuItemPayload, ReorderPayload, ServicesMenuItemPayload, SkinProblemsMenuItemPayload, WebshopMenuItemPayload } from "../payloads";

export class MenuApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxymenu";

    public getServicesMenu(): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpGet(`${MenuApi.baseUrl}/services`, true) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public getPricingMenu(): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpGet(`${MenuApi.baseUrl}/pricing`, true) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public getWebshopMenu(): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpGet(`${MenuApi.baseUrl}/webshop`, true) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public getSkinProblemsMenu(): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpGet(`${MenuApi.baseUrl}/skin-problems`, true) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public addServicesParentMenuItem(title: string): Promise<ServiceResult<MenuItemDto>> {
        return this.httpPost(`${MenuApi.baseUrl}/services/add-parent`, title) as Promise<ServiceResult<MenuItemDto>>;
    }

    public addServicesLeafMenuItem(payload: ServicesMenuItemPayload): Promise<ServiceResult<MenuItemDto>> {
        return this.httpPost(`${MenuApi.baseUrl}/services/add-leaf`, payload) as Promise<ServiceResult<MenuItemDto>>;
    }

    public deleteServicesMenuItem(id: number): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpDelete(`${MenuApi.baseUrl}/services/${id}`) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public reorderServicesParentMenuItems(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${MenuApi.baseUrl}/services/reorder-parent`, payload) as Promise<ServiceResult<boolean>>;
    }

    public reorderServicesLeafMenuItems(parentId: number, payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${MenuApi.baseUrl}/services/reorder-leaf/${parentId}`, payload) as Promise<ServiceResult<boolean>>;
    }

    public addPricingMenuItem(payload: PricingMenuItemPayload): Promise<ServiceResult<MenuItemDto>> {
        return this.httpPost(`${MenuApi.baseUrl}/pricing/add`, payload) as Promise<ServiceResult<MenuItemDto>>;
    }

    public deletePricingMenuItem(id: number): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpDelete(`${MenuApi.baseUrl}/pricing/${id}`) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public reorderPricingMenuItems(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${MenuApi.baseUrl}/pricing/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }

    public addWebshopMenuItem(payload: WebshopMenuItemPayload): Promise<ServiceResult<MenuItemDto>> {
        return this.httpPost(`${MenuApi.baseUrl}/webshop/add`, payload) as Promise<ServiceResult<MenuItemDto>>;
    }

    public deleteWebshopMenuItem(id: number): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpDelete(`${MenuApi.baseUrl}/webshop/${id}`) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public reorderWebshopMenuItems(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${MenuApi.baseUrl}/webshop/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }

    public addSkinProblemsMenuItem(payload: SkinProblemsMenuItemPayload): Promise<ServiceResult<MenuItemDto>> {
        return this.httpPost(`${MenuApi.baseUrl}/skin-problems/add`, payload) as Promise<ServiceResult<MenuItemDto>>;
    }

    public deleteSkinProblemsMenuItem(id: number): Promise<ServiceResult<MenuItemDto[]>> {
        return this.httpDelete(`${MenuApi.baseUrl}/skin-problems/${id}`) as Promise<ServiceResult<MenuItemDto[]>>;
    }

    public reorderSkinProblemsMenuItems(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${MenuApi.baseUrl}/skin-problems/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }
}
