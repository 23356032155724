import { ProtectedRestApi } from "./";
import { ServiceResult, PricingPageDto, PricingItemDto } from "../models";
import { PricingPageTextsPayload, PricingPageItemPayload, ReorderPayload } from "../payloads";

export class PricingPageApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxypricingpage";

    public getPricingPages(): Promise<ServiceResult<PricingPageDto[]>> {
        return this.httpGet(`${PricingPageApi.baseUrl}/all`) as Promise<ServiceResult<PricingPageDto[]>>;
    }

    public getPricingPageByPath(path: string): Promise<ServiceResult<PricingPageDto>> {
        return this.httpGet(`${PricingPageApi.baseUrl}/by-path/${path}`, true) as Promise<ServiceResult<PricingPageDto>>;
    }

    public updateTexts(pageId: number, payload: PricingPageTextsPayload): Promise<ServiceResult<PricingPageDto>> {
        return this.httpPost(`${PricingPageApi.baseUrl}/${pageId}/texts`, payload) as Promise<ServiceResult<PricingPageDto>>;
    }

    public addPricingItem(pricingPageId: number, payload: PricingPageItemPayload): Promise<ServiceResult<PricingItemDto>> {
        return this.httpPost(`${PricingPageApi.baseUrl}/${pricingPageId}/items`, payload) as Promise<ServiceResult<PricingItemDto>>;
    }

    public updatePricingItem(pricingPageId: number, pricingItemId: number, payload: PricingPageItemPayload): Promise<ServiceResult<PricingItemDto>> {
        return this.httpPost(`${PricingPageApi.baseUrl}/${pricingPageId}/items/${pricingItemId}`, payload) as Promise<ServiceResult<PricingItemDto>>;
    }

    public deletePricingItem(pricingPageId: number, pricingItemId: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${PricingPageApi.baseUrl}/${pricingPageId}/items/${pricingItemId}`) as Promise<ServiceResult<boolean>>;
    }

    public reorderPricingItems(pricingPageId: number, payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${PricingPageApi.baseUrl}/${pricingPageId}/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }

    public copyPricingPage(pageId: number, name: string, path: string): Promise<ServiceResult<PricingPageDto>> {
        return this.httpPost(`${PricingPageApi.baseUrl}/${pageId}/copy?name=${name}&path=${path}`) as Promise<ServiceResult<PricingPageDto>>;
    }

    public deletePricingPage(pricingPageId: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${PricingPageApi.baseUrl}/${pricingPageId}`) as Promise<ServiceResult<boolean>>;
    }
}
