import * as React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ImageDto } from "../../models";
import ReactCrop, { Crop } from 'react-image-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ImageCropperModalProps {
    isOpen: boolean;
    toggle: () => void;
    image: ImageDto | null;
    aspectRatio?: number;
    onConfirmCrop: (crop: Partial<Crop>) => void;
}

interface ImageCropperModalState {
    crop: Partial<Crop>;
}

export class ImageCropperModal extends React.PureComponent<ImageCropperModalProps, ImageCropperModalState> {
    private image: HTMLImageElement | null;

    constructor(props: ImageCropperModalProps) {
        super(props);

        this.state = {
            crop: {
                unit: "%",
                width: 50,
                height: props.aspectRatio == null ? 50 : undefined,
                aspect: props.aspectRatio
            }
        }

        this.image = null;
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={this.props.toggle}>Kép kivágása</ModalHeader>
                    <ModalBody>
                        {this.props.image != null && <ReactCrop
                            src={`/wp-content/uploads/new/${this.props.image.name}`}
                            crop={this.state.crop}
                            onChange={this.onChange}
                            onImageLoaded={this.onImageLoaded}
                        />}
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleConfirmCropClick} type="button" className="btn btn-primary">
                                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                            </button>

                            <button className="btn btn-secondary" onClick={this.props.toggle}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    private onImageLoaded = (image: HTMLImageElement) => {
        this.image = image;
    }

    private onChange = (crop: Partial<Crop>) => {
        this.setState({
            crop: crop
        });
    };

    private handleConfirmCropClick = () => {
        const image = this.image!!;
        const scale = (image.naturalWidth / image.width)
        this.props.onConfirmCrop({
            x: Math.floor(this.state.crop.x!! * scale),
            y: Math.floor(this.state.crop.y!! * scale),
            width: Math.floor(this.state.crop.width!! * scale),
            height: Math.floor(this.state.crop.height!! * scale),
        });
    }
}

export default ImageCropperModal;
