import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store/index";
import * as OxyStore from "../store/OxyStore";
import GalleryBigImage from "./GalleryBigImage";
import Gallery from "./Gallery";
import GalleryMobile from "./GalleryMobile";
import PromotedServices from "./PromotedServices";
import CurrentOffers from "./CurrentOffers";
import CustomerReviews from "./CustomerReviews";
import { GalleryEditorModal } from "./admin/GalleryEditorModal";
import { CustomerReviewEditorModal } from "./admin/CustomerReviewEditorModal";
import { PromotedServiceEditorModal } from "./admin/PromotedServiceEditorModal";
import { CurrentOfferEditorModal } from "./admin/CurrentOfferEditorModal";
import { NavMenu } from "./NavMenu";
import { Footer } from "./Footer";
import AdminEditorBadge, {
  AdminEditorBadgePosition,
} from "./admin/AdminEditorBadge";
import { Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AboutUsTextsPayload,
  SubscriptionPayload,
  VideoOverlayOpeningHoursPayload,
  VideoOverlayTitlesPayload,
} from "../payloads";
import NavMenuAdmin from "./admin/NavMenuAdmin";
import AdminBackButton from "./admin/AdminBackButton";
import ReactPlayer from "react-player/youtube";
import { isMobileOnly } from "react-device-detect";

interface MainComponentOwnProps {
  editing: boolean | null | undefined;
}

type MainComponentProps = OxyStore.OxyState &
  typeof OxyStore.actionCreators &
  RouteComponentProps<{}> &
  MainComponentOwnProps;

interface MainComponentState {
  aboutUsTextEditorOpen: boolean;
  aboutUsText: string;
  aboutUsSignatureText1: string;
  aboutUsSignatureText2: string;
  validationErrorsAboutUsText: string[];
  youtubeVideoIdEditorOpen: boolean;
  youtubeVideoId: string;
  validationErrorsYoutubeVideoId: string[];
  videoOverlayTitlesEditorOpen: boolean;
  videoOverlayMainTitle: string;
  videoOverlaySubTitle: string;
  videoOverlaySubText: string;
  validationErrorsVideoOverlayTitles: string[];
  videoOverlayDetailsEditorOpen: boolean;
  videoOverlayDetails: string;
  validationErrorsVideoOverlayDetails: string[];
  videoOverlayOpeningHoursEditorOpen: boolean;
  videoOverlayWeekdayOpeningHours: string;
  videoOverlayWeekendOpeningHours: string;
  validationErrorsVideoOverlayOpeningHours: string[];
  galleryEditorOpen: boolean;
  customerReviewEditorOpen: boolean;
  promotedServiceEditorOpen: boolean;
  currentOfferEditorOpen: boolean;
  adminEntitiesLoaded: boolean;
  youtubeBackgroundVideoWidth: number;
  youtubeBackgroundVideoHeight: number;
  lastName: string;
  firstName: string;
  email: string;
  acceptEula: boolean;
  subscriptionValidationErrors: string[];
}

class MainComponent extends React.PureComponent<
  MainComponentProps,
  MainComponentState
> {
  constructor(props: MainComponentProps) {
    super(props);

    const youtubeBackgroundVideoWidth = Math.max(
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
      965
    );
    const youtubeBackgroundVideoHeight = (youtubeBackgroundVideoWidth / 16) * 9;

    this.state = {
      aboutUsTextEditorOpen: false,
      aboutUsText: "",
      aboutUsSignatureText1: "",
      aboutUsSignatureText2: "",
      validationErrorsAboutUsText: [],
      youtubeVideoIdEditorOpen: false,
      youtubeVideoId: "",
      validationErrorsYoutubeVideoId: [],
      videoOverlayTitlesEditorOpen: false,
      videoOverlayMainTitle: "",
      videoOverlaySubTitle: "",
      videoOverlaySubText: "",
      validationErrorsVideoOverlayTitles: [],
      videoOverlayDetailsEditorOpen: false,
      videoOverlayDetails: "",
      validationErrorsVideoOverlayDetails: [],
      videoOverlayOpeningHoursEditorOpen: false,
      videoOverlayWeekdayOpeningHours: "",
      videoOverlayWeekendOpeningHours: "",
      validationErrorsVideoOverlayOpeningHours: [],
      galleryEditorOpen: false,
      customerReviewEditorOpen: false,
      promotedServiceEditorOpen: false,
      currentOfferEditorOpen: false,
      adminEntitiesLoaded: false,
      youtubeBackgroundVideoWidth: youtubeBackgroundVideoWidth,
      youtubeBackgroundVideoHeight: youtubeBackgroundVideoHeight,
      lastName: "",
      firstName: "",
      email: "",
      acceptEula: false,
      subscriptionValidationErrors: [],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.props.getMainPage();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentWillReceiveProps(nextProps: MainComponentProps) {
    if (nextProps.loggedInUser != null && !this.state.adminEntitiesLoaded) {
      this.setState(
        {
          adminEntitiesLoaded: true,
        },
        () => {
          const isAdmin =
            nextProps.loggedInUser != null && nextProps.loggedInUser.isAdmin;
          const isAdminAndEditing = this.props.editing && isAdmin;
          if (isAdminAndEditing) {
            this.props.getImages();
            this.props.getPages();
            this.props.getScrollingPages();
          }
        }
      );
    }
  }

  public render() {
    const user = this.props.loggedInUser;
    const isAdmin = user != null && user.isAdmin;
    const isAdminAndEditing = this.props.editing && isAdmin;

    return (
      <div id="boxed-wrapper">
        <div className="fusion-sides-frame"></div>
        <div id="wrapper" className="fusion-wrapper">
          <div id="home" style={{ position: "relative", top: "-1px" }}></div>
          <div id="sliders-container"></div>

          {!isAdminAndEditing && <NavMenu {...this.props} />}
          {isAdminAndEditing && <NavMenuAdmin />}
          {isAdminAndEditing && <AdminBackButton />}

          <div>
            <main id="main" className="clearfix width-100">
              <div className="fusion-row" style={{ maxWidth: "100%" }}>
                <section id="content" className="full-width">
                  <div
                    id="post-5"
                    className="post-5 page type-page status-publish hentry"
                  >
                    <div className="post-content">
                      <div
                        id="main-page-background-video-container"
                        className="fusion-fullwidth fullwidth-box fusion-builder-row-1 video-background nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                        style={{
                          backgroundColor: "rgb(177, 139, 94)",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          backgroundBlendMode: "overlay",
                          paddingTop: "0px",
                          paddingRight: "30px",
                          paddingBottom: "0px",
                          paddingLeft: "30px",
                          marginBottom: "0px",
                          borderWidth: "0px 0px 0px 0px",
                          borderColor: "#f7f7f7",
                          borderStyle: "solid",
                          overflow: "hidden",
                          height: isMobileOnly ? "250px" : "unset",
                        }}
                      >
                        <div
                          className="fusion-background-video-wrapper"
                          id="video-1"
                          data-mute="yes"
                          data-loop={1}
                          data-loop-adjustment
                          data-video-aspect-ratio="16:9"
                        >
                          <div
                            className="fusion-container-video-bg"
                            id="video-2-inner"
                          />
                          <div
                            className="fusion-video-cover"
                            style={{
                              zIndex: 0,
                              width: this.state.youtubeBackgroundVideoWidth,
                              height: this.state.youtubeBackgroundVideoHeight,
                              position: "absolute",
                            }}
                          >
                            &nbsp;
                          </div>
                          {this.props.mainPage != null && !isMobileOnly && (
                            <iframe
                              id="video-2-inner"
                              style={{
                                width: this.state.youtubeBackgroundVideoWidth,
                                height: this.state.youtubeBackgroundVideoHeight,
                                zIndex: -1,
                                visibility: "visible",
                              }}
                              className="fusion-container-video-bg"
                              width={this.state.youtubeBackgroundVideoWidth}
                              height={this.state.youtubeBackgroundVideoHeight}
                              src={`https://www.youtube-nocookie.com/embed/${this.props.mainPage.backgroundYoutubeVideoId}?controls=0&autoplay=1&mute=1&loop=1&modestbranding=1&showinfo=0&playsinline=1&playlist=${this.props.mainPage.backgroundYoutubeVideoId}`}
                              title="YouTube video player"
                              frameBorder={0}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                          )}
                          {this.props.mainPage != null && isMobileOnly && (
                            <ReactPlayer
                              url={`https://www.youtube.com/watch?v=${this.props.mainPage.backgroundYoutubeVideoId}`}
                              playing={true}
                              muted={true}
                              volume={0}
                              controls={false}
                              playsinline={true}
                              loop={true}
                              width={this.state.youtubeBackgroundVideoWidth}
                              height={this.state.youtubeBackgroundVideoHeight}
                              style={{
                                minWidth:
                                  this.state.youtubeBackgroundVideoWidth,
                                minHeight:
                                  this.state.youtubeBackgroundVideoHeight,
                                zIndex: -1,
                                visibility: "visible",
                              }}
                            />
                          )}
                        </div>
                        <div
                          className="fusion-builder-row fusion-row video-overlay"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            visibility: "hidden",
                          }}
                        >
                          <div
                            className="fusion-layout-column fusion_builder_column fusion-builder-column-0 fusion_builder_column_2_3 2_3 fusion-two-third fusion-column-first"
                            style={{
                              width: "100%",
                              marginTop: "30px",
                              marginBottom: "30px",
                            }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                WebkitBackgroundSize: "cover",
                                MozBackgroundSize: "cover",
                                OBackgroundSize: "cover",
                                backgroundSize: "cover",
                                backgroundColor: "rgba(249,4,0,0)",
                                padding: "0px 0px 0px 0px",
                              }}
                            >
                              <div className="fusion-text fusion-text-1">
                                {isAdminAndEditing && (
                                  <AdminEditorBadge
                                    position={AdminEditorBadgePosition.TopLeft}
                                    onClick={
                                      this.toggleVideoOverlayTitlesEditor
                                    }
                                  />
                                )}
                                <h1
                                  className="fusion-animated fadeInLeftBig"
                                  style={{
                                    marginBottom: 0,
                                    animationDuration: "1.6s",
                                  }}
                                  data-animationtype="fadeInLeftBig"
                                  data-animationduration="1.6"
                                >
                                  <strong>
                                    <span style={{ color: "#ffffff" }}>
                                      {this.props.mainPage != null
                                        ? this.props.mainPage
                                            .videoOverlayMainTitle
                                        : ""}
                                    </span>
                                  </strong>
                                </h1>
                                <strong>
                                  {isAdminAndEditing && (
                                    <AdminEditorBadge
                                      position={
                                        AdminEditorBadgePosition.TopRight
                                      }
                                      onClick={this.toggleYoutubeVideoIdEditor}
                                      title="Videó szerkesztése"
                                    />
                                  )}
                                  <h1
                                    className="fusion-animated fadeInUpBig"
                                    style={{
                                      marginTop: 0,
                                      animationDuration: "1.6s",
                                      marginBottom: 0,
                                    }}
                                    data-animationtype="fadeInUpBig"
                                    data-animationduration="1.6"
                                  >
                                    <span style={{ color: "#ebe1e1" }}>
                                      <strong>
                                        {this.props.mainPage != null
                                          ? this.props.mainPage
                                              .videoOverlaySubTitle
                                          : ""}
                                      </strong>
                                    </span>
                                  </h1>
                                  <p
                                    className="fusion-animated fadeInRightBig"
                                    style={{
                                      fontSize: "24pt",
                                      animationDuration: "1.6s",
                                    }}
                                    data-animationtype="fadeInRightBig"
                                    data-animationduration="1.6"
                                  >
                                    <span style={{ color: "#ffffff" }}>
                                      {this.props.mainPage != null
                                        ? this.props.mainPage
                                            .videoOverlaySubText
                                        : ""}
                                    </span>
                                  </p>
                                  {!isMobileOnly && (
                                    <React.Fragment>
                                      <br />
                                      <p
                                        style={{
                                          fontSize: "26px",
                                          lineHeight: "36px",
                                          paddingTop: "60px",
                                        }}
                                      >
                                        {isAdminAndEditing && (
                                          <AdminEditorBadge
                                            position={
                                              AdminEditorBadgePosition.BottomLeft
                                            }
                                            bottom={100}
                                            onClick={
                                              this
                                                .toggleVideoOverlayDetailsEditor
                                            }
                                          />
                                        )}
                                        <span style={{ color: "#ffffff" }}>
                                          {this.props.mainPage != null
                                            ? this.props.mainPage.videoOverlayDetails
                                                .split("\n")
                                                .map((row, index) => (
                                                  <React.Fragment key={index}>
                                                    {row}
                                                    {index <
                                                    this.props.mainPage!!.videoOverlayDetails.split(
                                                      "\n"
                                                    ).length -
                                                      1 ? (
                                                      <br />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </React.Fragment>
                                                ))
                                            : ""}
                                        </span>
                                      </p>
                                    </React.Fragment>
                                  )}
                                </strong>
                              </div>
                            </div>
                          </div>
                          {!isMobileOnly && (
                            <strong>
                              <div
                                className="fusion-layout-column fusion_builder_column fusion-builder-column-1 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-last fusion-animated"
                                style={{
                                  width: "100%",
                                  marginTop: "0px",
                                  marginBottom: "30px",
                                  animationDuration: "1.6s",
                                }}
                                data-animationoffset="100%"
                                data-animationtype="rubberBand"
                                data-animationduration="1.6"
                              >
                                <div
                                  className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                  style={{
                                    backgroundPosition: "left top",
                                    backgroundRepeat: "no-repeat",
                                    WebkitBackgroundSize: "cover",
                                    MozBackgroundSize: "cover",
                                    OBackgroundSize: "cover",
                                    backgroundSize: "cover",
                                    backgroundColor: "#b18b5e",
                                    padding: "9% 3% 9% 3%",
                                    marginLeft: "160px",
                                    marginRight: "-170px",
                                    marginTop: "30px",
                                  }}
                                >
                                  {isAdminAndEditing && (
                                    <AdminEditorBadge
                                      position={
                                        AdminEditorBadgePosition.TopRight
                                      }
                                      onClick={
                                        this
                                          .toggleVideoOverlayOpeningHoursEditor
                                      }
                                    />
                                  )}
                                  <div className="fusion-text fusion-text-2">
                                    <p style={{ textAlign: "center" }}>
                                      <strong>
                                        <span style={{ color: "#ffffff" }}>
                                          OXY ESZTÉTIKAI STÚDIÓ
                                        </span>
                                        <br />
                                      </strong>
                                      <span style={{ color: "#ffffff" }}>
                                        <b />
                                        <a
                                          className="white-link"
                                          style={{ color: "white" }}
                                          href="https://www.google.com/maps/place/Miskolc,+Arany+J%C3%A1nos+t%C3%A9r+1,+3526/@48.1077192,20.787912,17z/data=!3m1!4b1!4m5!3m4!1s0x47409ffd1c2aa6b3:0xf3bd9d14ffffc462!8m2!3d48.1077192!4d20.787912?hl"
                                          target="_blank"
                                        >
                                          3526 Miskolc
                                          <br />
                                          Arany János tér 1.
                                        </a>
                                        <br />
                                      </span>
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                      <a
                                        rel="noopener noreferrer"
                                        className="white-link"
                                        href="https://www.google.com/maps/place/Miskolc,+Arany+J%C3%A1nos+t%C3%A9r+1,+3526/@48.1077228,20.7857233,17z/data=!3m1!4b1!4m5!3m4!1s0x47409ffd1c2aa6b3:0xf3bd9d14ffffc462!8m2!3d48.1077192!4d20.787912"
                                        target="_blank"
                                      >
                                        <strong style={{ color: "white" }}>
                                          Térkép
                                        </strong>
                                      </a>
                                    </p>
                                  </div>
                                  <style
                                    type="text/css"
                                    dangerouslySetInnerHTML={{ __html: "" }}
                                  />
                                  <div
                                    className="fusion-title title fusion-title-1 fusion-sep-none fusion-title-center fusion-title-text fusion-title-size-three"
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <h3
                                      className="title-heading-center"
                                      style={{ margin: 0 }}
                                    >
                                      <span style={{ color: "#ffffff" }}>
                                        Nyitvatartásunk:
                                      </span>
                                    </h3>
                                  </div>
                                  <div className="fusion-sep-clear" />
                                  <div
                                    className="fusion-separator fusion-full-width-sep"
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      marginTop: "5px",
                                      marginBottom: "25px",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className="fusion-separator-border sep-single sep-solid"
                                      style={{
                                        borderColor: "#fffcfc",
                                        borderTopWidth: "4px",
                                      }}
                                    />
                                  </div>
                                  <div className="fusion-sep-clear" />
                                  <div className="fusion-text fusion-text-3">
                                    <div className="table-2">
                                      <table
                                        className="opening-hours-table"
                                        style={{
                                          width: "100%",
                                          height: "72px",
                                        }}
                                      >
                                        <tbody>
                                          <tr style={{ height: "24px" }}>
                                            <td
                                              style={{
                                                textAlign: "left",
                                                height: "24px",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#ffffff" }}
                                              >
                                                <strong>Kedd-Péntek</strong>
                                              </span>
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "right",
                                                height: "24px",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#ffffff" }}
                                              >
                                                <strong>
                                                  {this.props.mainPage != null
                                                    ? this.props.mainPage
                                                        .weekdayOpeningHours
                                                    : ""}
                                                </strong>
                                              </span>
                                            </td>
                                          </tr>
                                          <tr style={{ height: "24.4167px" }}>
                                            <td
                                              style={{
                                                textAlign: "left",
                                                height: "24px",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#ffffff" }}
                                              >
                                                <strong>Hétfő, Szombat</strong>
                                              </span>
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "right",
                                                height: "24px",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#ffffff" }}
                                              >
                                                <strong>
                                                  {this.props.mainPage != null
                                                    ? this.props.mainPage
                                                        .weekendOpeningHours
                                                    : ""}
                                                </strong>
                                              </span>
                                            </td>
                                          </tr>
                                          <tr style={{ height: "24.4167px" }}>
                                            <td
                                              style={{
                                                textAlign: "left",
                                                height: "24px",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#ffffff" }}
                                              >
                                                <strong>Vasárnap</strong>
                                              </span>
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "right",
                                                height: "24px",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#ffffff" }}
                                              >
                                                <strong>Zárva</strong>
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="fusion-sep-clear" />
                                  <div
                                    className="fusion-separator fusion-full-width-sep"
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      width: "100%",
                                    }}
                                  ></div>
                                  <div className="fusion-sep-clear" />
                                  <ul
                                    className="fusion-checklist fusion-checklist-1"
                                    style={{
                                      fontSize: "30px",
                                      lineHeight: "51px",
                                    }}
                                  >
                                    <li
                                      className="fusion-li-item"
                                      style={{
                                        paddingLeft: "13px",
                                        paddingRight: "13px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          height: "51px",
                                          width: "51px",
                                          marginRight: "21px",
                                          paddingTop: "4px",
                                        }}
                                        className="icon-wrapper circle-no"
                                      >
                                        <i
                                          className="fusion-li-icon fa fa-envelope"
                                          style={{ color: "#ffffff" }}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <div className="fusion-li-item-content">
                                        <h3
                                          style={{
                                            lineHeight: "51px !important",
                                            margin: "0 !important",
                                            marginTop: "0.5em",
                                          }}
                                        >
                                          <span style={{ color: "#ffffff" }}>
                                            <a
                                              style={{ color: "#ffffff" }}
                                              className="white-link"
                                              href="mailto:oxyesztetika@gmail.com"
                                            >
                                              oxyesztetika@gmail.com
                                            </a>
                                          </span>
                                        </h3>
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="fusion-sep-clear" />
                                  <ul
                                    className="fusion-checklist fusion-checklist-1"
                                    style={{
                                      fontSize: "30px",
                                      lineHeight: "51px",
                                    }}
                                  >
                                    <li
                                      className="fusion-li-item"
                                      style={{
                                        paddingLeft: "38px",
                                        paddingTop: "5.5px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          height: "51px",
                                          width: "51px",
                                          marginRight: "21px",
                                          marginTop: "6px",
                                          justifyContent: "right",
                                        }}
                                        className="icon-wrapper circle-no"
                                      >
                                        <i
                                          className="fusion-li-icon fa fa-phone"
                                          style={{ color: "#ffffff" }}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <div
                                        className="fusion-li-item-content"
                                        style={{ marginLeft: "72px" }}
                                      >
                                        <h3>
                                          <span style={{ color: "#ffffff" }}>
                                            <a
                                              style={{ color: "#ffffff" }}
                                              className="white-link"
                                              href="tel:06 30 464 2297"
                                            >
                                              06 30 464 2297
                                            </a>
                                          </span>
                                        </h3>
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="fusion-clearfix style={{ marginLeft: '58px', marginTop: '20px', fontWeight: 'bold' }}" />
                                  <strong>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-sep-clear " />
                                    <div className="fusion-button-wrapper">
                                      <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                        }}
                                      />
                                      <a
                                        className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no "
                                        style={{
                                          marginLeft: "58px",
                                          marginTop: "20px",
                                          fontWeight: "bold",
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://oxy-esztetikai-studio.salonic.hu"
                                      >
                                        <span className="fusion-button-text">
                                          IDŐPONTFOGLALÁS
                                        </span>
                                      </a>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </strong>
                                </div>
                              </div>
                            </strong>
                          )}
                        </div>
                        <strong>
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n\t\t\t\t\t\t\t\t\t\t.fusion-fullwidth.fusion-builder-row-1 {\n\t\t\t\t\t\t\t\t\t\t\toverflow: visible;\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t",
                            }}
                          />
                        </strong>
                      </div>
                      {isMobileOnly && (
                        <strong>
                          <div
                            className="fusion-fullwidth fullwidth-box fusion-builder-row-1 nonhundred-percent-fullwidth"
                            style={{ padding: "0 15px" }}
                          >
                            <div
                              className="fusion-layout-column fusion_builder_column fusion-builder-column-1 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-last fusion-animated"
                              style={{
                                width: "100%",
                                marginTop: "30px",
                                marginBottom: "30px",
                                animationDuration: "1.6s",
                              }}
                              data-animationoffset="100%"
                              data-animationtype="rubberBand"
                              data-animationduration="1.6"
                            >
                              <div
                                className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                style={{
                                  backgroundPosition: "left top",
                                  backgroundRepeat: "no-repeat",
                                  WebkitBackgroundSize: "cover",
                                  MozBackgroundSize: "cover",
                                  OBackgroundSize: "cover",
                                  backgroundSize: "cover",
                                  backgroundColor: "#b18b5e",
                                  padding: "9% 7% 9% 7%",
                                }}
                              >
                                {isAdminAndEditing && (
                                  <AdminEditorBadge
                                    position={AdminEditorBadgePosition.TopRight}
                                    onClick={
                                      this.toggleVideoOverlayOpeningHoursEditor
                                    }
                                  />
                                )}
                                <div className="fusion-text fusion-text-2">
                                  <p style={{ textAlign: "center" }}>
                                    <strong>
                                      <span style={{ color: "#ffffff" }}>
                                        OXY ESZTÉTIKAI STÚDIÓ
                                      </span>
                                      <br />
                                    </strong>
                                    <span style={{ color: "#ffffff" }}>
                                      <b />
                                      3526 Miskolc
                                      <br />
                                      Arany János tér 1.
                                      <br />
                                    </span>
                                  </p>
                                  <p style={{ textAlign: "center" }}>
                                    <a
                                      rel="noopener noreferrer"
                                      className="white-link"
                                      href="https://www.google.com/maps/place/Miskolc,+Arany+J%C3%A1nos+t%C3%A9r+1,+3526/@48.1077228,20.7857233,17z/data=!3m1!4b1!4m5!3m4!1s0x47409ffd1c2aa6b3:0xf3bd9d14ffffc462!8m2!3d48.1077192!4d20.787912"
                                      target="_blank"
                                    >
                                      <strong style={{ color: "white" }}>
                                        Térkép
                                      </strong>
                                    </a>
                                  </p>
                                </div>
                                <style
                                  type="text/css"
                                  dangerouslySetInnerHTML={{ __html: "" }}
                                />
                                <div
                                  className="fusion-title title fusion-title-1 fusion-sep-none fusion-title-center fusion-title-text fusion-title-size-three"
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <h3
                                    className="title-heading-center"
                                    style={{ margin: 0 }}
                                  >
                                    <span style={{ color: "#ffffff" }}>
                                      Nyitvatartásunk:
                                    </span>
                                  </h3>
                                </div>
                                <div className="fusion-sep-clear" />
                                <div
                                  className="fusion-separator fusion-full-width-sep"
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: "5px",
                                    marginBottom: "25px",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    className="fusion-separator-border sep-single sep-solid"
                                    style={{
                                      borderColor: "#fffcfc",
                                      borderTopWidth: "4px",
                                    }}
                                  />
                                </div>
                                <div className="fusion-sep-clear" />
                                <div className="fusion-text fusion-text-3">
                                  <div className="table-2">
                                    <table
                                      className="opening-hours-table"
                                      style={{ width: "100%", height: "72px" }}
                                    >
                                      <tbody>
                                        <tr style={{ height: "24px" }}>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              height: "24px",
                                            }}
                                          >
                                            <span style={{ color: "#ffffff" }}>
                                              <strong>Kedd-Péntek</strong>
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              height: "24px",
                                            }}
                                          >
                                            <span style={{ color: "#ffffff" }}>
                                              <strong>
                                                {this.props.mainPage != null
                                                  ? this.props.mainPage
                                                      .weekdayOpeningHours
                                                  : ""}
                                              </strong>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr style={{ height: "24.4167px" }}>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              height: "24px",
                                            }}
                                          >
                                            <span style={{ color: "#ffffff" }}>
                                              <strong>Hétfő, Szombat</strong>
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              height: "24px",
                                            }}
                                          >
                                            <span style={{ color: "#ffffff" }}>
                                              <strong>
                                                {this.props.mainPage != null
                                                  ? this.props.mainPage
                                                      .weekendOpeningHours
                                                  : ""}
                                              </strong>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr style={{ height: "24.4167px" }}>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              height: "24px",
                                            }}
                                          >
                                            <span style={{ color: "#ffffff" }}>
                                              <strong>Vasárnap</strong>
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              height: "24px",
                                            }}
                                          >
                                            <span style={{ color: "#ffffff" }}>
                                              <strong>Zárva</strong>
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="fusion-sep-clear" />
                                <div
                                  className="fusion-separator fusion-full-width-sep"
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    width: "100%",
                                  }}
                                ></div>
                                <div className="fusion-sep-clear" />
                                <ul
                                  className="fusion-checklist fusion-checklist-1"
                                  style={{
                                    fontSize: "30px",
                                    lineHeight: "51px",
                                  }}
                                >
                                  <li
                                    className="fusion-li-item"
                                    style={{
                                      paddingLeft: "13px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        height: "51px",
                                        width: "51px",
                                        marginRight: "21px",
                                        paddingTop: "4px",
                                      }}
                                      className="icon-wrapper circle-no"
                                    >
                                      <i
                                        className="fusion-li-icon fa fa-envelope"
                                        style={{ color: "#ffffff" }}
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <div className="fusion-li-item-content">
                                      <h3
                                        style={{
                                          lineHeight: "51px !important",
                                          margin: "0 !important",
                                          marginTop: "0.5em",
                                        }}
                                      >
                                        <span style={{ color: "#ffffff" }}>
                                          <a
                                            style={{ color: "#ffffff" }}
                                            className="white-link"
                                            href="mailto:oxyesztetika@gmail.com"
                                          >
                                            oxyesztetika@gmail.com
                                          </a>
                                        </span>
                                      </h3>
                                    </div>
                                  </li>
                                </ul>
                                <div className="fusion-sep-clear" />
                                <ul
                                  className="fusion-checklist fusion-checklist-1"
                                  style={{
                                    fontSize: "30px",
                                    lineHeight: "51px",
                                  }}
                                >
                                  <li
                                    className="fusion-li-item"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <span
                                      style={{
                                        height: "51px",
                                        width: "51px",
                                        marginRight: "10px",
                                      }}
                                      className="icon-wrapper circle-no"
                                    >
                                      <i
                                        className="fusion-li-icon fa fa-phone"
                                        style={{ color: "#ffffff" }}
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <div
                                      className="fusion-li-item-content"
                                      style={{ marginLeft: "73px" }}
                                    >
                                      <h3>
                                        <span style={{ color: "#ffffff" }}>
                                          <a
                                            style={{
                                              color: "#ffffff",
                                              lineHeight: "50px",
                                            }}
                                            className="white-link"
                                            href="tel:06 30 464 2297"
                                          >
                                            06 30 464 22 97
                                          </a>
                                        </span>
                                      </h3>
                                    </div>
                                  </li>
                                </ul>
                                <div className="fusion-sep-clear" />
                                <ul
                                  className="fusion-checklist fusion-checklist-1"
                                  style={{
                                    fontSize: "30px",
                                    lineHeight: "51px",
                                  }}
                                >
                                  <li
                                    className="fusion-li-item"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <div className="fusion-li-item-content">
                                      <div
                                        className="fusion-button-wrapper"
                                        style={{
                                          display: isMobileOnly
                                            ? "flex"
                                            : "unset",
                                          justifyContent: isMobileOnly
                                            ? "center"
                                            : "unset",
                                        }}
                                      >
                                        <style
                                          type="text/css"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                          }}
                                        />
                                        <a
                                          className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no "
                                          style={{
                                            marginLeft: isMobileOnly
                                              ? "unset"
                                              : "58px",
                                            marginTop: "20px",
                                            fontWeight: "bold",
                                          }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="https://oxy-esztetikai-studio.salonic.hu"
                                        >
                                          <span className="fusion-button-text">
                                            IDŐPONTFOGLALÁS
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <div className="fusion-clearfix" />
                              </div>
                            </div>
                          </div>
                        </strong>
                      )}
                      <strong>
                        <div className="fullwidth-box">
                          <div className="fusion-builder-row fusion-row">
                            <div
                              className="fusion-layout-column fusion_builder_column fusion-builder-column-25 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                              style={{
                                marginTop: "50px",
                                marginBottom: "30px",
                              }}
                            >
                              <div
                                className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                style={{
                                  backgroundPosition: "left top",
                                  backgroundRepeat: "no-repeat",
                                  WebkitBackgroundSize: "cover",
                                  MozBackgroundSize: "cover",
                                  OBackgroundSize: "cover",
                                  backgroundSize: "cover",
                                  padding: "1px",
                                  marginBottom: "-40px",
                                }}
                              >
                                <div className="fusion-text fusion-text-19">
                                  <h1
                                    style={{
                                      textAlign: "center",
                                      textShadow: "2px 2px 5px coral",
                                      fontFamily: "'Great Vibes', cursive",
                                      fontSize: "70px",
                                    }}
                                  >
                                    A Kortalan Szépség Titka
                                  </h1>
                                </div>
                                <div className="fusion-clearfix" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-26 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundColor: "#ffffff",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            paddingRight: "30px",
                            paddingBottom: "0px",
                            paddingLeft: "30px",
                            marginTop: "0px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="fusion-builder-row fusion-row">
                            <div
                              className="fusion-layout-column fusion_builder_column fusion-builder-column-71 fusion_builder_column_2_3 2_3 fusion-two-third"
                              style={{
                                width: "100%",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <div
                                className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                style={{
                                  backgroundPosition: "left top",
                                  backgroundRepeat: "no-repeat",
                                  WebkitBackgroundSize: "cover",
                                  MozBackgroundSize: "cover",
                                  OBackgroundSize: "cover",
                                  backgroundSize: "cover",
                                  padding: "5px 0px 5px 0px",
                                }}
                              >
                                <style
                                  type="text/css"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t.reading-box-container-1 .element-bottomshadow:before,\n\t\t\t\t\t\t\t\t\t\t\t\t\t.reading-box-container-1 .element-bottomshadow:after {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\topacity: 0.9;\n\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t",
                                  }}
                                />
                                <div
                                  className="fusion-reading-box-container reading-box-container-1"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "1px",
                                  }}
                                >
                                  <div
                                    className="reading-box reading-box-center element-bottomshadow"
                                    style={{
                                      backgroundColor: "#f6f6f6",
                                      borderWidth: "3px",
                                      borderColor: "#f6f6f6",
                                      borderTopWidth: "3px",
                                      borderTopColor: "var(--primary_color)",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <div className="reading-box-additional">
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "initial",
                                          color: "darkbrown",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            this.props.mainPage != null
                                              ? this.props.mainPage.aboutUsText.replace(
                                                  /\n/g,
                                                  "<br />"
                                                )
                                              : "",
                                        }}
                                      />
                                      {this.props.mainPage != null &&
                                        this.props.mainPage
                                          .aboutUsSignatureText1.length > 0 && (
                                          <p style={{ textAlign: "center" }}>
                                            {this.props.mainPage != null &&
                                              this.props.mainPage
                                                .aboutUsSignatureText1.length >
                                                0 && (
                                                <React.Fragment>
                                                  {
                                                    this.props.mainPage
                                                      .aboutUsSignatureText1
                                                  }
                                                  <br />
                                                </React.Fragment>
                                              )}
                                            {this.props.mainPage != null
                                              ? this.props.mainPage
                                                  .aboutUsSignatureText2
                                              : ""}
                                          </p>
                                        )}
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                <div className="fusion-clearfix" />
                              </div>
                            </div>
                            {isAdminAndEditing && (
                              <AdminEditorBadge
                                position={AdminEditorBadgePosition.BottomRight}
                                onClick={this.toggleAboutUsTextEditor}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-14 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundColor: "rgba(188,151,120,0.1)",
                            backgroundPosition: "left top",
                            backgroundRepeat: "no-repeat",
                            paddingRight: "30px",
                            paddingBottom: "0px",
                            paddingLeft: "30px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="fusion-builder-row fusion-row">
                            <div
                              className="fusion-layout-column fusion_builder_column fusion-builder-column-40 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                              style={{
                                marginTop: "30px",
                                marginBottom: "30px",
                              }}
                            >
                              <div
                                className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                style={{
                                  backgroundPosition: "left top",
                                  backgroundRepeat: "no-repeat",
                                  WebkitBackgroundSize: "cover",
                                  MozBackgroundSize: "cover",
                                  OBackgroundSize: "cover",
                                  backgroundSize: "cover",
                                  padding: "0px 0px 0px 0px",
                                }}
                              >
                                <div className="fusion-aligncenter">
                                  <a
                                    className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no"
                                    href="https://oxy-esztetikai-studio.salonic.hu"
                                  >
                                    <span className="fusion-button-text">
                                      ONLINE IDŐPONTFOGLALÁS
                                    </span>
                                  </a>
                                </div>
                                <div className="fusion-clearfix" />
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.props.mainPage != null &&
                          this.props.mainPage.gallery != null &&
                          this.props.mainPage.gallery.images.length > 0 &&
                          !isMobileOnly && (
                            <GalleryBigImage
                              isAdminAndEditing={isAdminAndEditing === true}
                              toggleEditor={this.toggleGalleryEditor}
                              image={
                                this.props.mainPage.gallery.images[0].image
                              }
                            />
                          )}
                        {this.props.mainPage != null &&
                          this.props.mainPage.gallery != null &&
                          this.props.mainPage.gallery.images.length > 1 &&
                          !isMobileOnly && (
                            <Gallery gallery={this.props.mainPage.gallery} />
                          )}
                        {this.props.mainPage != null &&
                          this.props.mainPage.gallery != null &&
                          this.props.mainPage.gallery.images.length > 0 &&
                          isMobileOnly && (
                            <GalleryMobile
                              gallery={this.props.mainPage.gallery}
                            />
                          )}

                        {this.props.mainPage != null && (
                          <PromotedServices
                            promotedServices={
                              this.props.mainPage.promotedServices
                            }
                            isAdminAndEditing={isAdminAndEditing === true}
                            toggleEditor={this.togglePromotedServiceEditor}
                          />
                        )}

                        {this.props.mainPage != null && (
                          <CurrentOffers
                            currentOffers={this.props.mainPage.currentOffers}
                            isAdminAndEditing={isAdminAndEditing === true}
                            toggleEditor={this.toggleCurrentOfferEditor}
                          />
                        )}

                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundColor: "rgba(232,232,232,0.2)",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            paddingRight: "30px",
                            paddingBottom: "0px",
                            paddingLeft: "30px",
                            marginBottom: "0px",
                            marginTop: "0px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="fusion-builder-row fusion-row">
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-14 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "rgba(188,151,120,0.1)",
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "30px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-40 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                                  style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div
                                      className="fusion-text fusion-text-33 fusion-animated"
                                      style={{
                                        textAlign: "center",
                                        animationDuration: "2.5s",
                                      }}
                                      data-animationoffset="100%"
                                      data-animationtype="bounce"
                                      data-animationduration="2.5"
                                    >
                                      <h2 style={{ textAlign: "center" }}>
                                        <strong>
                                          Keress minket bizalommal:
                                        </strong>
                                        <br />
                                        Szépítészeid, az Oxy-s lányok
                                      </h2>
                                    </div>
                                    <div className="fusion-aligncenter">
                                      <a
                                        className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no"
                                        href="https://oxy-esztetikai-studio.salonic.hu"
                                      >
                                        <span className="fusion-button-text">
                                          IDŐPONTFOGLALÁS
                                        </span>
                                      </a>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div
                                      className="fusion-separator"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginTop: "0px",
                                        marginBottom: "17px",
                                        width: "100%",
                                        maxWidth: "280px",
                                      }}
                                    ></div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-text fusion-text-34">
                                      <h4 style={{ textAlign: "center" }}>
                                        A szépség belülről fakad, de a
                                        gondoskodás rajtad múlik. Mi azért
                                        dolgozunk minden nap, hogy ebben a
                                        gondoskodásban segítsünk.
                                      </h4>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-15 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "#ffffff",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-41 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-first"
                                  style={{
                                    width:
                                      "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                      <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                        <div
                                          className="fusion-flip-box flip-up"
                                          tabIndex={0}
                                        >
                                          <div className="flip-box-inner-wrapper">
                                            <div
                                              className="flip-box-front"
                                              style={{
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "180px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-190px",
                                                backgroundColor: "#ffffff",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#747474",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-front-inner">
                                                <div
                                                  className="flip-box-grafix flip-box-circle"
                                                  style={{
                                                    backgroundColor: "#b18b5e",
                                                    borderColor: "#ebe1e1",
                                                  }}
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="fa-envelope far"
                                                    style={{ color: "#ffffff" }}
                                                  />
                                                </div>
                                                <h2
                                                  className="flip-box-heading"
                                                  style={{ color: "#333333" }}
                                                >
                                                  Írj nekünk!
                                                </h2>
                                                oxyesztetika@gmail.com
                                                <br />
                                                &nbsp;
                                              </div>
                                            </div>
                                            <div
                                              className="flip-box-back"
                                              style={{
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "180px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-190px",
                                                backgroundColor: "#b18b5e",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#ffffff",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-back-inner">
                                                <h3
                                                  className="flip-box-heading-back"
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  24 órán belül válaszolunk
                                                </h3>
                                                <p>
                                                  Amennyiben bármilyen kérdésed
                                                  felmerül, készséggel állunk
                                                  rendelkezésedre!
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearfix" />
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-42 fusion_builder_column_1_3 1_3 fusion-one-third"
                                  style={{
                                    width:
                                      "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                      <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                        <div
                                          className="fusion-flip-box flip-up"
                                          tabIndex={0}
                                        >
                                          <div className="flip-box-inner-wrapper">
                                            <div
                                              className="flip-box-front"
                                              style={{
                                                marginBottom: "-20px",
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "200px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-210px",
                                                backgroundColor: "#ffffff",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#747474",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-front-inner">
                                                <div
                                                  className="flip-box-grafix flip-box-circle"
                                                  style={{
                                                    backgroundColor: "#b18b5e",
                                                    borderColor: "#ebe1e1",
                                                  }}
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="fa-phone fas"
                                                    style={{ color: "#ffffff" }}
                                                  />
                                                </div>
                                                <h2
                                                  className="flip-box-heading"
                                                  style={{ color: "#333333" }}
                                                >
                                                  Hívj minket!
                                                </h2>
                                                06 30 464 2297
                                              </div>
                                            </div>
                                            <div
                                              className="flip-box-back"
                                              style={{
                                                marginBottom: "-20px",
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "200px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-210px",
                                                backgroundColor: "#b18b5e",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#ffffff",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-back-inner">
                                                <h3
                                                  className="flip-box-heading-back"
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  Szeretettel várjuk a hívásodat{" "}
                                                  <br /> kedd-péntek 9-18 óráig
                                                  <br />
                                                  hétfő, szombat 8-14 óráig
                                                </h3>
                                                <p>
                                                  Időpontfoglalásban és
                                                  teljeskörű tájékoztatásban
                                                  bármikor segítséget nyújtunk.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearfix" />
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*<div className="fusion-fullwidth fullwidth-box fusion-builder-row-16 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>*/}
                            {/*    <div className="fusion-builder-row fusion-row">*/}
                            {/*        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-44 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: '0px', marginBottom: '30px' }}>*/}
                            {/*            <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>*/}
                            {/*                <div className="fusion-sep-clear" />*/}
                            {/*                <div className="fusion-separator fusion-has-icon fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>*/}
                            {/*                    <div className="fusion-separator-border sep-single sep-solid" style={{ borderColor: 'rgba(181,148,115,0.76)', borderTopWidth: '1px' }}>*/}
                            {/*                    </div><span className="icon-wrapper" style={{ borderColor: 'rgba(181,148,115,0.76)', backgroundColor: 'rgba(255,255,255,0)', fontSize: '17px', width: '1.75em', height: '1.75em', borderWidth: '1px', padding: '1px', marginTop: '-0.5px' }}><i className=" fa fa-check-circle" style={{ fontSize: 'inherit', color: 'rgba(181,148,115,0.76)' }} aria-hidden="true" /></span>*/}
                            {/*                    <div className="fusion-separator-border sep-single sep-solid" style={{ borderColor: 'rgba(181,148,115,0.76)', borderTopWidth: '1px' }}>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="fusion-sep-clear" />*/}
                            {/*                <div className="fusion-clearfix" />*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*{this.props.mainPage != null && <CustomerReviews*/}
                            {/*    customerReviews={this.props.mainPage.customerReviews}*/}
                            {/*    isAdminAndEditing={isAdminAndEditing === true}*/}
                            {/*    toggleEditor={this.toggleCustomerReviewEditor}*/}
                            {/*/>}*/}

                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-20 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "#ffffff",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "0px",
                                marginTop: "0px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-55 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-sep-clear" />
                                    <div
                                      className="fusion-separator fusion-has-icon fusion-full-width-sep"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="fusion-separator-border sep-single sep-solid"
                                        style={{
                                          borderColor: "rgba(181,148,115,0.76)",
                                          borderTopWidth: "1px",
                                        }}
                                      ></div>
                                      <span
                                        className="icon-wrapper"
                                        style={{
                                          borderColor: "rgba(181,148,115,0.76)",
                                          backgroundColor:
                                            "rgba(255,255,255,0)",
                                          fontSize: "17px",
                                          width: "1.75em",
                                          height: "1.75em",
                                          borderWidth: "1px",
                                          padding: "1px",
                                          marginTop: "-0.5px",
                                        }}
                                      >
                                        <i
                                          className=" fa fa-check-circle"
                                          style={{
                                            fontSize: "inherit",
                                            color: "rgba(181,148,115,0.76)",
                                          }}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <div
                                        className="fusion-separator-border sep-single sep-solid"
                                        style={{
                                          borderColor: "rgba(181,148,115,0.76)",
                                          borderTopWidth: "1px",
                                        }}
                                      ></div>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-21 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "#ffffff",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "0px",
                                marginTop: "0px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-56 fusion_builder_column_1_6 1_6 fusion-one-sixth fusion-column-first"
                                  style={{
                                    width:
                                      "calc(16.666666666667% - ( ( 4% + 4% ) * 0.16666666666667 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-57 fusion_builder_column_2_3 2_3 fusion-two-third"
                                  style={{
                                    width:
                                      "calc(66.666666666667% - ( ( 4% + 4% ) * 0.66666666666667 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-text fusion-text-40">
                                      <h2 style={{ textAlign: "center" }}>
                                        Amit nálunk kapsz…
                                      </h2>
                                    </div>
                                    <ul
                                      className="fusion-checklist fusion-checklist-2"
                                      style={{
                                        fontSize: "20px",
                                        lineHeight: "34px",
                                      }}
                                    >
                                      <li
                                        className="fusion-li-item"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <span
                                          style={{
                                            backgroundColor: "#b18b5e",
                                            fontSize: "17.6px",
                                            height: "34px",
                                            width: "34px",
                                            marginRight: "14px",
                                          }}
                                          className="icon-wrapper circle-yes"
                                        >
                                          <i
                                            className="fusion-li-icon fa-smile-beam fas"
                                            style={{ color: "#ffffff" }}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <div
                                          className="fusion-li-item-content"
                                          style={{ marginLeft: "48px" }}
                                        >
                                          <p>
                                            Mindig szeretettel és mosollyal
                                            várunk
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        className="fusion-li-item"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <span
                                          style={{
                                            backgroundColor: "#b18b5e",
                                            fontSize: "17.6px",
                                            height: "34px",
                                            width: "34px",
                                            marginRight: "14px",
                                            marginTop: "15px",
                                          }}
                                          className="icon-wrapper circle-yes"
                                        >
                                          <i
                                            className="fusion-li-icon fa-hand-holding-heart fas"
                                            style={{ color: "#ffffff" }}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <div
                                          className="fusion-li-item-content"
                                          style={{ marginLeft: "48px" }}
                                        >
                                          <p>
                                            Minden egyes alkalommal törekszünk
                                            arra, hogy a lehető legkiválóbb
                                            szolgáltatást nyújtsuk számodra
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        className="fusion-li-item"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <span
                                          style={{
                                            backgroundColor: "#b18b5e",
                                            fontSize: "17.6px",
                                            height: "34px",
                                            width: "34px",
                                            marginRight: "14px",
                                            marginTop: "15px",
                                          }}
                                          className="icon-wrapper circle-yes"
                                        >
                                          <i
                                            className="fusion-li-icon fa-check fas"
                                            style={{ color: "#ffffff" }}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <div
                                          className="fusion-li-item-content"
                                          style={{ marginLeft: "48px" }}
                                        >
                                          <p>
                                            Előleget nem kérünk, fizetned csak a
                                            szolgáltatás igénybevételét követően
                                            szükséges
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        className="fusion-li-item"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <span
                                          style={{
                                            backgroundColor: "#b18b5e",
                                            fontSize: "17.6px",
                                            height: "34px",
                                            width: "34px",
                                            marginRight: "14px",
                                            marginTop: "15px",
                                          }}
                                          className="icon-wrapper circle-yes"
                                        >
                                          <i
                                            className="fusion-li-icon fa-envelope-open fas"
                                            style={{ color: "#ffffff" }}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <div
                                          className="fusion-li-item-content"
                                          style={{ marginLeft: "48px" }}
                                        >
                                          <p>
                                            Telefonon és üzenetben egyaránt a
                                            rendelkezésedre állunk és kedvesen
                                            tájékoztatunk, ha segítségre van
                                            szükséged
                                          </p>
                                        </div>
                                      </li>
                                      <li className="fusion-li-item">
                                        <span
                                          style={{
                                            backgroundColor: "#b18b5e",
                                            fontSize: "17.6px",
                                            height: "34px",
                                            width: "34px",
                                            marginRight: "14px",
                                            marginTop: "15px",
                                          }}
                                          className="icon-wrapper circle-yes"
                                        >
                                          <i
                                            className="fusion-li-icon fa-heart fas"
                                            style={{ color: "#ffffff" }}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        <div
                                          className="fusion-li-item-content"
                                          style={{ marginLeft: "48px" }}
                                        >
                                          <p>
                                            Véleményed és kényelmed nagyon
                                            fontos számunkra, így ha problémád
                                            adódik valamilyen szolgáltatásunkkal
                                            kapcsolatban, biztos megoldást
                                            nyújtunk a vendégelégedettségi
                                            garanciánk jegyében. Szeretnénk, ha
                                            mindig mosollyal az arcodon távoznál
                                            tőlünk, és ezért mindent megteszünk
                                          </p>
                                        </div>
                                      </li>
                                    </ul>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-58 fusion_builder_column_1_6 1_6 fusion-one-sixth fusion-column-last"
                                  style={{
                                    width:
                                      "calc(16.666666666667% - ( ( 4% + 4% ) * 0.16666666666667 ) )",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-22 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "#ffffff",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "0px",
                                marginTop: "0px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-59 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-first"
                                  style={{
                                    width:
                                      "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-text fusion-text-41">
                                      <h3 style={{ textAlign: "center" }}>
                                        <span className="5yl5">
                                          <strong>Garancia és biztonság</strong>
                                        </span>
                                      </h3>
                                    </div>
                                    <div className="imageframe-align-center">
                                      <span className=" fusion-imageframe imageframe-none imageframe-10 hover-type-none">
                                        <img
                                          width={300}
                                          height={300}
                                          data-image_id={17801}
                                          alt="Garancia és biztonság"
                                          src="/wp-content/uploads/new/garancia.png"
                                          className="img-responsive wp-image-17801"
                                          sizes="(max-width: 800px) 100vw, 300px"
                                        />
                                      </span>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div
                                      className="fusion-separator fusion-full-width-sep"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginBottom: "20px",
                                        width: "100%",
                                      }}
                                    ></div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-clearfix" />
                                    <div className="fusion-align-block">
                                      <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-8 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-8.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-8.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                        }}
                                      />
                                      <a
                                        className="fusion-button button-3d fusion-button-default-size button-default button-8 fusion-button-default-span"
                                        href="/garancia/garancia"
                                      >
                                        <span className="fusion-button-text">
                                          Vendégelégedettségi garanciánkról
                                          bővebben!
                                        </span>
                                      </a>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-60 fusion_builder_column_1_3 1_3 fusion-one-third"
                                  style={{
                                    width:
                                      "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-text fusion-text-42">
                                      <h3
                                        style={{ textAlign: "center" }}
                                        data-fontsize={46}
                                        data-lineheight={57}
                                      >
                                        <strong>
                                          Innováció
                                          <br />
                                        </strong>
                                      </h3>
                                    </div>
                                    <div className="imageframe-align-center">
                                      <span
                                        className=" fusion-imageframe imageframe-none imageframe-11 hover-type-none"
                                        style={{
                                          WebkitBorderRadius: "30px",
                                          MozBorderRadius: "30px",
                                          borderRadius: "30px",
                                        }}
                                      >
                                        <img
                                          width={300}
                                          height={300}
                                          data-image_id={17802}
                                          alt="Innováció"
                                          src="/wp-content/uploads/new/innovacio.png"
                                          className="img-responsive wp-image-17802"
                                          sizes="(max-width: 800px) 100vw, 300px"
                                        />
                                      </span>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div
                                      className="fusion-separator fusion-full-width-sep"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginBottom: "20px",
                                        width: "100%",
                                      }}
                                    ></div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-clearfix" />
                                    <div className="fusion-column-content-centered">
                                      <div className="fusion-column-content">
                                        <div className="fusion-align-block">
                                          <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-9 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-9.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-9.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                            }}
                                          />
                                          <a
                                            className="fusion-button button-3d fusion-button-default-size button-default button-9 fusion-button-default-span"
                                            href="/oldal/maria-galland-arckezelesek"
                                          >
                                            <span className="fusion-button-text">
                                              Folyamatos innovációnk a
                                              szépségmegőrzésben itt!
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-61 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-last"
                                  style={{
                                    width:
                                      "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-text fusion-text-43">
                                      <h3
                                        style={{ textAlign: "center" }}
                                        data-fontsize={46}
                                        data-lineheight={57}
                                      >
                                        <strong>Gondoskodás felsőfokon</strong>
                                      </h3>
                                    </div>
                                    <div className="imageframe-align-center">
                                      <span className=" fusion-imageframe imageframe-none imageframe-12 hover-type-none">
                                        <img
                                          width={300}
                                          height={300}
                                          data-image_id={17804}
                                          alt="Gondoskodás felsőfokon"
                                          src="/wp-content/uploads/new/gondoskodas.png"
                                          className="img-responsive wp-image-17804"
                                        />
                                      </span>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div
                                      className="fusion-separator fusion-full-width-sep"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginBottom: "20px",
                                        width: "100%",
                                      }}
                                    ></div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-clearfix" />
                                    <div className="fusion-column-content-centered">
                                      <div className="fusion-column-content">
                                        <div className="fusion-align-block">
                                          <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-10 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-10.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-10.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px #ebe1e1, 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                            }}
                                          />
                                          <a
                                            className="fusion-button button-3d fusion-button-default-size button-default button-10 fusion-button-default-span"
                                            href="/oldal/o2-balance-es-hyaluroninfuzios-kezeles"
                                          >
                                            <span className="fusion-button-text">
                                              Élményterápiás szépségápolás itt!
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-23 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "#ffffff",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "0px",
                                marginTop: "0px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-66 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-sep-clear" />
                                    <div
                                      className="fusion-separator fusion-has-icon fusion-full-width-sep"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="fusion-separator-border sep-single sep-solid"
                                        style={{
                                          borderColor: "rgba(181,148,115,0.76)",
                                          borderTopWidth: "1px",
                                        }}
                                      ></div>
                                      <span
                                        className="icon-wrapper"
                                        style={{
                                          borderColor: "rgba(181,148,115,0.76)",
                                          backgroundColor:
                                            "rgba(255,255,255,0)",
                                          fontSize: "17px",
                                          width: "1.75em",
                                          height: "1.75em",
                                          borderWidth: "1px",
                                          padding: "1px",
                                          marginTop: "-0.5px",
                                        }}
                                      >
                                        <i
                                          className=" fa fa-check-circle"
                                          style={{
                                            fontSize: "inherit",
                                            color: "rgba(181,148,115,0.76)",
                                          }}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <div
                                        className="fusion-separator-border sep-single sep-solid"
                                        style={{
                                          borderColor: "rgba(181,148,115,0.76)",
                                          borderTopWidth: "1px",
                                        }}
                                      ></div>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-24 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "rgba(188,151,120,0.1)",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "0px",
                                marginTop: "0px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-67 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last newspaper-title-inner-container"
                                  style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-text fusion-text-44">
                                      <h2 style={{ textAlign: "center" }}>
                                        Ne maradj le az szépségmegőrző
                                        újdonságokról és akciókról: Iratkozz fel
                                        hírlevelünkre most!
                                      </h2>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-25 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "#ffffff",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "0px",
                                marginTop: "0px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-68 fusion_builder_column_1_2 1_2 fusion-one-half fusion-column-first subscribe-form-inner-container"
                                  style={{
                                    width: "45%",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                    paddingTop: "60px",
                                    marginLeft: "50px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-text fusion-text-45" />
                                    {isMobileOnly && (
                                      <div
                                        className="fusion-column-wrapper fusion-flex-column-wrapper-legacy newspaper-image-container"
                                        style={{
                                          backgroundPosition: "left top",
                                          backgroundRepeat: "no-repeat",
                                          WebkitBackgroundSize: "cover",
                                          MozBackgroundSize: "cover",
                                          OBackgroundSize: "cover",
                                          backgroundSize: "cover",
                                          padding: "0px 0px 0px 0px",
                                        }}
                                      >
                                        <span className=" fusion-imageframe imageframe-none imageframe-13 hover-type-none">
                                          <img
                                            width={1000}
                                            height={1000}
                                            data-image_id={10140}
                                            alt="Exkluzív Szépségszalonunk prospektusa"
                                            src="/wp-content/uploads/new/beauty&spav4.png"
                                            className="img-responsive wp-image-10140"
                                            srcSet="/wp-content/uploads/new/beauty&spav4.png 200w, /wp-content/uploads/new/beauty&spav4.png 400w, /wp-content/uploads/new/beauty&spav4.png 600w, /wp-content/uploads/new/beauty&spav4.png 800w, /wp-content/uploads/new/beauty&spav4.png 1000w"
                                            sizes="(max-width: 800px) 100vw, 400px"
                                          />
                                        </span>
                                        <div className="fusion-clearfix" />
                                      </div>
                                    )}
                                    <div className="fusion-text fusion-text-46">
                                      <p></p>
                                      <table
                                        style={{ border: 0 }}
                                        cellSpacing={0}
                                        cellPadding={4}
                                      >
                                        <tbody>
                                          <tr>
                                            <td align="right">
                                              Vezetékneved:
                                              <span
                                                style={{ color: "#b18b5e" }}
                                              >
                                                *
                                              </span>
                                            </td>
                                            <td>
                                              <input
                                                name="f_9"
                                                type="text"
                                                value={this.state.lastName}
                                                onChange={
                                                  this.handleLastNameChange
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="right">
                                              Keresztneved:
                                              <span
                                                style={{ color: "#b18b5e" }}
                                              >
                                                *
                                              </span>
                                            </td>
                                            <td>
                                              <input
                                                name="f_8"
                                                type="text"
                                                value={this.state.firstName}
                                                onChange={
                                                  this.handleFirstNameChange
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="right">
                                              E-mail címed:
                                              <span
                                                style={{ color: "#b18b5e" }}
                                              >
                                                *
                                              </span>
                                            </td>
                                            <td>
                                              <input
                                                name="subscr"
                                                type="text"
                                                value={this.state.email}
                                                onChange={
                                                  this.handleEmailChange
                                                }
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2} align="center">
                                              {isMobileOnly && (
                                                <div className="form-check mb-2">
                                                  <div className="d-flex justify-content-start">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      id="inputAcceptPrivacyPolicy"
                                                      checked={
                                                        this.state.acceptEula
                                                      }
                                                      onChange={
                                                        this
                                                          .handleAcceptEulaChange
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="inputAcceptPrivacyPolicy"
                                                    >
                                                      Elfogadom az adatkezelési
                                                      szabályzatot
                                                    </label>
                                                  </div>
                                                </div>
                                              )}
                                              <div className="fusion-button-wrapper">
                                                <style
                                                  type="text/css"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                  }}
                                                />
                                                <button
                                                  className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no "
                                                  onClick={
                                                    this.handleSubscribeClick
                                                  }
                                                  disabled={
                                                    !this.isValidSubscription()
                                                  }
                                                >
                                                  <span className="fusion-button-text">
                                                    Feliratkozás
                                                  </span>
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>

                                      {this.state.subscriptionValidationErrors
                                        .length > 0 && (
                                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                                          {this.state.subscriptionValidationErrors.map(
                                            (error, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="form-invalid"
                                                >
                                                  {error}
                                                </div>
                                              );
                                            }
                                          )}
                                        </Card>
                                      )}

                                      <p className="fusion-no-small-visibility">
                                        <br />
                                        <br />
                                      </p>
                                    </div>
                                    {!isMobileOnly && (
                                      <div
                                        className="fusion-column-wrapper fusion-flex-column-wrapper-legacy newspaper-image-container"
                                        style={{
                                          backgroundPosition: "left top",
                                          backgroundRepeat: "no-repeat",
                                          WebkitBackgroundSize: "cover",
                                          MozBackgroundSize: "cover",
                                          OBackgroundSize: "cover",
                                          backgroundSize: "cover",
                                          padding: "0px 0px 0px 0px",
                                        }}
                                      >
                                        <span className=" fusion-imageframe imageframe-none imageframe-13 hover-type-none">
                                          <img
                                            width={1000}
                                            height={1000}
                                            data-image_id={10140}
                                            alt="Exkluzív Szépségszalonunk prospektusa"
                                            src="/wp-content/uploads/new/beauty&spav4.png"
                                            className="img-responsive wp-image-10140"
                                            srcSet="/wp-content/uploads/new/beauty&spav4.png 200w, /wp-content/uploads/new/beauty&spav4.png 400w, /wp-content/uploads/new/beauty&spav4.png 600w, /wp-content/uploads/new/beauty&spav4.png 800w, /wp-content/uploads/new/beauty&spav4.png 1000w"
                                            sizes="(max-width: 800px) 100vw, 400px"
                                          />
                                        </span>
                                        <div className="fusion-clearfix" />
                                      </div>
                                    )}
                                    {!isMobileOnly && (
                                      <div className="form-check mb-2">
                                        <div className="d-flex justify-content-start">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="inputAcceptPrivacyPolicy"
                                            checked={this.state.acceptEula}
                                            onChange={
                                              this.handleAcceptEulaChange
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inputAcceptPrivacyPolicy"
                                          >
                                            Elfogadom az adatkezelési
                                            szabályzatot
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    <div className="fusion-text fusion-text-47">
                                      <p>
                                        A személyes adataid biztonságban vannak,
                                        kizárólag hírlevelünk küldéséhez
                                        használjuk fel azokat.
                                      </p>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                {!isMobileOnly && (
                                  <div
                                    className="fusion-layout-column fusion_builder_column fusion-builder-column-69 fusion_builder_column_1_3 1_3 fusion-one-third fusion-no-small-visibility"
                                    style={{
                                      width: "35%",
                                      marginRight: "4%",
                                      marginTop: "0px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <div
                                      className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                      style={{
                                        backgroundPosition: "left top",
                                        backgroundRepeat: "no-repeat",
                                        WebkitBackgroundSize: "cover",
                                        MozBackgroundSize: "cover",
                                        OBackgroundSize: "cover",
                                        backgroundSize: "cover",
                                        padding: "0px 0px 0px 0px",
                                      }}
                                    >
                                      <span className=" fusion-imageframe imageframe-none imageframe-13 hover-type-none">
                                        <img
                                          width={1000}
                                          height={1000}
                                          data-image_id={10140}
                                          alt="Exkluzív Szépségszalonunk prospektusa"
                                          src="/wp-content/uploads/new/beauty&spav4.png"
                                          className="img-responsive wp-image-10140"
                                          srcSet="/wp-content/uploads/new/beauty&spav4.png 200w, /wp-content/uploads/new/beauty&spav4.png 400w, /wp-content/uploads/new/beauty&spav4.png 600w, /wp-content/uploads/new/beauty&spav4.png 800w, /wp-content/uploads/new/beauty&spav4.png 1000w"
                                          sizes="(max-width: 800px) 100vw, 400px"
                                        />
                                      </span>
                                      <div className="fusion-clearfix" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </strong>
                    </div>
                  </div>
                </section>

                {isAdminAndEditing && (
                  <Modal
                    isOpen={this.state.aboutUsTextEditorOpen}
                    toggle={this.toggleAboutUsTextEditor}
                    className="modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggleAboutUsTextEditor}>
                      Szöveg szerkesztése
                    </ModalHeader>
                    <ModalBody>
                      {this.props.mainPage != null && (
                        <React.Fragment>
                          <div className="form-group">
                            <label htmlFor="inputMainPageAboutUsText">
                              Leírás
                            </label>
                            <textarea
                              rows={10}
                              className="form-control"
                              id="inputMainPageAboutUsText"
                              autoComplete="off"
                              placeholder="Írd be a szöveget."
                              value={this.state.aboutUsText}
                              onChange={this.handleAboutUsTextChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputMainPageAboutUsSignatureText1">
                              Aláírás1 szöveg
                            </label>
                            <textarea
                              rows={3}
                              className="form-control"
                              id="inputMainPageAboutUsSignatureText1"
                              autoComplete="off"
                              placeholder="Írd be a szöveget."
                              value={this.state.aboutUsSignatureText1}
                              onChange={this.handleAboutUsSignatureText1Change}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputMainPageAboutUsSignatureText2">
                              Aláírás2 szöveg
                            </label>
                            <textarea
                              rows={3}
                              className="form-control"
                              id="inputMainPageAboutUsSignatureText2"
                              autoComplete="off"
                              placeholder="Írd be a szöveget."
                              value={this.state.aboutUsSignatureText2}
                              onChange={this.handleAboutUsSignatureText2Change}
                            />
                          </div>
                        </React.Fragment>
                      )}

                      {this.state.validationErrorsAboutUsText.length > 0 && (
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                          {this.state.validationErrorsAboutUsText.map(
                            (error, index) => {
                              return (
                                <div key={index} className="form-invalid">
                                  {error}
                                </div>
                              );
                            }
                          )}
                        </Card>
                      )}

                      <div className="d-flex justify-content-between align-items-center mt-4">
                        <button
                          onClick={this.handleUpdateAboutUsTextClick}
                          type="button"
                          className="btn btn-primary"
                          disabled={!this.isValidAboutUsTexts()}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "save"]}
                            className="mr-1"
                          />{" "}
                          Mentés
                        </button>

                        <button
                          className="btn btn-secondary"
                          onClick={this.toggleAboutUsTextEditor}
                        >
                          <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                )}

                {isAdminAndEditing && (
                  <Modal
                    isOpen={this.state.youtubeVideoIdEditorOpen}
                    toggle={this.toggleYoutubeVideoIdEditor}
                    className="modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggleYoutubeVideoIdEditor}>
                      Youtube háttérvideó szerkesztése
                    </ModalHeader>
                    <ModalBody>
                      {this.props.mainPage != null && (
                        <React.Fragment>
                          <div className="form-group">
                            <label htmlFor="inputMainPageYoutubeVideoId">
                              YouTube videóazonosító
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputMainPageYoutubeVideoId"
                              autoComplete="off"
                              placeholder="Írd be a videóazonosítót."
                              value={this.state.youtubeVideoId}
                              onChange={this.handleYoutubeVideoIdChange}
                            />
                          </div>
                        </React.Fragment>
                      )}

                      {this.state.validationErrorsYoutubeVideoId.length > 0 && (
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                          {this.state.validationErrorsYoutubeVideoId.map(
                            (error, index) => {
                              return (
                                <div key={index} className="form-invalid">
                                  {error}
                                </div>
                              );
                            }
                          )}
                        </Card>
                      )}

                      <div className="d-flex justify-content-between align-items-center mt-4">
                        <button
                          onClick={this.handleUpdateYoutubeVideoIdClick}
                          type="button"
                          className="btn btn-primary"
                          disabled={!this.isValidYoutubeVideoId()}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "save"]}
                            className="mr-1"
                          />{" "}
                          Mentés
                        </button>

                        <button
                          className="btn btn-secondary"
                          onClick={this.toggleYoutubeVideoIdEditor}
                        >
                          <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                )}

                {isAdminAndEditing && (
                  <Modal
                    isOpen={this.state.videoOverlayTitlesEditorOpen}
                    toggle={this.toggleVideoOverlayTitlesEditor}
                    className="modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggleVideoOverlayTitlesEditor}>
                      Címek szerkesztése
                    </ModalHeader>
                    <ModalBody>
                      {this.props.mainPage != null && (
                        <React.Fragment>
                          <div className="form-group">
                            <label htmlFor="inputMainPageVideoOverlayMainTitle">
                              Szalagcím
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputMainPageVideoOverlayMainTitle"
                              autoComplete="off"
                              placeholder="Írd be a szalagcímet."
                              value={this.state.videoOverlayMainTitle}
                              onChange={this.handleVideoOverlayMainTitleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputMainPageVideoOverlaySubTitle">
                              Alcím
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputMainPageVideoOverlaySubTitle"
                              autoComplete="off"
                              placeholder="Írd be az alcímet."
                              value={this.state.videoOverlaySubTitle}
                              onChange={this.handleVideoOverlaySubTitleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputMainPageVideoOverlaySubText">
                              Címszöveg
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputMainPageVideoOverlaySubText"
                              autoComplete="off"
                              placeholder="Írd be a címszöveget."
                              value={this.state.videoOverlaySubText}
                              onChange={this.handleVideoOverlaySubTextChange}
                            />
                          </div>
                        </React.Fragment>
                      )}

                      {this.state.validationErrorsVideoOverlayTitles.length >
                        0 && (
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                          {this.state.validationErrorsVideoOverlayTitles.map(
                            (error, index) => {
                              return (
                                <div key={index} className="form-invalid">
                                  {error}
                                </div>
                              );
                            }
                          )}
                        </Card>
                      )}

                      <div className="d-flex justify-content-between align-items-center mt-4">
                        <button
                          onClick={this.handleUpdateVideoOverlayTitlesClick}
                          type="button"
                          className="btn btn-primary"
                          disabled={!this.isValidVideoOverlayTitles()}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "save"]}
                            className="mr-1"
                          />{" "}
                          Mentés
                        </button>

                        <button
                          className="btn btn-secondary"
                          onClick={this.toggleVideoOverlayTitlesEditor}
                        >
                          <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                )}

                {isAdminAndEditing && (
                  <Modal
                    isOpen={this.state.videoOverlayDetailsEditorOpen}
                    toggle={this.toggleVideoOverlayDetailsEditor}
                    className="modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggleVideoOverlayDetailsEditor}>
                      Szolgáltatásösszegző szerkesztése
                    </ModalHeader>
                    <ModalBody>
                      {this.props.mainPage != null && (
                        <React.Fragment>
                          <div className="form-group">
                            <label htmlFor="inputMainPageVideoOverlayDetails">
                              Szolgáltatásösszegző
                            </label>
                            <textarea
                              rows={10}
                              className="form-control"
                              id="inputMainPageVideoOverlayDetails"
                              autoComplete="off"
                              placeholder="Írd be a szolgáltatásösszegzőt."
                              value={this.state.videoOverlayDetails}
                              onChange={this.handleVideoOverlayDetailsChange}
                            />
                          </div>
                        </React.Fragment>
                      )}

                      {this.state.validationErrorsVideoOverlayDetails.length >
                        0 && (
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                          {this.state.validationErrorsVideoOverlayDetails.map(
                            (error, index) => {
                              return (
                                <div key={index} className="form-invalid">
                                  {error}
                                </div>
                              );
                            }
                          )}
                        </Card>
                      )}

                      <div className="d-flex justify-content-between align-items-center mt-4">
                        <button
                          onClick={this.handleUpdateVideoOverlayDetailsClick}
                          type="button"
                          className="btn btn-primary"
                          disabled={!this.isValidVideoOverlayDetails()}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "save"]}
                            className="mr-1"
                          />{" "}
                          Mentés
                        </button>

                        <button
                          className="btn btn-secondary"
                          onClick={this.toggleVideoOverlayDetailsEditor}
                        >
                          <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                )}

                {isAdminAndEditing && (
                  <Modal
                    isOpen={this.state.videoOverlayOpeningHoursEditorOpen}
                    toggle={this.toggleVideoOverlayOpeningHoursEditor}
                    className="modal-dialog-centered"
                  >
                    <ModalHeader
                      toggle={this.toggleVideoOverlayOpeningHoursEditor}
                    >
                      Nyitvatartás szerkesztése
                    </ModalHeader>
                    <ModalBody>
                      {this.props.mainPage != null && (
                        <React.Fragment>
                          <div className="form-group">
                            <label htmlFor="inputMainPageVideoOverlayWeekdayOpeningHours">
                              Hétköznapi nyitvatartás
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputMainPageVideoOverlayWeekdayOpeningHours"
                              autoComplete="off"
                              placeholder="Írd be a hétköznapi nyitvatartást."
                              value={this.state.videoOverlayWeekdayOpeningHours}
                              onChange={
                                this.handleVideoOverlayWeekdayOpeningHoursChange
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputMainPageVideoOverlayWeekendOpeningHours">
                              Hétvégi nyitvatartás
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputMainPageVideoOverlayWeekendOpeningHours"
                              autoComplete="off"
                              placeholder="Írd be a hétvégi nyitvatartást."
                              value={this.state.videoOverlayWeekendOpeningHours}
                              onChange={
                                this.handleVideoOverlayWeekendOpeningHoursChange
                              }
                            />
                          </div>
                        </React.Fragment>
                      )}

                      {this.state.validationErrorsVideoOverlayOpeningHours
                        .length > 0 && (
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                          {this.state.validationErrorsVideoOverlayOpeningHours.map(
                            (error, index) => {
                              return (
                                <div key={index} className="form-invalid">
                                  {error}
                                </div>
                              );
                            }
                          )}
                        </Card>
                      )}

                      <div className="d-flex justify-content-between align-items-center mt-4">
                        <button
                          onClick={
                            this.handleUpdateVideoOverlayOpeningHoursClick
                          }
                          type="button"
                          className="btn btn-primary"
                          disabled={!this.isValidVideoOverlayOpeningHours()}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "save"]}
                            className="mr-1"
                          />{" "}
                          Mentés
                        </button>

                        <button
                          className="btn btn-secondary"
                          onClick={this.toggleVideoOverlayOpeningHoursEditor}
                        >
                          <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                )}
              </div>
            </main>

            <Footer />
          </div>
        </div>
        {this.props.mainPage != null && (
          <React.Fragment>
            {isAdminAndEditing && (
              <React.Fragment>
                <GalleryEditorModal
                  gallery={this.props.mainPage.gallery}
                  images={this.props.images}
                  addImage={(galleryId, imageId, crop) => {
                    this.props.addImageToGallery(galleryId, imageId, crop);
                    this.props.getImages();
                  }}
                  removeImage={this.props.removeImageFromGallery}
                  reorder={this.props.reorderImagesForGallery}
                  isOpen={this.state.galleryEditorOpen}
                  toggle={this.toggleGalleryEditor}
                  uploadImage={(file) => {
                    this.props.uploadImage(file);
                    this.props.getImages();
                  }}
                />
                <CustomerReviewEditorModal
                  customerReviews={this.props.mainPage.customerReviews}
                  isOpen={this.state.customerReviewEditorOpen}
                  toggle={this.toggleCustomerReviewEditor}
                  save={this.props.updateCustomerReview}
                />
                <PromotedServiceEditorModal
                  promotedServices={this.props.mainPage.promotedServices}
                  images={this.props.images}
                  pages={this.props.pages}
                  scrollingPages={this.props.scrollingPages}
                  isOpen={this.state.promotedServiceEditorOpen}
                  toggle={this.togglePromotedServiceEditor}
                  add={(payload) => {
                    this.props.addPromotedServiceToMainPage(payload);
                    this.props.getImages();
                  }}
                  update={(id, payload) => {
                    this.props.updatePromotedServiceForMainPage(id, payload);
                    this.props.getImages();
                  }}
                  delete={this.props.removePromotedServiceFromMainPage}
                  reorder={this.props.reorderPromotedServicesForMainPage}
                  uploadImage={(file) => {
                    this.props.uploadImage(file);
                    this.props.getImages();
                  }}
                />
                <CurrentOfferEditorModal
                  currentOffers={this.props.mainPage.currentOffers}
                  images={this.props.images}
                  pages={this.props.pages}
                  scrollingPages={this.props.scrollingPages}
                  isOpen={this.state.currentOfferEditorOpen}
                  toggle={this.toggleCurrentOfferEditor}
                  add={(payload) => {
                    this.props.addCurrentOfferToMainPage(payload);
                    this.props.getImages();
                  }}
                  update={(id, payload) => {
                    this.props.updateCurrentOfferForMainPage(id, payload);
                    this.props.getImages();
                  }}
                  delete={this.props.removeCurrentOfferFromMainPage}
                  reorder={this.props.reorderCurrentOffersForMainPage}
                  uploadImage={(file) => {
                    this.props.uploadImage(file);
                    this.props.getImages();
                  }}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  private toggleGalleryEditor = () => {
    this.setState({
      galleryEditorOpen: !this.state.galleryEditorOpen,
    });
  };

  private toggleCustomerReviewEditor = () => {
    this.setState({
      customerReviewEditorOpen: !this.state.customerReviewEditorOpen,
    });
  };

  private togglePromotedServiceEditor = () => {
    this.setState({
      promotedServiceEditorOpen: !this.state.promotedServiceEditorOpen,
    });
  };

  private toggleCurrentOfferEditor = () => {
    this.setState({
      currentOfferEditorOpen: !this.state.currentOfferEditorOpen,
    });
  };

  private toggleAboutUsTextEditor = () => {
    this.setState({
      aboutUsTextEditorOpen: !this.state.aboutUsTextEditorOpen,
      aboutUsText:
        this.state.aboutUsTextEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.aboutUsText,
      aboutUsSignatureText1:
        this.state.aboutUsTextEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.aboutUsSignatureText1,
      aboutUsSignatureText2:
        this.state.aboutUsTextEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.aboutUsSignatureText2,
    });
  };

  private handleAboutUsTextChange = (e: any) => {
    this.setState(
      {
        aboutUsText: e.target.value,
      },
      () => this.validateAboutUsTexts(true)
    );
  };

  private handleAboutUsSignatureText1Change = (e: any) => {
    this.setState(
      {
        aboutUsSignatureText1: e.target.value,
      },
      () => this.validateAboutUsTexts(true)
    );
  };

  private handleAboutUsSignatureText2Change = (e: any) => {
    this.setState(
      {
        aboutUsSignatureText2: e.target.value,
      },
      () => this.validateAboutUsTexts(true)
    );
  };

  private handleUpdateAboutUsTextClick = () => {
    const payload: AboutUsTextsPayload = {
      aboutUsText: this.state.aboutUsText,
      aboutUsSignatureText1: this.state.aboutUsSignatureText1,
      aboutUsSignatureText2: this.state.aboutUsSignatureText2,
    };

    this.props.updateMainPageAboutUsTexts(payload);
    this.setState({
      aboutUsTextEditorOpen: false,
      aboutUsText: "",
    });
  };

  private isValidAboutUsTexts = () => {
    return this.validateAboutUsTexts();
  };

  private validateAboutUsTexts = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    //if (this.state.aboutUsText.length === 0) {
    //    validationErrors.push("A leírás megadása kötelező.");
    //}

    //if (this.state.aboutUsText.length < 30) {
    //    validationErrors.push("A leírás túl rövid.");
    //}

    //if (this.state.aboutUsText.length > 1350) {
    //    validationErrors.push("A leírás túl hosszú.");
    //}

    //    if (this.state.aboutUsSignatureText1.length > 102) {
    //        validationErrors.push("Az aláírás1 szöveg túl hosszú.");
    //    }

    //    if (this.state.aboutUsSignatureText2.length === 0) {
    //        validationErrors.push("Az aláírás2 szöveg megadása kötelező.");
    //    }

    //    if (this.state.aboutUsSignatureText2.length < 6) {
    //        validationErrors.push("Az aláírás2 szöveg túl rövid.");
    //    }

    //    if (this.state.aboutUsSignatureText2.length > 80) {
    //        validationErrors.push("Az aláírás2 szöveg túl hosszú.");
    //    }

    //    if (shouldSetState) {
    //        this.setState({
    //            validationErrorsAboutUsText: validationErrors
    //        });
    //    }

    return validationErrors.length === 0;
  };

  private toggleYoutubeVideoIdEditor = () => {
    this.setState({
      youtubeVideoIdEditorOpen: !this.state.youtubeVideoIdEditorOpen,
      youtubeVideoId:
        this.state.youtubeVideoIdEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.backgroundYoutubeVideoId,
    });
  };

  private handleYoutubeVideoIdChange = (e: any) => {
    this.setState(
      {
        youtubeVideoId: e.target.value,
      },
      () => this.validateYoutubeVideoId(true)
    );
  };

  private handleUpdateYoutubeVideoIdClick = () => {
    this.props.updateMainPageYoutubeVideoId(this.state.youtubeVideoId);
    this.setState({
      youtubeVideoIdEditorOpen: false,
      youtubeVideoId: "",
    });
  };

  private isValidYoutubeVideoId = () => {
    return this.validateYoutubeVideoId();
  };

  private validateYoutubeVideoId = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.youtubeVideoId.length === 0) {
      validationErrors.push("A videó azonosító megadása kötelező.");
    }

    if (shouldSetState) {
      this.setState({
        validationErrorsYoutubeVideoId: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private toggleVideoOverlayTitlesEditor = () => {
    this.setState({
      videoOverlayTitlesEditorOpen: !this.state.videoOverlayTitlesEditorOpen,
      videoOverlayMainTitle:
        this.state.videoOverlayTitlesEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.videoOverlayMainTitle,
      videoOverlaySubTitle:
        this.state.videoOverlayTitlesEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.videoOverlaySubTitle,
      videoOverlaySubText:
        this.state.videoOverlayTitlesEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.videoOverlaySubText,
    });
  };

  private handleVideoOverlayMainTitleChange = (e: any) => {
    this.setState(
      {
        videoOverlayMainTitle: e.target.value,
      },
      () => this.validateVideoOverlayTitles(true)
    );
  };

  private handleVideoOverlaySubTitleChange = (e: any) => {
    this.setState(
      {
        videoOverlaySubTitle: e.target.value,
      },
      () => this.validateVideoOverlayTitles(true)
    );
  };

  private handleVideoOverlaySubTextChange = (e: any) => {
    this.setState(
      {
        videoOverlaySubText: e.target.value,
      },
      () => this.validateVideoOverlayTitles(true)
    );
  };

  private handleUpdateVideoOverlayTitlesClick = () => {
    const payload: VideoOverlayTitlesPayload = {
      mainTitle: this.state.videoOverlayMainTitle,
      subTitle: this.state.videoOverlaySubTitle,
      subText: this.state.videoOverlaySubText,
    };

    this.props.updateMainPageVideoOverlayTitles(payload);
    this.setState({
      videoOverlayTitlesEditorOpen: false,
      videoOverlayMainTitle: "",
      videoOverlaySubTitle: "",
      videoOverlaySubText: "",
    });
  };

  private isValidVideoOverlayTitles = () => {
    return this.validateVideoOverlayTitles();
  };

  private validateVideoOverlayTitles = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.videoOverlayMainTitle.length === 0) {
      validationErrors.push("A szalagcím megadása kötelező.");
    }

    if (this.state.videoOverlayMainTitle.length < 10) {
      validationErrors.push("A szalagcím túl rövid.");
    }

    if (this.state.videoOverlayMainTitle.length > 26) {
      validationErrors.push("A szalagcím túl hosszú.");
    }

    if (this.state.videoOverlaySubTitle.length === 0) {
      validationErrors.push("Az alcím megadása kötelező.");
    }

    if (this.state.videoOverlaySubTitle.length < 10) {
      validationErrors.push("Az alcím túl rövid.");
    }

    if (this.state.videoOverlaySubTitle.length > 26) {
      validationErrors.push("Az alcím túl hosszú.");
    }

    if (this.state.videoOverlaySubText.length === 0) {
      validationErrors.push("A címszöveg megadása kötelező.");
    }

    if (this.state.videoOverlaySubText.length < 10) {
      validationErrors.push("A címszöveg túl rövid.");
    }

    if (this.state.videoOverlaySubText.length > 49) {
      validationErrors.push("A címszöveg túl hosszú.");
    }

    if (shouldSetState) {
      this.setState({
        validationErrorsVideoOverlayTitles: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private toggleVideoOverlayDetailsEditor = () => {
    this.setState({
      videoOverlayDetailsEditorOpen: !this.state.videoOverlayDetailsEditorOpen,
      videoOverlayDetails:
        this.state.videoOverlayDetailsEditorOpen || this.props.mainPage == null
          ? ""
          : this.props.mainPage.videoOverlayDetails,
    });
  };

  private handleVideoOverlayDetailsChange = (e: any) => {
    this.setState(
      {
        videoOverlayDetails: e.target.value,
      },
      () => this.validateVideoOverlayDetails(true)
    );
  };

  private handleUpdateVideoOverlayDetailsClick = () => {
    this.props.updateMainPageVideoOverlayDetails(
      this.state.videoOverlayDetails
    );
    this.setState({
      videoOverlayDetailsEditorOpen: false,
      videoOverlayDetails: "",
    });
  };

  private isValidVideoOverlayDetails = () => {
    return this.validateVideoOverlayDetails();
  };

  private validateVideoOverlayDetails = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.videoOverlayDetails.length === 0) {
      validationErrors.push("A szolgáltatásösszegzés megadása kötelező.");
    }

    if (this.state.videoOverlayDetails.length < 124) {
      validationErrors.push("A szolgáltatásösszegzés túl rövid.");
    }

    if (this.state.videoOverlayDetails.length > 313) {
      validationErrors.push("A szolgáltatásösszegzés túl hosszú.");
    }

    if (validationErrors.length === 0) {
      const rows = this.state.videoOverlayDetails.split("\n");
      if (rows.find((x) => x.length > 55)) {
        validationErrors.push(
          "A szolgáltatásösszegzés valamelyik sora túl hosszú, új sorban folytasd."
        );
      }
    }

    if (shouldSetState) {
      this.setState({
        validationErrorsVideoOverlayDetails: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private toggleVideoOverlayOpeningHoursEditor = () => {
    this.setState({
      videoOverlayOpeningHoursEditorOpen:
        !this.state.videoOverlayOpeningHoursEditorOpen,
      videoOverlayWeekdayOpeningHours:
        this.state.videoOverlayOpeningHoursEditorOpen ||
        this.props.mainPage == null
          ? ""
          : this.props.mainPage.weekdayOpeningHours,
      videoOverlayWeekendOpeningHours:
        this.state.videoOverlayOpeningHoursEditorOpen ||
        this.props.mainPage == null
          ? ""
          : this.props.mainPage.weekendOpeningHours,
    });
  };

  private handleVideoOverlayWeekdayOpeningHoursChange = (e: any) => {
    this.setState(
      {
        videoOverlayWeekdayOpeningHours: e.target.value,
      },
      () => this.validateVideoOverlayOpeningHours(true)
    );
  };

  private handleVideoOverlayWeekendOpeningHoursChange = (e: any) => {
    this.setState(
      {
        videoOverlayWeekendOpeningHours: e.target.value,
      },
      () => this.validateVideoOverlayOpeningHours(true)
    );
  };

  private handleUpdateVideoOverlayOpeningHoursClick = () => {
    const payload: VideoOverlayOpeningHoursPayload = {
      weekdayOpeningHours: this.state.videoOverlayWeekdayOpeningHours,
      weekendOpeningHours: this.state.videoOverlayWeekendOpeningHours,
    };

    this.props.updateMainPageVideoOverlayOpeningHours(payload);
    this.setState({
      videoOverlayOpeningHoursEditorOpen: false,
      videoOverlayWeekdayOpeningHours: "",
      videoOverlayWeekendOpeningHours: "",
    });
  };

  private isValidVideoOverlayOpeningHours = () => {
    return this.validateVideoOverlayOpeningHours();
  };

  private validateVideoOverlayOpeningHours = (
    shouldSetState: boolean = false
  ) => {
    const validationErrors: string[] = [];

    if (this.state.videoOverlayWeekdayOpeningHours.length === 0) {
      validationErrors.push("A hétköznapi nyitvatartás megadása kötelező.");
    }

    if (this.state.videoOverlayWeekdayOpeningHours.length < 10) {
      validationErrors.push("A hétköznapi nyitvatartás túl rövid.");
    }

    if (this.state.videoOverlayWeekdayOpeningHours.length > 13) {
      validationErrors.push("A hétköznapi nyitvatartás túl hosszú.");
    }

    if (this.state.videoOverlayWeekendOpeningHours.length === 0) {
      validationErrors.push("A hétvégi nyitvatartás megadása kötelező.");
    }

    if (this.state.videoOverlayWeekendOpeningHours.length < 10) {
      validationErrors.push("A hétvégi nyitvatartás túl rövid.");
    }

    if (this.state.videoOverlayWeekendOpeningHours.length > 13) {
      validationErrors.push("A hétvégi nyitvatartás túl hosszú.");
    }

    if (shouldSetState) {
      this.setState({
        validationErrorsVideoOverlayOpeningHours: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private updateDimensions = () => {
    this.setState({
      youtubeBackgroundVideoWidth: Math.max(window.innerWidth, 965),
      youtubeBackgroundVideoHeight: (Math.max(window.innerWidth, 965) / 16) * 9,
    });
  };

  private handleLastNameChange = (e: any) => {
    this.setState(
      {
        lastName: e.target.value,
      },
      () => this.validateSubscription(true)
    );
  };

  private handleFirstNameChange = (e: any) => {
    this.setState(
      {
        firstName: e.target.value,
      },
      () => this.validateSubscription(true)
    );
  };

  private handleEmailChange = (e: any) => {
    this.setState(
      {
        email: e.target.value,
      },
      () => this.validateSubscription(true)
    );
  };

  private handleAcceptEulaChange = (e: any) => {
    this.setState(
      {
        acceptEula: e.target.checked,
      },
      () => this.validateSubscription(true)
    );
  };

  private isValidSubscription = () => {
    return this.validateSubscription();
  };

  private validateSubscription = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.lastName.length === 0) {
      validationErrors.push("A vezetéknév megadása kötelező.");
    }

    if (this.state.firstName.length === 0) {
      validationErrors.push("A keresztnév megadása kötelező.");
    }

    if (this.state.email.length === 0) {
      validationErrors.push("Az e-mail cím megadása kötelező.");
    }

    if (
      this.state.email.length > 0 &&
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.state.email
      )
    ) {
      validationErrors.push("Az e-mail cím érvénytelen.");
    }

    if (!this.state.acceptEula) {
      validationErrors.push("Az adatkezelési szabályzat elfogadása kötelező.");
    }

    if (shouldSetState) {
      this.setState({
        subscriptionValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private handleSubscribeClick = () => {
    const payload: SubscriptionPayload = {
      lastName: this.state.lastName,
      firstName: this.state.firstName,
      email: this.state.email,
    };

    this.props.subscribeToNewsletter(payload);
  };
}

export default connect(
  (state: ApplicationState, ownProps: MainComponentOwnProps) => {
    return { ...state.oxy, ...ownProps };
  },
  OxyStore.actionCreators
)(MainComponent as any);
