import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { PromotedServiceEditorCard } from "./PromotedServiceEditorCard";
import { ImageDto, PageDto, PromotedServiceDto, ScrollingPageDto } from "../../models";
import { PromotedServicePayload, ReorderPayload } from "../../payloads";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PromotedServiceEditor } from "./PromotedServiceEditor";
import Reorder from 'react-reorder';

interface PromotedServiceEditorModalProps {
    promotedServices: PromotedServiceDto[];
    images: ImageDto[];
    pages: PageDto[];
    scrollingPages: ScrollingPageDto[];
    add: (payload: PromotedServicePayload) => void;
    update: (id: number, payload: PromotedServicePayload) => void;
    delete: (id: number) => void;
    reorder: (payload: ReorderPayload) => void;
    isOpen: boolean;
    toggle: () => void;
    uploadImage: (file: File) => void;
}

interface PromotedServiceEditorModalState {
    promotedServiceIdForEdit: number;
    promotedServiceBeingEdited: PromotedServicePayload;
}

export class PromotedServiceEditorModal extends React.PureComponent<PromotedServiceEditorModalProps, PromotedServiceEditorModalState> {
    constructor(props: PromotedServiceEditorModalProps) {
        super(props);

        this.state = {
            promotedServiceIdForEdit: NaN,
            promotedServiceBeingEdited: {
                title: "",
                subTitle: "",
                imageId: NaN,
                imageSubTitle: "",
                details: "",
                targetId: null,
                targetScrollingId: null,
                crop: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100
                }
            }
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={this.props.toggle}>Kiemelt szolgáltatások szerkesztése</ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-end mb-4">
                            <Button color="primary" onClick={() => this.toggleEditor(0)}>
                                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                            </Button>
                        </div>
                        <div className="mt-2 mb-2">
                            <p>
                                A szolgáltatások sorrendjének módosításához egyszerűen fogd meg az adott szolgáltatást, és húzd a kívánt helyre!
                            </p>
                        </div>
                        {this.props.promotedServices.length <= 3 &&
                            <div className="mb-4">
                                <p>
                                    Egyik kiemelt szolgáltatás sem törölhető, mert az egységes kinézet miatt legalább 3 kiemelt szolgáltatásnak mindig lennie kell a rendszerben.
                                </p>
                            </div>
                        }
                        <div>
                            <Reorder
                                listClass="reorder-container"
                                itemKey="id"
                                list={this.props.promotedServices}
                                sharedProps={{
                                    onImageClick: this.onItemImageClick,
                                    onDeleteClick: this.onItemDeleteClick,
                                    onEditClick: this.onItemEditClick,
                                    deleteEnabled: this.props.promotedServices.length > 3
                                }}
                                holdTime={200}
                                template={PromotedServiceEditorCard}
                                callback={this.handleReorder}
                                itemClicked={() => { }}
                                itemClass=""
                            />
                        </div>
                    </ModalBody>
                </Modal>
                <PromotedServiceEditor
                    isCreate={this.state.promotedServiceIdForEdit === 0}
                    isOpen={!isNaN(this.state.promotedServiceIdForEdit)}
                    toggle={this.toggleEditor}
                    images={this.props.images}
                    pages={this.props.pages}
                    scrollingPages={this.props.scrollingPages}
                    save={this.save}
                    onImageClick={() => { }}
                    payload={this.state.promotedServiceBeingEdited}
                    uploadImage={this.props.uploadImage}
                />
            </React.Fragment>
        );
    }

    private save = (payload: PromotedServicePayload) => {
        if (this.state.promotedServiceIdForEdit === 0) {
            this.props.add(payload);
        } else {
            this.props.update(this.state.promotedServiceIdForEdit, payload);
        }

        this.toggleEditor();
    }

    private handleReorder = (event: any,
        itemThatHasBeenMoved: ImageDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: ImageDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorder(payload);
    }

    private toggleEditor = (id: number = NaN) => {
        this.setState({
            promotedServiceIdForEdit: id,
            promotedServiceBeingEdited: isNaN(id)
                ? {
                    title: "",
                    subTitle: "",
                    imageId: NaN,
                    imageSubTitle: "",
                    details: "",
                    targetId: null,
                    targetScrollingId: null,
                    crop: {
                        x: 0,
                        y: 0,
                        width: 100,
                        height: 100
                    }
                }
                : this.state.promotedServiceBeingEdited
        });
    }

    private onItemImageClick = (name: string) => {
    }

    private onItemDeleteClick = (id: number) => {
        this.props.delete(id);
    }

    private onItemEditClick = (id: number) => {
        const promotedService = this.props.promotedServices.find(x => x.id === id)!!;
        const payload: PromotedServicePayload = {
            title: promotedService.title,
            subTitle: promotedService.subTitle,
            imageId: promotedService.image.id,
            imageSubTitle: promotedService.imageSubTitle,
            details: promotedService.details,
            targetId: promotedService.target == null ? null : promotedService.target.id,
            targetScrollingId: promotedService.targetScrolling == null ? null : promotedService.targetScrolling.id,
            crop: {
                x: 0,
                y: 0,
                width: 100,
                height: 100
            }
        }

        this.setState({
            promotedServiceIdForEdit: id,
            promotedServiceBeingEdited: payload
        });
    }
}

export default PromotedServiceEditorModal;
