import { ProtectedRestApi } from "./";
import { BeforeAfterGalleryDto, BeforeAfterGalleryItemDto, ServiceResult } from "../models";
import { BeforeAfterGalleryItemPayload, ReorderPayload } from "../payloads";

export class BeforeAfterGalleryApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxybeforeaftergallery";

    public getAll(): Promise<ServiceResult<BeforeAfterGalleryDto[]>> {
        return this.httpGet(`${BeforeAfterGalleryApi.baseUrl}/all`, true) as Promise<ServiceResult<BeforeAfterGalleryDto[]>>;
    }

    public getById(galleryId: number): Promise<ServiceResult<BeforeAfterGalleryDto>> {
        return this.httpGet(`${BeforeAfterGalleryApi.baseUrl}/${galleryId}`, true) as Promise<ServiceResult<BeforeAfterGalleryDto>>;
    }

    public updateGallery(galleryId: number, title: string): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${BeforeAfterGalleryApi.baseUrl}/${galleryId}?title=${title}`) as Promise<ServiceResult<boolean>>;
    }

    public addGallery(title: string): Promise<ServiceResult<BeforeAfterGalleryDto>> {
        return this.httpPost(`${BeforeAfterGalleryApi.baseUrl}/add?title=${title}`) as Promise<ServiceResult<BeforeAfterGalleryDto>>;
    }

    public addItem(galleryId: number, payload: BeforeAfterGalleryItemPayload): Promise<ServiceResult<BeforeAfterGalleryItemDto>> {
        return this.httpPost(`${BeforeAfterGalleryApi.baseUrl}/${galleryId}/add`, payload) as Promise<ServiceResult<BeforeAfterGalleryItemDto>>;
    }

    public removeItem(galleryId: number, itemId: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${BeforeAfterGalleryApi.baseUrl}/${galleryId}/${itemId}`) as Promise<ServiceResult<boolean>>;
    }

    public reorderGalleries(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${BeforeAfterGalleryApi.baseUrl}/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }

    public reorderItems(galleryId: number, payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${BeforeAfterGalleryApi.baseUrl}/${galleryId}/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }
}
