import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store/index";
import * as OxyStore from "../store/OxyStore";
import { NavMenu } from "./NavMenu";
import { Footer } from "./Footer";
import { AboutUsSection, AboutUsSectionImagePosition } from "./AboutUsSection";
import { isMobileOnly } from "react-device-detect";
import GalleryBigImage from "./GalleryBigImage";
import Gallery from "./Gallery";
import GalleryMobile from "./GalleryMobile";
import GalleryEditorModal from "./admin/GalleryEditorModal";

interface AboutUsOwnProps {
  editing: boolean | null | undefined;
}

type AboutUsProps = OxyStore.OxyState &
  typeof OxyStore.actionCreators &
  RouteComponentProps<{}> &
  AboutUsOwnProps;

interface AboutUsState {
  adminEntitiesLoaded: boolean;
  galleryEditorOpen: boolean;
}

class AboutUs extends React.PureComponent<AboutUsProps, AboutUsState> {
  constructor(props: AboutUsProps) {
    super(props);

    this.state = {
      adminEntitiesLoaded: false,
      galleryEditorOpen: false,
    };
  }

  componentDidMount() {
    this.props.getAboutUsSections();
    this.props.getAboutUsPage();
  }

  componentWillReceiveProps(nextProps: AboutUsProps) {
    if (nextProps.loggedInUser != null && !this.state.adminEntitiesLoaded) {
      this.setState(
        {
          adminEntitiesLoaded: true,
        },
        () => {
          const isAdmin =
            nextProps.loggedInUser != null && nextProps.loggedInUser.isAdmin;
          const isAdminAndEditing = this.props.editing && isAdmin;
          if (isAdminAndEditing) {
            this.props.getImages();
          }
        }
      );
    }
  }

  public render() {
    const user = this.props.loggedInUser;
    const isAdmin = user != null && user.isAdmin;
    const isAdminAndEditing = this.props.editing && isAdmin;

    return (
      <div id="boxed-wrapper">
        <div className="fusion-sides-frame"></div>
        <div id="wrapper" className="fusion-wrapper">
          <div id="home" style={{ position: "relative", top: "-1px" }}></div>
          <div id="sliders-container"></div>

          <NavMenu {...this.props} />

          <div>
            <main id="main" className="clearfix width-100">
              <div className="fusion-row" style={{ maxWidth: "100%" }}>
                <section id="content" className="full-width">
                  <div
                    id="post-5"
                    className="post-5 page type-page status-publish hentry"
                  >
                    <div className="post-content">
                      <div>
                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundColor: "rgba(204,204,204,0.05)",
                            backgroundImage:
                              'url("/wp-content/uploads/new/placeholder-image.png")',
                            backgroundPosition: isMobileOnly
                              ? "unset"
                              : "center center",
                            backgroundRepeat: "no-repeat",
                            paddingTop: "0",
                            paddingRight: isMobileOnly ? "0" : "30px",
                            paddingBottom: isMobileOnly ? "0" : "400px",
                            paddingLeft: isMobileOnly ? "0" : "30px",
                            marginBottom: isMobileOnly ? "0" : "-100px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                            WebkitBackgroundSize: isMobileOnly
                              ? "contain"
                              : "cover",
                            MozBackgroundSize: isMobileOnly
                              ? "contain"
                              : "cover",
                            OBackgroundSize: isMobileOnly ? "contain" : "cover",
                            backgroundSize: isMobileOnly ? "contain" : "cover",
                          }}
                        >
                          <div
                            className="fusion-builder-row fusion-row"
                            style={{ visibility: "hidden" }}
                          >
                            <div
                              className="fusion-layout-column fusion_builder_column fusion-builder-column-0 fusion_builder_column_1_2 1_2 fusion-one-half fusion-column-first fusion-column-no-min-height fusion-column-inner-bg-wrapper"
                              style={{
                                width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                                marginRight: "4%",
                                marginTop: "30px",
                                marginBottom: "30px",
                              }}
                            >
                              <div
                                className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                style={{ padding: "0px 0px 0px 0px" }}
                              >
                                <div className="fusion-text fusion-text-1">
                                  <h1
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "30px",
                                    }}
                                  >
                                    <span style={{ color: "#ffffff" }}>
                                      <strong>
                                        Professzionális
                                        <br />
                                        SZEMÖLDÖK STYLING
                                      </strong>
                                    </span>
                                  </h1>
                                </div>
                                <div className="fusion-clearfix" />
                              </div>
                              <span className="fusion-column-inner-bg hover-type-zoomin">
                                <a
                                  href="javascript:void(0)"
                                  className="fusion-column-anchor"
                                >
                                  <span
                                    className="fusion-column-inner-bg-image"
                                    style={{
                                      backgroundColor: "rgba(215,188,178,0.47)",
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                    }}
                                  />
                                </a>
                              </span>
                            </div>
                            {!isMobileOnly && (
                              <div
                                className="fusion-layout-column fusion_builder_column fusion-builder-column-1 fusion_builder_column_1_2 1_2 fusion-one-half fusion-column-last fusion-column-no-min-height"
                                style={{
                                  width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                                  marginTop: "0px",
                                  marginBottom: "30px",
                                }}
                              >
                                <div
                                  className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                  style={{
                                    backgroundPosition: "left top",
                                    backgroundRepeat: "no-repeat",
                                    WebkitBackgroundSize: "cover",
                                    MozBackgroundSize: "cover",
                                    OBackgroundSize: "cover",
                                    backgroundSize: "cover",
                                    backgroundColor: "rgba(216,216,216,0)",
                                    padding: "30px 0px 0px 0px",
                                  }}
                                >
                                  <div className="fusion-clearfix" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <strong>
                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundColor: "rgba(232,232,232,0.2)",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            paddingTop: "0px",
                            paddingRight: "30px",
                            paddingBottom: "0px",
                            paddingLeft: "30px",
                            marginBottom: "0px",
                            marginTop: isMobileOnly ? "0" : "32px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="fusion-builder-row fusion-row">
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-14 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "rgba(188,151,120,0.1)",
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "30px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-40 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                                  style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div
                                      className="fusion-text fusion-text-33"
                                      style={{
                                        textAlign: "center",
                                        animationDuration: "2.5s",
                                      }}
                                      data-animationoffset="100%"
                                      data-animationtype="bounce"
                                      data-animationduration="2.5"
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "Garamond, cursive",
                                          textAlign: "center",
                                          fontStyle: "oblique",
                                        }}
                                      >
                                        <strong>OXY ESZTÉTIKAI STÚDIÓ</strong>
                                        <br />
                                        <br />
                                        <strong>
                                          Az, hogy hány éves vagy a Te dolgod,
                                          hogy mennyinek nézel ki, az a miénk!
                                        </strong>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </strong>
                      <div
                        className="fusion-fullwidth fusion-fullwidth-custom-background fullwidth-box fusion-builder-row-8 non-hundred-percent-height-scrolling"
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.84)",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          paddingTop: "0px",
                          paddingRight: "30px",
                          paddingBottom: "0px",
                          paddingLeft: "30px",
                          marginBottom: "30px",
                          marginTop: "0px",
                          borderWidth: "0px 0px 0px 0px",
                          borderColor: "#f7f7f7",
                          borderStyle: "solid",
                          backgroundImage:
                            "url('/wp-content/uploads/new/beautiful-woman-with-clean-face-logo.jpg')",
                          backgroundBlendMode: "overlay",
                          backgroundSize: "cover",
                        }}
                      >
                        <div className="fusion-builder-row fusion-row">
                          <div
                            className="fusion-layout-column fusion_builder_column fusion-builder-column-13 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                            style={{
                              marginTop: "0px",
                              marginBottom: isMobileOnly ? "0" : "30px",
                            }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                WebkitBackgroundSize: "cover",
                                MozBackgroundSize: "cover",
                                OBackgroundSize: "cover",
                                backgroundSize: "cover",
                                padding: "15px 0px 0px 0px",
                              }}
                            >
                              <div className="fusion-column-content-centered">
                                <div className="fusion-column-content">
                                  <div
                                    className="fusion-text fusion-text-33"
                                    style={{
                                      textAlign: "center",
                                      animationDuration: "2.5s",
                                    }}
                                    data-animationoffset="100%"
                                    data-animationtype="bounce"
                                    data-animationduration="2.5"
                                  >
                                    <h3
                                      style={{
                                        fontFamily: "Garamond, cursive",
                                        fontStyle: "oblique",
                                        textAlign: "center",
                                        color: "#b18b5e",
                                        fontWeight: 500,
                                        fontSize: "40px",
                                      }}
                                    >
                                      Vendégeink külső-belső harmóniájának
                                      megteremtésében segítünk, a bőr fiatalos
                                      állapotának, a fizikai és lelki állapot
                                      harmóniájának megőrzésén keresztül.
                                    </h3>
                                    <h3
                                      style={{
                                        textShadow: "white 2px 2px 5px",
                                        fontFamily: "Garamond, cursive",
                                        fontStyle: "oblique",
                                        textAlign: "justify",
                                        color: "#b18b5e",
                                        fontWeight: 500,
                                        fontSize: "40px",
                                      }}
                                    >
                                      Legfőbb célunk a megelőzés a bőr korai
                                      öregedését meggátolni és egy kisugárzó,
                                      ragyogó arcképet felépíteni. Ennek
                                      szellemében megelőző, a kialakult állapot
                                      fenntartására irányuló, személyre szabott
                                      kezeléseket végzünk. A szépség iránti
                                      elkötelezettségünk része, hogy
                                      vendégeinkkel őszinte, barátságos és
                                      hosszú távú kapcsolatot ápoljunk.
                                      Professzionális arckezeléseinket egy
                                      kiemelkedő prémium márkával a{" "}
                                      <strong>Maria Galland</strong> termék
                                      családdal végezzük. Maria Galland egy{" "}
                                      <strong>világszinten elismert</strong>{" "}
                                      francia kozmetikai márka, mely már az első
                                      pillanatban rabul ejt, egyedi, rendkívüli
                                      kifinomult illatvilágával és textúrájával.
                                      A kezelések által nem csak a tökéletes
                                      luxus érzés és kényeztetés garantált,
                                      hanem a különleges manuális technikák és
                                      exkluzív hatóanyagoknak köszönhetően
                                      azonnali lifting hatás érhető el.
                                    </h3>
                                    <h3
                                      style={{
                                        textShadow: "white 4px 4px 4px",
                                        fontFamily: "Garamond, cursive",
                                        fontStyle: "oblique",
                                        textAlign: "center",
                                        color: "#b18b5e",
                                        fontWeight: 500,
                                        fontSize: "40px",
                                      }}
                                    >
                                      Célunk, hogy a hozzánk forduló vendégeink
                                      részére teljes körű kezeléseket tudjuk
                                      nyújtani, a legmodernebb eljárások
                                      alkalmazásával, a vendég igényeit, egyéni
                                      adottságait szem előtt tartva.
                                    </h3>
                                    <h3
                                      style={{
                                        fontFamily: "Garamond, cursive",
                                        fontStyle: "oblique",
                                        textAlign: "center",
                                        color: "#b18b5e",
                                        fontWeight: 500,
                                        fontSize: "40px",
                                      }}
                                    >
                                      <strong>
                                        Szeretnénk egy olyan kis nyugodt
                                        szigetet teremteni, ahol ötvözzük a
                                        SPA-k nyugalmát és a kozmetikumok luxus
                                        mivoltát és ahonnan vendégeink
                                        megújulva, felfrissülve és magabiztosan
                                        léphetnek vissza rohanó világunkba.
                                      </strong>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <div className="fusion-clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.props.aboutUsSections
                        .slice(0, 3)
                        .map((section, index) => (
                          <AboutUsSection
                            key={index}
                            section={section}
                            imagePosition={
                              index % 2 === 0
                                ? AboutUsSectionImagePosition.Right
                                : AboutUsSectionImagePosition.Left
                            }
                            isLast={
                              index === this.props.aboutUsSections.length - 1
                            }
                          />
                        ))}

                      {this.props.aboutUsPage != null &&
                        this.props.aboutUsPage.gallery != null &&
                        this.props.aboutUsPage.gallery.images.length > 0 &&
                        !isMobileOnly && (
                          <GalleryBigImage
                            isAdminAndEditing={isAdminAndEditing === true}
                            toggleEditor={this.toggleGalleryEditor}
                            image={
                              this.props.aboutUsPage.gallery.images[0].image
                            }
                          />
                        )}
                      {this.props.aboutUsPage != null &&
                        this.props.aboutUsPage.gallery != null &&
                        this.props.aboutUsPage.gallery.images.length > 1 &&
                        !isMobileOnly && (
                          <Gallery gallery={this.props.aboutUsPage.gallery} />
                        )}
                      {this.props.aboutUsPage != null &&
                        this.props.aboutUsPage.gallery != null &&
                        this.props.aboutUsPage.gallery.images.length > 0 &&
                        isMobileOnly && (
                          <GalleryMobile
                            gallery={this.props.aboutUsPage.gallery}
                          />
                        )}

                      <strong>
                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling mt-5 pt-5"
                          style={{
                            backgroundColor: "rgba(232,232,232,0.2)",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            paddingTop: "0px",
                            paddingRight: "30px",
                            paddingBottom: "0px",
                            paddingLeft: "30px",
                            marginBottom: "0px",
                            marginTop: "32px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="fusion-builder-row fusion-row">
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-14 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "rgba(188,151,120,0.1)",
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "30px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-40 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                                  style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div
                                      className="fusion-text fusion-text-33 fusion-animated"
                                      style={{
                                        textAlign: "center",
                                        animationDuration: "2.5s",
                                      }}
                                      data-animationoffset="100%"
                                      data-animationtype="bounce"
                                      data-animationduration="2.5"
                                    >
                                      <h2
                                        style={{
                                          textAlign: "center",
                                          marginTop: "-60px",
                                        }}
                                      >
                                        <strong>
                                          Keress minket bizalommal:
                                        </strong>
                                        <br />
                                        Szépítészeid, az Oxy-s lányok
                                      </h2>
                                    </div>
                                    <div className="fusion-aligncenter">
                                      <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                        }}
                                      />
                                      <a
                                        className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no"
                                        href="https://oxy-esztetikai-studio.salonic.hu"
                                      >
                                        <span className="fusion-button-text">
                                          IDŐPONTFOGLALÁS
                                        </span>
                                      </a>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div
                                      className="fusion-separator"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginTop: "0px",
                                        marginBottom: "17px",
                                        width: "100%",
                                        maxWidth: "280px",
                                      }}
                                    ></div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-text fusion-text-34">
                                      <h4 style={{ textAlign: "center" }}>
                                        A szépség belülről fakad, de a
                                        gondoskodás rajtad múlik. Mi azért
                                        dolgozunk minden nap, hogy ebben a
                                        gondoskodásban segítsünk.
                                      </h4>
                                    </div>
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-15 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "#ffffff",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-41 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-first"
                                  style={{
                                    width:
                                      "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                      <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                        <div
                                          className="fusion-flip-box flip-up"
                                          tabIndex={0}
                                        >
                                          <div className="flip-box-inner-wrapper">
                                            <div
                                              className="flip-box-front"
                                              style={{
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "180px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-190px",
                                                backgroundColor: "#ffffff",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#747474",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-front-inner">
                                                <div
                                                  className="flip-box-grafix flip-box-circle"
                                                  style={{
                                                    backgroundColor: "#b18b5e",
                                                    borderColor: "#ebe1e1",
                                                  }}
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="fa-envelope far"
                                                    style={{ color: "#ffffff" }}
                                                  />
                                                </div>
                                                <h2
                                                  className="flip-box-heading"
                                                  style={{ color: "#333333" }}
                                                >
                                                  Írj nekünk!
                                                </h2>
                                                oxyesztetika@gmail.com
                                                <br />
                                                &nbsp;
                                              </div>
                                            </div>
                                            <div
                                              className="flip-box-back"
                                              style={{
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "180px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-190px",
                                                backgroundColor: "#b18b5e",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#ffffff",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-back-inner">
                                                <h3
                                                  className="flip-box-heading-back"
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  24 órán belül válaszolunk
                                                </h3>
                                                <p>
                                                  Amennyiben bármilyen kérdésed
                                                  felmerül, készséggel állunk
                                                  rendelkezésedre!
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearfix" />
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-42 fusion_builder_column_1_3 1_3 fusion-one-third"
                                  style={{
                                    width:
                                      "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                    marginRight: "4%",
                                    marginTop: "0px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                      <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                        <div
                                          className="fusion-flip-box flip-up"
                                          tabIndex={0}
                                        >
                                          <div className="flip-box-inner-wrapper">
                                            <div
                                              className="flip-box-front"
                                              style={{
                                                marginBottom: "-20px",
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "200px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-210px",
                                                backgroundColor: "#ffffff",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#747474",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-front-inner">
                                                <div
                                                  className="flip-box-grafix flip-box-circle"
                                                  style={{
                                                    backgroundColor: "#b18b5e",
                                                    borderColor: "#ebe1e1",
                                                  }}
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="fa-phone fas"
                                                    style={{ color: "#ffffff" }}
                                                  />
                                                </div>
                                                <h2
                                                  className="flip-box-heading"
                                                  style={{ color: "#333333" }}
                                                >
                                                  Hívj minket!
                                                </h2>
                                                06 30 464 2297
                                              </div>
                                            </div>
                                            <div
                                              className="flip-box-back"
                                              style={{
                                                marginBottom: "-20px",
                                                marginLeft: isMobileOnly
                                                  ? "unset"
                                                  : "200px",
                                                marginRight: isMobileOnly
                                                  ? "unset"
                                                  : "-210px",
                                                backgroundColor: "#b18b5e",
                                                borderColor:
                                                  "rgba(255,255,255,0)",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                color: "#ffffff",
                                                transitionDuration: "1.6s",
                                              }}
                                            >
                                              <div className="flip-box-back-inner">
                                                <h3
                                                  className="flip-box-heading-back"
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  Szeretettel várjuk a hívásodat{" "}
                                                  <br /> kedd-péntek 9-18 óráig
                                                  <br />
                                                  hétfő, szombat 8-14 óráig
                                                </h3>
                                                <p>
                                                  Időpontfoglalásban és
                                                  teljeskörű tájékoztatásban
                                                  bármikor segítséget nyújtunk.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearfix" />
                                    <div className="fusion-clearfix" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </strong>
                    </div>
                  </div>
                </section>
              </div>
            </main>

            <Footer />
          </div>
        </div>
        {this.props.aboutUsPage != null && (
          <React.Fragment>
            {isAdminAndEditing && (
              <React.Fragment>
                <GalleryEditorModal
                  gallery={this.props.aboutUsPage.gallery}
                  images={this.props.images}
                  addImage={(galleryId, imageId, crop) => {
                    this.props.addImageToGallery(galleryId, imageId, crop);
                    this.props.getImages();
                  }}
                  removeImage={this.props.removeImageFromGallery}
                  reorder={this.props.reorderImagesForGallery}
                  isOpen={this.state.galleryEditorOpen}
                  toggle={this.toggleGalleryEditor}
                  uploadImage={(file) => {
                    this.props.uploadImage(file);
                    this.props.getImages();
                  }}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  private toggleGalleryEditor = () => {
    this.setState({
      galleryEditorOpen: !this.state.galleryEditorOpen,
    });
  };
}

export default connect((state: ApplicationState, ownProps: AboutUsOwnProps) => {
  return { ...state.oxy, ...ownProps };
}, OxyStore.actionCreators)(AboutUs as any);
