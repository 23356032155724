export const QuillEditorUtils = {
    modules: {
        toolbar: [
            [],
            ['bold'],
            [],
            [],
            []
        ],
    },
    formats: [
        'bold'
    ]
}

export const Section3SubTitleQuillEditorUtils = {
    modules: {
        toolbar: [
            [],
            ['bold', 'italic'],
            [],
            [],
            []
        ],
    },
    formats: [
        'bold', 'italic'
    ]
}
