import { ProtectedRestApi } from "./";
import { ServiceResult, CurrentOfferDto } from "../models";
import { CurrentOfferPayload, ReorderPayload } from "../payloads";

export class CurrentOfferApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxycurrentoffer";

    public addCurrentOfferToMainPage(payload: CurrentOfferPayload): Promise<ServiceResult<CurrentOfferDto>> {
        return this.httpPost(`${CurrentOfferApi.baseUrl}/main/add`, payload) as Promise<ServiceResult<CurrentOfferDto>>;
    }

    public updateCurrentOfferForMainPage(id: number, payload: CurrentOfferPayload): Promise<ServiceResult<CurrentOfferDto>> {
        return this.httpPost(`${CurrentOfferApi.baseUrl}/main/${id}`, payload) as Promise<ServiceResult<CurrentOfferDto>>;
    }

    public removeCurrentOfferFromMainPage(id: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${CurrentOfferApi.baseUrl}/main/${id}`) as Promise<ServiceResult<boolean>>;
    }

    public reorderCurrentOffersForMainPage(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${CurrentOfferApi.baseUrl}/main/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }
}
