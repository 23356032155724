import * as React from "react";
import { PromotedServiceDto } from "../models/PromotedServiceDto";

interface PromotedServiceCardProps {
  promotedService: PromotedServiceDto;
}

class PromotedServiceCard extends React.PureComponent<PromotedServiceCardProps> {
  public render() {
    return (
      <React.Fragment>
        <div className="highlighted-services__background"></div>
        <div className="highlighted-services__item">
          <h2 className="highlighted-services__title">
            <strong>
              {this.props.promotedService.title
                .split("\n")
                .map((row, index) => (
                  <React.Fragment key={index}>
                    {row}
                    {index <
                    this.props.promotedService.title.split("\n").length - 1 ? (
                      <br />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ))}
            </strong>
          </h2>

          <div className="highlighted-services__subtitle">
            {this.props.promotedService.subTitle
              .split("\n")
              .map((row, index) => (
                <React.Fragment key={index}>
                  {row}
                  {index <
                  this.props.promotedService.subTitle.split("\n").length - 1 ? (
                    <br />
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
          </div>

          <img
            className="highlighted-services__image"
            src={`/wp-content/uploads/new/${this.props.promotedService.image.name}`}
          />

          <div className="highlighted-services__description">
            {this.props.promotedService.imageSubTitle}
          </div>

          <div className="highlighted-services__content">
            {this.props.promotedService.details}
          </div>

          {this.props.promotedService.webshopProduct != null && (
            <React.Fragment>
              <a
                href={`/shop/product/${this.props.promotedService.webshopProduct.id}`}
                className="highlighted-services__link"
              >
                Kattints ide a vásárláshoz!
              </a>
              <br />
            </React.Fragment>
          )}

          {this.props.promotedService.isLearnMoreLinkVisible && (
            <a
              href={
                this.props.promotedService.learnMoreExternalLink
                  ? this.props.promotedService.learnMoreExternalLink
                  : `/oldal/${
                      this.props.promotedService.target != null
                        ? this.props.promotedService.target.path
                        : this.props.promotedService.targetScrolling!!.path
                    }`
              }
              className="highlighted-services__link"
              target={
                this.props.promotedService.learnMoreExternalLink
                  ? "_blank"
                  : "_self"
              }
            >
              Bővebben a kezelésről
            </a>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PromotedServiceCard;
