import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
  ServiceResult,
  OxyUser,
  MainPageDto,
  ImageDto,
  PageDto,
  PromotedServiceDto,
  CurrentOfferDto,
  PageToDisplayDto,
  PricingPageDto,
  MenuItemDto,
  AboutUsSectionDto,
  CartItemDto,
  BarionPaymentStartDto,
  SubscriptionDto,
  CouponDto,
  CouponValidationResultDto,
  PricingItemDto,
  ScrollingPageToDisplayDto,
  ScrollingPageDto,
  BeforeAfterGalleryDto,
  BeforeAfterGalleryItemDto,
  GuaranteePageToDisplayDto,
  GuaranteePageDto,
  AboutUsPageDto,
  InfraTrainerPageDto,
} from "../models";
import {
  AuthApi,
  CurrentOfferApi,
  CustomerReviewApi,
  GalleryApi,
  ImageApi,
  PageApi,
  PromotedServiceApi,
  PricingPageApi,
  MenuApi,
  AboutUsApi,
  ContactUsApi,
  ProductCategoryApi,
  OrderApi,
  ProfileApi,
  SubscriptionApi,
  CouponApi,
  UserApi,
  ScrollingPageApi,
  BeforeAfterGalleryApi,
  GuaranteePageApi,
  AboutUsPageApi,
} from "../api";
import {
  AboutUsSectionPayload,
  AboutUsTextsPayload,
  BeforeAfterGalleryItemPayload,
  CartPayload,
  ContactUsMessagePayload,
  CropPayload,
  CurrentOfferPayload,
  CustomerReviewUpdatePayload,
  OrderPayload,
  PageBulletPointListWithTitlePayload,
  PageImageWithDescriptionPayload,
  PageImageWithTextSideBySidePayload,
  PageTitleWithTextSectionPayload,
  PricingMenuItemPayload,
  PricingPageItemPayload,
  PricingPageTextsPayload,
  ProductCategoryPayload,
  ProductOptionPayload,
  ProductPayload,
  PromotedServicePayload,
  ReorderPayload,
  Section8ImagePayload,
  ServicesMenuItemPayload,
  SkinProblemsMenuItemPayload,
  SubscriptionPayload,
  UpdateProfilePayload,
  VideoOverlayOpeningHoursPayload,
  VideoOverlayTitlesPayload,
  WebshopMenuItemPayload,
} from "../payloads";
import {
  CartType,
  OrderDto,
  ProductCategoryDto,
  ProductDto,
  ProductOptionDto,
} from "../models/shop";
import { PagedImageResult } from "../models/PagedImageResult";

export interface OxyState {
  userReceived: boolean;
  loggedInUser: OxyUser | null;
  mainPage: MainPageDto | null;
  infraTrainerPage: InfraTrainerPageDto | null;
  currentPage: PageToDisplayDto | null | undefined;
  currentPricingPage: PricingPageDto | null | undefined;
  currentGuaranteePage: GuaranteePageToDisplayDto | null | undefined;
  currentScrollingPage: ScrollingPageToDisplayDto | null | undefined;
  pages: PageDto[];
  scrollingPages: ScrollingPageDto[];
  guaranteePages: GuaranteePageDto[];
  pricingPages: PricingPageDto[];
  images: ImageDto[];
  imagesPaged: PagedImageResult;
  servicesMenu: MenuItemDto[];
  pricingMenu: MenuItemDto[];
  webshopMenu: MenuItemDto[];
  skinProblemsMenu: MenuItemDto[];
  aboutUsSections: AboutUsSectionDto[];
  result: boolean | null;
  loading: number;
  errorMessage: string | null;
  errorDetails: string | null;
  pageOperationFinished: boolean;
  guaranteePageOperationFinished: boolean;
  pricingPageOperationFinished: boolean;
  menuOperationFinished: boolean;
  aboutUsOperationFinished: boolean;
  contactUsMessageOperationFinished: boolean;
  webshopMainPageScrollPosition: number;
  productCategories: ProductCategoryDto[];
  productCategoryOperationFinished: boolean;
  promotedProducts: ProductDto[];
  myCart: CartItemDto[];
  cartType: CartType;
  barionPayment: BarionPaymentStartDto | null;
  order: OrderDto | null;
  myOrders: OrderDto[];
  allOrders: OrderDto[];
  subscriptionOperationFinished: boolean;
  subscriptions: SubscriptionDto[];
  coupons: CouponDto[];
  users: OxyUser[];
  aboutUsPage: AboutUsPageDto | null;
  couponValidationResult: CouponValidationResultDto | null;
  getOperationFinished: boolean;
  beforeAfterGalleries: BeforeAfterGalleryDto[];
}

export interface GetUserRequestAction {
  type: "GET_USER_REQUEST";
}

export interface GetUserResponseAction {
  type: "GET_USER_RESPONSE";
  user: OxyUser | null;
}

export interface GetMainPageRequestAction {
  type: "GET_MAIN_PAGE_REQUEST";
}

export interface GetMainPageResponseAction {
  type: "GET_MAIN_PAGE_RESPONSE";
  result: ServiceResult<MainPageDto>;
}

export interface GetInfraTrainerPageRequestAction {
  type: "GET_INFRATRAINER_PAGE_REQUEST";
}

export interface GetInfraTrainerPageResponseAction {
  type: "GET_INFRATRAINER_PAGE_RESPONSE";
  result: ServiceResult<InfraTrainerPageDto>;
}

export interface GetPagesRequestAction {
  type: "GET_PAGES_REQUEST";
}

export interface GetPagesResponseAction {
  type: "GET_PAGES_RESPONSE";
  result: ServiceResult<PageDto[]>;
}

export interface GetScrollingPagesRequestAction {
  type: "GET_SCROLLING_PAGES_REQUEST";
}

export interface GetScrollingPagesResponseAction {
  type: "GET_SCROLLING_PAGES_RESPONSE";
  result: ServiceResult<ScrollingPageDto[]>;
}

export interface GetPageByPathRequestAction {
  type: "GET_PAGE_BY_PATH_REQUEST";
}

export interface GetPageByPathResponseAction {
  type: "GET_PAGE_BY_PATH_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface GetScrollingPageByPathRequestAction {
  type: "GET_SCROLLING_PAGE_BY_PATH_REQUEST";
}

export interface GetScrollingPageByPathResponseAction {
  type: "GET_SCROLLING_PAGE_BY_PATH_RESPONSE";
  result: ServiceResult<ScrollingPageToDisplayDto>;
}

export interface UpdateMainPageAboutUsTextRequestAction {
  type: "UPDATE_MAIN_PAGE_ABOUT_US_TEXT_REQUEST";
}

export interface UpdateMainPageAboutUsTextResponseAction {
  type: "UPDATE_MAIN_PAGE_ABOUT_US_TEXT_RESPONSE";
  result: ServiceResult<boolean>;
  payload: AboutUsTextsPayload;
}

export interface UpdateMainPageYoutubeVideoIdRequestAction {
  type: "UPDATE_MAIN_PAGE_YOUTUBE_VIDEO_ID_REQUEST";
}

export interface UpdateMainPageYoutubeVideoIdResponseAction {
  type: "UPDATE_MAIN_PAGE_YOUTUBE_VIDEO_ID_RESPONSE";
  result: ServiceResult<boolean>;
  videoId: string;
}

export interface UpdateMainPageVideoOverlayTitlesRequestAction {
  type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_TITLES_REQUEST";
}

export interface UpdateMainPageVideoOverlayTitlesResponseAction {
  type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_TITLES_RESPONSE";
  result: ServiceResult<boolean>;
  payload: VideoOverlayTitlesPayload;
}

export interface UpdateMainPageVideoOverlayDetailsRequestAction {
  type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_DETAILS_REQUEST";
}

export interface UpdateMainPageVideoOverlayDetailsResponseAction {
  type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_DETAILS_RESPONSE";
  result: ServiceResult<boolean>;
  text: string;
}

export interface UpdateMainPageVideoOverlayOpeningHoursRequestAction {
  type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_OPENING_HOURS_REQUEST";
}

export interface UpdateMainPageVideoOverlayOpeningHoursResponseAction {
  type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_OPENING_HOURS_RESPONSE";
  result: ServiceResult<boolean>;
  payload: VideoOverlayOpeningHoursPayload;
}

export interface UpdatePageSection1RequestAction {
  type: "UPDATE_PAGE_SECTION_1_REQUEST";
}

export interface UpdatePageSection1ResponseAction {
  type: "UPDATE_PAGE_SECTION_1_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageSection3RequestAction {
  type: "UPDATE_PAGE_SECTION_3_REQUEST";
}

export interface UpdatePageSection3ResponseAction {
  type: "UPDATE_PAGE_SECTION_3_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageSection4RequestAction {
  type: "UPDATE_PAGE_SECTION_4_REQUEST";
}

export interface UpdatePageSection4ResponseAction {
  type: "UPDATE_PAGE_SECTION_4_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageSection5RequestAction {
  type: "UPDATE_PAGE_SECTION_5_REQUEST";
}

export interface UpdatePageSection5ResponseAction {
  type: "UPDATE_PAGE_SECTION_5_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageSection7RequestAction {
  type: "UPDATE_PAGE_SECTION_7_REQUEST";
}

export interface UpdatePageSection7ResponseAction {
  type: "UPDATE_PAGE_SECTION_7_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageSection8RequestAction {
  type: "UPDATE_PAGE_SECTION_8_REQUEST";
}

export interface UpdatePageSection8ResponseAction {
  type: "UPDATE_PAGE_SECTION_8_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageSection9RequestAction {
  type: "UPDATE_PAGE_SECTION_9_REQUEST";
}

export interface UpdatePageSection9ResponseAction {
  type: "UPDATE_PAGE_SECTION_9_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageSection10RequestAction {
  type: "UPDATE_PAGE_SECTION_10_REQUEST";
}

export interface UpdatePageSection10ResponseAction {
  type: "UPDATE_PAGE_SECTION_10_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface CopyPageRequestAction {
  type: "COPY_PAGE_REQUEST";
}

export interface CopyPageResponseAction {
  type: "COPY_PAGE_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface UpdatePageRequestAction {
  type: "UPDATE_PAGE_REQUEST";
}

export interface UpdatePageResponseAction {
  type: "UPDATE_PAGE_RESPONSE";
  result: ServiceResult<PageToDisplayDto>;
}

export interface DeletePageRequestAction {
  type: "DELETE_PAGE_REQUEST";
}

export interface DeletePageResponseAction {
  type: "DELETE_PAGE_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface GetPricingPagesRequestAction {
  type: "GET_PRICING_PAGES_REQUEST";
}

export interface GetPricingPagesResponseAction {
  type: "GET_PRICING_PAGES_RESPONSE";
  result: ServiceResult<PricingPageDto[]>;
}

export interface GetPricingPageByPathRequestAction {
  type: "GET_PRICING_PAGE_BY_PATH_REQUEST";
}

export interface GetPricingPageByPathResponseAction {
  type: "GET_PRICING_PAGE_BY_PATH_RESPONSE";
  result: ServiceResult<PricingPageDto>;
}

export interface UpdatePricingPageTextsRequestAction {
  type: "UPDATE_PRICING_PAGE_TEXTS_REQUEST";
}

export interface UpdatePricingPageTextsResponseAction {
  type: "UPDATE_PRICING_PAGE_TEXTS_RESPONSE";
  result: ServiceResult<PricingPageDto>;
}

export interface AddPricingPageItemRequestAction {
  type: "ADD_PRICING_PAGE_ITEM_REQUEST";
}

export interface AddPricingPageItemResponseAction {
  type: "ADD_PRICING_PAGE_ITEM_RESPONSE";
  result: ServiceResult<PricingItemDto>;
}

export interface UpdatePricingPageItemRequestAction {
  type: "UPDATE_PRICING_PAGE_ITEM_REQUEST";
}

export interface UpdatePricingPageItemResponseAction {
  type: "UPDATE_PRICING_PAGE_ITEM_RESPONSE";
  result: ServiceResult<PricingItemDto>;
}

export interface DeletePricingPageItemRequestAction {
  type: "DELETE_PRICING_PAGE_ITEM_REQUEST";
}

export interface DeletePricingPageItemResponseAction {
  type: "DELETE_PRICING_PAGE_ITEM_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface ReorderPricingPageItemsRequestAction {
  type: "REORDER_PRICING_PAGE_ITEMS_REQUEST";
}

export interface ReorderPricingPageItemsResponseAction {
  type: "REORDER_PRICING_PAGE_ITEMS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface CopyPricingPageRequestAction {
  type: "COPY_PRICING_PAGE_REQUEST";
}

export interface CopyPricingPageResponseAction {
  type: "COPY_PRICING_PAGE_RESPONSE";
  result: ServiceResult<PricingPageDto>;
}

export interface DeletePricingPageRequestAction {
  type: "DELETE_PRICING_PAGE_REQUEST";
}

export interface DeletePricingPageResponseAction {
  type: "DELETE_PRICING_PAGE_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface GetImagesRequestAction {
  type: "GET_IMAGES_REQUEST";
}

export interface GetImagesResponseAction {
  type: "GET_IMAGES_RESPONSE";
  result: ServiceResult<ImageDto[]>;
}

export interface GetImagesPagedRequestAction {
  type: "GET_IMAGES_PAGED_REQUEST";
}

export interface GetImagesPagedResponseAction {
  type: "GET_IMAGES_PAGED_RESPONSE";
  result: ServiceResult<PagedImageResult>;
}

export interface UploadImageRequestAction {
  type: "UPLOAD_IMAGE_REQUEST";
}

export interface UploadImageResponseAction {
  type: "UPLOAD_IMAGE_RESPONSE";
  result: ServiceResult<ImageDto>;
}

export interface DeleteImageRequestAction {
  type: "DELETE_IMAGE_REQUEST";
}

export interface DeleteImageResponseAction {
  type: "DELETE_IMAGE_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface AddPromotedServiceToMainPageRequestAction {
  type: "ADD_PROMOTED_SERVICE_TO_MAIN_PAGE_REQUEST";
}

export interface AddPromotedServiceToMainPageResponseAction {
  type: "ADD_PROMOTED_SERVICE_TO_MAIN_PAGE_RESPONSE";
  result: ServiceResult<PromotedServiceDto>;
}

export interface UpdatePromotedServiceForMainPageRequestAction {
  type: "UPDATE_PROMOTED_SERVICE_FOR_MAIN_PAGE_REQUEST";
}

export interface UpdatePromotedServiceForMainPageResponseAction {
  type: "UPDATE_PROMOTED_SERVICE_FOR_MAIN_PAGE_RESPONSE";
  result: ServiceResult<PromotedServiceDto>;
  id: number;
}

export interface RemovePromotedServiceFromMainPageRequestAction {
  type: "REMOVE_PROMOTED_SERVICE_FROM_MAIN_PAGE_REQUEST";
}

export interface RemovePromotedServiceFromMainPageResponseAction {
  type: "REMOVE_PROMOTED_SERVICE_FROM_MAIN_PAGE_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface ReorderPromotedServicesForMainPageRequestAction {
  type: "REORDER_PROMOTED_SERVICES_FOR_MAIN_PAGE_REQUEST";
}

export interface ReorderPromotedServicesForMainPageResponseAction {
  type: "REORDER_PROMOTED_SERVICES_FOR_MAIN_PAGE_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface AddCurrentOfferToMainPageRequestAction {
  type: "ADD_CURRENT_OFFER_TO_MAIN_PAGE_REQUEST";
}

export interface AddCurrentOfferToMainPageResponseAction {
  type: "ADD_CURRENT_OFFER_TO_MAIN_PAGE_RESPONSE";
  result: ServiceResult<CurrentOfferDto>;
}

export interface UpdateCurrentOfferForMainPageRequestAction {
  type: "UPDATE_CURRENT_OFFER_FOR_MAIN_PAGE_REQUEST";
}

export interface UpdateCurrentOfferForMainPageResponseAction {
  type: "UPDATE_CURRENT_OFFER_FOR_MAIN_PAGE_RESPONSE";
  result: ServiceResult<CurrentOfferDto>;
  id: number;
}

export interface RemoveCurrentOfferFromMainPageRequestAction {
  type: "REMOVE_CURRENT_OFFER_FROM_MAIN_PAGE_REQUEST";
}

export interface RemoveCurrentOfferFromMainPageResponseAction {
  type: "REMOVE_CURRENT_OFFER_FROM_MAIN_PAGE_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface ReorderCurrentOffersForMainPageRequestAction {
  type: "REORDER_CURRENT_OFFERS_FOR_MAIN_PAGE_REQUEST";
}

export interface ReorderCurrentOffersForMainPageResponseAction {
  type: "REORDER_CURRENT_OFFERS_FOR_MAIN_PAGE_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface UpdateCustomerReviewRequestAction {
  type: "UPDATE_CUSTOMER_REVIEW_REQUEST";
}

export interface UpdateCustomerReviewResponseAction {
  type: "UPDATE_CUSTOMER_REVIEW_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
  payload: CustomerReviewUpdatePayload;
}

export interface AddImageToGalleryRequestAction {
  type: "ADD_IMAGE_TO_GALLERY_REQUEST";
}

export interface AddImageToGalleryResponseAction {
  type: "ADD_IMAGE_TO_GALLERY_RESPONSE";
  result: ServiceResult<ImageDto>;
  galleryId: number;
  imageId: number;
}

export interface RemoveImageFromGalleryRequestAction {
  type: "REMOVE_IMAGE_FROM_GALLERY_REQUEST";
}

export interface RemoveImageFromGalleryResponseAction {
  type: "REMOVE_IMAGE_FROM_GALLERY_RESPONSE";
  result: ServiceResult<boolean>;
  galleryId: number;
  imageId: number;
}

export interface ReorderImagesForGalleryRequestAction {
  type: "REORDER_IMAGES_FOR_GALLERY_REQUEST";
}

export interface ReorderImagesForGalleryResponseAction {
  type: "REORDER_IMAGES_FOR_GALLERY_RESPONSE";
  result: ServiceResult<boolean>;
  galleryId: number;
  idsInOrder: number[];
}

export interface GetServicesMenuRequestAction {
  type: "GET_SERVICES_MENU_REQUEST";
}

export interface GetServicesMenuResponseAction {
  type: "GET_SERVICES_MENU_RESPONSE";
  result: ServiceResult<MenuItemDto[]>;
}

export interface GetPricingMenuRequestAction {
  type: "GET_PRICING_MENU_REQUEST";
}

export interface GetPricingMenuResponseAction {
  type: "GET_PRICING_MENU_RESPONSE";
  result: ServiceResult<MenuItemDto[]>;
}

export interface GetWebshopMenuRequestAction {
  type: "GET_WEBSHOP_MENU_REQUEST";
}

export interface GetWebshopMenuResponseAction {
  type: "GET_WEBSHOP_MENU_RESPONSE";
  result: ServiceResult<MenuItemDto[]>;
}

export interface GetSkinProblemsMenuRequestAction {
  type: "GET_SKIN_PROBLEMS_MENU_REQUEST";
}

export interface GetSkinProblemsMenuResponseAction {
  type: "GET_SKIN_PROBLEMS_MENU_RESPONSE";
  result: ServiceResult<MenuItemDto[]>;
}

export interface AddServicesParentMenuItemRequestAction {
  type: "ADD_SERVICES_PARENT_MENU_ITEM_REQUEST";
}

export interface AddServicesParentMenuItemResponseAction {
  type: "ADD_SERVICES_PARENT_MENU_ITEM_RESPONSE";
  result: ServiceResult<MenuItemDto>;
}

export interface AddServicesLeafMenuItemRequestAction {
  type: "ADD_SERVICES_LEAF_MENU_ITEM_REQUEST";
}

export interface AddServicesLeafMenuItemResponseAction {
  type: "ADD_SERVICES_LEAF_MENU_ITEM_RESPONSE";
  parentId: number;
  result: ServiceResult<MenuItemDto>;
}

export interface DeleteServicesMenuItemRequestAction {
  type: "DELETE_SERVICES_MENU_ITEM_REQUEST";
}

export interface DeleteServicesMenuItemResponseAction {
  type: "DELETE_SERVICES_MENU_ITEM_RESPONSE";
  id: number;
  result: ServiceResult<MenuItemDto[]>;
}

export interface ReorderServicesParentMenuItemsRequestAction {
  type: "REORDER_SERVICES_PARENT_MENU_ITEMS_REQUEST";
}

export interface ReorderServicesParentMenuItemsResponseAction {
  type: "REORDER_SERVICES_PARENT_MENU_ITEMS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface ReorderServicesLeafMenuItemsRequestAction {
  type: "REORDER_SERVICES_LEAF_MENU_ITEMS_REQUEST";
}

export interface ReorderServicesLeafMenuItemsResponseAction {
  type: "REORDER_SERVICES_LEAF_MENU_ITEMS_RESPONSE";
  result: ServiceResult<boolean>;
  parentId: number;
  idsInOrder: number[];
}

export interface AddPricingMenuItemRequestAction {
  type: "ADD_PRICING_MENU_ITEM_REQUEST";
}

export interface AddPricingMenuItemResponseAction {
  type: "ADD_PRICING_MENU_ITEM_RESPONSE";
  result: ServiceResult<MenuItemDto>;
}

export interface DeletePricingMenuItemRequestAction {
  type: "DELETE_PRICING_MENU_ITEM_REQUEST";
}

export interface DeletePricingMenuItemResponseAction {
  type: "DELETE_PRICING_MENU_ITEM_RESPONSE";
  id: number;
  result: ServiceResult<MenuItemDto[]>;
}

export interface ReorderPricingMenuItemsRequestAction {
  type: "REORDER_PRICING_MENU_ITEMS_REQUEST";
}

export interface ReorderPricingMenuItemsResponseAction {
  type: "REORDER_PRICING_MENU_ITEMS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface AddWebshopMenuItemRequestAction {
  type: "ADD_WEBSHOP_MENU_ITEM_REQUEST";
}

export interface AddWebshopMenuItemResponseAction {
  type: "ADD_WEBSHOP_MENU_ITEM_RESPONSE";
  result: ServiceResult<MenuItemDto>;
}

export interface DeleteWebshopMenuItemRequestAction {
  type: "DELETE_WEBSHOP_MENU_ITEM_REQUEST";
}

export interface DeleteWebshopMenuItemResponseAction {
  type: "DELETE_WEBSHOP_MENU_ITEM_RESPONSE";
  id: number;
  result: ServiceResult<MenuItemDto[]>;
}

export interface ReorderWebshopMenuItemsRequestAction {
  type: "REORDER_WEBSHOP_MENU_ITEMS_REQUEST";
}

export interface ReorderWebshopMenuItemsResponseAction {
  type: "REORDER_WEBSHOP_MENU_ITEMS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface AddSkinProblemsMenuItemRequestAction {
  type: "ADD_SKIN_PROBLEMS_MENU_ITEM_REQUEST";
}

export interface AddSkinProblemsMenuItemResponseAction {
  type: "ADD_SKIN_PROBLEMS_MENU_ITEM_RESPONSE";
  result: ServiceResult<MenuItemDto>;
}

export interface DeleteSkinProblemsMenuItemRequestAction {
  type: "DELETE_SKIN_PROBLEMS_MENU_ITEM_REQUEST";
}

export interface DeleteSkinProblemsMenuItemResponseAction {
  type: "DELETE_SKIN_PROBLEMS_MENU_ITEM_RESPONSE";
  id: number;
  result: ServiceResult<MenuItemDto[]>;
}

export interface ReorderSkinProblemsMenuItemsRequestAction {
  type: "REORDER_SKIN_PROBLEMS_MENU_ITEMS_REQUEST";
}

export interface ReorderSkinProblemsMenuItemsResponseAction {
  type: "REORDER_SKIN_PROBLEMS_MENU_ITEMS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface GetAboutUsSectionsRequestAction {
  type: "GET_ABOUT_US_SECTIONS_REQUEST";
}

export interface GetAboutUsSectionsResponseAction {
  type: "GET_ABOUT_US_SECTIONS_RESPONSE";
  result: ServiceResult<AboutUsSectionDto[]>;
}

export interface AddAboutUsSectionRequestAction {
  type: "ADD_ABOUT_US_SECTION_REQUEST";
}

export interface AddAboutUsSectionResponseAction {
  type: "ADD_ABOUT_US_SECTION_RESPONSE";
  result: ServiceResult<AboutUsSectionDto>;
}

export interface UpdateAboutUsSectionRequestAction {
  type: "UPDATE_ABOUT_US_SECTION_REQUEST";
}

export interface UpdateAboutUsSectionResponseAction {
  type: "UPDATE_ABOUT_US_SECTION_RESPONSE";
  result: ServiceResult<AboutUsSectionDto>;
  id: number;
}

export interface DeleteAboutUsSectionRequestAction {
  type: "DELETE_ABOUT_US_SECTION_REQUEST";
}

export interface DeleteAboutUsSectionResponseAction {
  type: "DELETE_ABOUT_US_SECTION_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface ReorderAboutUsSectionsRequestAction {
  type: "REORDER_ABOUT_US_SECTIONS_REQUEST";
}

export interface ReorderAboutUsSectionsResponseAction {
  type: "REORDER_ABOUT_US_SECTIONS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface SendContactUsMessageRequestAction {
  type: "SEND_CONTACT_US_MESSAGE_REQUEST";
}

export interface SendContactUsMessageResponseAction {
  type: "SEND_CONTACT_US_MESSAGE_RESPONSE";
  result: ServiceResult<boolean>;
}

export interface UpdateWebshopMainPageScrollPositionAction {
  type: "UPDATE_WEBSHOP_MAIN_PAGE_SCROLL_POSITION";
  scrollTop: number;
}

export interface GetProductCategoriesRequestAction {
  type: "GET_PRODUCT_CATEGORIES_REQUEST";
}

export interface GetProductCategoriesResponseAction {
  type: "GET_PRODUCT_CATEGORIES_RESPONSE";
  result: ServiceResult<ProductCategoryDto[]>;
}

export interface AddProductCategoryRequestAction {
  type: "ADD_PRODUCT_CATEGORY_REQUEST";
}

export interface AddProductCategoryResponseAction {
  type: "ADD_PRODUCT_CATEGORY_RESPONSE";
  result: ServiceResult<ProductCategoryDto>;
}

export interface UpdateProductCategoryRequestAction {
  type: "UPDATE_PRODUCT_CATEGORY_REQUEST";
}

export interface UpdateProductCategoryResponseAction {
  type: "UPDATE_PRODUCT_CATEGORY_RESPONSE";
  result: ServiceResult<ProductCategoryDto>;
}

export interface ToggleProductCategoryVisibleRequestAction {
  type: "TOGGLE_PRODUCT_CATEGORY_VISIBLE_REQUEST";
}

export interface ToggleProductCategoryVisibleResponseAction {
  type: "TOGGLE_PRODUCT_CATEGORY_VISIBLE_RESPONSE";
  result: ServiceResult<ProductCategoryDto>;
}

export interface ReorderProductCategoriesRequestAction {
  type: "REORDER_PRODUCT_CATEGORIES_REQUEST";
}

export interface ReorderProductCategoriesResponseAction {
  type: "REORDER_PRODUCT_CATEGORIES_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface AddProductRequestAction {
  type: "ADD_PRODUCT_REQUEST";
}

export interface AddProductResponseAction {
  type: "ADD_PRODUCT_RESPONSE";
  result: ServiceResult<ProductDto>;
}

export interface UpdateProductRequestAction {
  type: "UPDATE_PRODUCT_REQUEST";
}

export interface UpdateProductResponseAction {
  type: "UPDATE_PRODUCT_RESPONSE";
  result: ServiceResult<ProductDto>;
}

export interface ToggleProductVisibleRequestAction {
  type: "TOGGLE_PRODUCT_VISIBLE_REQUEST";
}

export interface ToggleProductVisibleResponseAction {
  type: "TOGGLE_PRODUCT_VISIBLE_RESPONSE";
  result: ServiceResult<ProductDto>;
}

export interface ReorderProductsRequestAction {
  type: "REORDER_PRODUCTS_REQUEST";
}

export interface ReorderProductsResponseAction {
  type: "REORDER_PRODUCTS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
  categoryId: number;
}

export interface AddProductOptionRequestAction {
  type: "ADD_PRODUCT_OPTION_REQUEST";
}

export interface AddProductOptionResponseAction {
  type: "ADD_PRODUCT_OPTION_RESPONSE";
  result: ServiceResult<ProductOptionDto>;
}

export interface UpdateProductOptionRequestAction {
  type: "UPDATE_PRODUCT_OPTION_REQUEST";
}

export interface UpdateProductOptionResponseAction {
  type: "UPDATE_PRODUCT_OPTION_RESPONSE";
  result: ServiceResult<ProductOptionDto>;
}

export interface ToggleProductOptionVisibleRequestAction {
  type: "TOGGLE_PRODUCT_OPTION_VISIBLE_REQUEST";
}

export interface ToggleProductOptionVisibleResponseAction {
  type: "TOGGLE_PRODUCT_OPTION_VISIBLE_RESPONSE";
  result: ServiceResult<ProductOptionDto>;
}

export interface ReorderProductOptionsRequestAction {
  type: "REORDER_PRODUCT_OPTIONS_REQUEST";
}

export interface ReorderProductOptionsResponseAction {
  type: "REORDER_PRODUCT_OPTIONS_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
  productId: number;
}

export interface GetPromotedProductsRequestAction {
  type: "GET_PROMOTED_PRODUCTS_REQUEST";
}

export interface GetPromotedProductsResponseAction {
  type: "GET_PROMOTED_PRODUCTS_RESPONSE";
  result: ServiceResult<ProductDto[]>;
}

export interface GetMyCartRequestAction {
  type: "GET_MY_CART_REQUEST";
}

export interface GetMyCartResponseAction {
  type: "GET_MY_CART_RESPONSE";
  result: ServiceResult<CartItemDto[]>;
}

export interface AddProductToMyCartRequestAction {
  type: "ADD_PRODUCT_TO_MY_CART_REQUEST";
}

export interface AddProductToMyCartResponseAction {
  type: "ADD_PRODUCT_TO_MY_CART_RESPONSE";
  result: ServiceResult<CartItemDto>;
}

export interface IncreaseMyCartItemQuantityRequestAction {
  type: "INCREASE_MY_CART_ITEM_QUANTITY_REQUEST";
}

export interface IncreaseMyCartItemQuantityResponseAction {
  type: "INCREASE_MY_CART_ITEM_QUANTITY_RESPONSE";
  result: ServiceResult<CartItemDto>;
}

export interface DecreaseMyCartItemQuantityRequestAction {
  type: "DECREASE_MY_CART_ITEM_QUANTITY_REQUEST";
}

export interface DecreaseMyCartItemQuantityResponseAction {
  type: "DECREASE_MY_CART_ITEM_QUANTITY_RESPONSE";
  result: ServiceResult<CartItemDto>;
}

export interface DeleteMyCartItemRequestAction {
  type: "DELETE_MY_CART_ITEM_REQUEST";
}

export interface DeleteMyCartItemResponseAction {
  type: "DELETE_MY_CART_ITEM_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface ValidateClientCartRequestAction {
  type: "VALIDATE_CLIENT_CART_REQUEST";
}

export interface ValidateClientCartResponseAction {
  type: "VALIDATE_CLIENT_CART_RESPONSE";
  result: ServiceResult<CartItemDto[]>;
}

export interface ConvertClientCartToServerCartRequestAction {
  type: "CONVERT_CLIENT_CART_TO_SERVER_CART_REQUEST";
}

export interface ConvertClientCartToServerCartResponseAction {
  type: "CONVERT_CLIENT_CART_TO_SERVER_CART_RESPONSE";
  result: ServiceResult<CartItemDto[]>;
}

export interface UpdateCartTypeAction {
  type: "UPDATE_CART_TYPE";
  cartType: CartType;
}

export interface SubmitOrderRequestAction {
  type: "SUBMIT_ORDER_REQUEST";
}

export interface SubmitOrderResponseAction {
  type: "SUBMIT_ORDER_RESPONSE";
  result: ServiceResult<BarionPaymentStartDto>;
}

export interface UpdateProfileRequestAction {
  type: "UPDATE_PROFILE_REQUEST";
}

export interface UpdateProfileResponseAction {
  type: "UPDATE_PROFILE_RESPONSE";
  result: ServiceResult<OxyUser>;
}

export interface GetOrderRequestAction {
  type: "GET_ORDER_REQUEST";
}

export interface GetOrderResponseAction {
  type: "GET_ORDER_RESPONSE";
  result: ServiceResult<OrderDto>;
}

export interface GetMyOrdersRequestAction {
  type: "GET_MY_ORDERS_REQUEST";
}

export interface GetMyOrdersResponseAction {
  type: "GET_MY_ORDERS_RESPONSE";
  result: ServiceResult<OrderDto[]>;
}

export interface GetAllOrdersRequestAction {
  type: "GET_ALL_ORDERS_REQUEST";
}

export interface GetAllOrdersResponseAction {
  type: "GET_ALL_ORDERS_RESPONSE";
  result: ServiceResult<OrderDto[]>;
}

export interface RestartPaymentRequestAction {
  type: "RESTART_PAYMENT_REQUEST";
}

export interface RestartPaymentResponseAction {
  type: "RESTART_PAYMENT_RESPONSE";
  result: ServiceResult<BarionPaymentStartDto>;
}

export interface SubscribeRequestAction {
  type: "SUBSCRIBE_REQUEST";
}

export interface SubscribeResponseAction {
  type: "SUBSCRIBE_RESPONSE";
  result: ServiceResult<boolean>;
}

export interface SubscribeWithCouponRequestAction {
  type: "SUBSCRIBE_WITH_COUPON_REQUEST";
}

export interface SubscribeWithCouponResponseAction {
  type: "SUBSCRIBE_WITH_COUPON_RESPONSE";
  result: ServiceResult<boolean>;
}

export interface GetAllSubscriptionsRequestAction {
  type: "GET_ALL_SUBSCRIPTIONS_REQUEST";
}

export interface GetAllSubscriptionsResponseAction {
  type: "GET_ALL_SUBSCRIPTIONS_RESPONSE";
  result: ServiceResult<SubscriptionDto[]>;
}

export interface GetAllCouponsRequestAction {
  type: "GET_ALL_COUPONS_REQUEST";
}

export interface GetAllCouponsResponseAction {
  type: "GET_ALL_COUPONS_RESPONSE";
  result: ServiceResult<CouponDto[]>;
}

export interface GetAllUsersRequestAction {
  type: "GET_ALL_USERS_REQUEST";
}

export interface GetAllUsersResponseAction {
  type: "GET_ALL_USERS_RESPONSE";
  result: ServiceResult<OxyUser[]>;
}

export interface ValidateCouponRequestAction {
  type: "VALIDATE_COUPON_REQUEST";
}

export interface ValidateCouponResponseAction {
  type: "VALIDATE_COUPON_RESPONSE";
  result: ServiceResult<CouponValidationResultDto>;
}

export interface ClearCouponValidationResultAction {
  type: "CLEAR_COUPON_VALIDATION_RESULT";
}

export interface DeleteUserRequestAction {
  type: "DELETE_USER_REQUEST";
}

export interface DeleteUserResponseAction {
  type: "DELETE_USER_RESPONSE";
  result: ServiceResult<boolean>;
  id: number;
}

export interface GetBeforeAfterGalleriesRequestAction {
  type: "GET_BEFORE_AFTER_GALLERIES_REQUEST";
}

export interface GetBeforeAfterGalleriesResponseAction {
  type: "GET_BEFORE_AFTER_GALLERIES_RESPONSE";
  result: ServiceResult<BeforeAfterGalleryDto[]>;
}

export interface UpdateBeforeAfterGalleryRequestAction {
  type: "UPDATE_BEFORE_AFTER_GALLERY_REQUEST";
}

export interface UpdateBeforeAfterGalleryResponseAction {
  type: "UPDATE_BEFORE_AFTER_GALLERY_RESPONSE";
  result: ServiceResult<boolean>;
  galleryId: number;
  title: string;
}

export interface AddBeforeAfterGalleryRequestAction {
  type: "ADD_BEFORE_AFTER_GALLERY_REQUEST";
}

export interface AddBeforeAfterGalleryResponseAction {
  type: "ADD_BEFORE_AFTER_GALLERY_RESPONSE";
  result: ServiceResult<BeforeAfterGalleryDto>;
}

export interface AddItemToBeforeAfterGalleryRequestAction {
  type: "ADD_ITEM_TO_BEFORE_AFTER_GALLERY_REQUEST";
}

export interface AddItemToBeforeAfterGalleryResponseAction {
  type: "ADD_ITEM_TO_BEFORE_AFTER_GALLERY_RESPONSE";
  result: ServiceResult<BeforeAfterGalleryItemDto>;
  galleryId: number;
}

export interface RemoveItemFromBeforeAfterGalleryRequestAction {
  type: "REMOVE_ITEM_FROM_BEFORE_AFTER_GALLERY_REQUEST";
}

export interface RemoveItemFromBeforeAfterGalleryResponseAction {
  type: "REMOVE_ITEM_FROM_BEFORE_AFTER_GALLERY_RESPONSE";
  result: ServiceResult<boolean>;
  galleryId: number;
  itemId: number;
}

export interface ReorderItemsForBeforeAfterGalleryRequestAction {
  type: "REORDER_ITEMS_FOR_BEFORE_AFTER_GALLERY_REQUEST";
}

export interface ReorderItemsForBeforeAfterGalleryResponseAction {
  type: "REORDER_ITEMS_FOR_BEFORE_AFTER_GALLERY_RESPONSE";
  result: ServiceResult<boolean>;
  galleryId: number;
  idsInOrder: number[];
}

export interface ReorderBeforeAfterGalleriesRequestAction {
  type: "REORDER_BEFORE_AFTER_GALLERIES_REQUEST";
}

export interface ReorderBeforeAfterGalleriesResponseAction {
  type: "REORDER_BEFORE_AFTER_GALLERIES_RESPONSE";
  result: ServiceResult<boolean>;
  idsInOrder: number[];
}

export interface GetGuaranteePagesRequestAction {
  type: "GET_GUARANTEE_PAGES_REQUEST";
}

export interface GetGuaranteePagesResponseAction {
  type: "GET_GUARANTEE_PAGES_RESPONSE";
  result: ServiceResult<GuaranteePageDto[]>;
}

export interface GetGuaranteePageByPathRequestAction {
  type: "GET_GUARANTEE_PAGE_BY_PATH_REQUEST";
}

export interface GetGuaranteePageByPathResponseAction {
  type: "GET_GUARANTEE_PAGE_BY_PATH_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection1RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_1_REQUEST";
}

export interface UpdateGuaranteePageSection1ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_1_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection2RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_2_REQUEST";
}

export interface UpdateGuaranteePageSection2ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_2_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection3RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_3_REQUEST";
}

export interface UpdateGuaranteePageSection3ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_3_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection4RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_4_REQUEST";
}

export interface UpdateGuaranteePageSection4ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_4_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection5RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_5_REQUEST";
}

export interface UpdateGuaranteePageSection5ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_5_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection6RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_6_REQUEST";
}

export interface UpdateGuaranteePageSection6ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_6_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection7RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_7_REQUEST";
}

export interface UpdateGuaranteePageSection7ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_7_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection8RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_8_REQUEST";
}

export interface UpdateGuaranteePageSection8ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_8_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection9RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_9_REQUEST";
}

export interface UpdateGuaranteePageSection9ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_9_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection10RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_10_REQUEST";
}

export interface UpdateGuaranteePageSection10ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_10_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageSection11RequestAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_11_REQUEST";
}

export interface UpdateGuaranteePageSection11ResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_SECTION_11_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface CopyGuaranteePageRequestAction {
  type: "COPY_GUARANTEE_PAGE_REQUEST";
}

export interface CopyGuaranteePageResponseAction {
  type: "COPY_GUARANTEE_PAGE_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface UpdateGuaranteePageRequestAction {
  type: "UPDATE_GUARANTEE_PAGE_REQUEST";
}

export interface UpdateGuaranteePageResponseAction {
  type: "UPDATE_GUARANTEE_PAGE_RESPONSE";
  result: ServiceResult<GuaranteePageToDisplayDto>;
}

export interface GetAboutUsPageRequestAction {
  type: "GET_ABOUT_US_PAGE_REQUEST";
}

export interface GetAboutUsPageResponseAction {
  type: "GET_ABOUT_US_PAGE_RESPONSE";
  result: ServiceResult<AboutUsPageDto>;
}

export interface ClearResultAction {
  type: "CLEAR_RESULT";
}

export interface ErrorAction {
  type: "ERROR";
  message: string;
}

export type KnownAction =
  | GetUserRequestAction
  | GetUserResponseAction
  | GetMainPageRequestAction
  | GetMainPageResponseAction
  | GetInfraTrainerPageRequestAction
  | GetInfraTrainerPageResponseAction
  | GetPagesRequestAction
  | GetPagesResponseAction
  | GetScrollingPagesRequestAction
  | GetScrollingPagesResponseAction
  | GetPageByPathRequestAction
  | GetPageByPathResponseAction
  | GetScrollingPageByPathRequestAction
  | GetScrollingPageByPathResponseAction
  | UpdateMainPageAboutUsTextRequestAction
  | UpdateMainPageAboutUsTextResponseAction
  | UpdateMainPageYoutubeVideoIdRequestAction
  | UpdateMainPageYoutubeVideoIdResponseAction
  | UpdateMainPageVideoOverlayTitlesRequestAction
  | UpdateMainPageVideoOverlayTitlesResponseAction
  | UpdateMainPageVideoOverlayDetailsRequestAction
  | UpdateMainPageVideoOverlayDetailsResponseAction
  | UpdateMainPageVideoOverlayOpeningHoursRequestAction
  | UpdateMainPageVideoOverlayOpeningHoursResponseAction
  | UpdatePageSection1RequestAction
  | UpdatePageSection1ResponseAction
  | UpdatePageSection3RequestAction
  | UpdatePageSection3ResponseAction
  | UpdatePageSection4RequestAction
  | UpdatePageSection4ResponseAction
  | UpdatePageSection5RequestAction
  | UpdatePageSection5ResponseAction
  | UpdatePageSection7RequestAction
  | UpdatePageSection7ResponseAction
  | UpdatePageSection8RequestAction
  | UpdatePageSection8ResponseAction
  | UpdatePageSection9RequestAction
  | UpdatePageSection9ResponseAction
  | UpdatePageSection10RequestAction
  | UpdatePageSection10ResponseAction
  | CopyPageRequestAction
  | CopyPageResponseAction
  | UpdatePageRequestAction
  | UpdatePageResponseAction
  | DeletePageRequestAction
  | DeletePageResponseAction
  | GetPricingPagesRequestAction
  | GetPricingPagesResponseAction
  | GetPricingPageByPathRequestAction
  | GetPricingPageByPathResponseAction
  | UpdatePricingPageTextsRequestAction
  | UpdatePricingPageTextsResponseAction
  | AddPricingPageItemRequestAction
  | AddPricingPageItemResponseAction
  | UpdatePricingPageItemRequestAction
  | UpdatePricingPageItemResponseAction
  | DeletePricingPageItemRequestAction
  | DeletePricingPageItemResponseAction
  | ReorderPricingPageItemsRequestAction
  | ReorderPricingPageItemsResponseAction
  | CopyPricingPageRequestAction
  | CopyPricingPageResponseAction
  | DeletePricingPageRequestAction
  | DeletePricingPageResponseAction
  | GetImagesRequestAction
  | GetImagesResponseAction
  | GetImagesPagedRequestAction
  | GetImagesPagedResponseAction
  | UploadImageRequestAction
  | UploadImageResponseAction
  | DeleteImageRequestAction
  | DeleteImageResponseAction
  | AddPromotedServiceToMainPageRequestAction
  | AddPromotedServiceToMainPageResponseAction
  | UpdatePromotedServiceForMainPageRequestAction
  | UpdatePromotedServiceForMainPageResponseAction
  | RemovePromotedServiceFromMainPageRequestAction
  | RemovePromotedServiceFromMainPageResponseAction
  | ReorderPromotedServicesForMainPageRequestAction
  | ReorderPromotedServicesForMainPageResponseAction
  | AddCurrentOfferToMainPageRequestAction
  | AddCurrentOfferToMainPageResponseAction
  | UpdateCurrentOfferForMainPageRequestAction
  | UpdateCurrentOfferForMainPageResponseAction
  | RemoveCurrentOfferFromMainPageRequestAction
  | RemoveCurrentOfferFromMainPageResponseAction
  | ReorderCurrentOffersForMainPageRequestAction
  | ReorderCurrentOffersForMainPageResponseAction
  | AddImageToGalleryRequestAction
  | AddImageToGalleryResponseAction
  | RemoveImageFromGalleryRequestAction
  | RemoveImageFromGalleryResponseAction
  | ReorderImagesForGalleryRequestAction
  | ReorderImagesForGalleryResponseAction
  | UpdateCustomerReviewRequestAction
  | UpdateCustomerReviewResponseAction
  | GetServicesMenuRequestAction
  | GetServicesMenuResponseAction
  | GetPricingMenuRequestAction
  | GetPricingMenuResponseAction
  | GetWebshopMenuRequestAction
  | GetWebshopMenuResponseAction
  | GetSkinProblemsMenuRequestAction
  | GetSkinProblemsMenuResponseAction
  | AddServicesParentMenuItemRequestAction
  | AddServicesParentMenuItemResponseAction
  | AddServicesLeafMenuItemRequestAction
  | AddServicesLeafMenuItemResponseAction
  | DeleteServicesMenuItemRequestAction
  | DeleteServicesMenuItemResponseAction
  | ReorderServicesParentMenuItemsRequestAction
  | ReorderServicesParentMenuItemsResponseAction
  | ReorderServicesLeafMenuItemsRequestAction
  | ReorderServicesLeafMenuItemsResponseAction
  | AddPricingMenuItemRequestAction
  | AddPricingMenuItemResponseAction
  | DeletePricingMenuItemRequestAction
  | DeletePricingMenuItemResponseAction
  | ReorderPricingMenuItemsRequestAction
  | ReorderPricingMenuItemsResponseAction
  | AddWebshopMenuItemRequestAction
  | AddWebshopMenuItemResponseAction
  | DeleteWebshopMenuItemRequestAction
  | DeleteWebshopMenuItemResponseAction
  | ReorderWebshopMenuItemsRequestAction
  | ReorderWebshopMenuItemsResponseAction
  | AddSkinProblemsMenuItemRequestAction
  | AddSkinProblemsMenuItemResponseAction
  | DeleteSkinProblemsMenuItemRequestAction
  | DeleteSkinProblemsMenuItemResponseAction
  | ReorderSkinProblemsMenuItemsRequestAction
  | ReorderSkinProblemsMenuItemsResponseAction
  | GetAboutUsSectionsRequestAction
  | GetAboutUsSectionsResponseAction
  | AddAboutUsSectionRequestAction
  | AddAboutUsSectionResponseAction
  | UpdateAboutUsSectionRequestAction
  | UpdateAboutUsSectionResponseAction
  | DeleteAboutUsSectionRequestAction
  | DeleteAboutUsSectionResponseAction
  | ReorderAboutUsSectionsRequestAction
  | ReorderAboutUsSectionsResponseAction
  | SendContactUsMessageRequestAction
  | SendContactUsMessageResponseAction
  | UpdateWebshopMainPageScrollPositionAction
  | GetProductCategoriesRequestAction
  | GetProductCategoriesResponseAction
  | AddProductCategoryRequestAction
  | AddProductCategoryResponseAction
  | UpdateProductCategoryRequestAction
  | UpdateProductCategoryResponseAction
  | ToggleProductCategoryVisibleRequestAction
  | ToggleProductCategoryVisibleResponseAction
  | ReorderProductCategoriesRequestAction
  | ReorderProductCategoriesResponseAction
  | AddProductRequestAction
  | AddProductResponseAction
  | UpdateProductRequestAction
  | UpdateProductResponseAction
  | ToggleProductVisibleRequestAction
  | ToggleProductVisibleResponseAction
  | ReorderProductsRequestAction
  | ReorderProductsResponseAction
  | AddProductOptionRequestAction
  | AddProductOptionResponseAction
  | UpdateProductOptionRequestAction
  | UpdateProductOptionResponseAction
  | ToggleProductOptionVisibleRequestAction
  | ToggleProductOptionVisibleResponseAction
  | ReorderProductOptionsRequestAction
  | ReorderProductOptionsResponseAction
  | GetPromotedProductsRequestAction
  | GetPromotedProductsResponseAction
  | GetMyCartRequestAction
  | GetMyCartResponseAction
  | AddProductToMyCartRequestAction
  | AddProductToMyCartResponseAction
  | IncreaseMyCartItemQuantityRequestAction
  | IncreaseMyCartItemQuantityResponseAction
  | DecreaseMyCartItemQuantityRequestAction
  | DecreaseMyCartItemQuantityResponseAction
  | DeleteMyCartItemRequestAction
  | DeleteMyCartItemResponseAction
  | ValidateClientCartRequestAction
  | ValidateClientCartResponseAction
  | ConvertClientCartToServerCartRequestAction
  | ConvertClientCartToServerCartResponseAction
  | UpdateCartTypeAction
  | SubmitOrderRequestAction
  | SubmitOrderResponseAction
  | UpdateProfileRequestAction
  | UpdateProfileResponseAction
  | GetOrderRequestAction
  | GetOrderResponseAction
  | GetMyOrdersRequestAction
  | GetMyOrdersResponseAction
  | GetAllOrdersRequestAction
  | GetAllOrdersResponseAction
  | RestartPaymentRequestAction
  | RestartPaymentResponseAction
  | SubscribeRequestAction
  | SubscribeResponseAction
  | SubscribeWithCouponRequestAction
  | SubscribeWithCouponResponseAction
  | GetAllSubscriptionsRequestAction
  | GetAllSubscriptionsResponseAction
  | GetAllCouponsRequestAction
  | GetAllCouponsResponseAction
  | GetAllUsersRequestAction
  | GetAllUsersResponseAction
  | ValidateCouponRequestAction
  | ValidateCouponResponseAction
  | ClearCouponValidationResultAction
  | DeleteUserRequestAction
  | DeleteUserResponseAction
  | GetBeforeAfterGalleriesRequestAction
  | GetBeforeAfterGalleriesResponseAction
  | UpdateBeforeAfterGalleryRequestAction
  | UpdateBeforeAfterGalleryResponseAction
  | AddBeforeAfterGalleryRequestAction
  | AddBeforeAfterGalleryResponseAction
  | AddItemToBeforeAfterGalleryRequestAction
  | AddItemToBeforeAfterGalleryResponseAction
  | RemoveItemFromBeforeAfterGalleryRequestAction
  | RemoveItemFromBeforeAfterGalleryResponseAction
  | ReorderItemsForBeforeAfterGalleryRequestAction
  | ReorderItemsForBeforeAfterGalleryResponseAction
  | ReorderBeforeAfterGalleriesRequestAction
  | ReorderBeforeAfterGalleriesResponseAction
  | GetGuaranteePagesRequestAction
  | GetGuaranteePagesResponseAction
  | GetGuaranteePageByPathRequestAction
  | GetGuaranteePageByPathResponseAction
  | UpdateGuaranteePageSection1RequestAction
  | UpdateGuaranteePageSection1ResponseAction
  | UpdateGuaranteePageSection2RequestAction
  | UpdateGuaranteePageSection2ResponseAction
  | UpdateGuaranteePageSection3RequestAction
  | UpdateGuaranteePageSection3ResponseAction
  | UpdateGuaranteePageSection4RequestAction
  | UpdateGuaranteePageSection4ResponseAction
  | UpdateGuaranteePageSection5RequestAction
  | UpdateGuaranteePageSection5ResponseAction
  | UpdateGuaranteePageSection6RequestAction
  | UpdateGuaranteePageSection6ResponseAction
  | UpdateGuaranteePageSection7RequestAction
  | UpdateGuaranteePageSection7ResponseAction
  | UpdateGuaranteePageSection8RequestAction
  | UpdateGuaranteePageSection8ResponseAction
  | UpdateGuaranteePageSection9RequestAction
  | UpdateGuaranteePageSection9ResponseAction
  | UpdateGuaranteePageSection10RequestAction
  | UpdateGuaranteePageSection10ResponseAction
  | UpdateGuaranteePageSection11RequestAction
  | UpdateGuaranteePageSection11ResponseAction
  | CopyGuaranteePageRequestAction
  | CopyGuaranteePageResponseAction
  | UpdateGuaranteePageRequestAction
  | UpdateGuaranteePageResponseAction
  | GetAboutUsPageRequestAction
  | GetAboutUsPageResponseAction
  | ClearResultAction
  | ErrorAction;

const authApi = new AuthApi();
const pageApi = new PageApi();
const imageApi = new ImageApi();
const currentOfferApi = new CurrentOfferApi();
const customerReviewApi = new CustomerReviewApi();
const galleryApi = new GalleryApi();
const promotedServiceApi = new PromotedServiceApi();
const pricingPageApi = new PricingPageApi();
const menuApi = new MenuApi();
const aboutUsApi = new AboutUsApi();
const contactUsApi = new ContactUsApi();
const productCategoryApi = new ProductCategoryApi();
const orderApi = new OrderApi();
const profileApi = new ProfileApi();
const subscriptionApi = new SubscriptionApi();
const couponApi = new CouponApi();
const userApi = new UserApi();
const scrollingPageApi = new ScrollingPageApi();
const beforeAfterGalleryApi = new BeforeAfterGalleryApi();
const guaranteePageApi = new GuaranteePageApi();
const aboutUsPageApi = new AboutUsPageApi();

export const actionCreators = {
  getLoggedInUser:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_USER_REQUEST" });
      try {
        const result = await authApi.getLoggedInUser();
        dispatch({ type: "GET_USER_RESPONSE", user: result.data });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
        dispatch({ type: "GET_USER_RESPONSE", user: null });
      }
    },
  getMainPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_MAIN_PAGE_REQUEST" });
      try {
        const result = await pageApi.getMainPage();
        dispatch({ type: "GET_MAIN_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getInfraTrainerPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_INFRATRAINER_PAGE_REQUEST" });
      try {
        const result = await pageApi.getInfraTrainerPage();
        dispatch({ type: "GET_INFRATRAINER_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getPages: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_PAGES_REQUEST" });
    try {
      const result = await pageApi.getPages();
      dispatch({ type: "GET_PAGES_RESPONSE", result: result });
    } catch (err: any) {
      dispatch({ type: "ERROR", message: err });
    }
  },
  getScrollingPages:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_SCROLLING_PAGES_REQUEST" });
      try {
        const result = await scrollingPageApi.getScrollingPages();
        dispatch({ type: "GET_SCROLLING_PAGES_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getPageByPath:
    (path: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_PAGE_BY_PATH_REQUEST" });
      try {
        const result = await pageApi.getPageByPath(path);
        dispatch({ type: "GET_PAGE_BY_PATH_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getScrollingPageByPath:
    (path: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_SCROLLING_PAGE_BY_PATH_REQUEST" });
      try {
        const result = await scrollingPageApi.getScrollingPageByPath(path);
        dispatch({
          type: "GET_SCROLLING_PAGE_BY_PATH_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateMainPageAboutUsTexts:
    (payload: AboutUsTextsPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_MAIN_PAGE_ABOUT_US_TEXT_REQUEST" });
      try {
        const result = await pageApi.updateMainPageAboutUsTexts(payload);
        dispatch({
          type: "UPDATE_MAIN_PAGE_ABOUT_US_TEXT_RESPONSE",
          result: result,
          payload: payload,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateMainPageYoutubeVideoId:
    (videoId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_MAIN_PAGE_YOUTUBE_VIDEO_ID_REQUEST" });
      try {
        const result = await pageApi.updateMainPageYoutubeVideoId(videoId);
        dispatch({
          type: "UPDATE_MAIN_PAGE_YOUTUBE_VIDEO_ID_RESPONSE",
          result: result,
          videoId: videoId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateMainPageVideoOverlayTitles:
    (payload: VideoOverlayTitlesPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_TITLES_REQUEST" });
      try {
        const result = await pageApi.updateMainPageVideoOverlayTitles(payload);
        dispatch({
          type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_TITLES_RESPONSE",
          result: result,
          payload: payload,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateMainPageVideoOverlayDetails:
    (text: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_DETAILS_REQUEST" });
      try {
        const result = await pageApi.updateMainPageVideoOverlayDetails(text);
        dispatch({
          type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_DETAILS_RESPONSE",
          result: result,
          text: text,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateMainPageVideoOverlayOpeningHours:
    (payload: VideoOverlayOpeningHoursPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_OPENING_HOURS_REQUEST",
      });
      try {
        const result = await pageApi.updateMainPageVideoOverlayOpeningHours(
          payload
        );
        dispatch({
          type: "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_OPENING_HOURS_RESPONSE",
          result: result,
          payload: payload,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection1:
    (
      pageId: number,
      payload: PageTitleWithTextSectionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_1_REQUEST" });
      try {
        const result = await pageApi.updatePageSection1(pageId, payload);
        dispatch({ type: "UPDATE_PAGE_SECTION_1_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection3:
    (
      pageId: number,
      isVisible: boolean,
      payload: PageBulletPointListWithTitlePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_3_REQUEST" });
      try {
        const result = await pageApi.updatePageSection3(
          pageId,
          isVisible,
          payload
        );
        dispatch({ type: "UPDATE_PAGE_SECTION_3_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection4:
    (
      pageId: number,
      isVisible: boolean,
      payload: PageImageWithTextSideBySidePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_4_REQUEST" });
      try {
        const result = await pageApi.updatePageSection4(
          pageId,
          isVisible,
          payload
        );
        dispatch({ type: "UPDATE_PAGE_SECTION_4_RESPONSE", result: result });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection5:
    (
      pageId: number,
      isVisible: boolean,
      text: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_5_REQUEST" });
      try {
        const result = await pageApi.updatePageSection5(
          pageId,
          isVisible,
          text
        );
        dispatch({ type: "UPDATE_PAGE_SECTION_5_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection7:
    (
      pageId: number,
      isVisible: boolean,
      text: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_7_REQUEST" });
      try {
        const result = await pageApi.updatePageSection7(
          pageId,
          isVisible,
          text
        );
        dispatch({ type: "UPDATE_PAGE_SECTION_7_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection8:
    (
      pageId: number,
      isVisible: boolean,
      payload: Section8ImagePayload[]
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_8_REQUEST" });
      try {
        const result = await pageApi.updatePageSection8(
          pageId,
          isVisible,
          payload
        );
        dispatch({ type: "UPDATE_PAGE_SECTION_8_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection9:
    (
      pageId: number,
      isVisible: boolean,
      text: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_9_REQUEST" });
      try {
        const result = await pageApi.updatePageSection9(
          pageId,
          isVisible,
          text
        );
        dispatch({ type: "UPDATE_PAGE_SECTION_9_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePageSection10:
    (
      pageId: number,
      isVisible: boolean,
      payload: PageBulletPointListWithTitlePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_SECTION_10_REQUEST" });
      try {
        const result = await pageApi.updatePageSection10(
          pageId,
          isVisible,
          payload
        );
        dispatch({ type: "UPDATE_PAGE_SECTION_10_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  copyPage:
    (pageId: number, name: string, path: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "COPY_PAGE_REQUEST" });
      try {
        const result = await pageApi.copyPage(pageId, name, path);
        dispatch({ type: "COPY_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePage:
    (
      pageId: number,
      name: string,
      path: string,
      pricingPageId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PAGE_REQUEST" });
      try {
        const result = await pageApi.updatePage(
          pageId,
          name,
          path,
          pricingPageId
        );
        dispatch({ type: "UPDATE_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deletePage:
    (pageId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_PAGE_REQUEST" });
      try {
        const result = await pageApi.deletePage(pageId);
        dispatch({ type: "DELETE_PAGE_RESPONSE", result: result, id: pageId });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getPricingPages:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_PRICING_PAGES_REQUEST" });
      try {
        const result = await pricingPageApi.getPricingPages();
        dispatch({ type: "GET_PRICING_PAGES_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getPricingPageByPath:
    (path: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_PRICING_PAGE_BY_PATH_REQUEST" });
      try {
        const result = await pricingPageApi.getPricingPageByPath(path);
        dispatch({ type: "GET_PRICING_PAGE_BY_PATH_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePricingPageTexts:
    (
      pageId: number,
      payload: PricingPageTextsPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PRICING_PAGE_TEXTS_REQUEST" });
      try {
        const result = await pricingPageApi.updateTexts(pageId, payload);
        dispatch({
          type: "UPDATE_PRICING_PAGE_TEXTS_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addPricingPageItem:
    (
      pricingPageId: number,
      payload: PricingPageItemPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_PRICING_PAGE_ITEM_REQUEST" });
      try {
        const result = await pricingPageApi.addPricingItem(
          pricingPageId,
          payload
        );
        dispatch({ type: "ADD_PRICING_PAGE_ITEM_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePricingPageItem:
    (
      pricingPageId: number,
      pricingItemId: number,
      payload: PricingPageItemPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PRICING_PAGE_ITEM_REQUEST" });
      try {
        const result = await pricingPageApi.updatePricingItem(
          pricingPageId,
          pricingItemId,
          payload
        );
        dispatch({ type: "UPDATE_PRICING_PAGE_ITEM_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deletePricingPageItem:
    (
      pricingPageId: number,
      pricingItemId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_PRICING_PAGE_ITEM_REQUEST" });
      try {
        const result = await pricingPageApi.deletePricingItem(
          pricingPageId,
          pricingItemId
        );
        dispatch({
          type: "DELETE_PRICING_PAGE_ITEM_RESPONSE",
          result: result,
          id: pricingItemId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderPricingItems:
    (
      pricingPageId: number,
      payload: ReorderPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_PRICING_PAGE_ITEMS_REQUEST" });
      try {
        const result = await pricingPageApi.reorderPricingItems(
          pricingPageId,
          payload
        );
        dispatch({
          type: "REORDER_PRICING_PAGE_ITEMS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  copyPricingPage:
    (pageId: number, name: string, path: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "COPY_PRICING_PAGE_REQUEST" });
      try {
        const result = await pricingPageApi.copyPricingPage(pageId, name, path);
        dispatch({ type: "COPY_PRICING_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deletePricingPage:
    (pricingPageId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_PRICING_PAGE_REQUEST" });
      try {
        const result = await pricingPageApi.deletePricingPage(pricingPageId);
        dispatch({
          type: "DELETE_PRICING_PAGE_RESPONSE",
          result: result,
          id: pricingPageId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getImages: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_IMAGES_REQUEST" });
    try {
      const result = await imageApi.getImages();
      dispatch({ type: "GET_IMAGES_RESPONSE", result: result });
    } catch (err: any) {
      dispatch({ type: "ERROR", message: err });
    }
  },
  getImagesPaged:
    (
      page: number,
      pageSize: number,
      query: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_IMAGES_PAGED_REQUEST" });
      try {
        const result = await imageApi.getImagesPaged(page, pageSize, query);
        dispatch({ type: "GET_IMAGES_PAGED_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  uploadImage:
    (file: File, autoResize: boolean = false): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPLOAD_IMAGE_REQUEST" });
      try {
        const result = await imageApi.uploadImage(file, autoResize);
        dispatch({ type: "UPLOAD_IMAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deleteImage:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_IMAGE_REQUEST" });
      try {
        const result = await imageApi.deleteImage(id);
        dispatch({ type: "DELETE_IMAGE_RESPONSE", result: result, id: id });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addPromotedServiceToMainPage:
    (payload: PromotedServicePayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_PROMOTED_SERVICE_TO_MAIN_PAGE_REQUEST" });
      try {
        const result = await promotedServiceApi.addPromotedServiceToMainPage(
          payload
        );
        dispatch({
          type: "ADD_PROMOTED_SERVICE_TO_MAIN_PAGE_RESPONSE",
          result: result,
        });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updatePromotedServiceForMainPage:
    (
      id: number,
      payload: PromotedServicePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PROMOTED_SERVICE_FOR_MAIN_PAGE_REQUEST" });
      try {
        const result =
          await promotedServiceApi.updatePromotedServiceForMainPage(
            id,
            payload
          );
        dispatch({
          type: "UPDATE_PROMOTED_SERVICE_FOR_MAIN_PAGE_RESPONSE",
          result: result,
          id: id,
        });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  removePromotedServiceFromMainPage:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REMOVE_PROMOTED_SERVICE_FROM_MAIN_PAGE_REQUEST" });
      try {
        const result =
          await promotedServiceApi.removePromotedServiceFromMainPage(id);
        dispatch({
          type: "REMOVE_PROMOTED_SERVICE_FROM_MAIN_PAGE_RESPONSE",
          result: result,
          id: id,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderPromotedServicesForMainPage:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_PROMOTED_SERVICES_FOR_MAIN_PAGE_REQUEST" });
      try {
        const result =
          await promotedServiceApi.reorderPromotedServicesForMainPage(payload);
        dispatch({
          type: "REORDER_PROMOTED_SERVICES_FOR_MAIN_PAGE_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addCurrentOfferToMainPage:
    (payload: CurrentOfferPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_CURRENT_OFFER_TO_MAIN_PAGE_REQUEST" });
      try {
        const result = await currentOfferApi.addCurrentOfferToMainPage(payload);
        dispatch({
          type: "ADD_CURRENT_OFFER_TO_MAIN_PAGE_RESPONSE",
          result: result,
        });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateCurrentOfferForMainPage:
    (id: number, payload: CurrentOfferPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_CURRENT_OFFER_FOR_MAIN_PAGE_REQUEST" });
      try {
        const result = await currentOfferApi.updateCurrentOfferForMainPage(
          id,
          payload
        );
        dispatch({
          type: "UPDATE_CURRENT_OFFER_FOR_MAIN_PAGE_RESPONSE",
          result: result,
          id: id,
        });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  removeCurrentOfferFromMainPage:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REMOVE_CURRENT_OFFER_FROM_MAIN_PAGE_REQUEST" });
      try {
        const result = await currentOfferApi.removeCurrentOfferFromMainPage(id);
        dispatch({
          type: "REMOVE_CURRENT_OFFER_FROM_MAIN_PAGE_RESPONSE",
          result: result,
          id: id,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderCurrentOffersForMainPage:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_CURRENT_OFFERS_FOR_MAIN_PAGE_REQUEST" });
      try {
        const result = await currentOfferApi.reorderCurrentOffersForMainPage(
          payload
        );
        dispatch({
          type: "REORDER_CURRENT_OFFERS_FOR_MAIN_PAGE_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateCustomerReview:
    (
      id: number,
      payload: CustomerReviewUpdatePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_CUSTOMER_REVIEW_REQUEST" });
      try {
        const result = await customerReviewApi.update(id, payload);
        dispatch({
          type: "UPDATE_CUSTOMER_REVIEW_RESPONSE",
          result: result,
          id: id,
          payload: payload,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addImageToGallery:
    (
      galleryId: number,
      imageId: number,
      crop: CropPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_IMAGE_TO_GALLERY_REQUEST" });
      try {
        const result = await galleryApi.addImage(galleryId, imageId, crop);
        dispatch({
          type: "ADD_IMAGE_TO_GALLERY_RESPONSE",
          result: result,
          galleryId: galleryId,
          imageId: imageId,
        });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  removeImageFromGallery:
    (galleryId: number, imageId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REMOVE_IMAGE_FROM_GALLERY_REQUEST" });
      try {
        const result = await galleryApi.removeImage(galleryId, imageId);
        dispatch({
          type: "REMOVE_IMAGE_FROM_GALLERY_RESPONSE",
          result: result,
          galleryId: galleryId,
          imageId: imageId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderImagesForGallery:
    (galleryId: number, payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_IMAGES_FOR_GALLERY_REQUEST" });
      try {
        const result = await galleryApi.reorder(galleryId, payload);
        dispatch({
          type: "REORDER_IMAGES_FOR_GALLERY_RESPONSE",
          result: result,
          galleryId: galleryId,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getServicesMenu:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_SERVICES_MENU_REQUEST" });
      try {
        const result = await menuApi.getServicesMenu();
        dispatch({ type: "GET_SERVICES_MENU_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getPricingMenu:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_PRICING_MENU_REQUEST" });
      try {
        const result = await menuApi.getPricingMenu();
        dispatch({ type: "GET_PRICING_MENU_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getWebshopMenu:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_WEBSHOP_MENU_REQUEST" });
      try {
        const result = await menuApi.getWebshopMenu();
        dispatch({ type: "GET_WEBSHOP_MENU_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getSkinProblemsMenu:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_SKIN_PROBLEMS_MENU_REQUEST" });
      try {
        const result = await menuApi.getSkinProblemsMenu();
        dispatch({ type: "GET_SKIN_PROBLEMS_MENU_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addServicesParentMenuItem:
    (title: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_SERVICES_PARENT_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.addServicesParentMenuItem(title);
        dispatch({
          type: "ADD_SERVICES_PARENT_MENU_ITEM_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addServicesLeafMenuItem:
    (payload: ServicesMenuItemPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_SERVICES_LEAF_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.addServicesLeafMenuItem(payload);
        dispatch({
          type: "ADD_SERVICES_LEAF_MENU_ITEM_RESPONSE",
          result: result,
          parentId: payload.parentMenuItemId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deleteServicesMenuItem:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_SERVICES_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.deleteServicesMenuItem(id);
        dispatch({
          type: "DELETE_SERVICES_MENU_ITEM_RESPONSE",
          result: result,
          id: id,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderServicesParentMenuItems:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_SERVICES_PARENT_MENU_ITEMS_REQUEST" });
      try {
        const result = await menuApi.reorderServicesParentMenuItems(payload);
        dispatch({
          type: "REORDER_SERVICES_PARENT_MENU_ITEMS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderServicesLeafMenuItems:
    (parentId: number, payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_SERVICES_LEAF_MENU_ITEMS_REQUEST" });
      try {
        const result = await menuApi.reorderServicesLeafMenuItems(
          parentId,
          payload
        );
        dispatch({
          type: "REORDER_SERVICES_LEAF_MENU_ITEMS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
          parentId: parentId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addPricingMenuItem:
    (payload: PricingMenuItemPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_PRICING_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.addPricingMenuItem(payload);
        dispatch({ type: "ADD_PRICING_MENU_ITEM_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deletePricingMenuItem:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_PRICING_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.deletePricingMenuItem(id);
        dispatch({
          type: "DELETE_PRICING_MENU_ITEM_RESPONSE",
          result: result,
          id: id,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderPricingMenuItems:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_PRICING_MENU_ITEMS_REQUEST" });
      try {
        const result = await menuApi.reorderPricingMenuItems(payload);
        dispatch({
          type: "REORDER_PRICING_MENU_ITEMS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addWebshopMenuItem:
    (payload: WebshopMenuItemPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_WEBSHOP_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.addWebshopMenuItem(payload);
        dispatch({ type: "ADD_WEBSHOP_MENU_ITEM_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deleteWebshopMenuItem:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_WEBSHOP_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.deleteWebshopMenuItem(id);
        dispatch({
          type: "DELETE_WEBSHOP_MENU_ITEM_RESPONSE",
          result: result,
          id: id,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderWebshopMenuItems:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_WEBSHOP_MENU_ITEMS_REQUEST" });
      try {
        const result = await menuApi.reorderWebshopMenuItems(payload);
        dispatch({
          type: "REORDER_WEBSHOP_MENU_ITEMS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addSkinProblemsMenuItem:
    (payload: SkinProblemsMenuItemPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_SKIN_PROBLEMS_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.addSkinProblemsMenuItem(payload);
        dispatch({
          type: "ADD_SKIN_PROBLEMS_MENU_ITEM_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deleteSkinProblemsMenuItem:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_SKIN_PROBLEMS_MENU_ITEM_REQUEST" });
      try {
        const result = await menuApi.deleteSkinProblemsMenuItem(id);
        dispatch({
          type: "DELETE_SKIN_PROBLEMS_MENU_ITEM_RESPONSE",
          result: result,
          id: id,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderSkinProblemsMenuItems:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_SKIN_PROBLEMS_MENU_ITEMS_REQUEST" });
      try {
        const result = await menuApi.reorderSkinProblemsMenuItems(payload);
        dispatch({
          type: "REORDER_SKIN_PROBLEMS_MENU_ITEMS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getAboutUsSections:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_ABOUT_US_SECTIONS_REQUEST" });
      try {
        const result = await aboutUsApi.getAboutUsSections();
        dispatch({ type: "GET_ABOUT_US_SECTIONS_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addAboutUsSection:
    (payload: AboutUsSectionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_ABOUT_US_SECTION_REQUEST" });
      try {
        const result = await aboutUsApi.addAboutUsSection(payload);
        dispatch({ type: "ADD_ABOUT_US_SECTION_RESPONSE", result: result });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateAboutUsSection:
    (id: number, payload: AboutUsSectionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_ABOUT_US_SECTION_REQUEST" });
      try {
        const result = await aboutUsApi.updateAboutUsSection(id, payload);
        dispatch({
          type: "UPDATE_ABOUT_US_SECTION_RESPONSE",
          result: result,
          id: id,
        });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deleteAboutUsSection:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_ABOUT_US_SECTION_REQUEST" });
      try {
        const result = await aboutUsApi.deleteAboutUsSection(id);
        dispatch({
          type: "DELETE_ABOUT_US_SECTION_RESPONSE",
          result: result,
          id: id,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderAboutUsSections:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_ABOUT_US_SECTIONS_REQUEST" });
      try {
        const result = await aboutUsApi.reorderAboutUsSections(payload);
        dispatch({
          type: "REORDER_ABOUT_US_SECTIONS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  sendContactUsMessage:
    (payload: ContactUsMessagePayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "SEND_CONTACT_US_MESSAGE_REQUEST" });
      try {
        const result = await contactUsApi.sendContactUsMessage(payload);
        dispatch({ type: "SEND_CONTACT_US_MESSAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateWebshopMainPageScrollPosition:
    (scrollTop: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: "UPDATE_WEBSHOP_MAIN_PAGE_SCROLL_POSITION",
        scrollTop: scrollTop,
      });
    },
  getAllProductCategories:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_PRODUCT_CATEGORIES_REQUEST" });
      try {
        const result = await productCategoryApi.getAll();
        dispatch({ type: "GET_PRODUCT_CATEGORIES_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addProductCategory:
    (payload: ProductCategoryPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_PRODUCT_CATEGORY_REQUEST" });
      try {
        const result = await productCategoryApi.createProductCategory(payload);
        dispatch({ type: "ADD_PRODUCT_CATEGORY_RESPONSE", result: result });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateProductCategory:
    (
      id: number,
      payload: ProductCategoryPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PRODUCT_CATEGORY_REQUEST" });
      try {
        const result = await productCategoryApi.updateProductCategory(
          id,
          payload
        );
        dispatch({ type: "UPDATE_PRODUCT_CATEGORY_RESPONSE", result: result });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  toggleProductCategoryVisible:
    (id: number, isVisible: boolean): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "TOGGLE_PRODUCT_CATEGORY_VISIBLE_REQUEST" });
      try {
        const result = await productCategoryApi.toggleProductCategoryVisible(
          id,
          isVisible
        );
        dispatch({
          type: "TOGGLE_PRODUCT_CATEGORY_VISIBLE_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderProductCategories:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_PRODUCT_CATEGORIES_REQUEST" });
      try {
        const result = await productCategoryApi.reorderProductCategories(
          payload
        );
        dispatch({
          type: "REORDER_PRODUCT_CATEGORIES_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addProduct:
    (
      categoryId: number,
      payload: ProductPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_PRODUCT_REQUEST" });
      try {
        const result = await productCategoryApi.createProduct(
          categoryId,
          payload
        );
        dispatch({ type: "ADD_PRODUCT_RESPONSE", result: result });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateProduct:
    (id: number, payload: ProductPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PRODUCT_REQUEST" });
      try {
        const result = await productCategoryApi.updateProduct(id, payload);
        dispatch({ type: "UPDATE_PRODUCT_RESPONSE", result: result });
        actionCreators.getImages()(dispatch, getState);
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  toggleProductVisible:
    (id: number, isVisible: boolean): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "TOGGLE_PRODUCT_VISIBLE_REQUEST" });
      try {
        const result = await productCategoryApi.toggleProductVisible(
          id,
          isVisible
        );
        dispatch({ type: "TOGGLE_PRODUCT_VISIBLE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderProducts:
    (
      categoryId: number,
      payload: ReorderPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_PRODUCTS_REQUEST" });
      try {
        const result = await productCategoryApi.reorderProducts(
          categoryId,
          payload
        );
        dispatch({
          type: "REORDER_PRODUCTS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
          categoryId: categoryId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addProductOption:
    (
      productId: number,
      payload: ProductOptionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_PRODUCT_OPTION_REQUEST" });
      try {
        const result = await productCategoryApi.createProductOption(
          productId,
          payload
        );
        dispatch({ type: "ADD_PRODUCT_OPTION_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateProductOption:
    (id: number, payload: ProductOptionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PRODUCT_OPTION_REQUEST" });
      try {
        const result = await productCategoryApi.updateProductOption(
          id,
          payload
        );
        dispatch({ type: "UPDATE_PRODUCT_OPTION_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  toggleProductOptionVisible:
    (id: number, isVisible: boolean): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "TOGGLE_PRODUCT_OPTION_VISIBLE_REQUEST" });
      try {
        const result = await productCategoryApi.toggleProductOptionVisible(
          id,
          isVisible
        );
        dispatch({
          type: "TOGGLE_PRODUCT_OPTION_VISIBLE_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderProductOptions:
    (productId: number, payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_PRODUCT_OPTIONS_REQUEST" });
      try {
        const result = await productCategoryApi.reorderProductOptions(
          productId,
          payload
        );
        dispatch({
          type: "REORDER_PRODUCT_OPTIONS_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
          productId: productId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getPromotedProducts:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_PROMOTED_PRODUCTS_REQUEST" });
      try {
        const result = await productCategoryApi.getPromotedProducts();
        dispatch({ type: "GET_PROMOTED_PRODUCTS_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getMyCart: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: "GET_MY_CART_REQUEST" });
    try {
      const result = await orderApi.getMyCart();
      dispatch({ type: "GET_MY_CART_RESPONSE", result: result });
    } catch (err: any) {
      dispatch({ type: "ERROR", message: err });
    }
  },
  addProductToMyCart:
    (
      productId: number | null,
      productOptionId: number | null,
      gifteeName: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_PRODUCT_TO_MY_CART_REQUEST" });
      try {
        const result = await orderApi.addProductToMyCart(
          productId,
          productOptionId,
          gifteeName
        );
        dispatch({ type: "ADD_PRODUCT_TO_MY_CART_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  increaseMyCartItemQuantity:
    (cartItemId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "INCREASE_MY_CART_ITEM_QUANTITY_REQUEST" });
      try {
        const result = await orderApi.increaseMyCartItemQuantity(cartItemId);
        dispatch({
          type: "INCREASE_MY_CART_ITEM_QUANTITY_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  decreaseMyCartItemQuantity:
    (cartItemId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DECREASE_MY_CART_ITEM_QUANTITY_REQUEST" });
      try {
        const result = await orderApi.decreaseMyCartItemQuantity(cartItemId);
        dispatch({
          type: "DECREASE_MY_CART_ITEM_QUANTITY_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  deleteItemFromMyCart:
    (cartItemId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_MY_CART_ITEM_REQUEST" });
      try {
        const result = await orderApi.deleteCartItem(cartItemId);
        dispatch({
          type: "DELETE_MY_CART_ITEM_RESPONSE",
          result: result,
          id: cartItemId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  validateClientCart:
    (payload: CartPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "VALIDATE_CLIENT_CART_REQUEST" });
      try {
        const result = await orderApi.validateClientCart(payload);
        dispatch({ type: "VALIDATE_CLIENT_CART_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  convertClientCartToServerCart:
    (payload: CartPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "CONVERT_CLIENT_CART_TO_SERVER_CART_REQUEST" });
      try {
        const result = await orderApi.convertClientCartToServerCart(payload);
        dispatch({
          type: "CONVERT_CLIENT_CART_TO_SERVER_CART_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  setCartItemsRaw:
    (items: CartItemDto[]): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_MY_CART_REQUEST" });
      try {
        dispatch({
          type: "GET_MY_CART_RESPONSE",
          result: {
            data: items,
            errorCode: 0,
            errorDetails: null,
            errorMessage: "",
            hasError: false,
          },
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateCartType:
    (cartType: CartType): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_CART_TYPE", cartType: cartType });
    },
  submitOrder:
    (payload: OrderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "SUBMIT_ORDER_REQUEST" });
      try {
        const isLoggedIn = getState().oxy?.loggedInUser != null;
        const result = isLoggedIn
          ? await orderApi.submitOrder(payload)
          : await orderApi.submitOrderAnonymous(payload);
        dispatch({ type: "SUBMIT_ORDER_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateProfile:
    (payload: UpdateProfilePayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_PROFILE_REQUEST" });
      try {
        const result = await profileApi.updateProfile(payload);
        dispatch({ type: "UPDATE_PROFILE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getOrder:
    (orderId: number, paymentId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_ORDER_REQUEST" });
      try {
        const isLoggedIn = getState().oxy?.loggedInUser != null;
        const result = isLoggedIn
          ? await orderApi.getOrder(orderId)
          : await orderApi.getOrderAnonymous(orderId, paymentId);
        dispatch({ type: "GET_ORDER_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getMyOrders:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_MY_ORDERS_REQUEST" });
      try {
        const result = await orderApi.getMyOrders();
        dispatch({ type: "GET_MY_ORDERS_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getAllOrders:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_ALL_ORDERS_REQUEST" });
      try {
        const result = await orderApi.getAllOrders();
        dispatch({ type: "GET_ALL_ORDERS_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  restartPayment:
    (orderId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "RESTART_PAYMENT_REQUEST" });
      try {
        const isLoggedIn = getState().oxy?.loggedInUser != null;
        const result = isLoggedIn
          ? await orderApi.restartPayment(orderId)
          : await orderApi.restartPaymentAnonymous(orderId);
        dispatch({ type: "RESTART_PAYMENT_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  subscribeToNewsletter:
    (payload: SubscriptionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "SUBSCRIBE_REQUEST" });
      try {
        const result = await subscriptionApi.subscribe(payload);
        dispatch({ type: "SUBSCRIBE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  subscribeToNewsletterWithCoupon:
    (payload: SubscriptionPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "SUBSCRIBE_WITH_COUPON_REQUEST" });
      try {
        const result = await subscriptionApi.subscribeWithCoupon(payload);
        dispatch({ type: "SUBSCRIBE_WITH_COUPON_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getAllSubscriptions:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_ALL_SUBSCRIPTIONS_REQUEST" });
      try {
        const result = await subscriptionApi.getSubscriptions();
        dispatch({ type: "GET_ALL_SUBSCRIPTIONS_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getAllCoupons:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_ALL_COUPONS_REQUEST" });
      try {
        const result = await couponApi.getCoupons();
        dispatch({ type: "GET_ALL_COUPONS_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getAllUsers:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_ALL_USERS_REQUEST" });
      try {
        const result = await userApi.getUsers();
        dispatch({ type: "GET_ALL_USERS_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  validateCoupon:
    (
      couponCode: string,
      cartPayload: CartPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "VALIDATE_COUPON_REQUEST" });
      try {
        const result = await couponApi.validateCoupon(couponCode, cartPayload);
        dispatch({ type: "VALIDATE_COUPON_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  clearCouponValidationResult: () =>
    ({
      type: "CLEAR_COUPON_VALIDATION_RESULT",
    } as ClearCouponValidationResultAction),
  deleteUser:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "DELETE_USER_REQUEST" });
      try {
        const result = await userApi.deleteUser(id);
        dispatch({ type: "DELETE_USER_RESPONSE", result: result, id: id });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getAllBeforeAfterGalleries:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_BEFORE_AFTER_GALLERIES_REQUEST" });
      try {
        const result = await beforeAfterGalleryApi.getAll();
        dispatch({
          type: "GET_BEFORE_AFTER_GALLERIES_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateBeforeAfterGallery:
    (galleryId: number, title: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_BEFORE_AFTER_GALLERY_REQUEST" });
      try {
        const result = await beforeAfterGalleryApi.updateGallery(
          galleryId,
          title
        );
        dispatch({
          type: "UPDATE_BEFORE_AFTER_GALLERY_RESPONSE",
          result: result,
          galleryId: galleryId,
          title: title,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addBeforeAfterGallery:
    (title: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_BEFORE_AFTER_GALLERY_REQUEST" });
      try {
        const result = await beforeAfterGalleryApi.addGallery(title);
        dispatch({ type: "ADD_BEFORE_AFTER_GALLERY_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  addItemToBeforeAfterGallery:
    (
      galleryId: number,
      payload: BeforeAfterGalleryItemPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "ADD_ITEM_TO_BEFORE_AFTER_GALLERY_REQUEST" });
      try {
        const result = await beforeAfterGalleryApi.addItem(galleryId, payload);
        dispatch({
          type: "ADD_ITEM_TO_BEFORE_AFTER_GALLERY_RESPONSE",
          result: result,
          galleryId: galleryId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  removeItemFromBeforeAfterGallery:
    (galleryId: number, itemId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REMOVE_ITEM_FROM_BEFORE_AFTER_GALLERY_REQUEST" });
      try {
        const result = await beforeAfterGalleryApi.removeItem(
          galleryId,
          itemId
        );
        dispatch({
          type: "REMOVE_ITEM_FROM_BEFORE_AFTER_GALLERY_RESPONSE",
          result: result,
          galleryId: galleryId,
          itemId: itemId,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderBeforeAfterGalleries:
    (payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_BEFORE_AFTER_GALLERIES_REQUEST" });
      try {
        const result = await beforeAfterGalleryApi.reorderGalleries(payload);
        dispatch({
          type: "REORDER_BEFORE_AFTER_GALLERIES_RESPONSE",
          result: result,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  reorderItemsForBeforeAfterGallery:
    (galleryId: number, payload: ReorderPayload): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "REORDER_ITEMS_FOR_BEFORE_AFTER_GALLERY_REQUEST" });
      try {
        const result = await beforeAfterGalleryApi.reorderItems(
          galleryId,
          payload
        );
        dispatch({
          type: "REORDER_ITEMS_FOR_BEFORE_AFTER_GALLERY_RESPONSE",
          result: result,
          galleryId: galleryId,
          idsInOrder: payload.idsInOrder,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getGuaranteePages:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_GUARANTEE_PAGES_REQUEST" });
      try {
        const result = await guaranteePageApi.getGuaranteePages();
        dispatch({ type: "GET_GUARANTEE_PAGES_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getGuaranteePageByPath:
    (path: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_GUARANTEE_PAGE_BY_PATH_REQUEST" });
      try {
        const result = await guaranteePageApi.getGuaranteePageByPath(path);
        dispatch({
          type: "GET_GUARANTEE_PAGE_BY_PATH_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection1:
    (
      guaranteePageId: number,
      payload: PageTitleWithTextSectionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_1_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection1(
          guaranteePageId,
          payload
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_1_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection2:
    (
      guaranteePageId: number,
      imageId: number,
      isVisible: boolean,
      payload: CropPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_2_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection2(
          guaranteePageId,
          imageId,
          isVisible,
          payload
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_2_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection3:
    (
      guaranteePageId: number,
      isVisible: boolean,
      payload: PageTitleWithTextSectionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_3_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection3(
          guaranteePageId,
          isVisible,
          payload
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_3_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection4:
    (
      guaranteePageId: number,
      isVisible: boolean,
      payload: PageBulletPointListWithTitlePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_4_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection4(
          guaranteePageId,
          isVisible,
          payload
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_4_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection5:
    (
      guaranteePageId: number,
      isVisible: boolean,
      payload: PageImageWithTextSideBySidePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_5_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection5(
          guaranteePageId,
          isVisible,
          payload
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_5_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection6:
    (
      guaranteePageId: number,
      isVisible: boolean,
      text: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_6_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection6(
          guaranteePageId,
          isVisible,
          text
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_6_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection7:
    (
      guaranteePageId: number,
      isVisible: boolean,
      payload: PageImageWithDescriptionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_7_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection7(
          guaranteePageId,
          isVisible,
          payload
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_7_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection8:
    (
      guaranteePageId: number,
      isVisible: boolean,
      text: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_8_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection8(
          guaranteePageId,
          isVisible,
          text
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_8_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection9:
    (
      guaranteePageId: number,
      isVisible: boolean,
      text: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_9_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection9(
          guaranteePageId,
          isVisible,
          text
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_9_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection10:
    (
      guaranteePageId: number,
      isVisible: boolean,
      payload: PageBulletPointListWithTitlePayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_10_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection10(
          guaranteePageId,
          isVisible,
          payload
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_10_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePageSection11:
    (
      guaranteePageId: number,
      isVisible: boolean,
      text: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_SECTION_11_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePageSection11(
          guaranteePageId,
          isVisible,
          text
        );
        dispatch({
          type: "UPDATE_GUARANTEE_PAGE_SECTION_11_RESPONSE",
          result: result,
        });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  copyGuaranteePage:
    (
      guaranteePageId: number,
      name: string,
      path: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "COPY_GUARANTEE_PAGE_REQUEST" });
      try {
        const result = await guaranteePageApi.copyGuaranteePage(
          guaranteePageId,
          name,
          path
        );
        dispatch({ type: "COPY_GUARANTEE_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  updateGuaranteePage:
    (
      guaranteePageId: number,
      name: string,
      path: string,
      pricingGuaranteePageId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "UPDATE_GUARANTEE_PAGE_REQUEST" });
      try {
        const result = await guaranteePageApi.updateGuaranteePage(
          guaranteePageId,
          name,
          path,
          pricingGuaranteePageId
        );
        dispatch({ type: "UPDATE_GUARANTEE_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  getAboutUsPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: "GET_ABOUT_US_PAGE_REQUEST" });
      try {
        const result = await aboutUsPageApi.getAboutUsPage();
        dispatch({ type: "GET_ABOUT_US_PAGE_RESPONSE", result: result });
      } catch (err: any) {
        dispatch({ type: "ERROR", message: err });
      }
    },
  clearResult: () => ({ type: "CLEAR_RESULT" } as ClearResultAction),
  error: (message: string) =>
    ({ type: "ERROR", message: message } as ErrorAction),
};

export const reducer: Reducer<OxyState> = (
  state: OxyState | undefined,
  incomingAction: Action
): OxyState => {
  if (state === undefined) {
    return {
      userReceived: false,
      loggedInUser: null,
      mainPage: null,
      infraTrainerPage: null,
      currentPage: undefined,
      currentPricingPage: undefined,
      currentGuaranteePage: undefined,
      currentScrollingPage: undefined,
      pages: [],
      scrollingPages: [],
      guaranteePages: [],
      pricingPages: [],
      images: [],
      imagesPaged: { count: 0, pageCount: 0, data: [] },
      servicesMenu: [],
      pricingMenu: [],
      webshopMenu: [],
      skinProblemsMenu: [],
      aboutUsSections: [],
      result: null,
      loading: 0,
      errorMessage: null,
      errorDetails: null,
      pageOperationFinished: false,
      guaranteePageOperationFinished: false,
      pricingPageOperationFinished: false,
      menuOperationFinished: false,
      aboutUsOperationFinished: false,
      contactUsMessageOperationFinished: false,
      webshopMainPageScrollPosition: 0,
      productCategories: [],
      productCategoryOperationFinished: false,
      promotedProducts: [],
      myCart: [],
      cartType: CartType.Client,
      barionPayment: null,
      order: null,
      myOrders: [],
      allOrders: [],
      subscriptionOperationFinished: false,
      subscriptions: [],
      coupons: [],
      users: [],
      aboutUsPage: null,
      couponValidationResult: null,
      getOperationFinished: false,
      beforeAfterGalleries: [],
    };
  }

  const action = incomingAction as KnownAction;
  let newState;
  switch (action.type) {
    case "GET_USER_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        loggedInUser: null,
      };
    case "GET_USER_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        userReceived: true,
        loggedInUser: action.user,
      };
    case "GET_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_MAIN_PAGE_RESPONSE":
      return {
        ...state,
        mainPage:
          action.result.data != null ? action.result.data : state.mainPage,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_INFRATRAINER_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_INFRATRAINER_PAGE_RESPONSE":
      return {
        ...state,
        infraTrainerPage:
          action.result.data != null
            ? action.result.data
            : state.infraTrainerPage,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_PAGES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_PAGES_RESPONSE":
      return {
        ...state,
        pages: action.result.data != null ? action.result.data : state.pages,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_SCROLLING_PAGES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_SCROLLING_PAGES_RESPONSE":
      return {
        ...state,
        scrollingPages:
          action.result.data != null
            ? action.result.data
            : state.scrollingPages,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_PAGE_BY_PATH_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_PAGE_BY_PATH_RESPONSE":
      return {
        ...state,
        currentPage:
          action.result.data != null
            ? { ...action.result.data }
            : state.currentPage === undefined
            ? null
            : state.currentPage,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_SCROLLING_PAGE_BY_PATH_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_SCROLLING_PAGE_BY_PATH_RESPONSE":
      return {
        ...state,
        currentScrollingPage:
          action.result.data != null
            ? {
                ...action.result.data,
              }
            : state.currentScrollingPage === undefined
            ? null
            : state.currentScrollingPage,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "UPDATE_MAIN_PAGE_ABOUT_US_TEXT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_MAIN_PAGE_ABOUT_US_TEXT_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.aboutUsText = action.payload.aboutUsText;
        newState.mainPage.aboutUsSignatureText1 =
          action.payload.aboutUsSignatureText1;
        newState.mainPage.aboutUsSignatureText2 =
          action.payload.aboutUsSignatureText2;
      }

      return newState;
    case "UPDATE_MAIN_PAGE_YOUTUBE_VIDEO_ID_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_MAIN_PAGE_YOUTUBE_VIDEO_ID_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.backgroundYoutubeVideoId = action.videoId;
      }

      return newState;
    case "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_TITLES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_TITLES_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.videoOverlayMainTitle = action.payload.mainTitle;
        newState.mainPage.videoOverlaySubTitle = action.payload.subTitle;
        newState.mainPage.videoOverlaySubText = action.payload.subText;
      }

      return newState;
    case "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_DETAILS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_DETAILS_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.videoOverlayDetails = action.text;
      }

      return newState;
    case "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_OPENING_HOURS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_MAIN_PAGE_VIDEO_OVERLAY_OPENING_HOURS_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.weekdayOpeningHours =
          action.payload.weekdayOpeningHours;
        newState.mainPage.weekendOpeningHours =
          action.payload.weekendOpeningHours;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_1_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_1_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_3_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_3_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_4_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_4_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_5_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_5_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_7_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_7_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_8_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_8_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_9_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_9_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "UPDATE_PAGE_SECTION_10_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_SECTION_10_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPage = action.result.data;
      }

      return newState;
    case "COPY_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "COPY_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.pages = newState.pages.slice().concat([action.result.data]);
      }

      return newState;
    case "UPDATE_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.pages = newState.pages.map((page) => {
          return page.id === action.result.data.id
            ? {
                ...action.result.data,
              }
            : page;
        });
      }

      return newState;
    case "DELETE_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_PAGE_RESPONSE":
      newState = {
        ...state,
        result: action.result.data,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.pages = newState.pages.filter((x) => x.id !== action.id);
      }

      return newState;
    case "GET_GUARANTEE_PAGES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "GET_GUARANTEE_PAGES_RESPONSE":
      return {
        ...state,
        guaranteePages:
          action.result.data != null
            ? action.result.data
            : state.guaranteePages,
        loading: state.loading - 1,
      };
    case "GET_GUARANTEE_PAGE_BY_PATH_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "GET_GUARANTEE_PAGE_BY_PATH_RESPONSE":
      return {
        ...state,
        currentGuaranteePage:
          action.result.data != null
            ? action.result.data
            : state.currentGuaranteePage === undefined
            ? null
            : state.currentGuaranteePage,
        loading: state.loading - 1,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_1_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_1_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_2_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_2_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_3_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_3_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_4_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_4_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_5_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_5_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_6_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_6_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_7_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_7_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_8_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_8_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_9_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_9_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_10_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_10_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_SECTION_11_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        guaranteePageOperationFinished: false,
      };
    case "UPDATE_GUARANTEE_PAGE_SECTION_11_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        guaranteePageOperationFinished: true,
      };

      if (action.result.data) {
        newState.currentGuaranteePage = action.result.data;
      }

      return newState;
    case "COPY_GUARANTEE_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "COPY_GUARANTEE_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
      };

      if (action.result.data) {
        newState.guaranteePages = newState.guaranteePages
          .slice()
          .concat([action.result.data]);
      }

      return newState;
    case "UPDATE_GUARANTEE_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
      };
    case "UPDATE_GUARANTEE_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
      };

      if (action.result.data) {
        newState.guaranteePages = newState.guaranteePages.map(
          (guaranteePage) => {
            return guaranteePage.id === action.result.data.id
              ? {
                  ...action.result.data,
                }
              : guaranteePage;
          }
        );
      }

      return newState;
    case "GET_PRICING_PAGES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_PRICING_PAGES_RESPONSE":
      return {
        ...state,
        pricingPages:
          action.result.data != null ? action.result.data : state.pricingPages,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_PRICING_PAGE_BY_PATH_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_PRICING_PAGE_BY_PATH_RESPONSE":
      return {
        ...state,
        currentPricingPage:
          action.result.data != null
            ? action.result.data
            : state.currentPricingPage === undefined
            ? null
            : state.currentPricingPage,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "UPDATE_PRICING_PAGE_TEXTS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pricingPageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PRICING_PAGE_TEXTS_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pricingPageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPricingPage = action.result.data;
      }

      return newState;
    case "ADD_PRICING_PAGE_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pricingPageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_PRICING_PAGE_ITEM_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pricingPageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
        currentPricingPage: {
          ...state.currentPricingPage!,
          items:
            action.result.data == null
              ? state.currentPricingPage!.items
              : state.currentPricingPage!.items.concat([action.result.data]),
        },
      };

      return newState;
    case "UPDATE_PRICING_PAGE_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pricingPageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PRICING_PAGE_ITEM_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pricingPageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
        currentPricingPage: {
          ...state.currentPricingPage!,
          items:
            action.result.data == null
              ? state.currentPricingPage!.items
              : state.currentPricingPage!.items.map((item) => {
                  return item.id === action.result.data.id
                    ? {
                        ...action.result.data,
                      }
                    : item;
                }),
        },
      };

      return newState;
    case "DELETE_PRICING_PAGE_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pricingPageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_PRICING_PAGE_ITEM_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pricingPageOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.currentPricingPage!.items =
          newState.currentPricingPage!.items.filter((x) => x.id !== action.id);
      }

      return newState;
    case "REORDER_PRICING_PAGE_ITEMS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        pricingPageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_PRICING_PAGE_ITEMS_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        pricingPageOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.currentPricingPage != null) {
        newState.currentPricingPage.items =
          newState.currentPricingPage.items.sort((a, b) =>
            action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
              ? 1
              : -1
          );
      }

      return newState;
    case "COPY_PRICING_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "COPY_PRICING_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.pricingPages = newState.pricingPages
          .slice()
          .concat([action.result.data]);
      }

      return newState;
    case "DELETE_PRICING_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_PRICING_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.pricingPages = newState.pricingPages.filter(
          (x) => x.id !== action.id
        );
      }

      return newState;
    case "GET_IMAGES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_IMAGES_RESPONSE":
      return {
        ...state,
        images: action.result.data != null ? action.result.data : state.images,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_IMAGES_PAGED_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_IMAGES_PAGED_RESPONSE":
      return {
        ...state,
        imagesPaged:
          action.result.data != null ? action.result.data : state.imagesPaged,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "UPLOAD_IMAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPLOAD_IMAGE_RESPONSE":
      return {
        ...state,
        images:
          action.result.data != null
            ? state.images
                .slice()
                .concat([action.result.data])
                .sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                )
            : state.images,
        loading: state.loading - 1,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "DELETE_IMAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_IMAGE_RESPONSE":
      return {
        ...state,
        images: action.result.data
          ? state.images.filter((x) => x.id !== action.id)
          : state.images,
        loading: state.loading - 1,
        result: action.result.data,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "ADD_PROMOTED_SERVICE_TO_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_PROMOTED_SERVICE_TO_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null && newState.mainPage != null) {
        newState.mainPage.promotedServices = newState.mainPage.promotedServices
          .slice()
          .concat([action.result.data]);
      }

      return newState;
    case "UPDATE_PROMOTED_SERVICE_FOR_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PROMOTED_SERVICE_FOR_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null && newState.mainPage != null) {
        newState.mainPage.promotedServices =
          newState.mainPage.promotedServices.map((promotedService) => {
            return promotedService.id === action.id
              ? action.result.data
              : promotedService;
          });
      }

      return newState;
    case "REMOVE_PROMOTED_SERVICE_FROM_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REMOVE_PROMOTED_SERVICE_FROM_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.promotedServices =
          newState.mainPage.promotedServices.filter((x) => x.id !== action.id);
      }

      return newState;
    case "REORDER_PROMOTED_SERVICES_FOR_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_PROMOTED_SERVICES_FOR_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.promotedServices =
          newState.mainPage.promotedServices.sort((a, b) =>
            action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
              ? 1
              : -1
          );
      }

      return newState;
    case "ADD_CURRENT_OFFER_TO_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_CURRENT_OFFER_TO_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null && newState.mainPage != null) {
        newState.mainPage.currentOffers = newState.mainPage.currentOffers
          .slice()
          .concat([action.result.data]);
      }

      return newState;
    case "UPDATE_CURRENT_OFFER_FOR_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_CURRENT_OFFER_FOR_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null && newState.mainPage != null) {
        newState.mainPage.currentOffers = newState.mainPage.currentOffers.map(
          (currentOffer) => {
            return currentOffer.id === action.id
              ? action.result.data
              : currentOffer;
          }
        );
      }

      return newState;
    case "REMOVE_CURRENT_OFFER_FROM_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REMOVE_CURRENT_OFFER_FROM_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.currentOffers =
          newState.mainPage.currentOffers.filter((x) => x.id !== action.id);
      }

      return newState;
    case "REORDER_CURRENT_OFFERS_FOR_MAIN_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_CURRENT_OFFERS_FOR_MAIN_PAGE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.currentOffers = newState.mainPage.currentOffers.sort(
          (a, b) =>
            action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
              ? 1
              : -1
        );
      }

      return newState;
    case "UPDATE_CUSTOMER_REVIEW_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_CUSTOMER_REVIEW_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data && newState.mainPage != null) {
        newState.mainPage.customerReviews =
          newState.mainPage.customerReviews.map((x) => {
            return x.id === action.id
              ? {
                  ...x,
                  author: action.payload.author,
                  text: action.payload.text,
                }
              : x;
          });
      }

      return newState;
    case "ADD_IMAGE_TO_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_IMAGE_TO_GALLERY_RESPONSE":
      newState = {
        ...state,
        mainPage:
          state.mainPage == null
            ? state.mainPage
            : {
                ...state.mainPage,
                gallery: {
                  ...state.mainPage!!.gallery,
                  images: state.mainPage!!.gallery.images,
                },
              },
        currentPage:
          state.currentPage == null
            ? state.currentPage
            : {
                ...state.currentPage,
                section2Gallery: {
                  ...state.currentPage.section2Gallery,
                  images: state.currentPage.section2Gallery.images,
                },
                section6Gallery: {
                  ...state.currentPage.section6Gallery,
                  images: state.currentPage.section6Gallery.images,
                },
              },
        aboutUsPage:
          state.aboutUsPage == null
            ? state.aboutUsPage
            : {
                ...state.aboutUsPage,
                gallery: {
                  ...state.aboutUsPage.gallery,
                  images: state.aboutUsPage.gallery.images,
                },
              },
        images: state.images,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        if (
          newState.mainPage != null &&
          newState.mainPage.gallery.id === action.galleryId
        ) {
          const image = action.result.data;
          newState.mainPage.gallery.images =
            newState.mainPage.gallery.images.concat([{ id: 0, image: image }]);
          newState.images = newState.images.slice().concat([image]);
        }

        if (newState.currentPage != null) {
          if (newState.currentPage.section2Gallery.id === action.galleryId) {
            const image = action.result.data;
            newState.currentPage.section2Gallery.images =
              newState.currentPage.section2Gallery.images.concat([
                { id: 0, image: image },
              ]);
            newState.images = newState.images.slice().concat([image]);
          }

          if (newState.currentPage.section6Gallery.id === action.galleryId) {
            const image = action.result.data;
            newState.currentPage.section6Gallery.images =
              newState.currentPage.section6Gallery.images.concat([
                { id: 0, image: image },
              ]);
            newState.images = newState.images.slice().concat([image]);
          }
        }

        if (newState.aboutUsPage != null) {
          if (newState.aboutUsPage.gallery.id === action.galleryId) {
            const image = action.result.data;
            newState.aboutUsPage.gallery.images =
              newState.aboutUsPage.gallery.images.concat([
                { id: 0, image: image },
              ]);
            newState.images = newState.images.slice().concat([image]);
          }
        }
      }

      return newState;
    case "REMOVE_IMAGE_FROM_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REMOVE_IMAGE_FROM_GALLERY_RESPONSE":
      newState = {
        ...state,
        mainPage:
          state.mainPage == null
            ? state.mainPage
            : {
                ...state.mainPage,
                gallery: {
                  ...state.mainPage!!.gallery,
                  images: state.mainPage!!.gallery.images,
                },
              },
        currentPage:
          state.currentPage == null
            ? state.currentPage
            : {
                ...state.currentPage,
                section2Gallery: {
                  ...state.currentPage.section2Gallery,
                  images: state.currentPage.section2Gallery.images,
                },
                section6Gallery: {
                  ...state.currentPage.section6Gallery,
                  images: state.currentPage.section6Gallery.images,
                },
              },
        aboutUsPage:
          state.aboutUsPage == null
            ? state.aboutUsPage
            : {
                ...state.aboutUsPage,
                gallery: {
                  ...state.aboutUsPage.gallery,
                  images: state.aboutUsPage.gallery.images,
                },
              },
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        if (
          newState.mainPage != null &&
          newState.mainPage.gallery.id === action.galleryId
        ) {
          newState.mainPage.gallery.images = newState.mainPage.gallery.images
            .slice()
            .filter((x) => x.image.id !== action.imageId);
        }

        if (newState.currentPage != null) {
          if (newState.currentPage.section2Gallery.id === action.galleryId) {
            newState.currentPage.section2Gallery.images =
              newState.currentPage.section2Gallery.images
                .slice()
                .filter((x) => x.image.id !== action.imageId);
          }

          if (newState.currentPage.section6Gallery.id === action.galleryId) {
            newState.currentPage.section6Gallery.images =
              newState.currentPage.section6Gallery.images
                .slice()
                .filter((x) => x.image.id !== action.imageId);
          }
        }

        if (newState.aboutUsPage != null) {
          if (newState.aboutUsPage.gallery.id === action.galleryId) {
            newState.aboutUsPage.gallery.images =
              newState.aboutUsPage.gallery.images
                .slice()
                .filter((x) => x.image.id !== action.imageId);
          }
        }
      }

      return newState;
    case "REORDER_IMAGES_FOR_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_IMAGES_FOR_GALLERY_RESPONSE":
      newState = {
        ...state,
        mainPage:
          state.mainPage == null
            ? state.mainPage
            : {
                ...state.mainPage,
                gallery: {
                  ...state.mainPage!!.gallery,
                  images: state.mainPage!!.gallery.images,
                },
              },
        currentPage:
          state.currentPage == null
            ? state.currentPage
            : {
                ...state.currentPage,
                section2Gallery: {
                  ...state.currentPage.section2Gallery,
                  images: state.currentPage.section2Gallery.images,
                },
                section6Gallery: {
                  ...state.currentPage.section6Gallery,
                  images: state.currentPage.section6Gallery.images,
                },
              },
        aboutUsPage:
          state.aboutUsPage == null
            ? state.aboutUsPage
            : {
                ...state.aboutUsPage,
                gallery: {
                  ...state.aboutUsPage!!.gallery,
                  images: state.aboutUsPage!!.gallery.images,
                },
              },
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        if (
          newState.mainPage != null &&
          newState.mainPage.gallery.id === action.galleryId
        ) {
          newState.mainPage.gallery.images =
            newState.mainPage.gallery.images.sort((a, b) =>
              action.idsInOrder.indexOf(a.image.id) >
              action.idsInOrder.indexOf(b.image.id)
                ? 1
                : -1
            );
        }

        if (newState.currentPage != null) {
          if (newState.currentPage.section2Gallery.id === action.galleryId) {
            newState.currentPage.section2Gallery.images =
              newState.currentPage.section2Gallery.images.sort((a, b) =>
                action.idsInOrder.indexOf(a.image.id) >
                action.idsInOrder.indexOf(b.image.id)
                  ? 1
                  : -1
              );
          }

          if (newState.currentPage.section6Gallery.id === action.galleryId) {
            newState.currentPage.section6Gallery.images =
              newState.currentPage.section6Gallery.images.sort((a, b) =>
                action.idsInOrder.indexOf(a.image.id) >
                action.idsInOrder.indexOf(b.image.id)
                  ? 1
                  : -1
              );
          }
        }

        if (
          newState.aboutUsPage != null &&
          newState.aboutUsPage.gallery.id === action.galleryId
        ) {
          newState.aboutUsPage.gallery.images =
            newState.aboutUsPage.gallery.images.sort((a, b) =>
              action.idsInOrder.indexOf(a.image.id) >
              action.idsInOrder.indexOf(b.image.id)
                ? 1
                : -1
            );
        }
      }

      return newState;
    case "GET_SERVICES_MENU_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_SERVICES_MENU_RESPONSE":
      return {
        ...state,
        servicesMenu:
          action.result.data != null ? action.result.data : state.servicesMenu,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_PRICING_MENU_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_PRICING_MENU_RESPONSE":
      return {
        ...state,
        pricingMenu:
          action.result.data != null ? action.result.data : state.pricingMenu,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_WEBSHOP_MENU_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_WEBSHOP_MENU_RESPONSE":
      return {
        ...state,
        webshopMenu:
          action.result.data != null ? action.result.data : state.webshopMenu,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_SKIN_PROBLEMS_MENU_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_SKIN_PROBLEMS_MENU_RESPONSE":
      return {
        ...state,
        skinProblemsMenu:
          action.result.data != null
            ? action.result.data
            : state.skinProblemsMenu,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "ADD_SERVICES_PARENT_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_SERVICES_PARENT_MENU_ITEM_RESPONSE":
      return {
        ...state,
        servicesMenu:
          action.result.data != null
            ? state.servicesMenu.concat(action.result.data)
            : state.servicesMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "ADD_SERVICES_LEAF_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_SERVICES_LEAF_MENU_ITEM_RESPONSE":
      return {
        ...state,
        servicesMenu:
          action.result.data != null
            ? state.servicesMenu.map((menuItem) => {
                return menuItem.id === action.parentId
                  ? {
                      ...menuItem,
                      children: menuItem.children.concat([action.result.data]),
                    }
                  : menuItem;
              })
            : state.servicesMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "DELETE_SERVICES_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_SERVICES_MENU_ITEM_RESPONSE":
      return {
        ...state,
        servicesMenu:
          action.result.data != null ? action.result.data : state.servicesMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "REORDER_SERVICES_PARENT_MENU_ITEMS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_SERVICES_PARENT_MENU_ITEMS_RESPONSE":
      newState = {
        ...state,
        servicesMenu: state.servicesMenu.slice(),
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.servicesMenu = newState.servicesMenu.sort((a, b) =>
          action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
            ? 1
            : -1
        );
      }

      return newState;
    case "REORDER_SERVICES_LEAF_MENU_ITEMS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_SERVICES_LEAF_MENU_ITEMS_RESPONSE":
      newState = {
        ...state,
        servicesMenu: state.servicesMenu
          .map((menuItem) => {
            return {
              ...menuItem,
              children: menuItem.children.slice(),
            };
          })
          .slice(),
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.servicesMenu = newState.servicesMenu.map((menuItem) => {
          return menuItem.id === action.parentId
            ? {
                ...menuItem,
                children: menuItem.children.sort((a, b) =>
                  action.idsInOrder.indexOf(a.id) >
                  action.idsInOrder.indexOf(b.id)
                    ? 1
                    : -1
                ),
              }
            : menuItem;
        });
      }

      return newState;
    case "ADD_PRICING_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_PRICING_MENU_ITEM_RESPONSE":
      return {
        ...state,
        pricingMenu:
          action.result.data != null
            ? state.pricingMenu.concat(action.result.data)
            : state.pricingMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "DELETE_PRICING_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_PRICING_MENU_ITEM_RESPONSE":
      return {
        ...state,
        pricingMenu:
          action.result.data != null ? action.result.data : state.pricingMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "REORDER_PRICING_MENU_ITEMS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_PRICING_MENU_ITEMS_RESPONSE":
      newState = {
        ...state,
        pricingMenu: state.pricingMenu.slice(),
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.pricingMenu = newState.pricingMenu.sort((a, b) =>
          action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
            ? 1
            : -1
        );
      }

      return newState;
    case "ADD_WEBSHOP_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_WEBSHOP_MENU_ITEM_RESPONSE":
      return {
        ...state,
        webshopMenu:
          action.result.data != null
            ? state.webshopMenu.concat(action.result.data)
            : state.webshopMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "DELETE_WEBSHOP_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_WEBSHOP_MENU_ITEM_RESPONSE":
      return {
        ...state,
        webshopMenu:
          action.result.data != null ? action.result.data : state.webshopMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "REORDER_WEBSHOP_MENU_ITEMS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_WEBSHOP_MENU_ITEMS_RESPONSE":
      newState = {
        ...state,
        webshopMenu: state.webshopMenu.slice(),
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.webshopMenu = newState.webshopMenu.sort((a, b) =>
          action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
            ? 1
            : -1
        );
      }

      return newState;
    case "ADD_SKIN_PROBLEMS_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_SKIN_PROBLEMS_MENU_ITEM_RESPONSE":
      return {
        ...state,
        skinProblemsMenu:
          action.result.data != null
            ? state.skinProblemsMenu.concat(action.result.data)
            : state.skinProblemsMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "DELETE_SKIN_PROBLEMS_MENU_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_SKIN_PROBLEMS_MENU_ITEM_RESPONSE":
      return {
        ...state,
        skinProblemsMenu:
          action.result.data != null
            ? action.result.data
            : state.skinProblemsMenu,
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "REORDER_SKIN_PROBLEMS_MENU_ITEMS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        menuOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_SKIN_PROBLEMS_MENU_ITEMS_RESPONSE":
      newState = {
        ...state,
        skinProblemsMenu: state.skinProblemsMenu.slice(),
        loading: state.loading - 1,
        menuOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.skinProblemsMenu = newState.skinProblemsMenu.sort((a, b) =>
          action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
            ? 1
            : -1
        );
      }

      return newState;
    case "GET_ABOUT_US_SECTIONS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_ABOUT_US_SECTIONS_RESPONSE":
      return {
        ...state,
        aboutUsSections:
          action.result.data != null
            ? action.result.data
            : state.aboutUsSections,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "ADD_ABOUT_US_SECTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        aboutUsOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_ABOUT_US_SECTION_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        aboutUsOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.aboutUsSections = newState.aboutUsSections
          .slice()
          .concat([action.result.data]);
      }

      return newState;
    case "UPDATE_ABOUT_US_SECTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        aboutUsOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_ABOUT_US_SECTION_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        aboutUsOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.aboutUsSections = newState.aboutUsSections.map((section) => {
          return section.id === action.id ? action.result.data : section;
        });
      }

      return newState;
    case "DELETE_ABOUT_US_SECTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        aboutUsOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_ABOUT_US_SECTION_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        aboutUsOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.aboutUsSections = newState.aboutUsSections.filter(
          (x) => x.id !== action.id
        );
      }

      return newState;
    case "REORDER_ABOUT_US_SECTIONS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        aboutUsOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_ABOUT_US_SECTIONS_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        aboutUsOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.aboutUsSections = newState.aboutUsSections.sort((a, b) =>
          action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
            ? 1
            : -1
        );
      }

      return newState;
    case "SEND_CONTACT_US_MESSAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        contactUsMessageOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "SEND_CONTACT_US_MESSAGE_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        contactUsMessageOperationFinished: true,
        result: action.result.data,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "UPDATE_WEBSHOP_MAIN_PAGE_SCROLL_POSITION":
      return {
        ...state,
        webshopMainPageScrollPosition: action.scrollTop,
      };
    case "GET_PRODUCT_CATEGORIES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_PRODUCT_CATEGORIES_RESPONSE":
      return {
        ...state,
        productCategories:
          action.result.data != null
            ? action.result.data
            : state.productCategories,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "ADD_PRODUCT_CATEGORY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_PRODUCT_CATEGORY_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories
          .slice()
          .concat([action.result.data]);
      }

      return newState;
    case "UPDATE_PRODUCT_CATEGORY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PRODUCT_CATEGORY_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (section) => {
            return section.id === action.result.data.id
              ? action.result.data
              : section;
          }
        );
      }

      return newState;
    case "TOGGLE_PRODUCT_CATEGORY_VISIBLE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "TOGGLE_PRODUCT_CATEGORY_VISIBLE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (section) => {
            return section.id === action.result.data.id
              ? action.result.data
              : section;
          }
        );
      }

      return newState;
    case "REORDER_PRODUCT_CATEGORIES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_PRODUCT_CATEGORIES_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.productCategories = newState.productCategories.sort((a, b) =>
          action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
            ? 1
            : -1
        );
      }

      return newState;
    case "ADD_PRODUCT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_PRODUCT_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.id === action.result.data.categoryId
              ? {
                  ...category,
                  products: category.products
                    .slice()
                    .concat([action.result.data]),
                }
              : category;
          }
        );
      }

      return newState;
    case "UPDATE_PRODUCT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PRODUCT_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.id === action.result.data.categoryId
              ? {
                  ...category,
                  products: category.products.map((product) => {
                    return product.id === action.result.data.id
                      ? action.result.data
                      : product;
                  }),
                }
              : category;
          }
        );
      }

      return newState;
    case "TOGGLE_PRODUCT_VISIBLE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "TOGGLE_PRODUCT_VISIBLE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.id === action.result.data.categoryId
              ? {
                  ...category,
                  products: category.products.map((product) => {
                    return product.id === action.result.data.id
                      ? action.result.data
                      : product;
                  }),
                }
              : category;
          }
        );
      }

      return newState;
    case "REORDER_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_PRODUCTS_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.id === action.categoryId
              ? {
                  ...category,
                  products: category.products.sort((a, b) =>
                    action.idsInOrder.indexOf(a.id) >
                    action.idsInOrder.indexOf(b.id)
                      ? 1
                      : -1
                  ),
                }
              : category;
          }
        );
      }

      return newState;
    case "ADD_PRODUCT_OPTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_PRODUCT_OPTION_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.products.find(
              (x) => x.id === action.result.data.productId
            ) != null
              ? {
                  ...category,
                  products: category.products.map((product) => {
                    return product.id === action.result.data.productId
                      ? {
                          ...product,
                          options: product.options
                            .slice()
                            .concat([action.result.data]),
                        }
                      : product;
                  }),
                }
              : category;
          }
        );
      }

      return newState;
    case "UPDATE_PRODUCT_OPTION_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PRODUCT_OPTION_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.products.find(
              (x) => x.id === action.result.data.productId
            ) != null
              ? {
                  ...category,
                  products: category.products.map((product) => {
                    return product.id === action.result.data.productId
                      ? {
                          ...product,
                          options: product.options.map((option) => {
                            return option.id === action.result.data.id
                              ? action.result.data
                              : option;
                          }),
                        }
                      : product;
                  }),
                }
              : category;
          }
        );
      }

      return newState;
    case "TOGGLE_PRODUCT_OPTION_VISIBLE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "TOGGLE_PRODUCT_OPTION_VISIBLE_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data != null) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.products.find(
              (x) => x.id === action.result.data.productId
            ) != null
              ? {
                  ...category,
                  products: category.products.map((product) => {
                    return product.id === action.result.data.productId
                      ? {
                          ...product,
                          options: product.options.map((option) => {
                            return option.id === action.result.data.id
                              ? action.result.data
                              : option;
                          }),
                        }
                      : product;
                  }),
                }
              : category;
          }
        );
      }

      return newState;
    case "REORDER_PRODUCT_OPTIONS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        productCategoryOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_PRODUCT_OPTIONS_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        productCategoryOperationFinished: true,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.productCategories = newState.productCategories.map(
          (category) => {
            return category.products.find((x) => x.id === action.productId) !=
              null
              ? {
                  ...category,
                  products: category.products.map((product) => {
                    return product.id === action.productId
                      ? {
                          ...product,
                          options: product.options.sort((a, b) =>
                            action.idsInOrder.indexOf(a.id) >
                            action.idsInOrder.indexOf(b.id)
                              ? 1
                              : -1
                          ),
                        }
                      : product;
                  }),
                }
              : category;
          }
        );
      }

      return newState;
    case "GET_PROMOTED_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_PROMOTED_PRODUCTS_RESPONSE":
      return {
        ...state,
        promotedProducts:
          action.result.data != null
            ? action.result.data
            : state.promotedProducts,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_MY_CART_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_MY_CART_RESPONSE":
      return {
        ...state,
        myCart: action.result.data != null ? action.result.data : state.myCart,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "ADD_PRODUCT_TO_MY_CART_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_PRODUCT_TO_MY_CART_RESPONSE":
      return {
        ...state,
        myCart:
          action.result.data != null
            ? state.myCart.concat([action.result.data])
            : state.myCart,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "INCREASE_MY_CART_ITEM_QUANTITY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "INCREASE_MY_CART_ITEM_QUANTITY_RESPONSE":
      return {
        ...state,
        myCart:
          action.result.data != null
            ? state.myCart.map((cartItem) => {
                return cartItem.id === action.result.data.id
                  ? action.result.data
                  : cartItem;
              })
            : state.myCart,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "DECREASE_MY_CART_ITEM_QUANTITY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "DECREASE_MY_CART_ITEM_QUANTITY_RESPONSE":
      return {
        ...state,
        myCart:
          action.result.data != null
            ? state.myCart.map((cartItem) => {
                return cartItem.id === action.result.data.id
                  ? action.result.data
                  : cartItem;
              })
            : state.myCart,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "DELETE_MY_CART_ITEM_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_MY_CART_ITEM_RESPONSE":
      return {
        ...state,
        myCart: action.result.data
          ? state.myCart.filter((x) => x.id !== action.id)
          : state.myCart,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "VALIDATE_CLIENT_CART_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "VALIDATE_CLIENT_CART_RESPONSE":
      return {
        ...state,
        myCart: action.result.data != null ? action.result.data : state.myCart,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "CONVERT_CLIENT_CART_TO_SERVER_CART_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "CONVERT_CLIENT_CART_TO_SERVER_CART_RESPONSE":
      return {
        ...state,
        myCart: action.result.data != null ? action.result.data : state.myCart,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "UPDATE_CART_TYPE":
      return {
        ...state,
        cartType: action.cartType,
      };
    case "SUBMIT_ORDER_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "SUBMIT_ORDER_RESPONSE":
      return {
        ...state,
        barionPayment: action.result.data,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "UPDATE_PROFILE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "UPDATE_PROFILE_RESPONSE":
      return {
        ...state,
        loggedInUser:
          action.result.data != null ? action.result.data : state.loggedInUser,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "GET_ORDER_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_ORDER_RESPONSE":
      return {
        ...state,
        order:
          action.result.data != null
            ? {
                ...action.result.data,
                orderedAt: new Date(action.result.data.orderedAt),
                items: action.result.data.items.map((orderItem) => {
                  return {
                    ...orderItem,
                    vouchers: orderItem.vouchers.map((voucher) => {
                      return {
                        ...voucher,
                        expiresAt: new Date(voucher.expiresAt),
                      };
                    }),
                  };
                }),
              }
            : state.order,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_MY_ORDERS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_MY_ORDERS_RESPONSE":
      return {
        ...state,
        myOrders:
          action.result.data != null
            ? action.result.data.map((x) => {
                return {
                  ...x,
                  orderedAt: new Date(x.orderedAt),
                  items: x.items.map((orderItem) => {
                    return {
                      ...orderItem,
                      vouchers: orderItem.vouchers.map((voucher) => {
                        return {
                          ...voucher,
                          expiresAt: new Date(voucher.expiresAt),
                        };
                      }),
                    };
                  }),
                };
              })
            : state.myOrders,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_ALL_ORDERS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_ALL_ORDERS_RESPONSE":
      return {
        ...state,
        allOrders:
          action.result.data != null
            ? action.result.data.map((x) => {
                return {
                  ...x,
                  orderedAt: new Date(x.orderedAt),
                  items: x.items.map((orderItem) => {
                    return {
                      ...orderItem,
                      vouchers: orderItem.vouchers.map((voucher) => {
                        return {
                          ...voucher,
                          expiresAt: new Date(voucher.expiresAt),
                        };
                      }),
                    };
                  }),
                };
              })
            : state.allOrders,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "RESTART_PAYMENT_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "RESTART_PAYMENT_RESPONSE":
      return {
        ...state,
        barionPayment: action.result.data,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "SUBSCRIBE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        subscriptionOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "SUBSCRIBE_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        subscriptionOperationFinished: true,
        result: action.result.data,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "SUBSCRIBE_WITH_COUPON_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        subscriptionOperationFinished: false,
        result: null,
        getOperationFinished: false,
      };
    case "SUBSCRIBE_WITH_COUPON_RESPONSE":
      return {
        ...state,
        loading: state.loading - 1,
        subscriptionOperationFinished: true,
        result: action.result.data,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "GET_ALL_SUBSCRIPTIONS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_ALL_SUBSCRIPTIONS_RESPONSE":
      return {
        ...state,
        subscriptions:
          action.result.data != null ? action.result.data : state.subscriptions,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_ALL_COUPONS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_ALL_COUPONS_RESPONSE":
      return {
        ...state,
        coupons:
          action.result.data != null
            ? action.result.data.map((coupon) => {
                return { ...coupon, expiresAt: new Date(coupon.expiresAt) };
              })
            : state.coupons,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "GET_ALL_USERS_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_ALL_USERS_RESPONSE":
      return {
        ...state,
        users: action.result.data != null ? action.result.data : state.users,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "VALIDATE_COUPON_REQUEST":
      return {
        ...state,
        couponValidationResult: null,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "VALIDATE_COUPON_RESPONSE":
      return {
        ...state,
        couponValidationResult:
          action.result.data != null
            ? action.result.data
            : state.couponValidationResult,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "CLEAR_COUPON_VALIDATION_RESULT":
      return { ...state, couponValidationResult: null };
    case "DELETE_USER_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "DELETE_USER_RESPONSE":
      return {
        ...state,
        users: action.result.data
          ? state.users.filter((x) => x.id !== action.id)
          : state.users,
        loading: state.loading - 1,
        result: action.result.data,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };
    case "GET_BEFORE_AFTER_GALLERIES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_BEFORE_AFTER_GALLERIES_RESPONSE":
      return {
        ...state,
        beforeAfterGalleries:
          action.result.data != null
            ? action.result.data
            : state.beforeAfterGalleries,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "UPDATE_BEFORE_AFTER_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
      };
    case "UPDATE_BEFORE_AFTER_GALLERY_RESPONSE":
      newState = {
        ...state,
        beforeAfterGalleries: action.result.data
          ? state.beforeAfterGalleries.map((gallery) => {
              return gallery.id === action.galleryId
                ? { ...gallery, title: action.title }
                : gallery;
            })
          : state.beforeAfterGalleries,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
      };

      return newState;
    case "ADD_BEFORE_AFTER_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_BEFORE_AFTER_GALLERY_RESPONSE":
      newState = {
        ...state,
        beforeAfterGalleries:
          action.result.data != null
            ? state.beforeAfterGalleries.slice().concat([action.result.data])
            : state.beforeAfterGalleries,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      return newState;
    case "ADD_ITEM_TO_BEFORE_AFTER_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "ADD_ITEM_TO_BEFORE_AFTER_GALLERY_RESPONSE":
      newState = {
        ...state,
        beforeAfterGalleries:
          action.result.data != null
            ? state.beforeAfterGalleries.map((gallery) => {
                return gallery.id === action.galleryId
                  ? {
                      ...gallery,
                      items: gallery.items
                        .slice()
                        .concat([{ ...action.result.data }]),
                    }
                  : gallery;
              })
            : state.beforeAfterGalleries,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      return newState;
    case "REMOVE_ITEM_FROM_BEFORE_AFTER_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REMOVE_ITEM_FROM_BEFORE_AFTER_GALLERY_RESPONSE":
      newState = {
        ...state,
        beforeAfterGalleries: action.result.data
          ? state.beforeAfterGalleries.map((gallery) => {
              return gallery.id === action.galleryId
                ? {
                    ...gallery,
                    items: gallery.items.filter((x) => x.id !== action.itemId),
                  }
                : gallery;
            })
          : state.beforeAfterGalleries,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      return newState;
    case "REORDER_ITEMS_FOR_BEFORE_AFTER_GALLERY_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_ITEMS_FOR_BEFORE_AFTER_GALLERY_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.beforeAfterGalleries = newState.beforeAfterGalleries.map((x) =>
          x.id === action.galleryId
            ? {
                ...x,
                items: x.items
                  .slice()
                  .sort((a, b) =>
                    action.idsInOrder.indexOf(a.id) >
                    action.idsInOrder.indexOf(b.id)
                      ? 1
                      : -1
                  ),
              }
            : x
        );
      }

      return newState;
    case "REORDER_BEFORE_AFTER_GALLERIES_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "REORDER_BEFORE_AFTER_GALLERIES_RESPONSE":
      newState = {
        ...state,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: false,
      };

      if (action.result.data) {
        newState.beforeAfterGalleries = newState.beforeAfterGalleries
          .slice()
          .sort((a, b) =>
            action.idsInOrder.indexOf(a.id) > action.idsInOrder.indexOf(b.id)
              ? 1
              : -1
          );
      }

      return newState;
    case "GET_ABOUT_US_PAGE_REQUEST":
      return {
        ...state,
        loading: state.loading + 1,
        result: null,
        getOperationFinished: false,
      };
    case "GET_ABOUT_US_PAGE_RESPONSE":
      return {
        ...state,
        aboutUsPage: action.result.data != null ? action.result.data : null,
        loading: state.loading - 1,
        result: !action.result.hasError,
        errorMessage: action.result.errorMessage,
        getOperationFinished: true,
      };
    case "CLEAR_RESULT":
      return { ...state, result: null, errorMessage: null, errorDetails: null };
    case "ERROR":
      return { ...state, result: false, loading: state.loading - 1 };
    default:
      return state;
  }
};
