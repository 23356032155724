import { ProtectedRestApi } from "./";
import { BarionPaymentStartDto, CartItemDto, ServiceResult } from "../models";
import { CartPayload, OrderPayload } from "../payloads";
import { OrderDto } from "../models/shop";

export class OrderApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyorder";

    public getMyCart(): Promise<ServiceResult<CartItemDto[]>> {
        return this.httpGet(`${OrderApi.baseUrl}/my-cart`) as Promise<ServiceResult<CartItemDto[]>>;
    }

    public addProductToMyCart(productId: number | null, productOptionId: number | null, gifteeName: string): Promise<ServiceResult<CartItemDto>> {
        return this.httpPut(`${OrderApi.baseUrl}/add-to-my-cart?productId=${productId == null ? "" : productId}&productOptionId=${productOptionId == null ? "" : productOptionId}&gifteeName=${gifteeName}`) as Promise<ServiceResult<CartItemDto>>;
    }

    public increaseMyCartItemQuantity(cartItemId: number): Promise<ServiceResult<CartItemDto>> {
        return this.httpPost(`${OrderApi.baseUrl}/increase-cart-item-quantity?cartItemId=${cartItemId}`) as Promise<ServiceResult<CartItemDto>>;
    }

    public decreaseMyCartItemQuantity(cartItemId: number): Promise<ServiceResult<CartItemDto>> {
        return this.httpPost(`${OrderApi.baseUrl}/decrease-cart-item-quantity?cartItemId=${cartItemId}`) as Promise<ServiceResult<CartItemDto>>;
    }

    public deleteCartItem(cartItemId: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${OrderApi.baseUrl}/delete-cart-item?cartItemId=${cartItemId}`) as Promise<ServiceResult<boolean>>;
    }

    public validateClientCart(payload: CartPayload): Promise<ServiceResult<CartItemDto[]>> {
        return this.httpPost(`${OrderApi.baseUrl}/validate-client-cart`, payload, true, true) as Promise<ServiceResult<CartItemDto[]>>;
    }

    public convertClientCartToServerCart(payload: CartPayload): Promise<ServiceResult<CartItemDto[]>> {
        return this.httpPost(`${OrderApi.baseUrl}/convert-client-cart-to-server-cart`, payload, true, true) as Promise<ServiceResult<CartItemDto[]>>;
    }

    public submitOrder(orderPayload: OrderPayload): Promise<ServiceResult<BarionPaymentStartDto>> {
        return this.httpPost(`${OrderApi.baseUrl}/submit-order`, orderPayload) as Promise<ServiceResult<BarionPaymentStartDto>>;
    }

    public submitOrderAnonymous(orderPayload: OrderPayload): Promise<ServiceResult<BarionPaymentStartDto>> {
        return this.httpPost(`${OrderApi.baseUrl}/submit-order`, orderPayload, true, true) as Promise<ServiceResult<BarionPaymentStartDto>>;
    }

    public getOrder(orderId: number): Promise<ServiceResult<OrderDto>> {
        return this.httpGet(`${OrderApi.baseUrl}/${orderId}`) as Promise<ServiceResult<OrderDto>>;
    }

    public getOrderAnonymous(orderId: number, paymentId: string): Promise<ServiceResult<OrderDto>> {
        return this.httpGet(`${OrderApi.baseUrl}/${orderId}?paymentId=${paymentId}`, true) as Promise<ServiceResult<OrderDto>>;
    }

    public getMyOrders(): Promise<ServiceResult<OrderDto[]>> {
        return this.httpGet(`${OrderApi.baseUrl}/my-orders`) as Promise<ServiceResult<OrderDto[]>>;
    }

    public getAllOrders(): Promise<ServiceResult<OrderDto[]>> {
        return this.httpGet(`${OrderApi.baseUrl}/all-orders`) as Promise<ServiceResult<OrderDto[]>>;
    }

    public restartPayment(orderId: number): Promise<ServiceResult<BarionPaymentStartDto>> {
        return this.httpPost(`${OrderApi.baseUrl}/restart-payment/${orderId}`) as Promise<ServiceResult<BarionPaymentStartDto>>;
    }

    public restartPaymentAnonymous(orderId: number): Promise<ServiceResult<BarionPaymentStartDto>> {
        return this.httpPost(`${OrderApi.baseUrl}/restart-payment/${orderId}`, undefined, true, true) as Promise<ServiceResult<BarionPaymentStartDto>>;
    }
}
