import * as React from 'react';
import { PromotedServiceDto } from '../models/PromotedServiceDto';
import { AdminEditorBadgePosition } from './admin/AdminEditorBadge';
import AdminEditorBadge from './admin/AdminEditorBadge';
import PromotedServiceRow from './PromotedServiceRow';
import { isMobileOnly } from 'react-device-detect';

interface PromotedServicesProps {
    promotedServices: PromotedServiceDto[];
    isAdminAndEditing: boolean;
    toggleEditor: () => void;
}

class PromotedServices extends React.PureComponent<PromotedServicesProps> {
    public render() {
        return (
            <React.Fragment>
                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-8 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(188,151,120,0.1)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: isMobileOnly ? "0" : '50px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                    <div className="fusion-builder-row fusion-row">
                        {this.props.isAdminAndEditing && <AdminEditorBadge position={AdminEditorBadgePosition.TopRight} onClick={this.props.toggleEditor} />}
                        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-25 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: isMobileOnly ? "0" : '50px', marginBottom: '30px' }}>
                            <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                <div className="fusion-text fusion-text-19">
                                    <h1 style={{ textAlign: 'center' }}>Kiemelt szolgáltatásaink</h1>
                                </div>
                                <div className="fusion-clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
                <PromotedServiceRow
                    promotedServices={this.props.promotedServices}
                />
            </React.Fragment>
        );
    }
}

export default PromotedServices;
