import * as React from 'react';
import { BeforeAfterGalleryDto, BeforeAfterGalleryItemDto, ImageDto } from "../../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReorderPayload } from '../../payloads';
import Reorder from 'react-reorder';
import BeforeAfterGalleryItemEditorCard from "./BeforeAfterGalleryItemEditorCard";

interface BeforeAfterGalleryEditorCardProps {
    item: BeforeAfterGalleryDto;
    sharedProps?: {
        onItemDelete: (galleryId: number, itemId: number) => void;
        onAddItemClick: (id: number) => void;
        images: ImageDto[];
        reorderItems: (galleryId: number, payload: ReorderPayload) => void;
        expandedId: number;
        toggleExpandedId: (id: number) => void;
        updateGallery: (galleryId: number, title: string) => void;
    }
}

interface BeforeAfterGalleryEditorCardState {
    updateModalOpen: boolean;
    galleryTitle: string;
    deleteId: number;
    validationErrorsGallery: string[];
}

export class BeforeAfterGalleryEditorCard extends React.PureComponent<BeforeAfterGalleryEditorCardProps, BeforeAfterGalleryEditorCardState> {
    constructor(props: BeforeAfterGalleryEditorCardProps) {
        super(props);

        this.state = {
            updateModalOpen: false,
            galleryTitle: "",
            deleteId: NaN,
            validationErrorsGallery: []
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center px-4 cursor-pointer">
                        <h3>{this.props.item.title}</h3>
                        <div><Button onClick={this.toggleUpdateModal} color="primary" className="mr-3">
                            <FontAwesomeIcon icon={["fas", "pencil-alt"]} className="mr-1" /> Szerkesztés
                        </Button>
                            <span className="ml-4 cursor-pointer" onClick={() => this.props.sharedProps?.toggleExpandedId(this.props.item.id)}>
                                <FontAwesomeIcon icon={["fas", this.props.sharedProps?.expandedId === this.props.item.id ? "chevron-up" : "chevron-down"]} className="mr-1" />
                            </span>
                        </div>
                    </div>
                    {this.props.sharedProps?.expandedId === this.props.item.id &&
                        <div>
                            <div className="d-flex justify-content-end mb-3">
                                <Button onClick={() => this.props.sharedProps?.onAddItemClick(this.props.item.id)} color="primary">
                                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új elem hozzáadása
                                </Button>
                            </div>
                            <div className="mb-2">
                                <p>
                                    Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                                </p>
                            </div>

                            {this.props.item.items.length === 0 &&
                                <div>
                                    <h3>Nem található elem.</h3>
                                </div>
                            }

                            <Reorder
                                listClass="list-group"
                                itemKey="id"
                                list={this.props.item.items}
                                sharedProps={{
                                    onDeleteClick: this.handleDeleteClick
                                }}
                                holdTime={200}
                                template={BeforeAfterGalleryItemEditorCard}
                                callback={this.handleReorderItems}
                                itemClicked={() => { }}
                                itemClass="list-group-item list-group-item-action"
                            />
                        </div>
                    }
                </div>
                <Modal isOpen={!isNaN(this.state.deleteId)} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                    <ModalHeader toggle={this.handleCancelDeleteClick}>Elem törlése</ModalHeader>
                    <ModalBody>
                        <div>
                            <p>
                                Biztosan törölni szeretnéd az elemet?
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-danger">
                                <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                            </button>

                            <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.updateModalOpen} toggle={this.toggleUpdateModal} className="modal-dialog-centered">
                    <ModalHeader toggle={this.handleCancelDeleteClick}>Elem törlése</ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="form-group">
                                <label htmlFor={`inputGalleryTitle-${this.props.item.id}`}>Cím</label>
                                <input type="text" className="form-control" id={`inputGalleryTitle-${this.props.item.id}`} autoComplete="off" placeholder="Írd be a címet." value={this.state.galleryTitle} onChange={this.handleGalleryTitleChange} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleUpdateGalleryClick} type="button" className="btn btn-primary" disabled={!this.isValidGallery()}>
                                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                            </button>

                            <button className="btn btn-secondary" onClick={this.toggleUpdateModal}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    private handleUpdateGalleryClick = () => {
        this.props.sharedProps!!.updateGallery(this.props.item.id, this.state.galleryTitle);
        this.toggleUpdateModal();
    }

    private handleGalleryTitleChange = (e: any) => {
        this.setState({
            galleryTitle: e.target.value
        });
    }

    private isValidGallery = () => {
        return this.validateGallery();
    }

    private validateGallery = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.galleryTitle.length === 0) {
            validationErrors.push("A név megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsGallery: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private toggleUpdateModal = () => {
        this.setState({
            updateModalOpen: !this.state.updateModalOpen,
            galleryTitle: this.state.updateModalOpen ? "" : this.props.item.title
        });
    }

    private handleDeleteClick = (id: number) => {
        this.setState({
            deleteId: id
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteId: NaN
        });
    }

    private handleConfirmDeleteClick = () => {
        if (this.props.sharedProps != null) {
            this.props.sharedProps.onItemDelete(this.props.item.id, this.state.deleteId);
            this.setState({
                deleteId: NaN
            });
        }
    }

    private handleReorderItems = (event: any,
        itemThatHasBeenMoved: BeforeAfterGalleryItemDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: BeforeAfterGalleryItemDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.sharedProps?.reorderItems(this.props.item.id, payload);
    }
}

export default BeforeAfterGalleryEditorCard;
