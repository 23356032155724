import { ProtectedRestApi } from "./";
import { ImageDto, ServiceResult } from "../models";
import { CropPayload, ReorderPayload } from "../payloads";

export class GalleryApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxygallery";

    public addImage(galleryId: number, imageId: number, crop: CropPayload): Promise<ServiceResult<ImageDto>> {
        return this.httpPost(`${GalleryApi.baseUrl}/${galleryId}/add/${imageId}`, crop) as Promise<ServiceResult<ImageDto>>;
    }

    public removeImage(galleryId: number, imageId: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${GalleryApi.baseUrl}/${galleryId}/${imageId}`) as Promise<ServiceResult<boolean>>;
    }

    public reorder(galleryId: number, payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${GalleryApi.baseUrl}/${galleryId}/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }
}
