import { ProtectedRestApi } from "./";
import { CouponDto, CouponValidationResultDto, ServiceResult } from "../models";
import { CartPayload } from "../payloads";

export class CouponApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxycoupon";

    public getCoupons(): Promise<ServiceResult<CouponDto[]>> {
        return this.httpGet(`${CouponApi.baseUrl}/all`) as Promise<ServiceResult<CouponDto[]>>;
    }

    public validateCoupon(couponCode: string, cartPayload: CartPayload): Promise<ServiceResult<CouponValidationResultDto>> {
        return this.httpPost(`${CouponApi.baseUrl}/validate?couponCode=${couponCode}`, cartPayload, true, true) as Promise<ServiceResult<CouponValidationResultDto>>;
    }
}
