import { ProtectedRestApi } from "./";
import { ServiceResult, GuaranteePageDto, GuaranteePageToDisplayDto } from "../models";
import {
    CropPayload,
    PageBulletPointListWithTitlePayload,
    PageImageWithDescriptionPayload,
    PageImageWithTextSideBySidePayload,
    PageTitleWithTextSectionPayload
} from "../payloads";

export class GuaranteePageApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyguaranteepage";

    public getGuaranteePages(): Promise<ServiceResult<GuaranteePageDto[]>> {
        return this.httpGet(`${GuaranteePageApi.baseUrl}/all`) as Promise<ServiceResult<GuaranteePageDto[]>>;
    }

    public getGuaranteePageByPath(path: string): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpGet(`${GuaranteePageApi.baseUrl}/by-path/${path}`, true) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection1(pageId: number, payload: PageTitleWithTextSectionPayload): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-1`, payload) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection2(pageId: number, imageId: number, isVisible: boolean, payload: CropPayload): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-2?imageId=${imageId}&isVisible=${isVisible}`, payload) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection3(pageId: number, isVisible: boolean, payload: PageTitleWithTextSectionPayload): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-3?isVisible=${isVisible}`, payload) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection4(pageId: number, isVisible: boolean, payload: PageBulletPointListWithTitlePayload): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-4?isVisible=${isVisible}`, payload) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection5(pageId: number, isVisible: boolean, payload: PageImageWithTextSideBySidePayload): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-5?isVisible=${isVisible}`, payload) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection6(pageId: number, isVisible: boolean, text: string): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-6?isVisible=${isVisible}`, text) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection7(pageId: number, isVisible: boolean, payload: PageImageWithDescriptionPayload): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-7?isVisible=${isVisible}`, payload) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection8(pageId: number, isVisible: boolean, text: string): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-8?isVisible=${isVisible}`, text) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection9(pageId: number, isVisible: boolean, text: string): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-9?isVisible=${isVisible}`, text) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection10(pageId: number, isVisible: boolean, payload: PageBulletPointListWithTitlePayload): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-10?isVisible=${isVisible}`, payload) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePageSection11(pageId: number, isVisible: boolean, text: string): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/section-11?isVisible=${isVisible}`, text) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public copyGuaranteePage(pageId: number, name: string, path: string): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}/copy?name=${name}&path=${path}`) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }

    public updateGuaranteePage(pageId: number, name: string, path: string, pricingPageId: number): Promise<ServiceResult<GuaranteePageToDisplayDto>> {
        return this.httpPost(`${GuaranteePageApi.baseUrl}/${pageId}?name=${name}&path=${path}&pricingPageId=${pricingPageId}`) as Promise<ServiceResult<GuaranteePageToDisplayDto>>;
    }
}
