import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { ApplicationState } from "../store";
import * as OxyStore from "../store/OxyStore";
import authService from "../components/api-authorization/AuthorizeService";
import { Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SubscriptionPayload } from "../payloads";
import { Constants } from "../utils";
import ToastContainer from "./ToastContainer";
import { LoadingIndicator } from "./LoadingIndicator";

type LayoutPropsType = OxyStore.OxyState &
  typeof OxyStore.actionCreators &
  RouteComponentProps<{}> & { children?: React.ReactNode };

interface LayoutState {
  userReceived: boolean;
  notLoggedIn: boolean;
  couponModalOpen: boolean;
  lastName: string;
  firstName: string;
  email: string;
  acceptEula: boolean;
  subscriptionValidationErrors: string[];
  toastOpen: boolean;
  result: boolean | null;
}

class Layout extends React.PureComponent<LayoutPropsType, LayoutState> {
  constructor(props: LayoutPropsType) {
    super(props);

    this.state = {
      userReceived: false,
      notLoggedIn: false,
      couponModalOpen: false,
      lastName: "",
      firstName: "",
      email: "",
      acceptEula: false,
      subscriptionValidationErrors: [],
      toastOpen: false,
      result: null,
    };
  }

  componentDidMount() {
    authService.isAuthenticated().then((authenticated) => {
      this.setState({
        userReceived: true,
      });

      if (authenticated) {
        this.props.getLoggedInUser();
      } else {
        this.setState({
          notLoggedIn: true,
        });

        const popupShownFromLocalStorage = localStorage.getItem(
          Constants.couponPopupLocalStorageKey
        );

        if (popupShownFromLocalStorage == null) {
          const timeoutSeconds =
            Math.floor(
              Math.random() *
                (Constants.couponPopupMaximumTimeoutSeconds -
                  Constants.couponPopupMinimumTimeoutSeconds +
                  1)
            ) + Constants.couponPopupMinimumTimeoutSeconds;
          window.setTimeout(() => {
            this.setState(
              {
                couponModalOpen: true,
              },
              () => {
                localStorage.setItem(
                  Constants.couponPopupLocalStorageKey,
                  "true"
                );
              }
            );
          }, timeoutSeconds * 1000);
        }
      }
    });
  }

  componentWillReceiveProps(nextProps: LayoutPropsType) {
    if (nextProps.result !== this.props.result && nextProps.result != null) {
      this.setState({
        result: nextProps.result,
        toastOpen: true,
      });

      window.setTimeout(() => {
        this.setState(
          {
            toastOpen: false,
          },
          () => {
            if (this.props.result) {
              this.props.clearResult();
            }
          }
        );
      }, 3000);
    }
  }

  render() {
    const user = this.props.loggedInUser;

    if (user != null) {
      if (
        !user.isAdmin &&
        this.props.location.pathname.toLowerCase().startsWith("/admin")
      ) {
        return <Redirect to="/" />;
      }
    }

    return (
      <React.Fragment>
        <LoadingIndicator show={this.props.loading > 0} />

        {this.props.children}

        <ToastContainer
          open={this.state.toastOpen}
          success={this.state.result === true}
          getOperationFinished={this.props.getOperationFinished}
          message={this.props.errorMessage}
          close={this.props.clearResult}
        />

        {this.props.loggedInUser == null && this.getCouponModal()}
      </React.Fragment>
    );
  }

  private getCouponModal = () => {
    return (
      <Modal
        isOpen={this.state.couponModalOpen}
        toggle={this.toggleCouponModal}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader toggle={this.toggleCouponModal}>Kupon ajánlat</ModalHeader>
        <ModalBody>
          <p className="mb-3">
            Iratkozz fel a hírlevelünkre, és cserébe megajándékozunk egy 3 000
            Ft értékű kuponnal, amelyet bármilyen 10 000 Ft feletti rendelésnél
            felhasználhatsz a webshopban!
          </p>
          <div className="form-group">
            <label htmlFor="inputSubscriptionModalLastName">Vezetékneved</label>
            <input
              type="text"
              className="form-control"
              id="inputSubscriptionModalLastName"
              value={this.state.lastName}
              onChange={this.handleLastNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputSubscriptionModalFirstName">
              Keresztneved
            </label>
            <input
              type="text"
              className="form-control"
              id="inputSubscriptionModalFirstName"
              value={this.state.firstName}
              onChange={this.handleFirstNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputSubscriptionModalEmail">E-mail címed</label>
            <input
              type="text"
              className="form-control"
              id="inputSubscriptionModalEmail"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div className="form-check mb-2">
            <div className="d-flex justify-content-start">
              <input
                type="checkbox"
                className="form-check-input"
                id="inputSubscriptionModalAcceptEula"
                checked={this.state.acceptEula}
                onChange={this.handleAcceptEulaChange}
              />
              <label
                className="form-check-label"
                htmlFor="inputSubscriptionModalAcceptEula"
              >
                Elfogadom az adatkezelési szabályzatot
              </label>
            </div>
          </div>

          {this.state.subscriptionValidationErrors.length > 0 && (
            <Card className="mt-4 mb-2 p-2 bg-danger text-white">
              {this.state.subscriptionValidationErrors.map((error, index) => {
                return (
                  <div key={index} className="form-invalid">
                    {error}
                  </div>
                );
              })}
            </Card>
          )}

          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              onClick={this.handleSubscribeClick}
              type="button"
              className="btn btn-primary"
              disabled={!this.isValidSubscription()}
            >
              <FontAwesomeIcon icon={["fas", "newspaper"]} className="mr-1" />{" "}
              Feliratkozom
            </button>

            <button
              className="btn btn-secondary"
              onClick={this.toggleCouponModal}
            >
              <FontAwesomeIcon icon={["fas", "ban"]} /> Nem kérem
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private toggleCouponModal = () => {
    this.setState({
      couponModalOpen: !this.state.couponModalOpen,
    });
  };

  private handleLastNameChange = (e: any) => {
    this.setState(
      {
        lastName: e.target.value,
      },
      () => this.validateSubscription(true)
    );
  };

  private handleFirstNameChange = (e: any) => {
    this.setState(
      {
        firstName: e.target.value,
      },
      () => this.validateSubscription(true)
    );
  };

  private handleEmailChange = (e: any) => {
    this.setState(
      {
        email: e.target.value,
      },
      () => this.validateSubscription(true)
    );
  };

  private handleAcceptEulaChange = (e: any) => {
    this.setState(
      {
        acceptEula: e.target.checked,
      },
      () => this.validateSubscription(true)
    );
  };

  private isValidSubscription = () => {
    return this.validateSubscription();
  };

  private validateSubscription = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.lastName.length === 0) {
      validationErrors.push("A vezetéknév megadása kötelező.");
    }

    if (this.state.firstName.length === 0) {
      validationErrors.push("A keresztnév megadása kötelező.");
    }

    if (this.state.email.length === 0) {
      validationErrors.push("Az e-mail cím megadása kötelező.");
    }

    if (
      this.state.email.length > 0 &&
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.state.email
      )
    ) {
      validationErrors.push("Az e-mail cím érvénytelen.");
    }

    if (!this.state.acceptEula) {
      validationErrors.push("Az adatkezelési szabályzat elfogadása kötelező.");
    }

    if (shouldSetState) {
      this.setState({
        subscriptionValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private handleSubscribeClick = () => {
    const payload: SubscriptionPayload = {
      lastName: this.state.lastName,
      firstName: this.state.firstName,
      email: this.state.email,
    };

    this.props.subscribeToNewsletterWithCoupon(payload);
    this.setState({
      couponModalOpen: false,
      lastName: "",
      firstName: "",
      email: "",
    });
  };
}

export default compose(
  withRouter,
  connect((state: ApplicationState) => state.oxy, OxyStore.actionCreators)
)(Layout) as React.ComponentType;
