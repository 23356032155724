import { ProtectedRestApi } from "./";
import { ServiceResult, PromotedServiceDto } from "../models";
import { PromotedServicePayload, ReorderPayload } from "../payloads";

export class PromotedServiceApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxypromotedservice";

    public addPromotedServiceToMainPage(payload: PromotedServicePayload): Promise<ServiceResult<PromotedServiceDto>> {
        return this.httpPost(`${PromotedServiceApi.baseUrl}/main/add`, payload) as Promise<ServiceResult<PromotedServiceDto>>;
    }

    public updatePromotedServiceForMainPage(id: number, payload: PromotedServicePayload): Promise<ServiceResult<PromotedServiceDto>> {
        return this.httpPost(`${PromotedServiceApi.baseUrl}/main/${id}`, payload) as Promise<ServiceResult<PromotedServiceDto>>;
    }

    public removePromotedServiceFromMainPage(id: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${PromotedServiceApi.baseUrl}/main/${id}`) as Promise<ServiceResult<boolean>>;
    }

    public reorderPromotedServicesForMainPage(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${PromotedServiceApi.baseUrl}/main/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }
}
