import * as React from 'react';
import NavMenuShop from './NavMenuShop';
import Footer from '../Footer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import ScrollToTop from '../ScrollToTop';
import ProductCard from './ProductCard';
import { ProductCategoryDto } from "../../models/shop";

type WebshopMainPageProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface WebshopMainPageState {
    category: ProductCategoryDto | null;
}

class WebshopMainPage extends React.PureComponent<WebshopMainPageProps, WebshopMainPageState> {
    constructor(props: WebshopMainPageProps) {
        super(props);

        this.state = {
            category: null
        };
    }

    componentDidMount() {
        window.scrollTo({
            left: 0,
            top: this.props.webshopMainPageScrollPosition,
            behavior: "smooth"
        });

        window.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    componentWillMount() {
        if (this.state.category == null) {
            this.setState({
                category: this.props.productCategories.find(x => x.isVisible) || null
            });
        }
    }

    componentWillReceiveProps(nextProps: WebshopMainPageProps) {
        if (this.props.productCategories.length === 0 && nextProps.productCategories.length > 0) {
            this.setState({
                category: nextProps.productCategories.find(x => x.isVisible) || null
            });
        }
    }

    public render() {
        require("../../shop.css");
        require("../../DancingScript.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="webshop-main-container">
                    <header className="header mb--30">
                        <img className="header__image" src="/wp-content/uploads/new/webshop2.png" />
                    </header>

                    <section className="section mb--30 webshop-main-decor-image-container">
                        <div className="wrapper">
                            <img className="decor decor--site-wide" src="/wp-content/uploads/new/minta-1-v2.png" alt="" />
                        </div>
                    </section>

                    <section className="services">
                        <div className="wrapper">
                            <h1 className="services__title color--primary text-center">
                                Ajándékutalványok
                            </h1>

                            <div className="product-list">
                                {this.state.category == null ? null : this.state.category.products.filter(x => x.isVisible && (!x.hasOptions || x.options.length > 0)).map((product, index) => {
                                    return (
                                        <ProductCard
                                            key={index}
                                            product={product}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </React.Fragment>
        );
    }

    private onScroll = () => {
        this.props.updateWebshopMainPageScrollPosition(document.documentElement.scrollTop);
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(WebshopMainPage as any);
