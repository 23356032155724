import * as React from 'react';
import { PricingItemDto } from "../../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PricingPageEditorCardProps {
    item: PricingItemDto;
    sharedProps: {
        onDeleteClick: (id: number) => void;
        onEditClick: (id: number) => void;
    }
}

interface PricingPageEditorCardState {
}

export class PricingPageEditorCard extends React.PureComponent<PricingPageEditorCardProps, PricingPageEditorCardState> {
    constructor(props: PricingPageEditorCardProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        return (
            <div className="d-flex justify-content-between align-items-center cursor-pointer">
                <div className="pricing-item-editor-row" dangerouslySetInnerHTML={{ __html: this.props.item.column1Value }} />
                <div>
                    <button onClick={() => this.props.sharedProps.onEditClick(this.props.item.id)} type="button" className="btn btn-primary mr-2">
                        <FontAwesomeIcon icon={["fas", "pencil-alt"]} /> Szerkesztés
                    </button>
                    <button onClick={() => this.props.sharedProps.onDeleteClick(this.props.item.id)} type="button" className="btn btn-danger">
                        <FontAwesomeIcon icon={["fas", "trash-alt"]} /> Törlés
                    </button>
                </div>
            </div>
        );
    }
}

export default PricingPageEditorCard;
