import { ProtectedRestApi } from "./";
import { OxyUser, ServiceResult } from "../models";

export class AuthApi extends ProtectedRestApi {
    private static baseUrl = "/api/auth";

    public getLoggedInUser(): Promise<ServiceResult<OxyUser>> {
        return this.httpGet(AuthApi.baseUrl) as Promise<ServiceResult<OxyUser>>;
    }
}
