import * as React from 'react';
import { Button, Card, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from './NavMenuAdmin';
import Reorder from 'react-reorder';
import { MenuItemDto } from '../../models';
import { PricingMenuItemPayload, ReorderPayload, SkinProblemsMenuItemPayload, WebshopMenuItemPayload } from '../../payloads';
import { MenuItemEditorCard } from "./MenuItemEditorCard";
import { ServicesParentMenuItemEditorCard } from "./ServicesParentMenuItemEditorCard";
import { PricingPagePickerModal } from "./PricingPagePickerModal";
import AdminBackButton from "./AdminBackButton";
import PagePickerModal from './PagePickerModal';

type MenuEditorProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface MenuEditorState {
    servicesMenuItemTitle: string;
    servicesParentMenuItemId: number;
    pricingMenuItemPayload: PricingMenuItemPayload;
    webshopMenuItemPayload: WebshopMenuItemPayload;
    skinProblemsMenuItemPayload: SkinProblemsMenuItemPayload;
    addServicesParentMenuItemModalOpen: boolean;
    addPricingMenuItemModalOpen: boolean;
    addWebshopMenuItemModalOpen: boolean;
    addSkinProblemsMenuItemModalOpen: boolean;
    pricingPagePickerOpen: boolean;
    skinProblemsPagePickerOpen: boolean;
    expandedServicesMenuId: number;
    validationErrorsServices: string[];
    validationErrorsPricing: string[];
    validationErrorsWebshop: string[];
    validationErrorsSkinProblems: string[];
}

class MenuEditor extends React.PureComponent<MenuEditorProps, MenuEditorState> {
    constructor(props: MenuEditorProps) {
        super(props);

        this.state = {
            servicesMenuItemTitle: "",
            servicesParentMenuItemId: NaN,
            pricingMenuItemPayload: {
                title: "",
                targetPricingPageId: NaN
            },
            webshopMenuItemPayload: {
                title: "",
                targetPath: ""
            },
            skinProblemsMenuItemPayload: {
                title: "",
                targetSkinProblemsPageId: NaN
            },
            addServicesParentMenuItemModalOpen: false,
            addPricingMenuItemModalOpen: false,
            addWebshopMenuItemModalOpen: false,
            addSkinProblemsMenuItemModalOpen: false,
            pricingPagePickerOpen: false,
            skinProblemsPagePickerOpen: false,
            expandedServicesMenuId: NaN,
            validationErrorsServices: [],
            validationErrorsPricing: [],
            validationErrorsWebshop: [],
            validationErrorsSkinProblems: []
        }
    }

    componentDidMount() {
        this.props.getServicesMenu();
        this.props.getPricingMenu();
        this.props.getWebshopMenu();
        this.props.getSkinProblemsMenu();
        this.props.getPages();
        this.props.getScrollingPages();
        this.props.getPricingPages();
    }

    public render() {
        return (
            <React.Fragment>
                <NavMenuAdmin />
                <Container>
                    <AdminBackButton />
                    <div>
                        <h1>Menü kezelése</h1>
                    </div>
                    <div className="tabbable full-width-tabs mt-4">
                        <ul className="nav nav-tabs mb-3 d-flex justify-content-start align-items-center" id="contracts-tab-list" role="tablist">
                            <li className="d-flex">
                                <a className="nav-link active" id="services-menu-tab" data-toggle="pill" href="#services-menu" role="tab" aria-controls="services" aria-selected="true">Szolgáltatások</a>
                            </li>
                            <li className="d-flex">
                                <a className="nav-link" id="pricing-menu-tab" data-toggle="pill" href="#pricing-menu" role="tab" aria-controls="pricing" aria-selected="false">Árak</a>
                            </li>
                            <li className="d-flex">
                                <a className="nav-link" id="skin-problems-menu-tab" data-toggle="pill" href="#skin-problems-menu" role="tab" aria-controls="skin-problems" aria-selected="false">Bőrproblémák kezelése</a>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content" id="menu-tab-content">
                        <div className="tab-pane fade show active" id="services-menu" role="tabpanel" aria-labelledby="services-menu-tab">
                            <div className="mb-2">
                                <p>
                                    Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                                    <br />
                                    A kategóriák sorrendje csak akkor módosítható, ha egyik sincs kinyitva.
                                </p>
                            </div>
                            <div className="d-flex justify-content-end mb-3">
                                <Button onClick={this.toggleAddServicesParentMenuItemModal} color="primary">
                                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                                </Button>
                            </div>
                            <Reorder
                                listClass="list-group"
                                itemKey="id"
                                list={this.props.servicesMenu}
                                sharedProps={{
                                    onDeleteClick: this.onServicesMenuItemDeleteClick,
                                    onLeafMenuItemAdd: this.props.addServicesLeafMenuItem,
                                    pages: this.props.pages,
                                    scrollingPages: this.props.scrollingPages,
                                    reorderMenuItems: this.props.reorderServicesLeafMenuItems,
                                    expandedId: this.state.expandedServicesMenuId,
                                    toggleExpandedId: this.toggleExpandedServicesMenuId
                                }}
                                holdTime={200}
                                template={ServicesParentMenuItemEditorCard}
                                callback={this.handleServicesMenuReorder}
                                itemClicked={() => { }}
                                itemClass="list-group-item list-group-item-action"
                                disableReorder={!isNaN(this.state.expandedServicesMenuId)}
                            />
                        </div>
                        <div className="tab-pane fade" id="pricing-menu" role="tabpanel" aria-labelledby="pricing-menu-tab">
                            <div className="mb-2">
                                <p>
                                    Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                                </p>
                            </div>
                            <div className="d-flex justify-content-end mb-3">
                                <Button onClick={this.toggleAddPricingMenuItemModal} color="primary">
                                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                                </Button>
                            </div>
                            <Reorder
                                listClass="list-group"
                                itemKey="id"
                                list={this.props.pricingMenu}
                                sharedProps={{
                                    onDeleteClick: this.onPricingMenuItemDeleteClick
                                }}
                                holdTime={200}
                                template={MenuItemEditorCard}
                                callback={this.handlePricingMenuReorder}
                                itemClicked={() => { }}
                                itemClass="list-group-item list-group-item-action"
                            />
                        </div>
                        <div className="tab-pane fade" id="skin-problems-menu" role="tabpanel" aria-labelledby="skin-problems-menu-tab">
                            <div className="mb-2">
                                <p>
                                    Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                                </p>
                            </div>
                            <div className="d-flex justify-content-end mb-3">
                                <Button onClick={this.toggleAddSkinProblemsMenuItemModal} color="primary">
                                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                                </Button>
                            </div>
                            <Reorder
                                listClass="list-group"
                                itemKey="id"
                                list={this.props.skinProblemsMenu}
                                sharedProps={{
                                    onDeleteClick: this.onSkinProblemsMenuItemDeleteClick
                                }}
                                holdTime={200}
                                template={MenuItemEditorCard}
                                callback={this.handleSkinProblemsMenuReorder}
                                itemClicked={() => { }}
                                itemClass="list-group-item list-group-item-action"
                            />
                        </div>
                    </div>
                </Container>
                {this.getAddServicesParentMenuItemModal()}
                {this.getAddPricingMenuItemModal()}
                {this.getAddSkinProblemsMenuItemModal()}
                <PricingPagePickerModal
                    isOpen={this.state.pricingPagePickerOpen}
                    toggle={this.togglePricingPagePicker}
                    pricingPages={this.props.pricingPages}
                    onPricingPageSelected={this.handlePricingPageSelected}
                />
                <PagePickerModal
                    isOpen={this.state.skinProblemsPagePickerOpen}
                    toggle={this.toggleSkinProblemsPagePicker}
                    pages={this.props.pages}
                    onPageSelected={this.handleSkinProblemsPageSelected}
                />
            </React.Fragment>
        );
    }

    private getAddServicesParentMenuItemModal = () => {
        return (
            <Modal isOpen={this.state.addServicesParentMenuItemModalOpen} toggle={this.toggleAddServicesParentMenuItemModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.toggleAddServicesParentMenuItemModal}>Új menüelem hozzáadása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputNewServicesParentMenuItemTitle">Új menüelem neve</label>
                        <input type="text" className="form-control" id="inputNewServicesParentMenuItemTitle" autoComplete="off" placeholder="Írd be a nevet." value={this.state.servicesMenuItemTitle} onChange={this.handleServicesMenuItemTitleChange} />
                    </div>

                    {this.state.validationErrorsServices.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrorsServices.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleNewServicesParentMenuItemSaveClick} type="button" className="btn btn-primary" disabled={!this.isValidServices()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.toggleAddServicesParentMenuItemModal}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getAddPricingMenuItemModal = () => {
        return (
            <Modal isOpen={this.state.addPricingMenuItemModalOpen} toggle={this.toggleAddPricingMenuItemModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.toggleAddPricingMenuItemModal}>Új menüelem hozzáadása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputNewPricingMenuItemTitle">Új menüelem neve</label>
                        <input type="text" className="form-control" id="inputNewPricingMenuItemTitle" autoComplete="off" placeholder="Írd be a nevet." value={this.state.pricingMenuItemPayload.title} onChange={this.handlePricingMenuItemTitleChange} />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <div>
                            Céloldal: {isNaN(this.state.pricingMenuItemPayload.targetPricingPageId) ? " - " : this.props.pricingPages.find(x => x.id === this.state.pricingMenuItemPayload.targetPricingPageId)!!.name}
                        </div>
                        <div>
                            <Button color="primary" onClick={this.togglePricingPagePicker}>
                                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Hivatkozás kiválasztása
                            </Button>
                        </div>
                    </div>

                    {this.state.validationErrorsPricing.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrorsPricing.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleNewPricingMenuItemSaveClick} type="button" className="btn btn-primary" disabled={!this.isValidPricing()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.toggleAddPricingMenuItemModal}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getAddSkinProblemsMenuItemModal = () => {
        return (
            <Modal isOpen={this.state.addSkinProblemsMenuItemModalOpen} toggle={this.toggleAddSkinProblemsMenuItemModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.toggleAddSkinProblemsMenuItemModal}>Új menüelem hozzáadása</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputNewSkinProblemsMenuItemTitle">Új menüelem neve</label>
                        <input type="text" className="form-control" id="inputNewSkinProblemsMenuItemTitle" autoComplete="off" placeholder="Írd be a nevet." value={this.state.skinProblemsMenuItemPayload.title} onChange={this.handleSkinProblemsMenuItemTitleChange} />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <div>
                            Céloldal: {isNaN(this.state.skinProblemsMenuItemPayload.targetSkinProblemsPageId) ? " - " : this.props.pages.find(x => x.id === this.state.skinProblemsMenuItemPayload.targetSkinProblemsPageId)!!.name /* TODO */ }
                        </div>
                        <div>
                            <Button color="primary" onClick={this.toggleSkinProblemsPagePicker}>
                                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Hivatkozás kiválasztása
                            </Button>
                        </div>
                    </div>

                    {this.state.validationErrorsSkinProblems.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrorsSkinProblems.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleNewSkinProblemsMenuItemSaveClick} type="button" className="btn btn-primary" disabled={!this.isValidSkinProblems()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.toggleAddSkinProblemsMenuItemModal}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private toggleExpandedServicesMenuId = (id: number) => {
        this.setState({
            expandedServicesMenuId: id === this.state.expandedServicesMenuId ? NaN : id
        });
    }

    private handleNewServicesParentMenuItemSaveClick = () => {
        this.props.addServicesParentMenuItem(this.state.servicesMenuItemTitle);
        this.setState({
            servicesMenuItemTitle: "",
            addServicesParentMenuItemModalOpen: false
        });
    }

    private handleServicesMenuItemTitleChange = (e: any) => {
        this.setState({
            servicesMenuItemTitle: e.target.value
        }, () => this.validateServices(true));
    }

    private handleNewPricingMenuItemSaveClick = () => {
        this.props.addPricingMenuItem(this.state.pricingMenuItemPayload);
        this.setState({
            addPricingMenuItemModalOpen: false,
            pricingMenuItemPayload: {
                title: "",
                targetPricingPageId: NaN
            }
        });
    }

    private handleNewSkinProblemsMenuItemSaveClick = () => {
        this.props.addSkinProblemsMenuItem(this.state.skinProblemsMenuItemPayload);
        this.setState({
            addSkinProblemsMenuItemModalOpen: false,
            skinProblemsMenuItemPayload: {
                title: "",
                targetSkinProblemsPageId: NaN
            }
        });
    }

    private handlePricingPageSelected = (id: number) => {
        this.setState({
            pricingMenuItemPayload: {
                ...this.state.pricingMenuItemPayload,
                targetPricingPageId: id
            }
        }, () => this.validatePricing(true));

        this.togglePricingPagePicker();
    }

    private handleSkinProblemsPageSelected = (id: number) => {
        this.setState({
            skinProblemsMenuItemPayload: {
                ...this.state.skinProblemsMenuItemPayload,
                targetSkinProblemsPageId: id
            }
        }, () => this.validateSkinProblems(true));

        this.toggleSkinProblemsPagePicker();
    }

    private togglePricingPagePicker = () => {
        this.setState({
            pricingPagePickerOpen: !this.state.pricingPagePickerOpen
        });
    }

    private toggleSkinProblemsPagePicker = () => {
        this.setState({
            skinProblemsPagePickerOpen: !this.state.skinProblemsPagePickerOpen
        });
    }

    private handlePricingMenuItemTitleChange = (e: any) => {
        this.setState({
            pricingMenuItemPayload: {
                ...this.state.pricingMenuItemPayload,
                title: e.target.value
            }
        }, () => this.validatePricing(true));
    }

    private handleSkinProblemsMenuItemTitleChange = (e: any) => {
        this.setState({
            skinProblemsMenuItemPayload: {
                ...this.state.skinProblemsMenuItemPayload,
                title: e.target.value
            }
        }, () => this.validateSkinProblems(true));
    }

    private toggleAddServicesParentMenuItemModal = () => {
        this.setState({
            addServicesParentMenuItemModalOpen: !this.state.addServicesParentMenuItemModalOpen,
            servicesMenuItemTitle: ""
        });
    }

    private toggleAddPricingMenuItemModal = () => {
        this.setState({
            addPricingMenuItemModalOpen: !this.state.addPricingMenuItemModalOpen,
            pricingMenuItemPayload: {
                title: "",
                targetPricingPageId: NaN
            }
        });
    }

    private toggleAddSkinProblemsMenuItemModal = () => {
        this.setState({
            addSkinProblemsMenuItemModalOpen: !this.state.addSkinProblemsMenuItemModalOpen,
            skinProblemsMenuItemPayload: {
                title: "",
                targetSkinProblemsPageId: NaN
            }
        });
    }

    private onServicesMenuItemDeleteClick = (id: number) => {
        this.props.deleteServicesMenuItem(id);
    }

    private onPricingMenuItemDeleteClick = (id: number) => {
        this.props.deletePricingMenuItem(id);
    }

    private onSkinProblemsMenuItemDeleteClick = (id: number) => {
        this.props.deleteSkinProblemsMenuItem(id);
    }

    private handleServicesMenuReorder = (event: any,
        itemThatHasBeenMoved: MenuItemDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: MenuItemDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorderServicesParentMenuItems(payload);
    }

    private handlePricingMenuReorder = (event: any,
        itemThatHasBeenMoved: MenuItemDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: MenuItemDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorderPricingMenuItems(payload);
    }

    private handleSkinProblemsMenuReorder = (event: any,
        itemThatHasBeenMoved: MenuItemDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: MenuItemDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorderSkinProblemsMenuItems(payload);
    }

    private isValidServices = () => {
        return this.validateServices();
    }

    private validateServices = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (shouldSetState) {
            this.setState({
                validationErrorsServices: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidPricing = () => {
        return this.validatePricing();
    }

    private validatePricing = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (shouldSetState) {
            this.setState({
                validationErrorsPricing: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidSkinProblems = () => {
        return this.validateSkinProblems();
    }

    private validateSkinProblems = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.skinProblemsMenuItemPayload.title.length === 0) {
            validationErrors.push("A név megadása kötelező!");
        }

        if (isNaN(this.state.skinProblemsMenuItemPayload.targetSkinProblemsPageId)) {
            validationErrors.push("A céloldal kiválasztása kötelező!");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsSkinProblems: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(MenuEditor as any);
