import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as OxyStore from '../store/OxyStore'
import { Footer } from "./Footer";
import 'react-quill/dist/quill.snow.css';
import { PricingItemDto } from '../models';
import { isMobileOnly } from 'react-device-detect';
import NavMenu from './NavMenu';

interface OxyPricingPageComponentOwnProps {
    path?: string;
}

type OxyPricingPageComponentProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ path: string }>
    & OxyPricingPageComponentOwnProps;

interface OxyPricingPageComponentState {
    textEditorOpen: boolean;
    itemsEditorOpen: boolean;
    title: string;
    name: string;
    path: string;
    columnCount: number;
    column1Header: string;
    column2Header: string;
    column3Header: string;
    column4Header: string;
    items: PricingItemDto[];
    textValidationErrors: string[];
    itemValidationErrors: string[];
}

class OxyPricingPageComponent extends React.PureComponent<OxyPricingPageComponentProps, OxyPricingPageComponentState> {
    constructor(props: OxyPricingPageComponentProps) {
        super(props);

        this.state = {
            textEditorOpen: false,
            itemsEditorOpen: false,
            title: "",
            name: "",
            path: "",
            columnCount: 4,
            column1Header: "",
            column2Header: "",
            column3Header: "",
            column4Header: "",
            items: [],
            textValidationErrors: [],
            itemValidationErrors: []
        }
    }

    public componentDidMount() {
        const pricingPagePath: string = this.props.path || this.props.match.params.path;

        if (pricingPagePath != null && pricingPagePath.length > 0) {
            this.props.getPricingPageByPath(pricingPagePath);
        }
    }

    componentWillReceiveProps(nextProps: OxyPricingPageComponentProps) {
        if ((this.props.currentPricingPage == null && nextProps.currentPricingPage != null) || (nextProps.currentPricingPage != null && !this.props.pricingPageOperationFinished && nextProps.pricingPageOperationFinished)) {
            const currentPricingPage = nextProps.currentPricingPage;

            this.setState({
                name: currentPricingPage.name,
                path: currentPricingPage.path,
                title: currentPricingPage.title,
                columnCount: currentPricingPage.columnCount,
                column1Header: currentPricingPage.column1Header,
                column2Header: currentPricingPage.column2Header,
                column3Header: currentPricingPage.column3Header,
                column4Header: currentPricingPage.column4Header,
                items: currentPricingPage.items.slice()
            });
        }
    }

    public render() {
        if (this.props.currentPricingPage !== undefined && this.props.currentPricingPage == null) {
            window.location.href = "/";
            return null;
        }

        const columnsIterator = this.props.currentPricingPage == null ? [] : Array.from(Array(this.props.currentPricingPage.columnCount).keys()).map(x => x + 1);

        return (
            <div id="boxed-wrapper">
                <div className="fusion-sides-frame"></div>
                <div id="wrapper" className="fusion-wrapper">
                    <div id="home" style={{ position: "relative", top: "-1px" }}></div>
                    <div id="sliders-container">
                    </div>

                    <NavMenu {...this.props} />

                    <div>
                        {this.props.currentPricingPage != null &&
                            <main id="main" className="clearfix width-100" style={{ marginBottom: "485px" }}>
                                <div className="fusion-row" style={{ maxWidth: '100%' }}>
                                    <section id="content" className="full-width">
                                        <div id="post-5" className="post-5 page type-page status-publish hentry">
                                            <div className="post-content">
                                                <div className="image-with-gradient-border" />
                                                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{
                                                    backgroundColor: 'rgba(204,204,204,0.05)',
                                                    backgroundImage: `url("/wp-content/uploads/new/placeholder-image.png")`,
                                                    backgroundPosition: 'center 0px',
                                                    backgroundRepeat: 'no-repeat',
                                                    padding: "0",
                                                    marginBottom: '0px',
                                                    borderWidth: '0px 0px 0px 0px',
                                                    borderColor: '#f7f7f7',
                                                    borderStyle: 'solid',
                                                    WebkitBackgroundSize: isMobileOnly ? "cover" : 'cover',
                                                    MozBackgroundSize: isMobileOnly ? "cover" : 'cover',
                                                    OBackgroundSize: isMobileOnly ? "cover" : 'cover',
                                                    backgroundSize: isMobileOnly ? "cover" : 'cover',
                                                    height: isMobileOnly ? "122px" : "540px"
                                                }}>
                                                </div>
                                                <div className="image-with-gradient-border" />
                                                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(188,151,120,0.1)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                                                    <div className="fusion-builder-row fusion-row">
                                                        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-25 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: '50px', marginBottom: '30px' }}>
                                                            <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                                                <div className="fusion-text fusion-text-19" style={{ textAlign: "center" }}>
                                                                    <h1>
                                                                        <strong>
                                                                            {this.props.currentPricingPage.title.split("\n").map((row, index) => <React.Fragment key={index}>{row}{index < this.props.currentPricingPage!!.title.split("\n").length - 1 ? <br /> : ""}</React.Fragment>)}
                                                                        </strong>
                                                                    </h1>
                                                                </div>
                                                                <div className="fusion-clearfix" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(188,151,120,0.1)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                                                    <div className="fusion-builder-row fusion-row">
                                                        <table className="table table-hover pricing-table pricing-table-desktop">
                                                            <thead className="thead-dark thead-oxy">
                                                                <tr>
                                                                    {columnsIterator.map((value, index) => {
                                                                        let headerText = "";

                                                                        switch (value) {
                                                                            case 1:
                                                                                headerText = this.props.currentPricingPage!!.column1Header;
                                                                                break;
                                                                            case 2:
                                                                                headerText = this.props.currentPricingPage!!.column2Header;
                                                                                break;
                                                                            case 3:
                                                                                headerText = this.props.currentPricingPage!!.column3Header;
                                                                                break;
                                                                            case 4:
                                                                                headerText = this.props.currentPricingPage!!.column4Header;
                                                                                break;
                                                                        }

                                                                        return (
                                                                            <th key={index} scope="col">{headerText}</th>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.currentPricingPage.items.map((pricingItem, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            {columnsIterator.map((value, innerIndex) => {
                                                                                let cellText = "";

                                                                                switch (value) {
                                                                                    case 1:
                                                                                        cellText = pricingItem.column1Value;
                                                                                        break;
                                                                                    case 2:
                                                                                        cellText = pricingItem.column2Value;
                                                                                        break;
                                                                                    case 3:
                                                                                        cellText = pricingItem.column3Value;
                                                                                        break;
                                                                                    case 4:
                                                                                        cellText = pricingItem.column4Value;
                                                                                        break;
                                                                                }

                                                                                return (
                                                                                    <td key={innerIndex} scope="col" dangerouslySetInnerHTML={{ __html: cellText }} />
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        <div className="pricing-table-mobile">
                                                            {this.props.currentPricingPage.items.map((pricingItem, index) => {
                                                                return (
                                                                    <div key={index} className="pricing-item-mobile">
                                                                        <div>
                                                                            <strong dangerouslySetInnerHTML={{ __html: pricingItem.column1Value }}></strong>
                                                                        </div>
                                                                        {this.props.currentPricingPage!!.column2Header.length > 0 && pricingItem.column2Value.length > 0 && pricingItem.column2Value !== "<p></p>" &&
                                                                            <div>
                                                                                <strong dangerouslySetInnerHTML={{ __html: this.props.currentPricingPage!!.column2Header }}></strong>:
                                                                                <span dangerouslySetInnerHTML={{ __html: pricingItem.column2Value }}></span>
                                                                            </div>
                                                                        }
                                                                        {this.props.currentPricingPage!!.column3Header.length > 0 && pricingItem.column3Value.length > 0 && pricingItem.column3Value !== "<p></p>" &&
                                                                            <div>
                                                                                <strong dangerouslySetInnerHTML={{ __html: this.props.currentPricingPage!!.column3Header }}></strong>:
                                                                                <span dangerouslySetInnerHTML={{ __html: pricingItem.column3Value }}></span>
                                                                            </div>
                                                                        }
                                                                        {this.props.currentPricingPage!!.column4Header.length > 0 && pricingItem.column4Value.length > 0 && pricingItem.column4Value !== "<p></p>" &&
                                                                            <div>
                                                                                <strong dangerouslySetInnerHTML={{ __html: this.props.currentPricingPage!!.column4Header }}></strong>:
                                                                                <span dangerouslySetInnerHTML={{ __html: pricingItem.column4Value }}></span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </main>}

                        <Footer />
                    </div>
                </div>                
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(OxyPricingPageComponent as any);
