import * as React from 'react';
import { Button, Card, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../store/index';
import * as OxyStore from '../../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from '../../admin/NavMenuAdmin';
import Reorder from 'react-reorder';
import { AboutUsSectionDto } from '../../../models';
import { ProductOptionPayload, ReorderPayload } from '../../../payloads';
import { ProductOptionEditorCard } from "./ProductOptionEditorCard";
import AdminBackButton from '../../admin/AdminBackButton';
import { ProductDto } from '../../../models/shop';

type ProductOptionEditorProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ id?: string }>;

interface ProductOptionEditorState {
    product: ProductDto | null;
    editId: number;
    imagePickerOpen: boolean;
    payload: ProductOptionPayload;
    validationErrors: string[];
}

class ProductOptionEditor extends React.PureComponent<ProductOptionEditorProps, ProductOptionEditorState> {
    constructor(props: ProductOptionEditorProps) {
        super(props);

        this.state = {
            product: null,
            editId: NaN,
            imagePickerOpen: false,
            payload: {
                name: "",
                price: 0
            },
            validationErrors: []
        }
    }

    componentDidMount() {
        if (this.props.productCategories.length === 0) {
            this.props.getAllProductCategories();
            this.props.getImages();
        }

        const id = this.props.match.params.id;
        const idAsNumber = Number(id);
        if (!isNaN(idAsNumber) && idAsNumber > 0 && this.props.productCategories.length > 0) {
            const category = this.props.productCategories.find(x => x.products.find(y => y.id === idAsNumber) != null);

            if (category == null) {
                window.location.href = "/shop/admin/categories";
            }
            else {
                const product = category.products.find(x => x.id === idAsNumber);

                if (product == null) {
                    window.location.href = "/shop/admin/categories";
                }
                else {
                    this.setState({
                        product: product
                    });
                }
            }
        }
    }

    componentWillReceiveProps(nextProps: ProductOptionEditorProps) {
        if (this.props.productCategories.length !== nextProps.productCategories.length || !this.props.productCategoryOperationFinished && nextProps.productCategoryOperationFinished) {
            const id = this.props.match.params.id;
            const idAsNumber = Number(id);
            if (!isNaN(idAsNumber) && idAsNumber > 0 && nextProps.productCategories.length > 0) {
                const category = nextProps.productCategories.find(x => x.products.find(y => y.id === idAsNumber) != null);

                if (category == null) {
                    window.location.href = "/shop/admin/categories";
                }
                else {
                    const product = category.products.find(x => x.id === idAsNumber);

                    if (product == null) {
                        window.location.href = "/shop/admin/categories";
                    }
                    else {
                        this.setState({
                            product: product
                        });
                    }
                }
            }
        }
    }

    public render() {
        return (
            <React.Fragment>
                <NavMenuAdmin />
                {this.state.product != null &&
                    <Container>
                    <AdminBackButton to={`/shop/admin/category/${this.state.product.categoryId}`} />
                        <div>
                            <h1>Opciók kezelése</h1>
                        </div>

                        <div className="mb-2">
                            <p>
                                Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                            </p>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <Button onClick={() => this.toggleEditor(-1)} color="primary">
                                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                            </Button>
                        </div>
                        <Reorder
                            listClass="list-group"
                            itemKey="id"
                            list={this.state.product.options}
                            sharedProps={{
                                onEditClick: this.toggleEditor,
                                onDeleteClick: this.onDeleteClick
                            }}
                            holdTime={200}
                            template={ProductOptionEditorCard}
                            callback={this.handleReorder}
                            itemClicked={() => { }}
                            itemClass="list-group-item list-group-item-action"
                        />
                        {this.state.product.options.length === 0 && <div>
                            <h3>Nem található opció.</h3>
                        </div>}
                    </Container>
                }
                {this.getEditModal()}
            </React.Fragment>
        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editId)} toggle={() => this.toggleEditor(NaN)} className="modal-dialog-centered">
                <ModalHeader toggle={() => this.toggleEditor(NaN)}>Opció {this.state.editId > 0 ? "szerkesztése" : "hozzáadása"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputProductOptionName">Név</label>
                        <input type="text" className="form-control" id="inputProductOptionName" autoComplete="off" placeholder="Írd be a nevet." value={this.state.payload.name} onChange={this.handleNameChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputProductOptionPrice">Ár (Ft)</label>
                        <input type="text" className="form-control" id="inputProductOptionPrice" autoComplete="off" placeholder="Írd be az árat." value={this.state.payload.price} onChange={this.handlePriceChange} />
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={() => this.toggleEditor(NaN)}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleSaveClick = () => {
        if (this.state.editId <= 0) {
            this.props.addProductOption(this.state.product!!.id, this.state.payload);
        }
        else {
            this.props.updateProductOption(this.state.editId, this.state.payload);
        }

        this.toggleEditor(NaN);
    }

    private onDeleteClick = (id: number, isVisible: boolean) => {
        this.props.toggleProductOptionVisible(id, isVisible);
    }

    private handleNameChange = (e: any) => {
        this.setState({
            payload: {
                ...this.state.payload,
                name: e.target.value
            }
        }, () => this.validate(true));
    }

    private handlePriceChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            const targetValue = parseInt(e.target.value.replace(/\D/, ""));
            this.setState({
                payload: {
                    ...this.state.payload,
                    price: isNaN(targetValue) ? 0 : targetValue
                }
            }, () => { this.validate(true); });
        }
    }

    private toggleEditor = (id: number) => {
        const targetId = this.state.editId === id ? NaN : id;
        const optionToEdit = this.state.product!!.options.find(x => x.id === targetId);

        this.setState({
            editId: targetId,
            payload: {
                name: optionToEdit == null ? "" : optionToEdit.name,
                price: optionToEdit == null ? 0 : optionToEdit.price
            }
        });
    }

    private handleReorder = (event: any,
        itemThatHasBeenMoved: AboutUsSectionDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: AboutUsSectionDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorderProductOptions(this.state.product!!.id, payload);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.payload.name.length === 0) {
            validationErrors.push("A név megadása kötelező.");
        }

        if (this.state.payload.price === 0) {
            validationErrors.push("Az ár megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(ProductOptionEditor as any);
