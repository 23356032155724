export const Constants = {
  productShortDescriptionMaxLength: 340,
  webshopClientCartLocalStorageKey: "oxyShopCart",
  couponPopupMinimumTimeoutSeconds: 120,
  couponPopupMaximumTimeoutSeconds: 300,
  couponPopupLocalStorageKey: "oxyCouponPopupShown",
  emailValidationRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  pageSize: 20,
  debounceTimeoutMs: 300,
};
