import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ImageCard from "./ImageCard";
import { ImageDto, ImageGalleryDto } from "../../models";
import { CropPayload, ReorderPayload } from "../../payloads";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImagePickerModal } from "./ImagePickerModal";
import Reorder from 'react-reorder';

interface GalleryEditorModalProps {
    gallery: ImageGalleryDto;
    images: ImageDto[];
    addImage: (galleryId: number, imageId: number, crop: CropPayload) => void;
    removeImage: (galleryId: number, imageId: number) => void;
    reorder: (galleryId: number, payload: ReorderPayload) => void;
    isOpen: boolean;
    toggle: () => void;
    uploadImage: (file: File) => void;
    title?: string;
}

interface GalleryEditorModalState {
    imagePickerOpen: boolean;
}

export class GalleryEditorModal extends React.PureComponent<GalleryEditorModalProps, GalleryEditorModalState> {
    constructor(props: GalleryEditorModalProps) {
        super(props);

        this.state = {
            imagePickerOpen: false
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={this.props.toggle}>{this.props.title || "Galéria szerkesztése"}</ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-end mb-4">
                            <Button color="primary" onClick={this.toggleImagePicker}>
                                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Kép hozzáadása
                            </Button>
                        </div>
                        <div className="mt-2 mb-2">
                            <p>
                                A képek sorrendjének módosításához egyszerűen fogd meg az adott képet, és húzd a kívánt helyre!
                            </p>
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <Reorder
                                listClass="reorder-container"
                                itemKey="id"
                                list={this.props.gallery.images.map(x => x.image)}
                                sharedProps={{
                                    onDeleteClick: this.handleDeleteImage
                                }}
                                holdTime={200}
                                template={ImageCard}
                                callback={this.handleReorder}
                                itemClicked={() => { }}
                                itemClass=""
                            />
                        </div>
                    </ModalBody>
                </Modal>
                <ImagePickerModal
                    isOpen={this.state.imagePickerOpen}
                    toggle={this.toggleImagePicker}
                    images={this.props.images.filter(x => this.props.gallery.images.find(y => y.image.id === x.id) == null)}
                    onImageSelected={this.handleImageSelected}
                    aspectRatio={359 / 239}
                    uploadImage={this.props.uploadImage}
                />
            </React.Fragment>
        );
    }

    private handleReorder = (event: any,
        itemThatHasBeenMoved: ImageDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: ImageDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorder(this.props.gallery.id, payload)
    }

    private toggleImagePicker = () => {
        this.setState({
            imagePickerOpen: !this.state.imagePickerOpen
        });
    }

    private handleImageSelected = (id: number, x: number, y: number, width: number, height: number) => {
        this.props.addImage(this.props.gallery.id, id, {
            x,
            y,
            width,
            height
        });
        this.toggleImagePicker();
    }

    private handleDeleteImage = (id: number) => {
        this.props.removeImage(this.props.gallery.id, id);
    }
}

export default GalleryEditorModal;
