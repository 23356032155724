import authService from "../components/api-authorization/AuthorizeService";

export class ProtectedRestApi {
    protected httpGet = async (url: string, allowAnonymous: boolean = false): Promise<any> => {
        return fetch(url, await this.getFetchOptions("GET", undefined, allowAnonymous))
            .then(this.processResponse);
    }

    protected httpPost = async (url: string, body?: any, serializeBody: boolean = true, allowAnonymous: boolean = false): Promise<any> => {
        return fetch(url, await this.getFetchOptions("POST", body != null && serializeBody ? JSON.stringify(body) : body, allowAnonymous))
            .then(this.processResponse);
    }

    protected httpPostWithBlobResponse = async (url: string, body?: any, serializeBody: boolean = true): Promise<any> => {
        return fetch(url, await this.getFetchOptions("POST", body != null && serializeBody ? JSON.stringify(body) : body))
            .then(this.processResponseBlob);
    }

    protected httpPut = async (url: string, body?: any, serializeBody: boolean = true): Promise<any> => {
        return fetch(url, await this.getFetchOptions("PUT", body != null && serializeBody ? JSON.stringify(body) : body))
            .then(this.processResponse);
    }

    protected httpPutForm = async (url: string, body?: any, serializeBody: boolean = true): Promise<any> => {
        return fetch(url, await this.getFetchOptionsForFormData("PUT", body))
            .then(this.processResponse);
    }

    protected httpDelete = async (url: string, body?: any, serializeBody: boolean = true): Promise<any> => {
        return fetch(url, await this.getFetchOptions("DELETE", body != null && serializeBody ? JSON.stringify(body) : body))
            .then(this.processResponse);
    }

    private getFetchOptions = async (method: string, body?: FormData | string | null, allowAnonymous: boolean = false): Promise<RequestInit> => {
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append("Pragma", "no-cache");

        if (!allowAnonymous) {
            const token = await authService.getAccessToken();

            if (token == null) {
                throw new Error("User is not authenticated!");
            }

            headers.append("Authorization", `Bearer ${token}`);
        }

        return { method, body, headers } as RequestInit;
    }

    private getFetchOptionsForFormData = async (method: string, body?: FormData | string | null): Promise<RequestInit> => {
        const token = await authService.getAccessToken();

        if (token == null) {
            throw new Error("User is not authenticated!");
        }

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Pragma", "no-cache");
        headers.append("Authorization", `Bearer ${token}`);

        return { method, body, headers } as RequestInit;
    }

    private processResponseBlob = (response: Response) => {
        if (!response.ok) {
            throw response;
        }

        return response.blob();
    }

    private processResponse = (response: Response) => {
        if (!response.ok) {
            throw response;
        }

        return response.json();
    }
}
