import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';

const hideFacebookSidebarCss = `
    .aspexifbsidebox {
        display: none;
    }
`;

interface NavMenuAdminProps {

}

interface NavMenuAdminState {
    isOpen: boolean;
}

export class NavMenuAdmin extends React.PureComponent<NavMenuAdminProps, NavMenuAdminState> {
    constructor(props: NavMenuAdminProps) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    public render() {
        return (
            <React.Fragment>
                <style>{hideFacebookSidebarCss}</style>
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 w-100" light>
                        <Container>
                            <NavbarBrand tag={Link} to="/admin" className="">OxyDaySpa</NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className="mr-2" />
                            <Collapse className="d-sm-inline-flex ml-auto justify-content-end" isOpen={this.state.isOpen} onClick={this.toggle} navbar>
                                <a className="text-dark nav-link" href="/admin/main"><FontAwesomeIcon icon={["fas", "home"]} className="fa-fw mr-1" /> Főoldal</a>
                                <Link className="text-dark nav-link" to="/admin/pages"><FontAwesomeIcon icon={["fas", "copy"]} className="fa-fw mr-1" /> Oldalak</Link>
                                <Link className="text-dark nav-link" to="/admin/pricing-pages"><FontAwesomeIcon icon={["fas", "dollar-sign"]} className="fa-fw mr-1" /> Árak</Link>
                                <Link className="text-dark nav-link" to="/admin/menu"><FontAwesomeIcon icon={["fas", "list"]} className="fa-fw mr-1" /> Menü</Link>
                                <Link className="text-dark nav-link" to="/admin/about-us"><FontAwesomeIcon icon={["fas", "id-card"]} className="fa-fw mr-1" /> Rólunk</Link>
                                <Link className="text-dark nav-link" to="/admin/images"><FontAwesomeIcon icon={["fas", "images"]} className="fa-fw mr-1" /> Képek</Link>
                                <a className="text-dark nav-link" href="/shop/admin"><FontAwesomeIcon icon={["fas", "shopping-cart"]} className="fa-fw mr-1" /> Webshop</a>
                                <a className="text-dark nav-link" href="/Identity/Account/Manage/ChangePassword"><FontAwesomeIcon icon={["fas", "key"]} className="fa-fw mr-1" /> Jelszó</a>
                                <a className="text-dark nav-link" href={ApplicationPaths.LogOut}><FontAwesomeIcon icon={["fas", "sign-out-alt"]} className="fa-fw mr-1" /> Kijelentkezés</a>
                            </Collapse>
                        </Container>
                    </Navbar>
                </header>
            </React.Fragment>
        );
    }

    private toggle = () => {
        if (isMobileOnly) {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }
}

export default NavMenuAdmin;
