import * as React from 'react';
import { Link } from 'react-router-dom';

export class Footer extends React.PureComponent {
    public render() {
        return (
            <React.Fragment>
                <div className="fusion-footer fusion-footer-parallax" style={{ position: "relative", margin: "0", padding: "0", bottom: "0" }}>
                    <strong>
                        <footer className="fusion-footer-widget-area fusion-widget-area">
                            <div className="fusion-row">
                                <div className="fusion-columns fusion-columns-3 fusion-widget-area">
                                    <div className="fusion-column col-lg-4 col-md-4 col-sm-4">
                                        <section id="contact_info-widget-2" className="fusion-footer-widget-column widget contact_info" style={{ borderStyle: 'solid', borderColor: 'transparent', borderWidth: '0px' }}>
                                            <h4 className="widget-title">Kapcsolat</h4>
                                            <div className="contact-info-container">
                                                <p className="address">Oxy Esztétikai Stúdió</p>
                                                <p className="address">
                                                    <a href="https://www.google.com/maps/place/Miskolc,+Arany+J%C3%A1nos+t%C3%A9r+1,+3526/@48.1077192,20.787912,17z/data=!3m1!4b1!4m5!3m4!1s0x47409ffd1c2aa6b3:0xf3bd9d14ffffc462!8m2!3d48.1077192!4d20.787912?hl" target="_blank">
                                                        Miskolc, Arany János tér 1.
                                                    </a>
                                                </p>
                                                <p className="mobile">Telefon: <a href="tel:+36 30 464 2297">+36 30 464 2297</a>
                                                </p>
                                                <p className="email">Email: <a href="mailto:oxyesztetika@gmail.com">oxyesztetika@gmail.com</a>
                                                </p>
                                                <p className="web">Web: <a href="/">
                                                    oxyesztetika.hu </a></p>
                                            </div>
                                            <div style={{ clear: 'both' }} />
                                        </section>
                                    </div>
                                    <div className="fusion-column fusion-column-last col-lg-4 col-md-4 col-sm-4">
                                        <section id="facebook-like-widget-3" className="fusion-footer-widget-column widget facebook_like" style={{ borderStyle: 'solid', borderColor: 'transparent', borderWidth: '0px' }}>
                                            <h4 className="widget-title">Stúdiónk a Facebookon</h4>
                                            <div className="fb-like-box-container facebook-like-widget-3" id="fb-root">
                                                <div className="fb-page" data-href="https://www.facebook.com/oxyesztetika/" data-original-width={250} data-width={250} data-adapt-container-width="true" data-small-header="false" data-height={240} data-hide-cover="false" data-show-facepile="true" data-tabs />
                                            </div>
                                            <div style={{ clear: 'both' }} />
                                        </section>
                                    </div>
                                    <div className="fusion-column col-lg-4 col-md-4 col-sm-4">
                                        <section id="contact_info-widget-2" className="fusion-footer-widget-column widget contact_info" style={{ borderStyle: 'solid', borderColor: 'transparent', borderWidth: '0px' }}>
                                            <h4 className="widget-title">Térkép</h4>
                                            <div>
                                                <iframe
                                                    title="Google Térkép"
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2664.130269548841!2d20.78342733367621!3d48.107722694764654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47409ffd1c2aa6b3%3A0xf3bd9d14ffffc462!2sMiskolc%2C%20Arany%20J%C3%A1nos%20t%C3%A9r%201%2C%203526!5e0!3m2!1sen!2shu!4v1663790846562!5m2!1sen!2shu"
                                                    style={{ width: '100%', height: '130px' }}
                                                    frameBorder={0}
                                                    allowFullScreen
                                                />
                                            </div>
                                            <div style={{ clear: 'both' }} />
                                        </section>
                                    </div>
                                    <div className="fusion-clearfix" />
                                </div> {/* fusion-columns */}
                            </div> {/* fusion-row */}
                        </footer> {/* fusion-footer-widget-area */}
                        <footer id="footer" className="fusion-footer-copyright-area fusion-footer-copyright-center">
                            <div className="fusion-row d-flex justify-content-between align-items-center">
                                <div>
                                    <img src="/wp-content/uploads/new/barion_logo.png" />
                                </div>
                                <div className="fusion-copyright-content">
                                    <div className="fusion-copyright-notice">
                                        <div>
                                            Minden jog fenntartva oxyesztetika.hu | <a href="/kapcsolat">Kapcsolat</a> |<Link to="/shop/aszf"> ÁSZF és Adatvédelmi nyilatkozat</Link>
                                        </div>
                                    </div>
                                    <div className="fusion-social-links-footer">
                                        <div className="fusion-social-networks boxed-icons">
                                            <div className="fusion-social-networks-wrapper"><a className="fusion-social-network-icon fusion-tooltip fusion-facebook fusion-icon-facebook" style={{}} data-placement="top" data-title="Facebook" data-toggle="tooltip" title="Facebook" href="https://www.facebook.com/oxyesztetika/" target="_blank" rel="noopener noreferrer"><span className="screen-reader-text">Facebook</span></a><a className="fusion-social-network-icon fusion-tooltip fusion-instagram fusion-icon-instagram" style={{}} data-placement="top" data-title="Instagram" data-toggle="tooltip" title="Instagram" href="https://www.instagram.com/oxyesztetikaistudio/" target="_blank" rel="noopener noreferrer"><span className="screen-reader-text">Instagram</span></a><a className="fusion-social-network-icon fusion-tooltip fusion-youtube fusion-icon-youtube" style={{}} data-placement="top" data-title="YouTube" data-toggle="tooltip" title="YouTube" href="https://www.youtube.com/channel/UCsHqPQEY9K1-LK2d0_H1IVA" target="_blank" rel="noopener noreferrer"><span className="screen-reader-text">YouTube</span></a><a className="fusion-social-network-icon fusion-tooltip fusion-mail fusion-icon-mail" style={{}} data-placement="top" data-title="Email" data-toggle="tooltip" title="Email" href="mailto: oxyesztetika@gmail.com" target="_self" rel="noopener noreferrer"><span className="screen-reader-text">Email</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div> {/* fusion-fusion-copyright-content */}
                                <div className="invisible">
                                    <img src="/wp-content/uploads/new/barion_logo.png" />
                                </div>
                            </div> {/* fusion-row */}
                        </footer> {/* #footer */}
                    </strong>
                </div> {/* fusion-footer */}
                <div className="fusion-sliding-bar-wrapper">
                </div>
            </React.Fragment>
        );
    }
}

export default Footer;
