import * as React from 'react';
import { connect } from 'react-redux';
import { NavMenu } from "./NavMenu";
import { Footer } from "./Footer";
import { isMobileOnly } from 'react-device-detect';
import * as OxyStore from '../store/OxyStore';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';

type ThankYouForContactingProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

function ThankYouForContacting(props: ThankYouForContactingProps) {
    return (
        <div id="boxed-wrapper">
            <div className="fusion-sides-frame"></div>
            <div id="wrapper" className="fusion-wrapper">
                <div id="home" style={{ position: "relative", top: "-1px" }}></div>
                <div id="sliders-container">
                </div>

                <NavMenu { ...props } />

                <div>
                    <main id="main" className="clearfix width-100">
                        <div className="fusion-row" style={{ maxWidth: '100%' }}>
                            <section id="content" className="full-width">
                                <div id="post-5" className="post-5 page type-page status-publish hentry">
                                    <div className="post-content">
                                        <div>
                                            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{
                                                backgroundColor: 'rgba(204,204,204,0.05)',
                                                backgroundImage: 'url("/wp-content/uploads/new/placeholder-image.png")',
                                                backgroundPosition: isMobileOnly ? "unset" : 'center center',
                                                backgroundRepeat: 'no-repeat',
                                                paddingTop: '0',
                                                paddingRight: isMobileOnly ? "0" : '30px',
                                                paddingBottom: isMobileOnly ? "0" : '400px',
                                                paddingLeft: isMobileOnly ? "0" : '30px',
                                                marginBottom: isMobileOnly ? '0' : '-100px',
                                                borderWidth: '0px 0px 0px 0px',
                                                borderColor: '#f7f7f7',
                                                borderStyle: 'solid',
                                                WebkitBackgroundSize: isMobileOnly ? "contain" : 'cover',
                                                MozBackgroundSize: isMobileOnly ? "contain" : 'cover',
                                                OBackgroundSize: isMobileOnly ? "contain" : 'cover',
                                                backgroundSize: isMobileOnly ? "contain" : 'cover'
                                            }}>
                                                <div className="fusion-builder-row fusion-row" style={{ visibility: "hidden" }}>
                                                    <div className="fusion-layout-column fusion_builder_column fusion-builder-column-0 fusion_builder_column_1_2 1_2 fusion-one-half fusion-column-first fusion-column-no-min-height fusion-column-inner-bg-wrapper" style={{ width: 'calc(50% - ( ( 4% ) * 0.5 ) )', marginRight: '4%', marginTop: '30px', marginBottom: '30px' }}>
                                                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ padding: '0px 0px 0px 0px' }}>
                                                            <div className="fusion-text fusion-text-1">
                                                                <h1 style={{ textAlign: 'left', paddingLeft: '30px' }}><span style={{ color: '#ffffff' }}><strong>Professzionális<br />
                                                                    SZEMÖLDÖK STYLING</strong></span></h1>
                                                            </div>
                                                            <div className="fusion-clearfix" />
                                                        </div>
                                                        <span className="fusion-column-inner-bg hover-type-zoomin">
                                                            <a href="javascript:void(0)" className="fusion-column-anchor">
                                                                <span className="fusion-column-inner-bg-image" style={{ backgroundColor: 'rgba(215,188,178,0.47)', backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover' }} />
                                                            </a>
                                                        </span>
                                                    </div>
                                                    {!isMobileOnly && <div className="fusion-layout-column fusion_builder_column fusion-builder-column-1 fusion_builder_column_1_2 1_2 fusion-one-half fusion-column-last fusion-column-no-min-height" style={{ width: 'calc(50% - ( ( 4% ) * 0.5 ) )', marginTop: '0px', marginBottom: '30px' }}>
                                                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', backgroundColor: 'rgba(216,216,216,0)', padding: '30px 0px 0px 0px' }}>
                                                            <div className="fusion-clearfix" />
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>

                                        <strong>
                                            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(232,232,232,0.2)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: isMobileOnly ? "0" : '32px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                                                <div className="fusion-builder-row fusion-row">
                                                    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-14 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(188,151,120,0.1)', backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '30px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                                                        <div className="fusion-builder-row fusion-row">
                                                            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-40 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                                <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                                                    <div className="fusion-text fusion-text-33" style={{ textAlign: 'center', animationDuration: '2.5s' }} data-animationoffset="100%" data-animationtype="bounce" data-animationduration="2.5">
                                                                        <h2 style={{ fontFamily: 'Garamond, cursive', textAlign: 'center', fontStyle: 'oblique' }}>
                                                                            <strong>
                                                                                Köszönjük az üzeneted, hamarosan jelentkezünk!
                                                                            </strong>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </strong>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>

                    <Footer />
                </div>
            </div>
        </div>
    );
}


export default connect(
    (state: ApplicationState, ownProps: {}) => {
        return { ...state.oxy };
    },
    OxyStore.actionCreators
)(ThankYouForContacting as any);
