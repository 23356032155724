import { ProtectedRestApi } from "./";
import { ServiceResult, OxyUser } from "../models";
import { UpdateProfilePayload } from "../payloads";

export class ProfileApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyprofile";

    public updateProfile(payload: UpdateProfilePayload): Promise<ServiceResult<OxyUser>> {
        return this.httpPost(`${ProfileApi.baseUrl}`, payload) as Promise<ServiceResult<OxyUser>>;
    }
}
