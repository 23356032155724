import * as React from 'react';
import { ProductDto } from '../../models/shop/ProductDto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface ProductCardProps {
    product: ProductDto;
}

class ProductCard extends React.PureComponent<ProductCardProps> {
    private currencyFormatter = new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    public render() {
        return (
            <Link to={`/shop/product/${this.props.product.id}`}>
                <div className="product-card">
                    <div className="product-header-container">
                        <div className="product-image-container">
                            <img src={`/wp-content/uploads/new/${this.props.product.image.name}`} />
                        </div>
                        <div className="price-container">
                            <div className="price">
                                {this.props.product.hasOptions && this.props.product.options.length > 0 && this.currencyFormatter.format(this.props.product.options[0].price)}
                                {!this.props.product.hasOptions && this.props.product.price != null && this.currencyFormatter.format(this.props.product.price)}
                            </div>
                        </div>
                    </div>
                    <div className="product-data">
                        <div className="title">{this.props.product.name}</div>
                        <div className="action">
                            <FontAwesomeIcon icon={["fas", "angle-right"]} />
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default ProductCard;
