import { ProtectedRestApi } from "./";
import { ServiceResult } from "../models";
import { CustomerReviewUpdatePayload } from "../payloads";

export class CustomerReviewApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxycustomerreview";

    public update(id: number, payload: CustomerReviewUpdatePayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${CustomerReviewApi.baseUrl}/main/${id}`, payload) as Promise<ServiceResult<boolean>>;
    }
}
