import * as React from 'react';
import { AdminEditorBadgePosition } from './admin/AdminEditorBadge';
import AdminEditorBadge from './admin/AdminEditorBadge';
import { ImageDto } from '../models';

interface GalleryBigImageProps {
    image: ImageDto;
    isAdminAndEditing: boolean;
    toggleEditor: () => void;
}

class GalleryBigImage extends React.PureComponent<GalleryBigImageProps> {
    public render() {
        return (
            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-4 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: 'rgba(188,151,120,0.1)', backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                <div className="fusion-builder-row fusion-row">
                    <div className="fusion-layout-column fusion_builder_column fusion-builder-column-666 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                            {this.props.isAdminAndEditing && <AdminEditorBadge position={AdminEditorBadgePosition.TopRight} onClick={this.props.toggleEditor} />}
                            <span className=" fusion-imageframe imageframe-dropshadow imageframe-1 hover-type-zoomin" style={{ WebkitBoxShadow: '3px 3px 7px rgba(0,0,0,0.3)', boxShadow: '3px 3px 7px rgba(0,0,0,0.3)' }}>
                                <a href={`/wp-content/uploads/new/${this.props.image.name}`} className="fusion-lightbox" data-rel="iLightbox[46d7c3f3fc1a077dfb3]">
                                    <img alt="" width={2050} height={780} data-image_id={this.props.image.id} src={`/wp-content/uploads/new/${this.props.image.name}`} className="img-responsive wp-image-18057" srcSet={`/wp-content/uploads/new/${this.props.image.name} 200w, /wp-content/uploads/new/${this.props.image.name} 400w, /wp-content/uploads/new/${this.props.image.name} 600w, /wp-content/uploads/new/${this.props.image.name} 800w, /wp-content/uploads/new/${this.props.image.name} 1200w, /wp-content/uploads/new/${this.props.image.name} 2050w`} sizes="(max-width: 800px) 100vw, 1200px" />
                                </a>
                            </span>
                            <div className="fusion-clearfix" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GalleryBigImage;
