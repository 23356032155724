import { ProtectedRestApi } from "./";
import { OxyUser, ServiceResult } from "../models";

export class UserApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyuser";

    public getUsers(): Promise<ServiceResult<OxyUser[]>> {
        return this.httpGet(`${UserApi.baseUrl}/all`) as Promise<ServiceResult<OxyUser[]>>;
    }

    public deleteUser(id: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${UserApi.baseUrl}/${id}`) as Promise<ServiceResult<boolean>>;
    }
}
