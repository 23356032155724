import { ProtectedRestApi } from "./";
import { ServiceResult, AboutUsPageDto } from "../models";

export class AboutUsPageApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyaboutuspage";

    public getAboutUsPage(): Promise<ServiceResult<AboutUsPageDto>> {
        return this.httpGet(`${AboutUsPageApi.baseUrl}`, true) as Promise<ServiceResult<AboutUsPageDto>>;
    }
}
