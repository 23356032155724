import * as React from 'react';
import { ImageGalleryDto } from '../models/ImageGalleryDto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface GalleryMobileProps {
    gallery: ImageGalleryDto;
}

interface GalleryMobileState {
    currentIndex: number;
}

class GalleryMobileMobile extends React.PureComponent<GalleryMobileProps, GalleryMobileState> {
    constructor(props: GalleryMobileProps) {
        super(props);

        this.state = {
            currentIndex: 1
        };
    }

    public render() {
        const image = this.state.currentIndex < this.props.gallery.images.length ? this.props.gallery.images[this.state.currentIndex] : null;
        return image == null ? null : (
            <div className="fusion-fullwidth fullwidth-box fusion-builder-row-5 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start" style={{ maxWidth: '1216.8px', marginLeft: 'calc(-4% / 2 )', marginRight: 'calc(-4% / 2 )' }}>
                    <span style={{ position: "absolute", left: "-20px", top: "calc(50% - 50px)", fontSize: "40px", fontWeight: 900, cursor: this.state.currentIndex > 1 ? "pointer" : "not-allowed", color: this.state.currentIndex > 1 ? "inherit" : "lightgray" }} onClick={this.handlePreviousClick}>
                        <FontAwesomeIcon icon={["fas", "angle-left"]} className="mr-2" />
                    </span>
                    {
                        this.props.gallery.images.length > 1 &&
                        <React.Fragment>
                            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-777 fusion_builder_column_1_3 1_3 fusion-flex-column">
                                <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                    <div>
                                        <span className=" fusion-imageframe imageframe-dropshadow imageframe-2 hover-type-zoomin" style={{ WebkitBoxShadow: '3px 3px 7px rgba(0,0,0,0.3)', boxShadow: '3px 3px 7px rgba(0,0,0,0.3)' }}>
                                            <a href={`/wp-content/uploads/new/${this.props.gallery.images[this.state.currentIndex].image.name}`} className="fusion-lightbox" data-rel={`iLightbox[5ad9ddcb83e4c2f8a6${image.id}]`}>
                                                <img alt="" width={2560} height={1760} data-image_id={image.id} src={`/wp-content/uploads/new/${image.image.name}`} className="img-responsive wp-image-17485" srcSet={`/wp-content/uploads/new/${image.image.name} 200w, /wp-content/uploads/new/${image.image.name} 400w, /wp-content/uploads/new/${image.image.name} 600w, /wp-content/uploads/new/${image.image.name} 800w, /wp-content/uploads/new/${image.image.name} 1200w, /wp-content/uploads/new/${image.image.name} 2560w`} sizes="(max-width: 1024px) 100vw, (max-width: 640px) 100vw, 400px" />
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\twidth: 33.333333333333% !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 30px;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-right: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-left: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:1024px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:640px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t" }} />
                        </React.Fragment>
                    }

                    {this.props.gallery.images.length > this.state.currentIndex - 1  && <span style={{ position: "absolute", right: "-30px", top: "calc(50% - 50px)", fontSize: "40px", fontWeight: 900, cursor: this.props.gallery.images.length - 1 > this.state.currentIndex ? "pointer" : "not-allowed", color: this.props.gallery.images.length - 1 > this.state.currentIndex ? "inherit" : "lightgray" }} onClick={this.handleNextClick}>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} className="mr-2" />
                    </span>}
                </div>
                <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-flex-container.fusion-builder-row-5 {\n\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-right: 30px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-left: 30px;\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t" }} />
            </div>
        );
    }

    private handlePreviousClick = () => {
        if (this.state.currentIndex > 1) {
            this.setState({
                currentIndex: this.state.currentIndex - 1
            });
        }
    }

    private handleNextClick = () => {
        if (this.state.currentIndex < this.props.gallery.images.length - 1) {
            this.setState({
                currentIndex: this.state.currentIndex + 1
            });
        }
    }
}

export default GalleryMobileMobile;
