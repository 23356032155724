import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import NavMenuShop from './NavMenuShop';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';
import MyOrderCard from './MyOrderCard';

type MyOrdersProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface MyOrdersState {
}

class MyOrders extends React.PureComponent<MyOrdersProps, MyOrdersState> {
    constructor(props: MyOrdersProps) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        if (this.props.myOrders.length === 0) {
            this.props.getMyOrders();
        }
    }

    public render() {
        require("../../shop.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="container checkout-main-container mb-4 orders-list-container">
                    <h1>Rendeléseim</h1>
                    {this.props.myOrders.length === 0 && <div>
                        <h4>Nem található rendelés.</h4>
                    </div>}
                    {this.props.myOrders.length > 0 && <div className="list-group">
                        {this.props.myOrders.map((order, index) => {
                            return (
                                <MyOrderCard
                                    key={index}
                                    order={order}
                                />
                            );
                        })}
                    </div>}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(MyOrders as any);
