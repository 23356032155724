import * as React from 'react';
import { Button, Card, CardBody, Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from './NavMenuAdmin';
import AdminBackButton from './AdminBackButton';

type ManagePricingPagesProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface ManagePricingPagesState {
    copyIndex: number;
    newPageName: string;
    newPagePath: string;
    validationErrors: string[];
    deleteIndex: number;
}

class ManagePricingPages extends React.PureComponent<ManagePricingPagesProps, ManagePricingPagesState> {
    constructor(props: ManagePricingPagesProps) {
        super(props);

        this.state = {
            copyIndex: NaN,
            newPageName: "",
            newPagePath: "",
            validationErrors: [],
            deleteIndex: NaN
        };
    }

    componentDidMount() {
        this.props.getPricingPages();
    }

    public render() {
        return this.props.loggedInUser != null && this.props.loggedInUser.isAdmin ?
            <React.Fragment>
                <NavMenuAdmin />
                <Container>
                    <AdminBackButton />
                    <div>
                        <h1>Árak kezelése</h1>
                    </div>
                    <Card>
                        <CardBody className="d-flex flex-column align-items-center">
                            <div className="list-group">
                                {this.props.pricingPages.map((pricingPage, index) =>
                                    <div key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                        <a href={`/arak/${pricingPage.path}`}>
                                            {pricingPage.name}
                                        </a>
                                        <div>
                                            <Link className="btn btn-primary ml-4 mr-3" to={`/admin/arak/${pricingPage.path}`}>
                                                <FontAwesomeIcon icon={["fas", "pencil-alt"]} /> Szerkesztés
                                            </Link>
                                            <Button className="mr-3" color="warning" onClick={() => this.toggleCopyModal(index)}>
                                                <FontAwesomeIcon icon={["fas", "copy"]} /> Másolás
                                            </Button>
                                            <Button color="danger" onClick={() => this.handleDeleteClick(index)}>
                                                <FontAwesomeIcon icon={["fas", "trash-alt"]} /> Törlés
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Container>
                <Modal isOpen={!isNaN(this.state.copyIndex)} toggle={() => this.toggleCopyModal(NaN)} className="modal-dialog-centered">
                    <ModalHeader toggle={() => this.toggleCopyModal(NaN)}>{!isNaN(this.state.copyIndex) && this.props.pricingPages[this.state.copyIndex].name} oldal másolása</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="inputNewPageName">Új oldal neve</label>
                            <input type="text" className="form-control" id="inputNewPageName" autoComplete="off" placeholder="Írd be a nevet." value={this.state.newPageName} onChange={this.handleNewPageNameChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputNewPagePath">Új oldal útvonala</label>
                            <input type="text" className="form-control" id="inputNewPagePath" autoComplete="off" placeholder="Írd be az útvonalat." value={this.state.newPagePath} onChange={this.handleNewPagePathChange} />
                        </div>
                        <div>
                            <p>
                                Az útvonal csak ékezet nélküli kisbetűket, számokat és kötőjelet tartalmazhat.
                            </p>
                        </div>

                        {this.state.validationErrors.length > 0 &&
                            <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                                {this.state.validationErrors.map((error, index) => {
                                    return (
                                        <div key={index} className="form-invalid">
                                            {error}
                                        </div>
                                    );
                                })}
                            </Card>
                        }

                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleNewPageSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                            </button>

                            <button className="btn btn-secondary" onClick={() => this.toggleCopyModal(NaN)}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={!isNaN(this.state.deleteIndex)} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                    <ModalHeader toggle={this.handleCancelDeleteClick}>Oldal törlése</ModalHeader>
                    <ModalBody>
                        <div>
                            <p>
                                Biztosan törölni szeretnéd az oldalt?
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-danger">
                                <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                            </button>

                            <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
            : null
            ;
    }

    private handleDeleteClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleConfirmDeleteClick = () => {
        this.props.deletePricingPage(this.props.pricingPages[this.state.deleteIndex].id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private toggleCopyModal = (index: number) => {
        this.setState({
            copyIndex: index
        });
    }

    private handleNewPageNameChange = (e: any) => {
        this.setState({
            newPageName: e.target.value
        }, () => this.validate(true));
    }

    private handleNewPagePathChange = (e: any) => {
        this.setState({
            newPagePath: e.target.value.replace(/[^0-9a-z\-]+/g, "")
        }, () => this.validate(true));
    }

    private handleNewPageSaveClick = () => {
        this.props.copyPricingPage(this.props.pricingPages[this.state.copyIndex].id, this.state.newPageName, this.state.newPagePath);
        this.toggleCopyModal(NaN);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.newPageName.length === 0) {
            validationErrors.push("A név kitöltése kötelező.");
        }

        if (this.state.newPagePath.length === 0) {
            validationErrors.push("Az útvonal kitöltése kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(ManagePricingPages as any);
