import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { AboutUsSectionDto } from '../models';

interface AboutUsSectionProps {
    section: AboutUsSectionDto;
    imagePosition: AboutUsSectionImagePosition;
    isLast: boolean;
}

export enum AboutUsSectionImagePosition {
    Left,
    Right
}

export class AboutUsSection extends React.PureComponent<AboutUsSectionProps> {
    public render() {
        return (
            <React.Fragment>
                {isMobileOnly || this.props.imagePosition === AboutUsSectionImagePosition.Left ?
                    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-5 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid', marginTop: "24px" }}>
                        <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start" style={{ maxWidth: '1216.8px', marginLeft: 'calc(-4% / 2 )', marginRight: 'calc(-4% / 2 )' }}>
                            <div id="fake-slider-1" className="fusion-layout-column fusion_builder_column fusion_builder_column_1_3 1_3 fusion-flex-column">
                                <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "24px", marginRight: "24px", justifyContent: "space-between", flexDirection: isMobileOnly ? "column" : "row" }}>
                                        <div style={{ marginRight: "24px", minWidth: isMobileOnly ? "80%" : "25%", maxWidth: isMobileOnly ? "80%" : "40%", display: "flex", marginBottom: isMobileOnly ? "16px" : "0" }}>
                                            <span className=" fusion-imageframe imageframe-dropshadow imageframe-2 hover-type-zoomin" style={{ WebkitBoxShadow: '3px 3px 7px rgba(0,0,0,0.3)', boxShadow: '3px 3px 7px rgba(0,0,0,0.3)' }}>
                                                <a href={`/wp-content/uploads/new/${this.props.section.image.name}`} className="fusion-lightbox" data-rel="iLightbox[9dcf9ae6c2d42b0001d]">
                                                    <img style={{ maxHeight: "350px", objectFit: "contain", width: "auto", minHeight: isMobileOnly ? "350px" : "unset" }} height={1760} alt="" src={`/wp-content/uploads/new/${this.props.section.image.name}`} className="img-responsive" srcSet={`/wp-content/uploads/new/${this.props.section.image.name} 200w, /wp-content/uploads/new/${this.props.section.image.name} 400w, /wp-content/uploads/new/${this.props.section.image.name} 600w, /wp-content/uploads/new/${this.props.section.image.name} 800w, /wp-content/uploads/new/${this.props.section.image.name} 1200w, /wp-content/uploads/new/${this.props.section.image.name} 2560w`} sizes="(max-width: 1024px) 100vw, (max-width: 640px) 100vw, 400px" />
                                                </a>
                                            </span>
                                        </div>
                                        <div className="d-flex flex-column justify-content-around h-100">
                                            <div>
                                                <h4 style={{ fontWeight: "bold", fontSize: "21px" }}>
                                                    {this.props.section.title}
                                                </h4>
                                            </div>
                                            <div className="standard-text oxy-text-justified">
                                                <h4 style={{ fontStyle: "italic" }}>
                                                    {this.props.section.description.split("\n").map((row, index) => <React.Fragment key={index}>{row}{index < this.props.section.description.split("\n").length - 1 ? <br /> : ""}</React.Fragment>)}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\twidth: 33.333333333333% !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 30px;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-right: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-left: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:1024px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:640px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t" }} />
                        </div>
                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-flex-container.fusion-builder-row-5 {\n\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-right: 30px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-left: 30px;\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t" }} />
                    </div>
                    :
                    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-5 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid', marginTop: "24px" }}>
                        <div className="fusion-builder-row fusion-row fusion-flex-align-items-flex-start" style={{ maxWidth: '1216.8px', marginLeft: 'calc(-4% / 2 )', marginRight: 'calc(-4% / 2 )' }}>
                            <div id="fake-slider-1" className="fusion-layout-column fusion_builder_column fusion_builder_column_1_3 1_3 fusion-flex-column">
                                <div className="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "24px", marginRight: "24px", justifyContent: "space-between" }}>
                                        <div className="d-flex flex-column justify-content-around h-100">
                                            <div>
                                                <h4 style={{ fontWeight: "bold", fontSize: "21px" }}>
                                                    {this.props.section.title}
                                                </h4>
                                            </div>
                                            <div className="standard-text oxy-text-justified">
                                                <h4 style={{ fontStyle: "italic" }}>
                                                    {this.props.section.description.split("\n").map((row, index) => <React.Fragment key={index}>{row}{index < this.props.section.description.split("\n").length - 1 ? <br /> : ""}</React.Fragment>)}
                                                </h4>
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: "24px", minWidth: "25%", maxWidth: "40%", display: "flex", justifyContent: "flex-end" }}>
                                            <span className=" fusion-imageframe imageframe-dropshadow imageframe-3 hover-type-zoomin" style={{ WebkitBoxShadow: '3px 3px 7px rgba(0,0,0,0.3)', boxShadow: '3px 3px 7px rgba(0,0,0,0.3)' }}>
                                                <a href={`/wp-content/uploads/new/${this.props.section.image.name}`} className="fusion-lightbox" data-rel="iLightbox[5ad9ddcb83e4c2f8a6c]">
                                                    <img style={{ maxHeight: "350px", objectFit: "contain", width: "auto" }} height={1202} alt="" src={`wp-content/uploads/new/${this.props.section.image.name}`} className="img-responsive" srcSet={`/wp-content/uploads/new/${this.props.section.image.name} 200w, /wp-content/uploads/new/${this.props.section.image.name} 400w, /wp-content/uploads/new/${this.props.section.image.name} 600w, /wp-content/uploads/new/${this.props.section.image.name} 800w, /wp-content/uploads/new/${this.props.section.image.name} 1200w, /wp-content/new/${this.props.section.image.name} 2000w`} sizes="(max-width: 1024px) 100vw, (max-width: 640px) 100vw, 400px" />
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\twidth: 33.333333333333% !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 30px;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-right: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tpadding-left: 0px !important;\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 5.76%;\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:1024px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t@media only screen and (max-width:640px) {\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-builder-column-777 {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 100% !important;\n\t\t\t\t\t\t\t\t\t\t\t\t\torder: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.fusion-builder-column-777>.fusion-column-wrapper {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-left: 1.92%;\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t" }} />
                        </div>
                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t.fusion-body .fusion-flex-container.fusion-builder-row-5 {\n\t\t\t\t\t\t\t\t\t\t\tpadding-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-top: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-right: 30px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tmargin-bottom: 0px;\n\t\t\t\t\t\t\t\t\t\t\tpadding-left: 30px;\n\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t" }} />
                    </div>
                }
                {!this.props.isLast &&
                    <div className="fusion-fullwidth fullwidth-box fusion-builder-row-16 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling" style={{ backgroundColor: '#ffffff', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', paddingTop: '0px', paddingRight: '30px', paddingBottom: '0px', paddingLeft: '30px', marginBottom: '0px', marginTop: '0px', borderWidth: '0px 0px 0px 0px', borderColor: '#f7f7f7', borderStyle: 'solid' }}>
                        <div className="fusion-builder-row fusion-row">
                            <div className="fusion-layout-column fusion_builder_column fusion-builder-column-44 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last" style={{ marginTop: '0px', marginBottom: '0' }}>
                                <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy" style={{ backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', padding: '0px 0px 0px 0px' }}>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-separator fusion-has-icon fusion-full-width-sep" style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                                        <div className="fusion-separator-border sep-single sep-solid" style={{ borderColor: 'rgba(181,148,115,0.76)', borderTopWidth: '1px' }}>
                                        </div><span className="icon-wrapper" style={{ borderColor: 'rgba(181,148,115,0.76)', backgroundColor: 'rgba(255,255,255,0)', fontSize: '17px', width: '1.75em', height: '1.75em', borderWidth: '1px', padding: '1px', marginTop: '-0.5px' }}><i className=" fa fa-heart" style={{ fontSize: 'inherit', color: 'rgba(181,148,115,0.76)' }} aria-hidden="true" /></span>
                                        <div className="fusion-separator-border sep-single sep-solid" style={{ borderColor: 'rgba(181,148,115,0.76)', borderTopWidth: '1px' }}>
                                        </div>
                                    </div>
                                    <div className="fusion-sep-clear" />
                                    <div className="fusion-clearfix" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default AboutUsSection;
