import * as React from 'react';
import { Button, Card, CardBody, Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from './NavMenuAdmin';
import AdminBackButton from './AdminBackButton';

type ManagePagesProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface ManagePagesState {
    copyIndex: number;
    newPageName: string;
    newPagePath: string;
    pagePickerOpen: boolean;
    pageIndexForEditing: number;
    pageName: string;
    pagePath: string;
    pagePricingPageId: number;
    validationErrors: string[];
    validationErrorsForEdit: string[];
    deleteIndex: number;
}

class ManagePages extends React.PureComponent<ManagePagesProps, ManagePagesState> {
    constructor(props: ManagePagesProps) {
        super(props);

        this.state = {
            copyIndex: NaN,
            newPageName: "",
            newPagePath: "",
            pagePickerOpen: false,
            pageIndexForEditing: NaN,
            pageName: "",
            pagePath: "",
            pagePricingPageId: NaN,
            validationErrors: [],
            validationErrorsForEdit: [],
            deleteIndex: NaN
        };
    }

    componentDidMount() {
        this.props.getPages();
        this.props.getScrollingPages();
        this.props.getPricingPages();
    }

    public render() {
        return this.props.loggedInUser != null && this.props.loggedInUser.isAdmin ?
            <React.Fragment>
                <NavMenuAdmin />
                <Container>
                    <AdminBackButton />
                    <div>
                        <h1>Oldalak kezelése</h1>
                    </div>
                    <Card>
                        <CardBody className="d-flex flex-column align-items-center">
                            <div className="list-group">
                                {this.props.pages.map((page, index) =>
                                    <div key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                        <a href={`/oldal/${page.path}`}>
                                            {page.name}
                                        </a>
                                        <div>
                                            <a className="btn btn-primary ml-4 mr-3" href={`/admin/oldal/${page.path}`}>
                                                <FontAwesomeIcon icon={["fas", "pencil-alt"]} /> Szerkesztés
                                            </a>
                                            <Button className="mr-3" color="secondary" onClick={() => this.toggleEditModal(index)}>
                                                <FontAwesomeIcon icon={["fas", "cog"]} /> Beállítások
                                            </Button>
                                            <Button className="mr-3" color="warning" onClick={() => this.toggleCopyModal(index)}>
                                                <FontAwesomeIcon icon={["fas", "copy"]} /> Másolás
                                            </Button>
                                            <Button color="danger" onClick={() => this.handleDeleteClick(index)}>
                                                <FontAwesomeIcon icon={["fas", "trash-alt"]} /> Törlés
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Container>
                <Modal isOpen={!isNaN(this.state.copyIndex)} toggle={() => this.toggleCopyModal(NaN)} className="modal-dialog-centered">
                    <ModalHeader toggle={() => this.toggleCopyModal(NaN)}>{!isNaN(this.state.copyIndex) && this.props.pages[this.state.copyIndex].name} oldal másolása</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="inputNewPageName">Új oldal neve</label>
                            <input type="text" className="form-control" id="inputNewPageName" autoComplete="off" placeholder="Írd be a nevet." value={this.state.newPageName} onChange={this.handleNewPageNameChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputNewPagePath">Új oldal útvonala</label>
                            <input type="text" className="form-control" id="inputNewPagePath" autoComplete="off" placeholder="Írd be az útvonalat." value={this.state.newPagePath} onChange={this.handleNewPagePathChange} />
                        </div>
                        <div>
                            <p>
                                Az útvonal csak ékezet nélküli kisbetűket, számokat és kötőjelet tartalmazhat.
                            </p>
                        </div>

                        {this.state.validationErrors.length > 0 &&
                            <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                                {this.state.validationErrors.map((error, index) => {
                                    return (
                                        <div key={index} className="form-invalid">
                                            {error}
                                        </div>
                                    );
                                })}
                            </Card>
                        }

                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleNewPageSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                            </button>

                            <button className="btn btn-secondary" onClick={() => this.toggleCopyModal(NaN)}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={!isNaN(this.state.pageIndexForEditing)} toggle={() => this.toggleEditModal(NaN)} className="modal-dialog-centered">
                    <ModalHeader toggle={() => this.toggleEditModal(NaN)}>{!isNaN(this.state.pageIndexForEditing) && this.props.pages[this.state.pageIndexForEditing].name} oldal szerkesztése</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="inputEditPageName">Oldal neve</label>
                            <input type="text" className="form-control" id="inputEditPageName" autoComplete="off" placeholder="Írd be a nevet." value={this.state.pageName} onChange={this.handleEditPageNameChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputEditPagePath">Oldal útvonala</label>
                            <input type="text" className="form-control" id="inputEditPagePath" autoComplete="off" placeholder="Írd be az útvonalat." value={this.state.pagePath} onChange={this.handleEditPagePathChange} />
                        </div>
                        <div>
                            <p>
                                Az útvonal csak ékezet nélküli kisbetűket, számokat és kötőjelet tartalmazhat.
                            </p>
                        </div>
                        <div className="dropdown mt-2">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuPagePricingPage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Árak oldal {isNaN(this.state.pagePricingPageId) ? "" : `(${this.props.pricingPages.find(x => x.id === this.state.pagePricingPageId)!!.name})`}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuPagePricingPage">
                                {this.props.pricingPages.map((pricingPage, index) => {
                                    return (
                                        <div key={index} className="dropdown-item cursor-pointer" onClick={() => this.handleEditPagePricingPageChange(pricingPage.id)}>
                                            {pricingPage.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {this.state.validationErrorsForEdit.length > 0 &&
                            <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                                {this.state.validationErrorsForEdit.map((error, index) => {
                                    return (
                                        <div key={index} className="form-invalid">
                                            {error}
                                        </div>
                                    );
                                })}
                            </Card>
                        }

                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleEditPageSaveClick} type="button" className="btn btn-primary" disabled={!this.isValidEdit()}>
                                <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                            </button>

                            <button className="btn btn-secondary" onClick={() => this.toggleEditModal(NaN)}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={!isNaN(this.state.deleteIndex)} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                    <ModalHeader toggle={this.handleCancelDeleteClick}>Oldal törlése</ModalHeader>
                    <ModalBody>
                        <div>
                            <p>
                                Biztosan törölni szeretnéd az oldalt?
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-danger">
                                <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                            </button>

                            <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
            : null
            ;
    }

    private handleDeleteClick = (index: number) => {
        this.setState({
            deleteIndex: index
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteIndex: NaN
        });
    }

    private handleConfirmDeleteClick = () => {
        this.props.deletePage(this.props.pages[this.state.deleteIndex].id);
        this.setState({
            deleteIndex: NaN
        });
    }

    private toggleCopyModal = (index: number) => {
        this.setState({
            copyIndex: index
        });
    }

    private toggleEditModal = (index: number) => {
        this.setState({
            pageIndexForEditing: index,
            pageName: isNaN(index) ? "" : this.props.pages[index].name,
            pagePath: isNaN(index) ? "" : this.props.pages[index].path,
            pagePricingPageId: isNaN(index) || this.props.pages[index].pricingPage == null ? NaN : this.props.pages[index].pricingPage!!.id
        });
    }

    private handleNewPageNameChange = (e: any) => {
        this.setState({
            newPageName: e.target.value
        }, () => this.validate(true));
    }

    private handleNewPagePathChange = (e: any) => {
        this.setState({
            newPagePath: e.target.value.replace(/[^0-9a-z\-]+/g, "")
        }, () => this.validate(true));
    }

    private handleNewPageSaveClick = () => {
        this.props.copyPage(this.props.pages[this.state.copyIndex].id, this.state.newPageName, this.state.newPagePath);
        this.toggleCopyModal(NaN);
    }

    private handleEditPageSaveClick = () => {
        this.props.updatePage(this.props.pages[this.state.pageIndexForEditing].id, this.state.pageName, this.state.pagePath, this.state.pagePricingPageId);
        this.toggleEditModal(NaN);
    }

    private handleEditPageNameChange = (e: any) => {
        this.setState({
            pageName: e.target.value
        }, () => this.validateEdit(true));
    }

    private handleEditPagePathChange = (e: any) => {
        this.setState({
            pagePath: e.target.value.replace(/[^0-9a-z\-]+/g, "")
        }, () => this.validateEdit(true));
    }

    private handleEditPagePricingPageChange = (id: number) => {
        this.setState({
            pagePricingPageId: id
        }, () => this.validateEdit(true));
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.newPageName.length === 0) {
            validationErrors.push("A név kitöltése kötelező.");
        }

        if (this.state.newPagePath.length === 0) {
            validationErrors.push("Az útvonal kitöltése kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidEdit = () => {
        return this.validateEdit();
    }

    private validateEdit = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.pageName.length === 0) {
            validationErrors.push("A név kitöltése kötelező.");
        }

        if (this.state.pagePath.length === 0) {
            validationErrors.push("Az útvonal kitöltése kötelező.");
        }

        if (isNaN(this.state.pagePricingPageId)) {
            validationErrors.push("Az árak oldal kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsForEdit: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(ManagePages as any);
