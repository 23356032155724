import * as React from 'react';
import { Card, Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from './NavMenuAdmin';
import { PricingPageTextsPayload, PricingPageItemPayload, ReorderPayload } from '../../payloads';
import AdminBackButton from "./AdminBackButton";
import { QuillEditorUtils } from '../../utils';
import ReactQuill from 'react-quill';
import Reorder from 'react-reorder';
import PricingPageEditorCard from './PricingPageEditorCard';
import { PricingItemDto } from '../../models';

type PricingPageEditorProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ path: string }>;

interface PricingPageEditorState {
    textEditorOpen: boolean;
    itemsEditorOpen: boolean;
    title: string;
    name: string;
    path: string;
    columnCount: number;
    column1Header: string;
    column2Header: string;
    column3Header: string;
    column4Header: string;
    textValidationErrors: string[];
    itemValidationErrors: string[];
    editingItemId: number;
    deleteItemId: number;
    column1Value: string;
    column2Value: string;
    column3Value: string;
    column4Value: string;
}

class PricingPageEditor extends React.PureComponent<PricingPageEditorProps, PricingPageEditorState> {
    constructor(props: PricingPageEditorProps) {
        super(props);

        this.state = {
            textEditorOpen: false,
            itemsEditorOpen: false,
            title: "",
            name: "",
            path: "",
            columnCount: 4,
            column1Header: "",
            column2Header: "",
            column3Header: "",
            column4Header: "",
            textValidationErrors: [],
            itemValidationErrors: [],
            editingItemId: NaN,
            deleteItemId: NaN,
            column1Value: "",
            column2Value: "",
            column3Value: "",
            column4Value: ""
        }
    }

    public componentDidMount() {
        const pricingPagePath: string = this.props.match.params.path;

        if (pricingPagePath != null && pricingPagePath.length > 0) {
            this.props.getPricingPageByPath(pricingPagePath);
        }
    }

    componentWillReceiveProps(nextProps: PricingPageEditorProps) {
        const oldPricingPagePath: string = this.props.match.params.path;
        const newPricingPagePath: string = nextProps.match.params.path;

        if (oldPricingPagePath !== newPricingPagePath) {
            return;
        }

        if ((this.props.currentPricingPage == null && nextProps.currentPricingPage != null) || (nextProps.currentPricingPage != null && !this.props.pricingPageOperationFinished && nextProps.pricingPageOperationFinished) || (this.props.currentPricingPage != null && nextProps.currentPricingPage != null && this.props.currentPricingPage.id !== nextProps.currentPricingPage.id)) {
            const currentPricingPage = nextProps.currentPricingPage;

            this.setState({
                name: currentPricingPage.name,
                path: currentPricingPage.path,
                title: currentPricingPage.title,
                columnCount: currentPricingPage.columnCount,
                column1Header: currentPricingPage.column1Header,
                column2Header: currentPricingPage.column2Header,
                column3Header: currentPricingPage.column3Header,
                column4Header: currentPricingPage.column4Header
            });
        }
    }

    public render() {
        if (this.props.currentPricingPage !== undefined && this.props.currentPricingPage == null) {
            window.location.href = "/";
            return null;
        }

        const columnsIteratorForEditing = Array.from(Array(this.state.columnCount).keys()).map(x => x + 1);

        return (
            <React.Fragment>
                <NavMenuAdmin />
                <Container>
                    <AdminBackButton to="/admin/pricing-pages" />
                    <div>
                        <h1>Árak kezelése - {this.props.currentPricingPage != null && this.props.currentPricingPage.name}</h1>
                    </div>

                    <Card className="p-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="my-0">Alapadatok szerkesztése</h3>
                            <span className="ml-4 cursor-pointer" onClick={this.toggleTextEditor}>
                                <FontAwesomeIcon icon={["fas", this.state.textEditorOpen ? "chevron-up" : "chevron-down"]} className="mr-1" />
                            </span>
                        </div>
                        {this.props.currentPricingPage != null && this.state.textEditorOpen && <div className="px-4 mt-4">
                            <div className="form-group">
                                <label htmlFor="inputPricingPageName">Név</label>
                                <input type="text" className="form-control" id="inputPricingPageName" autoComplete="off" placeholder="Írd be a nevet." value={this.state.name} onChange={this.handleNameChange} />
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="inputPricingPagePath">Útvonal</label>
                                <input type="text" className="form-control" id="inputPricingPagePath" autoComplete="off" placeholder="Írd be az útvonalat." value={this.state.path} onChange={this.handlePathChange} />
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="inputPricingPageTitle">Cím</label>
                                <textarea rows={3} className="form-control" id="inputPricingPageTitle" autoComplete="off" placeholder="Írd be a címet." value={this.state.title} onChange={this.handleTitleChange} />
                            </div>

                            {columnsIteratorForEditing.map((value, index) => {
                                let headerValue = "";
                                switch (value) {
                                    case 1:
                                        headerValue = this.state.column1Header;
                                        break;
                                    case 2:
                                        headerValue = this.state.column2Header;
                                        break;
                                    case 3:
                                        headerValue = this.state.column3Header;
                                        break;
                                    case 4:
                                        headerValue = this.state.column4Header;
                                        break;
                                }

                                return (
                                    <div key={index} className="form-group">
                                        <label htmlFor={`inputColumn${value}Header`}>Oszlop {value} fejléc</label>
                                        <input type="text" className="form-control" id={`inputColumn${value}Header`} autoComplete="off" placeholder="Írd be az fejlécet." value={headerValue} onChange={(e: any) => this.handleColumnHeaderChange(value, e)} />
                                    </div>
                                );
                            })}

                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuColumnCountButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Oszlopok száma ({this.state.columnCount})
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuColumnCountButton">
                                    <div className="dropdown-item cursor-pointer" onClick={() => this.handleColumnCountChange(2)}>2</div>
                                    <div className="dropdown-item cursor-pointer" onClick={() => this.handleColumnCountChange(3)}>3</div>
                                    <div className="dropdown-item cursor-pointer" onClick={() => this.handleColumnCountChange(4)}>4</div>
                                </div>
                            </div>


                            {this.state.textValidationErrors.length > 0 &&
                                <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                                    {this.state.textValidationErrors.map((error, index) => {
                                        return (
                                            <div key={index} className="form-invalid">
                                                {error}
                                            </div>
                                        );
                                    })}
                                </Card>
                            }

                            <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
                                <button onClick={this.handleSaveTextsClick} type="button" className="btn btn-primary" disabled={!this.isValidTexts()}>
                                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Alapadatok mentése
                                </button>
                            </div>
                        </div>}
                    </Card>

                    <Card className="p-4 mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="my-0">Tételek szerkesztése</h3>
                            <span className="ml-4 cursor-pointer" onClick={this.toggleItemsEditor}>
                                <FontAwesomeIcon icon={["fas", this.state.itemsEditorOpen ? "chevron-up" : "chevron-down"]} className="mr-1" />
                            </span>
                        </div>

                        {this.props.currentPricingPage != null && this.state.itemsEditorOpen && <div className="px-4 mt-4">
                            <div className="mb-2">
                                <p>
                                    Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                                </p>
                            </div>
                            <div className="d-flex justify-content-end mb-3 mt-4">
                                <button onClick={this.handleAddItemClick} type="button" className="btn btn-primary">
                                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                                </button>
                            </div>
                            <Reorder
                                listClass="list-group"
                                itemKey="id"
                                list={this.props.currentPricingPage.items}
                                sharedProps={{
                                    onDeleteClick: this.toggleItemDelete,
                                    onEditClick: this.toggleItemEditing
                                }}
                                holdTime={200}
                                template={PricingPageEditorCard}
                                callback={this.handleReorderItems}
                                itemClass="list-group-item list-group-item-action"
                            />
                        </div>}
                    </Card>
                </Container>
                {this.getItemEditorModal()}
                {this.getConfirmDeleteModal()}
            </React.Fragment>
        );
    }

    private getItemEditorModal = () => {
        const columnsIteratorForEditing = Array.from(Array(this.state.columnCount).keys()).map(x => x + 1);

        return (
            <Modal isOpen={!isNaN(this.state.editingItemId)} toggle={() => this.toggleItemEditing()} className="modal-dialog-centered">
                <ModalHeader toggle={() => this.toggleItemEditing()}>Ár {this.state.editingItemId === 0 ? "hozzáadása" : "szerkesztése"}</ModalHeader>
                <ModalBody>
                    {columnsIteratorForEditing.map((value, innerIndex) => {
                        let columnHeader = "";
                        let columnValue = "";

                        switch (value) {
                            case 1:
                                columnHeader = this.props.currentPricingPage == null ? "" : this.props.currentPricingPage!.column1Header;
                                columnValue = this.state.column1Value;
                                break;
                            case 2:
                                columnHeader = this.props.currentPricingPage == null ? "" : this.props.currentPricingPage!.column2Header;
                                columnValue = this.state.column2Value;
                                break;
                            case 3:
                                columnHeader = this.props.currentPricingPage == null ? "" : this.props.currentPricingPage!.column3Header;
                                columnValue = this.state.column3Value;
                                break;
                            case 4:
                                columnHeader = this.props.currentPricingPage == null ? "" : this.props.currentPricingPage!.column4Header;
                                columnValue = this.state.column4Value;
                                break;
                        }

                        return (
                            <div key={innerIndex} className="form-group mt-2">
                                <label>{columnHeader}</label>
                                <ReactQuill
                                    theme="snow"
                                    value={columnValue}
                                    onChange={text => this.handleColumnValueChange(value, text)}
                                    modules={QuillEditorUtils.modules}
                                    formats={QuillEditorUtils.formats}
                                />
                            </div>
                        );
                    })}

                    {this.state.itemValidationErrors.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                            {this.state.itemValidationErrors.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveItemsClick} type="button" className="btn btn-primary" disabled={!this.isValidItems()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={() => this.toggleItemEditing()}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getConfirmDeleteModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.deleteItemId)} toggle={() => this.toggleItemDelete()} className="modal-dialog-centered">
                <ModalHeader toggle={() => this.toggleItemDelete()}>Ár törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd az árat?
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-danger">
                            <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={() => this.toggleItemDelete()}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private toggleItemDelete = (id: number = NaN) => {
        this.setState({
            deleteItemId: id
        });
    }

    private toggleItemEditing = (id: number = NaN) => {
        const item = this.props.currentPricingPage?.items?.find(x => x.id === id);

        this.setState({
            editingItemId: id,
            column1Value: item == null ? "" : item.column1Value,
            column2Value: item == null ? "" : item.column2Value,
            column3Value: item == null ? "" : item.column3Value,
            column4Value: item == null ? "" : item.column4Value
        }, () => this.validateItems(true));
    }

    private toggleTextEditor = () => {
        this.setState({
            textEditorOpen: !this.state.textEditorOpen
        });
    }

    private toggleItemsEditor = () => {
        this.setState({
            itemsEditorOpen: !this.state.itemsEditorOpen
        });
    }

    private handleAddItemClick = () => {
        this.setState({
            column1Value: "",
            column2Value: "",
            column3Value: "",
            column4Value: "",
            editingItemId: 0
        });
    }

    private handleNameChange = (e: any) => {
        this.setState({
            name: e.target.value
        }, () => this.validateTexts(true));
    }

    private handlePathChange = (e: any) => {
        this.setState({
            path: e.target.value.replace(/[^0-9a-z\-]+/g, "")
        }, () => this.validateTexts(true));
    }

    private handleTitleChange = (e: any) => {
        this.setState({
            title: e.target.value
        }, () => this.validateTexts(true));
    }

    private handleColumnCountChange = (count: number) => {
        this.setState({
            columnCount: count,
            column3Header: count >= 3 ? this.state.column3Header : "",
            column4Header: count >= 4 ? this.state.column4Header : ""
        }, () => this.validateTexts(true));
    }

    private handleColumnHeaderChange = (column: number, e: any) => {
        this.setState({
            column1Header: column === 1 ? e.target.value : this.state.column1Header,
            column2Header: column === 2 ? e.target.value : this.state.column2Header,
            column3Header: column === 3 ? e.target.value : this.state.column3Header,
            column4Header: column === 4 ? e.target.value : this.state.column4Header,
        }, () => this.validateTexts(true));
    }

    private handleSaveItemsClick = () => {
        if (this.state.editingItemId === 0) {
            const payload: PricingPageItemPayload = {
                column1Value: this.state.column1Value,
                column2Value: this.state.column2Value,
                column3Value: this.state.column3Value,
                column4Value: this.state.column4Value
            };

            this.props.addPricingPageItem(this.props.currentPricingPage!.id, payload);
        } else {
            const payload: PricingPageItemPayload = {
                column1Value: this.state.column1Value,
                column2Value: this.state.column2Value,
                column3Value: this.state.column3Value,
                column4Value: this.state.column4Value
            };

            this.props.updatePricingPageItem(this.props.currentPricingPage!.id, this.state.editingItemId, payload);
        }

        this.toggleItemEditing();
    }

    private handleReorderItems = (event: any,
        itemThatHasBeenMoved: PricingItemDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: PricingItemDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorderPricingItems(this.props.currentPricingPage!.id, payload);
    }

    private handleConfirmDeleteClick = () => {
        this.props.deletePricingPageItem(this.props.currentPricingPage!.id, this.state.deleteItemId);

        this.setState({
            deleteItemId: NaN
        });
    }

    private handleColumnValueChange = (column: number, text: string) => {
        this.setState({
            column1Value: column === 1 ? text : this.state.column1Value,
            column2Value: column === 2 ? text : this.state.column2Value,
            column3Value: column === 3 ? text : this.state.column3Value,
            column4Value: column === 4 ? text : this.state.column4Value
        }, () => this.validateItems(true));
    }

    private handleSaveTextsClick = () => {
        const payload: PricingPageTextsPayload = {
            name: this.state.name,
            path: this.state.path,
            title: this.state.title,
            columnCount: this.state.columnCount,
            column1Header: this.state.column1Header,
            column2Header: this.state.column2Header,
            column3Header: this.state.column3Header,
            column4Header: this.state.column4Header
        };

        this.props.updatePricingPageTexts(this.props.currentPricingPage!!.id, payload);
    }

    private isValidTexts = () => {
        return this.validateTexts();
    }

    private validateTexts = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.name.length === 0) {
            validationErrors.push("A név kitöltése kötelező.");
        }

        if (this.state.path.length === 0) {
            validationErrors.push("Az útvonal kitöltése kötelező.");
        }

        if (this.state.title.length === 0) {
            validationErrors.push("A cím kitöltése kötelező.");
        }

        if (this.state.column1Header.length === 0) {
            validationErrors.push("Az oszlop 1 fejléc kitöltése kötelező.");
        }

        if (this.state.column2Header.length === 0) {
            validationErrors.push("Az oszlop 2 fejléc kitöltése kötelező.");
        }

        if (this.state.columnCount > 2) {
            if (this.state.column3Header.length === 0) {
                validationErrors.push("Az oszlop 3 fejléc kitöltése kötelező.");
            }

            if (this.state.columnCount > 3) {
                if (this.state.column4Header.length === 0) {
                    validationErrors.push("Az oszlop 4 fejléc kitöltése kötelező.");
                }
            }
        }

        if (shouldSetState) {
            this.setState({
                textValidationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidItems = () => {
        return this.validateItems();
    }

    private validateItems = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];
        const column1Header = this.props.currentPricingPage == null ? "" : this.props.currentPricingPage.column1Header;

        if (this.state.column1Value.length === 0 || this.state.column1Value === "<p></p>" || this.state.column1Value === "<p><br></p>") {
            validationErrors.push(`A(z) ${column1Header} megadása kötelező.`);
        }

        if (shouldSetState) {
            this.setState({
                itemValidationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(PricingPageEditor as any);
