import * as React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ScrollingPageDto } from "../../models";

interface ScrollingPagePickerModalProps {
    scrollingPages: ScrollingPageDto[];
    onScrollingPageSelected: (id: number) => void;
    isOpen: boolean;
    toggle: () => void;
}

interface ScrollingPagePickerModalState {
}

export class ScrollingPagePickerModal extends React.PureComponent<ScrollingPagePickerModalProps, ScrollingPagePickerModalState> {
    constructor(props: ScrollingPagePickerModalProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={this.props.toggle}>Hivatkozás kiválasztása</ModalHeader>
                    <ModalBody>
                        <div className="list-group">
                            {this.props.scrollingPages.map((scrollingPage, index) => {
                                return (
                                    <div key={index} className="list-group-item list-group-item-action" style={{ cursor: "pointer" }} onClick={() => this.props.onScrollingPageSelected(scrollingPage.id)}>
                                        {scrollingPage.name}
                                    </div>
                                );
                            })}
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ScrollingPagePickerModal;
