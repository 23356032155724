import * as React from 'react';
import { Button, Card, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from './NavMenuAdmin';
import Reorder from 'react-reorder';
import { AboutUsSectionDto } from '../../models';
import { AboutUsSectionPayload, ReorderPayload } from '../../payloads';
import { AboutUsSectionEditorCard } from "./AboutUsSectionEditorCard";
import ImagePickerModal from './ImagePickerModal';
import AdminBackButton from './AdminBackButton';

type AboutUsEditorProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface AboutUsEditorState {
    result: boolean | null;
    editId: number;
    imagePickerOpen: boolean;
    payload: AboutUsSectionPayload;
    validationErrors: string[];
}

class AboutUsEditor extends React.PureComponent<AboutUsEditorProps, AboutUsEditorState> {
    constructor(props: AboutUsEditorProps) {
        super(props);

        this.state = {
            result: null,
            editId: NaN,
            imagePickerOpen: false,
            payload: {
                title: "",
                description: "",
                imageId: NaN,
                crop: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100
                }
            },
            validationErrors: []
        }
    }

    componentDidMount() {
        this.props.getAboutUsSections();
        this.props.getImages();
    }

    public render() {
        return (
            <React.Fragment>
                <NavMenuAdmin />
                <Container>
                    <AdminBackButton />
                    <div>
                        <h1>Rólunk oldal szerkesztése</h1>
                    </div>

                    <div className="mb-2">
                        <p>
                            Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                        </p>
                    </div>
                    <div className="d-flex justify-content-end mb-3">
                        <Button onClick={() => this.toggleEditor(-1)} color="primary">
                            <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                        </Button>
                    </div>
                    <Reorder
                        listClass="list-group"
                        itemKey="id"
                        list={this.props.aboutUsSections}
                        sharedProps={{
                            onEditClick: this.toggleEditor,
                            onDeleteClick: this.onDeleteClick
                        }}
                        holdTime={200}
                        template={AboutUsSectionEditorCard}
                        callback={this.handleReorder}
                        itemClicked={() => { }}
                        itemClass="list-group-item list-group-item-action"
                    />
                </Container>
                {this.getEditModal()}
                <ImagePickerModal
                    isOpen={this.state.imagePickerOpen}
                    toggle={this.toggleImagePicker}
                    images={this.props.images}
                    onImageSelected={this.handleImageSelected}
                    aspectRatio={233 / 350}
                    uploadImage={this.props.uploadImage}
                />
            </React.Fragment>
        );
    }

    private getEditModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.editId)} toggle={() => this.toggleEditor(NaN)} className="modal-dialog-centered">
                <ModalHeader toggle={() => this.toggleEditor(NaN)}>Szekció {this.state.editId > 0 ? "szerkesztése" : "hozzáadása"}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputAboutUsSectionTitle">Név, beosztás</label>
                        <input type="text" className="form-control" id="inputAboutUsSectionTitle" autoComplete="off" placeholder="Írd be a nevet." value={this.state.payload.title} onChange={this.handleTitleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAboutUsSectionDescription">Bemutatkozás</label>
                        <textarea rows={5} className="form-control" id="inputAboutUsSectionDescription" autoComplete="off" placeholder="Írd be a bemutatkozást." value={this.state.payload.description} onChange={this.handleDescriptionChange} />
                    </div>
                    <div className="d-flex mt-2">
                        <div style={{ height: "200px", minHeight: "200px", maxHeight: "200px" }}>
                            {!isNaN(this.state.payload.imageId) &&
                                <img alt="" style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "contain", overflow: "hidden" }} src={`/wp-content/uploads/new/${this.props.images.find(x => x.id === this.state.payload.imageId)!!.name}`} />
                            }
                        </div>
                        <div>
                            <Button color="primary" onClick={this.toggleImagePicker}>
                                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Kép kiválasztása
                            </Button>
                        </div>
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                        {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={() => this.toggleEditor(NaN)}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleImageSelected = (id: number, x: number, y: number, width: number, height: number) => {
        this.setState({
            payload: {
                ...this.state.payload,
                imageId: id,
                crop: {
                    x,
                    y,
                    width,
                    height
                }
            },
            imagePickerOpen: false
        }, () => this.validate(true));
    }

    private toggleImagePicker = () => {
        this.setState({
            imagePickerOpen: !this.state.imagePickerOpen
        });
    }

    private handleSaveClick = () => {
        if (this.state.editId <= 0) {
            this.props.addAboutUsSection(this.state.payload);
        }
        else {
            this.props.updateAboutUsSection(this.state.editId, this.state.payload);
        }

        this.toggleEditor(NaN);
    }

    private onDeleteClick = (id: number) => {
        this.props.deleteAboutUsSection(id);
    }

    private handleTitleChange = (e: any) => {
        this.setState({
            payload: {
                ...this.state.payload,
                title: e.target.value
            }
        }, () => this.validate(true));
    }

    private handleDescriptionChange = (e: any) => {
        this.setState({
            payload: {
                ...this.state.payload,
                description: e.target.value
            }
        }, () => this.validate(true));
    }

    private toggleEditor = (id: number) => {
        const targetId = this.state.editId === id ? NaN : id;
        const sectionToEdit = this.props.aboutUsSections.find(x => x.id === targetId);

        this.setState({
            editId: targetId,
            payload: {
                title: sectionToEdit == null ? "" : sectionToEdit.title,
                description: sectionToEdit == null ? "" : sectionToEdit.description,
                imageId: sectionToEdit == null ? NaN : sectionToEdit.image.id,
                crop: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100
                }
            }
        });
    }

    private handleReorder = (event: any,
        itemThatHasBeenMoved: AboutUsSectionDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: AboutUsSectionDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorderAboutUsSections(payload);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.payload.title.length === 0) {
            validationErrors.push("A név megadása kötelező.");
        }

        if (this.state.payload.description.length === 0) {
            validationErrors.push("A bemutatkozás megadása kötelező.");
        }

        if (isNaN(this.state.payload.imageId)) {
            validationErrors.push("A kép kiválasztása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(AboutUsEditor as any);
