import { ProtectedRestApi } from "./";
import { ServiceResult } from "../models";
import { ProductCategoryPayload, ProductOptionPayload, ProductPayload, ReorderPayload } from "../payloads";
import { ProductCategoryDto, ProductDto, ProductOptionDto } from "../models/shop";

export class ProductCategoryApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyproductcategory";

    public getAll(): Promise<ServiceResult<ProductCategoryDto[]>> {
        return this.httpGet(`${ProductCategoryApi.baseUrl}/all`, true) as Promise<ServiceResult<ProductCategoryDto[]>>;
    }

    public getPromotedProducts(): Promise<ServiceResult<ProductDto[]>> {
        return this.httpGet(`${ProductCategoryApi.baseUrl}/promoted-products`, true) as Promise<ServiceResult<ProductDto[]>>;
    }

    public createProductCategory(payload: ProductCategoryPayload): Promise<ServiceResult<ProductCategoryDto>> {
        return this.httpPut(`${ProductCategoryApi.baseUrl}`, payload) as Promise<ServiceResult<ProductCategoryDto>>;
    }

    public updateProductCategory(id: number, payload: ProductCategoryPayload): Promise<ServiceResult<ProductCategoryDto>> {
        return this.httpPost(`${ProductCategoryApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<ProductCategoryDto>>;
    }

    public toggleProductCategoryVisible(id: number, isVisible: boolean): Promise<ServiceResult<ProductCategoryDto>> {
        return this.httpDelete(`${ProductCategoryApi.baseUrl}/${id}`, isVisible) as Promise<ServiceResult<ProductCategoryDto>>;
    }

    public reorderProductCategories(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${ProductCategoryApi.baseUrl}/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }

    public createProduct(categoryId: number, payload: ProductPayload): Promise<ServiceResult<ProductDto>> {
        return this.httpPut(`${ProductCategoryApi.baseUrl}/${categoryId}/product`, payload) as Promise<ServiceResult<ProductDto>>;
    }

    public updateProduct(id: number, payload: ProductPayload): Promise<ServiceResult<ProductDto>> {
        return this.httpPost(`${ProductCategoryApi.baseUrl}/product/${id}`, payload) as Promise<ServiceResult<ProductDto>>;
    }

    public toggleProductVisible(id: number, isVisible: boolean): Promise<ServiceResult<ProductDto>> {
        return this.httpDelete(`${ProductCategoryApi.baseUrl}/product/${id}`, isVisible) as Promise<ServiceResult<ProductDto>>;
    }

    public reorderProducts(categoryId: number, payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${ProductCategoryApi.baseUrl}/${categoryId}/product/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }

    public createProductOption(productId: number, payload: ProductOptionPayload): Promise<ServiceResult<ProductOptionDto>> {
        return this.httpPut(`${ProductCategoryApi.baseUrl}/product/${productId}/option`, payload) as Promise<ServiceResult<ProductOptionDto>>;
    }

    public updateProductOption(id: number, payload: ProductOptionPayload): Promise<ServiceResult<ProductOptionDto>> {
        return this.httpPost(`${ProductCategoryApi.baseUrl}/product-option/${id}`, payload) as Promise<ServiceResult<ProductOptionDto>>;
    }

    public toggleProductOptionVisible(id: number, isVisible: boolean): Promise<ServiceResult<ProductOptionDto>> {
        return this.httpDelete(`${ProductCategoryApi.baseUrl}/product-option/${id}`, isVisible) as Promise<ServiceResult<ProductOptionDto>>;
    }

    public reorderProductOptions(productId: number, payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${ProductCategoryApi.baseUrl}/product/${productId}/options/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }
}
