import * as React from 'react';
import AboutUs from '../AboutUs';

export class AboutUsComponentEditor extends React.PureComponent {
    public render() {
        return (
            <AboutUs editing={true} />
        );
    }
}

export default AboutUsComponentEditor;
