import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as OxyStore from '../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CartItemDto } from '../../models';
import { CartType } from '../../models/shop';
import { Constants } from '../../utils';

interface CartSidebarOwnProps {
    isOpen: boolean;
    toggleOpen: () => void;
    userAuthenticated: boolean;
}

type CartSidebarProps = CartSidebarOwnProps
    & OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ id?: string }>;

interface CartSidebarState {
}

class CartSidebar extends React.PureComponent<CartSidebarProps, CartSidebarState> {
    constructor(props: CartSidebarProps) {
        super(props);

        this.state = {
        }
    }

    private currencyFormatter = new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    public render() {
        require("../../shop.css");

        const total = this.props.myCart.reduce((prev, curr) => {
            const currPrice = curr.product != null ? curr.product.price!! : curr.productOption!!.price;
            return prev + currPrice * curr.quantity;
        }, 0);

        return (
            <React.Fragment>
                {this.props.productCategories.length > 0 && <aside className={`cart-sidebar-outer-container ${this.props.isOpen ? "open" : "closed"}`}>
                    <div className="cart-sidebar-inner-container">
                        <div className="cart-sidebar-container">
                            <div className="cart-sidebar-header">
                                <h3>Kosár</h3>
                                <span className="cursor-pointer" onClick={this.props.toggleOpen}>
                                    <FontAwesomeIcon icon={["fas", "times"]} />
                                </span>
                            </div>
                            <div className="cart-sidebar-content-container">
                                <div className="cart-sidebar-content">
                                    {this.props.myCart.length > 0 && <div className="scrollbar-container ps">
                                        {this.props.myCart.map((cartItem, index) => {
                                            const product = cartItem.product != null ? cartItem.product : this.props.productCategories.find(x => x.products.find(y => y.id === cartItem.productOption!!.productId))!!.products.find(x => x.id === cartItem.productOption!!.productId)!!;
                                            return (
                                                <div key={index} className="cart-sidebar-product-container">
                                                    <Link to={`/shop/product/${product.id}`} className="cart-sidebar-product-image-container">
                                                        <div style={{ display: 'inline-block', maxWidth: '100%', overflow: 'hidden', position: 'relative', boxSizing: 'border-box', margin: '0px' }}>
                                                            <div style={{ boxSizing: 'border-box', display: 'block', maxWidth: '100%' }}>
                                                                <img aria-hidden="true" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEwIiBoZWlnaHQ9IjEyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4=" style={{ maxWidth: '100%', display: 'block', margin: '0px', border: 'none', padding: '0px' }} />
                                                            </div>
                                                            <img src={`/wp-content/uploads/new/${product.image.name}`} className="cart-sidebar-product-image" />
                                                        </div>
                                                    </Link>
                                                    <div className="cart-sidebar-product-details-container">
                                                        <div>
                                                            <Link to={`/shop/product/${product.id}`} className="cart-sidebar-product-details-name">{product.name}{cartItem.productOption != null && ` - ${cartItem.productOption.name}`}</Link>
                                                            <div className="cart-sidebar-product-amount">{cartItem.quantity} x <span className="cart-sidebar-product-price">{this.currencyFormatter.format(cartItem.productOption != null ? cartItem.productOption.price : cartItem.product!!.price!!)}</span></div>
                                                            <div className="cart-sidebar-product-amount-buttons-container">
                                                                <span className={cartItem.quantity > 1 ? "cursor-pointer" : "cursor-not-allowed"} onClick={() => this.decreaseMyCartItemQuantity(cartItem)}>
                                                                    <FontAwesomeIcon icon={["fas", "minus"]} />
                                                                </span>
                                                                <input
                                                                    className="cart-sidebar-product-amount-input"
                                                                    type="text"
                                                                    size={9}
                                                                    readOnly
                                                                    value={cartItem.quantity}
                                                                />
                                                                <span className="cursor-pointer" onClick={() => this.increaseMyCartItemQuantity(cartItem)}>
                                                                    <FontAwesomeIcon icon={["fas", "plus"]} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <span className="cursor-pointer" onClick={() => this.deleteItemFromMyCart(cartItem)}>
                                                            <FontAwesomeIcon icon={["fas", "times"]} />
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.props.myCart.length === 0 && <div className="empty-cart-message-container">
                                        <div className="empty-cart-icon">
                                            <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                                        </div>
                                        <div className="empty-cart-text">
                                            <h2>
                                                A kosár üres.
                                            </h2>
                                        </div>
                                    </div>}
                                </div>
                                <div className="cart-sidebar-footer">
                                    {this.props.myCart.length > 0 && <Link to="/shop/checkout">
                                        <span>Tovább</span>
                                        <span className="cart-sidebar-footer-checkout-price">{this.currencyFormatter.format(total)}</span>
                                    </Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cart-sidebar-footer-spacer" onClick={this.props.toggleOpen} />
                </aside>}
            </React.Fragment>
        );
    }

    private increaseMyCartItemQuantity = (cartItem: CartItemDto) => {
        if (this.props.cartType === CartType.Server) {
            this.props.increaseMyCartItemQuantity(cartItem.id);
        } else if (this.props.cartType === CartType.Client) {
            const cartFromLocalStorage = localStorage.getItem(Constants.webshopClientCartLocalStorageKey);
            const cartItemsParsed = (cartFromLocalStorage == null ? [] : JSON.parse(cartFromLocalStorage) as CartItemDto[])
                .map(item => {
                    if (item.product != null && cartItem.product != null && item.product.id === cartItem.product.id) {
                        return {
                            ...item,
                            quantity: item.quantity + 1
                        }
                    } else if (item.productOption != null && cartItem.productOption != null && item.productOption.id === cartItem.productOption.id) {
                        return {
                            ...item,
                            quantity: item.quantity + 1
                        }
                    } else {
                        return item;
                    }
                });

            localStorage.setItem(Constants.webshopClientCartLocalStorageKey, JSON.stringify(cartItemsParsed));
            this.props.setCartItemsRaw(cartItemsParsed);
        }
    }

    private decreaseMyCartItemQuantity = (cartItem: CartItemDto) => {
        if (cartItem.quantity > 1) {
            if (this.props.cartType === CartType.Server) {
                this.props.decreaseMyCartItemQuantity(cartItem.id);
            } else if (this.props.cartType === CartType.Client) {
                const cartFromLocalStorage = localStorage.getItem(Constants.webshopClientCartLocalStorageKey);
                const cartItemsParsed = (cartFromLocalStorage == null ? [] : JSON.parse(cartFromLocalStorage) as CartItemDto[])
                    .map(item => {
                        if (item.product != null && cartItem.product != null && item.product.id === cartItem.product.id) {
                            return {
                                ...item,
                                quantity: item.quantity - 1
                            }
                        } else if (item.productOption != null && cartItem.productOption != null && item.productOption.id === cartItem.productOption.id) {
                            return {
                                ...item,
                                quantity: item.quantity - 1
                            }
                        } else {
                            return item;
                        }
                    });

                localStorage.setItem(Constants.webshopClientCartLocalStorageKey, JSON.stringify(cartItemsParsed));
                this.props.setCartItemsRaw(cartItemsParsed);
            }
        }
    }

    private deleteItemFromMyCart = (cartItem: CartItemDto) => {
        if (this.props.cartType === CartType.Server) {
            this.props.deleteItemFromMyCart(cartItem.id);
        } else if (this.props.cartType === CartType.Client) {
            const cartFromLocalStorage = localStorage.getItem(Constants.webshopClientCartLocalStorageKey);
            const cartItemsParsed = (cartFromLocalStorage == null ? [] : JSON.parse(cartFromLocalStorage) as CartItemDto[])
                .filter(item => {
                    if (item.product != null && cartItem.product != null && item.product.id === cartItem.product.id) {
                        return false;
                    } else if (item.productOption != null && cartItem.productOption != null && item.productOption.id === cartItem.productOption.id) {
                        return false;
                    } else {
                        return true;
                    }
                });

            localStorage.setItem(Constants.webshopClientCartLocalStorageKey, JSON.stringify(cartItemsParsed));
            this.props.setCartItemsRaw(cartItemsParsed);
        }
    }
}

const mapStateToProps = (
    state: OxyStore.OxyState,
    ownProps: CartSidebarOwnProps
) => ({
    ...ownProps
});

export default connect(mapStateToProps)(CartSidebar);
