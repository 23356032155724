import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store/index';
import * as OxyStore from '../../../store/OxyStore';
import NavMenuShop from './../NavMenuShop';
import Footer from '../../Footer';
import ScrollToTop from '../../ScrollToTop';
import CouponCard from './CouponCard';
import { CouponDto } from '../../../models';

type CouponsProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface CouponsState {
    search: string;
    filteredCoupons: CouponDto[];
}

class Coupons extends React.PureComponent<CouponsProps, CouponsState> {
    constructor(props: CouponsProps) {
        super(props);

        this.state = {
            search: "",
            filteredCoupons: props.coupons
        }
    }

    componentDidMount() {
        if (this.props.coupons.length === 0) {
            this.props.getAllCoupons();
        }
    }

    componentWillReceiveProps(nextProps: CouponsProps) {
        if (this.props.coupons.length !== nextProps.coupons.length) {
            this.setState({
                search: "",
                filteredCoupons: nextProps.coupons
            })
        }
    }

    public render() {
        require("../../../shop.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="container checkout-main-container mb-4">
                    <h1>Kuponok</h1>
                    <div className="mt-2 mb-2">
                        <div className="form-group">
                            <input type="text" className="form-control" autoComplete="off" placeholder="Keresés..." value={this.state.search} onChange={this.handleSearchChange} />
                        </div>
                    </div>
                    {this.state.filteredCoupons.length === 0 && <div>
                        <h4>Nem található kupon.</h4>
                    </div>}
                    {this.state.filteredCoupons.length > 0 && <div className="list-group">
                        {this.state.filteredCoupons.map((coupon, index) => {
                            return (
                                <CouponCard
                                    key={index}
                                    coupon={coupon}
                                />
                            );
                        })}
                    </div>}
                </div>
                <Footer />
            </React.Fragment>
        );
    }

    private handleSearchChange = (e: any) => {
        const search = e.target.value;

        this.setState({
            search: search,
            filteredCoupons: this.filterCoupons(this.props.coupons, search)
        });
    }

    private filterCoupons = (coupons: CouponDto[], search: string): CouponDto[] => {
        search = search.toLowerCase();
        return coupons.filter(coupon => {
            if (coupon.sentTo.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if (coupon.code.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            return false;
        });
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(Coupons as any);
