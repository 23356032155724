import * as React from 'react';
import { ImageDto } from "../../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';

interface ImageCardProps {
    item: ImageDto;
    sharedProps?: {
        onImageClick?: () => void;
        onDeleteClick?: (id: number) => void;
    }
}

interface ImageCardState {
    deleteModalOpen: boolean;
}

class ImageCard extends React.PureComponent<ImageCardProps, ImageCardState> {
    constructor(props: ImageCardProps) {
        super(props);

        this.state = {
            deleteModalOpen: false
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div id={`image-admin-card-${this.props.item.id}`} style={{ maxWidth: "250px", minWidth: "250px", margin: "12px", padding: "15px", border: "1px solid black" }}>
                    <div style={{ height: "200px", minHeight: "200px", maxHeight: "200px" }}>
                        <img alt="" onClick={() => this.props.sharedProps != null && this.props.sharedProps.onImageClick != null && this.props.sharedProps.onImageClick()} style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "contain", overflow: "hidden" }} src={`/wp-content/uploads/new/${this.props.item.name}`} />
                    </div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {this.props.item.name}
                    </div>
                    {this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null && <div>
                        <Button onClick={this.handleDeleteClick} color="danger">
                            <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                        </Button>
                    </div>}
                    <Modal isOpen={this.state.deleteModalOpen} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                        <ModalHeader toggle={this.handleCancelDeleteClick}>Kép törlése</ModalHeader>
                        <ModalBody>
                            <div>
                                <p>
                                    Biztosan törölni szeretnéd a képet?
                                </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-4">
                                <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-primary">
                                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                                </button>

                                <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
                <UncontrolledTooltip key={this.props.item.id} placement='top' target={`image-admin-card-${this.props.item.id}`}>
                    {this.props.item.name}
                </UncontrolledTooltip>
            </React.Fragment>
        );
    }

    private handleDeleteClick = () => {
        this.setState({
            deleteModalOpen: true
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteModalOpen: false
        });
    }

    private handleConfirmDeleteClick = () => {
        if (this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null) {
            this.props.sharedProps.onDeleteClick(this.props.item.id);
            this.setState({
                deleteModalOpen: false
            });
        }
    }
}

export default ImageCard;
