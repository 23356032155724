import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store/index";
import * as OxyStore from "../store/OxyStore";
import { QuillEditorUtils } from "../utils";
import { NavMenu } from "./NavMenu";
import { Footer } from "./Footer";
import ImageWithTextSideBySideItem, {
  ImagePosition,
} from "./ImageWithTextSideBySideItem";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  PageBulletPointListWithTitlePayload,
  PageImageWithTextSideBySidePayload,
  PageTitleWithTextSectionPayload,
  Section8ImagePayload,
} from "../payloads";
import AdminEditorBadge, {
  AdminEditorBadgePosition,
} from "./admin/AdminEditorBadge";
import { ImagePickerModal } from "./admin/ImagePickerModal";
import NavMenuAdmin from "./admin/NavMenuAdmin";
import AdminBackButton from "./admin/AdminBackButton";
import { isMobileOnly } from "react-device-detect";
import GalleryBigImage from "./GalleryBigImage";
import Gallery from "./Gallery";
import GalleryMobile from "./GalleryMobile";
import GalleryEditorModal from "./admin/GalleryEditorModal";
import OxyScrollingPageComponent from "./OxyScrollingPageComponent";

interface OxyPageComponentOwnProps {
  editing: boolean | null | undefined;
  path?: string;
}

type OxyPageComponentProps = OxyStore.OxyState &
  typeof OxyStore.actionCreators &
  RouteComponentProps<{ path: string }> &
  OxyPageComponentOwnProps;

interface OxyPageComponentState {
  validationErrorsForEdit: string[];
  adminEntitiesLoaded: boolean;
  section1EditorOpen: boolean;
  section2EditorOpen: boolean;
  section3EditorOpen: boolean;
  section4EditorOpen: boolean;
  section4ImagePickerOpen: number;
  section5EditorOpen: boolean;
  section6EditorOpen: boolean;
  section7EditorOpen: boolean;
  section8EditorOpen: boolean;
  section8ImagePickerOpen: number;
  section9EditorOpen: boolean;
  section10EditorOpen: boolean;
  section1ValidationErrors: string[];
  section3ValidationErrors: string[];
  section4ValidationErrors: string[];
  section5ValidationErrors: string[];
  section7ValidationErrors: string[];
  section8ValidationErrors: string[];
  section9ValidationErrors: string[];
  section10ValidationErrors: string[];
  section1Payload: PageTitleWithTextSectionPayload;
  section3IsVisible: boolean;
  section3Payload: PageBulletPointListWithTitlePayload;
  section4IsVisible: boolean;
  section4Payload: PageImageWithTextSideBySidePayload;
  section5IsVisible: boolean;
  section5Text: string;
  section7IsVisible: boolean;
  section7Text: string;
  section8IsVisible: boolean;
  section8Payload: Section8ImagePayload[];
  section9IsVisible: boolean;
  section9Text: string;
  section10IsVisible: boolean;
  section10Payload: PageBulletPointListWithTitlePayload;
}

class OxyPageComponent extends React.PureComponent<
  OxyPageComponentProps,
  OxyPageComponentState
> {
  constructor(props: OxyPageComponentProps) {
    super(props);

    this.state = {
      validationErrorsForEdit: [],
      adminEntitiesLoaded: false,
      section1EditorOpen: false,
      section2EditorOpen: false,
      section3EditorOpen: false,
      section4EditorOpen: false,
      section4ImagePickerOpen: NaN,
      section5EditorOpen: false,
      section6EditorOpen: false,
      section7EditorOpen: false,
      section8EditorOpen: false,
      section8ImagePickerOpen: NaN,
      section9EditorOpen: false,
      section10EditorOpen: false,
      section1ValidationErrors: [],
      section3ValidationErrors: [],
      section4ValidationErrors: [],
      section5ValidationErrors: [],
      section7ValidationErrors: [],
      section8ValidationErrors: [],
      section9ValidationErrors: [],
      section10ValidationErrors: [],
      section1Payload: {
        title: "",
        subTitle: "",
        subText: "",
        isTitleAlignedCenter: false,
      },
      section3IsVisible: false,
      section3Payload: {
        title: "",
        items: [],
      },
      section4IsVisible: false,
      section4Payload: {
        items: [],
      },
      section5IsVisible: false,
      section5Text: "",
      section7IsVisible: false,
      section7Text: "",
      section8IsVisible: false,
      section8Payload: [],
      section9IsVisible: false,
      section9Text: "",
      section10IsVisible: false,
      section10Payload: {
        title: "",
        items: [],
      },
    };
  }

  public componentDidMount() {
    const pagePath: string = this.props.path || this.props.match.params.path;

    if (pagePath != null && pagePath.length > 0) {
      this.props.getPageByPath(pagePath);
      this.props.getScrollingPageByPath(pagePath);
    }

    (window as any).avadaLightBox &&
      (window as any).avadaLightBox.initialize_lightbox();
  }

  componentWillReceiveProps(nextProps: OxyPageComponentProps) {
    if (nextProps.loggedInUser != null && !this.state.adminEntitiesLoaded) {
      this.setState(
        {
          adminEntitiesLoaded: true,
        },
        () => {
          const isAdmin =
            nextProps.loggedInUser != null && nextProps.loggedInUser.isAdmin;
          const isAdminAndEditing = this.props.editing && isAdmin;
          if (isAdminAndEditing) {
            this.props.getImages();
            this.props.getPages();
            this.props.getScrollingPages();
          }
        }
      );
    }

    if (
      (this.props.currentPage == null && nextProps.currentPage != null) ||
      (nextProps.currentPage != null &&
        !this.props.pageOperationFinished &&
        nextProps.pageOperationFinished)
    ) {
      const currentPage = nextProps.currentPage;

      this.setState({
        section1Payload: {
          title: currentPage.section1Title,
          subTitle: currentPage.section1SubTitle,
          subText: currentPage.section1SubText,
          isTitleAlignedCenter: currentPage.isSection1TitleAlignedCenter,
        },
        section3IsVisible: currentPage.section3Visible,
        section3Payload: {
          title: currentPage.section3Title,
          items: currentPage.section3Items.map((x) => x.text),
        },
        section4IsVisible: currentPage.section4Visible,
        section4Payload: {
          items: currentPage.section4Items.map((x) => {
            return {
              imageId: x.image.id,
              text: x.text,
              crop: {
                x: 0,
                y: 0,
                width: 100,
                height: 100,
              },
              scrollingPageId:
                x.scrollingPage == null ? undefined : x.scrollingPage.id,
            };
          }),
        },
        section5IsVisible: currentPage.section6Visible,
        section5Text: currentPage.section5Text,
        section7IsVisible: currentPage.section7Visible,
        section7Text: currentPage.section7Text,
        section8IsVisible: currentPage.section8Visible,
        section8Payload: currentPage.section8Images.map((item) => {
          return {
            imageId: item.image.id,
            crop: {
              x: 0,
              y: 0,
              width: 100,
              height: 100,
            },
          };
        }),
        section9IsVisible: currentPage.section9Visible,
        section9Text: currentPage.section9Text,
        section10IsVisible: currentPage.section10Visible,
        section10Payload: {
          title: currentPage.section10Title,
          items: currentPage.section10Items.map((x) => x.text),
        },
      });
    }
  }

  public render() {
    if (
      this.props.currentPage !== undefined &&
      this.props.currentScrollingPage !== undefined
    ) {
      if (
        this.props.currentPage == null &&
        this.props.currentScrollingPage == null
      ) {
        window.location.href = "/";
        return null;
      }

      if (
        this.props.currentPage == null &&
        this.props.currentScrollingPage != null
      ) {
        return <OxyScrollingPageComponent {...this.props} />;
      }
    }

    const user = this.props.loggedInUser;
    const isAdmin = user != null && user.isAdmin;
    const isAdminAndEditing = this.props.editing && isAdmin;

    return (
      <div id="boxed-wrapper">
        <div className="fusion-sides-frame"></div>
        <div id="wrapper" className="fusion-wrapper">
          <div id="home" style={{ position: "relative", top: "-1px" }}></div>
          <div id="sliders-container"></div>

          {!isAdminAndEditing && <NavMenu {...this.props} />}
          {isAdminAndEditing && <NavMenuAdmin />}
          {isAdminAndEditing && <AdminBackButton to="/admin/pages" />}

          <div>
            {this.props.currentPage != null && (
              <main
                id="main"
                className="clearfix width-100"
                style={{ marginBottom: "485px" }}
              >
                <div className="fusion-row" style={{ maxWidth: "100%" }}>
                  <section id="content" className="full-width">
                    <div
                      id="post-5"
                      className="post-5 page type-page status-publish hentry"
                    >
                      <div className="post-content">
                        <React.Fragment>
                          <div className="image-with-gradient-border" />
                          <div
                            className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "rgba(204,204,204,0.05)",
                              backgroundImage: `url("/wp-content/uploads/new/${this.props.currentPage.mainImage.name}")`,
                              backgroundPosition: "center 0px",
                              backgroundRepeat: "no-repeat",
                              padding: "0",
                              marginBottom: "0px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                              WebkitBackgroundSize: isMobileOnly
                                ? "cover"
                                : "cover",
                              MozBackgroundSize: isMobileOnly
                                ? "cover"
                                : "cover",
                              OBackgroundSize: isMobileOnly ? "cover" : "cover",
                              backgroundSize: isMobileOnly ? "cover" : "cover",
                              height: isMobileOnly ? "122px" : "540px",
                            }}
                          ></div>
                          <div className="image-with-gradient-border" />
                        </React.Fragment>
                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundColor: "rgba(188,151,120,0.1)",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            paddingTop: "0px",
                            paddingRight: "30px",
                            paddingBottom: "0px",
                            paddingLeft: "30px",
                            marginBottom: "0px",
                            marginTop: "0px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="fusion-builder-row fusion-row">
                            {isAdminAndEditing && (
                              <AdminEditorBadge
                                position={AdminEditorBadgePosition.TopRight}
                                onClick={this.toggleSection1Editor}
                              />
                            )}
                            <div
                              className="fusion-layout-column fusion_builder_column fusion-builder-column-25 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                              style={{
                                marginTop: "50px",
                                marginBottom: this.props.currentPage
                                  .section2Visible
                                  ? "0"
                                  : this.props.currentPage.section3Visible
                                  ? "-24px"
                                  : "24px",
                              }}
                            >
                              <div
                                className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                style={{
                                  backgroundPosition: "left top",
                                  backgroundRepeat: "no-repeat",
                                  WebkitBackgroundSize: "cover",
                                  MozBackgroundSize: "cover",
                                  OBackgroundSize: "cover",
                                  backgroundSize: "cover",
                                  padding: "0px 0px 0px 0px",
                                }}
                              >
                                <div
                                  className="fusion-text fusion-text-19"
                                  style={{
                                    textAlign: this.props.currentPage
                                      .isSection1TitleAlignedCenter
                                      ? "center"
                                      : "start",
                                  }}
                                >
                                  <h1>
                                    <strong style={{ wordBreak: "break-word" }}>
                                      {this.props.currentPage.section1Title
                                        .split("\n")
                                        .map((row, index) => (
                                          <React.Fragment key={index}>
                                            {row}
                                            {index <
                                            this.props.currentPage!!.section1Title.split(
                                              "\n"
                                            ).length -
                                              1 ? (
                                              <br />
                                            ) : (
                                              ""
                                            )}
                                          </React.Fragment>
                                        ))}
                                    </strong>
                                  </h1>
                                </div>
                                {this.props.currentPage.section1SubTitle.replace(
                                  /(<\/?[^>]+(>|$)|&nbsp;|\s)/g,
                                  ""
                                ).length > 0 && (
                                  <div
                                    className="fusion-text fusion-text-36 oxy-text-justified"
                                    style={{ display: "block" }}
                                  >
                                    <p className="m-5481381658492837685p3">
                                      <span
                                        className="m-5481381658492837685s2 standard-text"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            this.props.currentPage
                                              .section1SubTitle,
                                        }}
                                      />
                                    </p>
                                  </div>
                                )}
                                {this.props.currentPage.section1SubText.replace(
                                  /(<\/?[^>]+(>|$)|&nbsp;|\s)/g,
                                  ""
                                ).length > 0 && (
                                  <div className="fusion-text fusion-text-34 standard-text oxy-text-justified">
                                    <h4
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          this.props.currentPage
                                            .section1SubText,
                                      }}
                                    />
                                  </div>
                                )}
                                <div className="fusion-clearfix" />
                              </div>
                            </div>
                          </div>
                        </div>

                        {isAdminAndEditing &&
                          (!this.props.currentPage.section2Visible ||
                            this.props.currentPage.section2Gallery.images
                              .length === 0) && (
                            <div
                              className="container"
                              style={{ position: "relative", height: "200px" }}
                            >
                              <AdminEditorBadge
                                position={AdminEditorBadgePosition.TopRight}
                                onClick={this.toggleSection2Editor}
                              />
                            </div>
                          )}
                        {this.props.currentPage.section2Visible &&
                          this.props.currentPage.section2Gallery.images.length >
                            0 && (
                            <GalleryBigImage
                              isAdminAndEditing={isAdminAndEditing === true}
                              toggleEditor={this.toggleSection2Editor}
                              image={
                                this.props.currentPage.section2Gallery.images[0]
                                  .image
                              }
                            />
                          )}
                        {this.props.currentPage.section2Visible &&
                          this.props.currentPage.section2Gallery.images.length >
                            1 &&
                          !isMobileOnly && (
                            <Gallery
                              gallery={this.props.currentPage.section2Gallery}
                            />
                          )}
                        {this.props.currentPage.section2Visible &&
                          this.props.currentPage.section2Gallery.images.length >
                            0 &&
                          isMobileOnly && (
                            <GalleryMobile
                              gallery={this.props.currentPage.section2Gallery}
                            />
                          )}

                        {(this.props.currentPage.section3Visible ||
                          isAdminAndEditing) && (
                          <div
                            className="fusion-fullwidth fusion-fullwidth-custom-background fullwidth-box fusion-builder-row-8 hundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "rgb(232,218,200)",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              paddingTop: "0px",
                              paddingRight: "30px",
                              paddingBottom: "0px",
                              paddingLeft: "30px",
                              marginBottom:
                                (!this.props.currentPage.section5Visible &&
                                  this.props.currentPage.section6Visible) ||
                                (!this.props.currentPage.section5Visible &&
                                  !this.props.currentPage.section6Visible &&
                                  !this.props.currentPage.section7Visible &&
                                  this.props.currentPage.section8Visible) ||
                                (!this.props.currentPage.section5Visible &&
                                  !this.props.currentPage.section6Visible &&
                                  !this.props.currentPage.section7Visible &&
                                  !this.props.currentPage.section8Visible &&
                                  !this.props.currentPage.section9Visible &&
                                  !this.props.currentPage.section10Visible)
                                  ? "0"
                                  : "30px",
                              marginTop: this.props.currentPage.section2Visible
                                ? "0"
                                : "40px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                            }}
                          >
                            <div className="fusion-builder-row fusion-row">
                              {isAdminAndEditing && (
                                <AdminEditorBadge
                                  position={AdminEditorBadgePosition.TopRight}
                                  onClick={this.toggleSection3Editor}
                                />
                              )}
                              <div
                                className={`fusion-layout-column fusion_builder_column fusion-builder-column-13 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last ${
                                  !this.props.currentPage.section3Visible &&
                                  isAdminAndEditing
                                    ? "admin-editing-blurred"
                                    : ""
                                }`}
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "20px",
                                }}
                              >
                                <div
                                  className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                  style={{
                                    backgroundPosition: "left top",
                                    backgroundRepeat: "no-repeat",
                                    WebkitBackgroundSize: "cover",
                                    MozBackgroundSize: "cover",
                                    OBackgroundSize: "cover",
                                    backgroundSize: "cover",
                                    padding: "15px 0px 0px 0px",
                                  }}
                                >
                                  <div className="fusion-column-content-centered">
                                    <div className="fusion-column-content">
                                      <div
                                        style={{
                                          textAlign: "center",
                                          marginTop: "24px",
                                        }}
                                        className="fusion-text fusion-text-36"
                                      >
                                        <p className="m-5481381658492837685p3">
                                          <strong>
                                            <span
                                              className="m-5481381658492837685s2"
                                              style={{
                                                fontSize: "25px",
                                                letterSpacing: "4px",
                                                color: "#b18b5e",
                                              }}
                                            >
                                              {
                                                this.props.currentPage
                                                  .section3Title
                                              }
                                            </span>
                                          </strong>
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        className="fusion-text fusion-text-34"
                                      >
                                        <div>
                                          <h4
                                            style={{
                                              fontSize: "25px",
                                              fontWeight: 400,
                                              letterSpacing: "4px",
                                            }}
                                          >
                                            {this.props.currentPage.section3Items.map(
                                              (bulletPointListItem, index) => {
                                                const iconType =
                                                  index % 2 === 0
                                                    ? "far"
                                                    : "fas";
                                                return (
                                                  <React.Fragment key={index}>
                                                    <span
                                                      style={{
                                                        fontSize: "17.6px",
                                                        height: "34px",
                                                        width: "34px",
                                                        marginRight: "14px",
                                                      }}
                                                      className="icon-wrapper heart"
                                                    >
                                                      <i
                                                        className={`fusion-li-icon fa-heart ${iconType}`}
                                                        style={{
                                                          color: "#b18b5e",
                                                        }}
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                    {bulletPointListItem.text}
                                                    {index <
                                                      this.props.currentPage!!
                                                        .section3Items.length -
                                                        1 && <br />}
                                                  </React.Fragment>
                                                );
                                              }
                                            )}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fusion-clearfix" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(this.props.currentPage.section4Visible ||
                          isAdminAndEditing) && (
                          <React.Fragment>
                            {this.props.currentPage.section4Items.map(
                              (item, index) => (
                                <ImageWithTextSideBySideItem
                                  key={index}
                                  item={item}
                                  isVisible={
                                    this.props.currentPage!!.section4Visible
                                  }
                                  imagePosition={
                                    index % 2 === 0
                                      ? ImagePosition.Left
                                      : ImagePosition.Right
                                  }
                                  marginTop={index === 0 ? undefined : "24px"}
                                  marginBottom={
                                    index ===
                                    this.props.currentPage!!.section4Items
                                      .length -
                                      1
                                      ? "10px"
                                      : undefined
                                  }
                                  isAdminAndEditing={isAdminAndEditing === true}
                                  toggleEditor={
                                    index === 0
                                      ? this.toggleSection4Editor
                                      : undefined
                                  }
                                />
                              )
                            )}
                          </React.Fragment>
                        )}

                        {isAdminAndEditing &&
                          this.props.currentPage.section4Items.length === 0 && (
                            <div
                              style={{ position: "relative", height: "170px" }}
                            >
                              <AdminEditorBadge
                                position={AdminEditorBadgePosition.TopRight}
                                onClick={this.toggleSection4Editor}
                              />
                            </div>
                          )}

                        {(this.props.currentPage.section5Visible ||
                          isAdminAndEditing) && (
                          <div
                            className="fusion-fullwidth fusion-fullwidth-custom-background fullwidth-box fusion-builder-row-3 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "#b18b5e",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              paddingTop: "0px",
                              paddingRight: "30px",
                              paddingBottom: "0px",
                              paddingLeft: "30px",
                              marginBottom: "0px",
                              marginTop: "0px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                            }}
                          >
                            <div className="fusion-builder-row fusion-row">
                              {isAdminAndEditing && (
                                <AdminEditorBadge
                                  position={AdminEditorBadgePosition.TopRight}
                                  onClick={this.toggleSection5Editor}
                                />
                              )}
                              <div
                                className={`fusion-layout-column fusion_builder_column fusion-builder-column-4 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last ${
                                  !this.props.currentPage.section5Visible &&
                                  isAdminAndEditing
                                    ? "admin-editing-blurred"
                                    : ""
                                }`}
                                style={{
                                  marginTop:
                                    this.props.currentPage.section4Visible &&
                                    !this.props.currentPage.section5Visible
                                      ? "30px"
                                      : "20px",
                                  marginBottom: "20px",
                                }}
                              >
                                <div
                                  className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                  style={{
                                    backgroundPosition: "left top",
                                    backgroundRepeat: "no-repeat",
                                    WebkitBackgroundSize: "cover",
                                    MozBackgroundSize: "cover",
                                    OBackgroundSize: "cover",
                                    backgroundSize: "cover",
                                    padding: "0px 0px 0px 0px",
                                  }}
                                >
                                  <div className="fusion-text fusion-text-2">
                                    <h2 style={{ textAlign: "center" }}>
                                      <span style={{ color: "#ffffff" }}>
                                        <strong>
                                          {this.props.currentPage.section5Text
                                            .split("\n")
                                            .map((row, index) => (
                                              <React.Fragment key={index}>
                                                {row}
                                                {index <
                                                this.props.currentPage!!.section5Text.split(
                                                  "\n"
                                                ).length -
                                                  1 ? (
                                                  <br />
                                                ) : (
                                                  ""
                                                )}
                                              </React.Fragment>
                                            ))}
                                        </strong>
                                      </span>
                                      <br />
                                    </h2>
                                  </div>
                                  <div className="fusion-clearfix" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {isAdminAndEditing &&
                          (!this.props.currentPage.section6Visible ||
                            this.props.currentPage.section6Gallery.images
                              .length === 0) && (
                            <div
                              className="container"
                              style={{ position: "relative", height: "200px" }}
                            >
                              <AdminEditorBadge
                                position={AdminEditorBadgePosition.TopRight}
                                onClick={this.toggleSection6Editor}
                              />
                            </div>
                          )}
                        {this.props.currentPage.section6Visible &&
                          this.props.currentPage.section6Gallery.images.length >
                            0 && (
                            <GalleryBigImage
                              isAdminAndEditing={isAdminAndEditing === true}
                              toggleEditor={this.toggleSection6Editor}
                              image={
                                this.props.currentPage.section6Gallery.images[0]
                                  .image
                              }
                            />
                          )}
                        {this.props.currentPage.section6Visible &&
                          this.props.currentPage.section6Gallery.images.length >
                            1 &&
                          !isMobileOnly && (
                            <Gallery
                              gallery={this.props.currentPage.section6Gallery}
                            />
                          )}
                        {this.props.currentPage.section6Visible &&
                          this.props.currentPage.section6Gallery.images.length >
                            0 &&
                          isMobileOnly && (
                            <GalleryMobile
                              gallery={this.props.currentPage.section6Gallery}
                            />
                          )}

                        {(this.props.currentPage.section7Visible ||
                          isAdminAndEditing) && (
                          <div
                            className="fusion-fullwidth fusion-fullwidth-custom-background fullwidth-box fusion-builder-row-3 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "#b18b5e",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              paddingTop: "0px",
                              paddingRight: "30px",
                              paddingBottom: "0px",
                              paddingLeft: "30px",
                              marginBottom: this.props.currentPage
                                .section8Visible
                                ? "30px"
                                : "0",
                              marginTop: this.props.currentPage.section6Visible
                                ? "0"
                                : this.props.currentPage.section4Visible &&
                                  !this.props.currentPage.section5Visible &&
                                  !this.props.currentPage.section6Visible &&
                                  !this.props.currentPage.section7Visible
                                ? "0"
                                : "20px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                            }}
                          >
                            <div className="fusion-builder-row fusion-row">
                              {isAdminAndEditing && (
                                <AdminEditorBadge
                                  position={AdminEditorBadgePosition.TopRight}
                                  onClick={this.toggleSection7Editor}
                                />
                              )}
                              <div
                                className={`fusion-layout-column fusion_builder_column fusion-builder-column-4 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last ${
                                  !this.props.currentPage.section7Visible &&
                                  isAdminAndEditing
                                    ? "admin-editing-blurred"
                                    : ""
                                }`}
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "20px",
                                }}
                              >
                                <div
                                  className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                  style={{
                                    backgroundPosition: "left top",
                                    backgroundRepeat: "no-repeat",
                                    WebkitBackgroundSize: "cover",
                                    MozBackgroundSize: "cover",
                                    OBackgroundSize: "cover",
                                    backgroundSize: "cover",
                                    padding: "0px 0px 0px 0px",
                                  }}
                                >
                                  <div className="fusion-text fusion-text-2">
                                    <h2 style={{ textAlign: "center" }}>
                                      <span style={{ color: "#ffffff" }}>
                                        <strong>
                                          {this.props.currentPage.section7Text
                                            .split("\n")
                                            .map((row, index) => (
                                              <React.Fragment key={index}>
                                                {row}
                                                {index <
                                                this.props.currentPage!!.section7Text.split(
                                                  "\n"
                                                ).length -
                                                  1 ? (
                                                  <br />
                                                ) : (
                                                  ""
                                                )}
                                              </React.Fragment>
                                            ))}
                                        </strong>
                                      </span>
                                      <br />
                                    </h2>
                                  </div>
                                  <div className="fusion-clearfix" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(this.props.currentPage.section8Visible ||
                          isAdminAndEditing) && (
                          <div
                            className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "rgba(188,151,120,0.1)",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              paddingTop: "0px",
                              paddingRight: "30px",
                              paddingBottom: "0px",
                              paddingLeft: "30px",
                              marginBottom:
                                this.props.currentPage.section8Visible &&
                                !this.props.currentPage.section9Visible
                                  ? "30px"
                                  : "0px",
                              marginTop: "0px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                              minWidth:
                                !this.props.currentPage.section3Visible &&
                                isAdminAndEditing
                                  ? "75%"
                                  : "",
                            }}
                          >
                            {isAdminAndEditing && (
                              <AdminEditorBadge
                                position={AdminEditorBadgePosition.TopRight}
                                onClick={this.toggleSection8Editor}
                              />
                            )}
                            <div
                              className={`fusion-builder-row fusion-row ${
                                !this.props.currentPage.section8Visible &&
                                isAdminAndEditing
                                  ? "admin-editing-blurred"
                                  : ""
                              }`}
                              style={{
                                display: "flex",
                                maxWidth: isMobileOnly
                                  ? "unset"
                                  : "calc(100% - 100px)",
                                flexWrap: isMobileOnly ? "wrap" : "unset",
                              }}
                            >
                              {this.props.currentPage.section8Images.map(
                                (image, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        className="fusion-imageframe imageframe-dropshadow imageframe-1 hover-type-zoomin"
                                        style={{
                                          WebkitBoxShadow:
                                            "3px 3px 7px rgba(0,0,0,0.3)",
                                          boxShadow:
                                            "3px 3px 7px rgba(0,0,0,0.3)",
                                          marginLeft: "10px",
                                          marginRight: "10px",
                                          flex: isMobileOnly ? "unset" : "1",
                                          marginBottom:
                                            isMobileOnly &&
                                            index <
                                              this.props.currentPage!!
                                                .section8Images.length -
                                                1
                                              ? "10px"
                                              : "unset",
                                          width: isMobileOnly
                                            ? "100%"
                                            : "unset",
                                        }}
                                      >
                                        <a
                                          href={`/wp-content/uploads/new/${image.image.name}`}
                                          className="fusion-lightbox"
                                          data-rel="iLightbox[46d7c3f3fc1a077d728]"
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={`/wp-content/uploads/new/${image.image.name}`}
                                            className="img-responsive wp-image-18057"
                                            srcSet={`/wp-content/uploads/new/${image.image.name} 200w, /wp-content/uploads/new/${image.image.name} 400w, /wp-content/uploads/new/${image.image.name} 600w, /wp-content/uploads/new/${image.image.name} 800w, /wp-content/uploads/new/${image.image.name} 1200w, /wp-content/uploads/new/${image.image.name} 2050w`}
                                            sizes="(max-width: 800px) 100vw, 1200px"
                                            style={{
                                              flexShrink: 0,
                                              minWidth: "100%",
                                              minHeight: "100%",
                                              objectFit: "cover",
                                              height: isMobileOnly
                                                ? "200px"
                                                : "unset",
                                            }}
                                          />
                                        </a>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}

                        {(this.props.currentPage.section9Visible ||
                          isAdminAndEditing) && (
                          <div
                            className="fusion-fullwidth fullwidth-box fusion-builder-row-2 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "rgba(188,151,120,0.1)",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              paddingTop: "0px",
                              paddingRight: "30px",
                              paddingBottom: "0px",
                              paddingLeft: "30px",
                              marginBottom: "0px",
                              marginTop: "0px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                            }}
                          >
                            <div className="fusion-builder-row fusion-row">
                              {isAdminAndEditing && (
                                <AdminEditorBadge
                                  position={AdminEditorBadgePosition.TopRight}
                                  onClick={this.toggleSection9Editor}
                                />
                              )}
                              <div
                                className={`fusion-layout-column fusion_builder_column fusion-builder-column-25 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last ${
                                  !this.props.currentPage.section9Visible &&
                                  isAdminAndEditing
                                    ? "admin-editing-blurred"
                                    : ""
                                }`}
                                style={{
                                  marginTop:
                                    !this.props.currentPage.section4Visible &&
                                    !this.props.currentPage.section5Visible &&
                                    !this.props.currentPage.section6Visible &&
                                    !this.props.currentPage.section7Visible &&
                                    !this.props.currentPage.section8Visible
                                      ? "-24px"
                                      : "30px",
                                  marginBottom: "30px",
                                }}
                              >
                                <div
                                  className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                  style={{
                                    backgroundPosition: "left top",
                                    backgroundRepeat: "no-repeat",
                                    WebkitBackgroundSize: "cover",
                                    MozBackgroundSize: "cover",
                                    OBackgroundSize: "cover",
                                    backgroundSize: "cover",
                                    padding: "0px 0px 0px 0px",
                                  }}
                                >
                                  <div className="fusion-text fusion-text-34 standard-text oxy-text-justified">
                                    <h4
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          this.props.currentPage.section9Text,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(this.props.currentPage.section10Visible ||
                          isAdminAndEditing) && (
                          <div
                            className="fusion-fullwidth fusion-fullwidth-custom-background fullwidth-box fusion-builder-row-8 hundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "rgb(232,218,200)",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              paddingTop: "0px",
                              paddingRight: "30px",
                              paddingBottom: "0px",
                              paddingLeft: "30px",
                              marginBottom: "0",
                              marginTop:
                                this.props.currentPage.section7Visible &&
                                !this.props.currentPage.section8Visible &&
                                !this.props.currentPage.section9Visible
                                  ? "30px"
                                  : "0px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                            }}
                          >
                            <div className="fusion-builder-row fusion-row">
                              {isAdminAndEditing && (
                                <AdminEditorBadge
                                  position={AdminEditorBadgePosition.TopRight}
                                  onClick={this.toggleSection10Editor}
                                />
                              )}
                              <div
                                className={`fusion-layout-column fusion_builder_column fusion-builder-column-13 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last ${
                                  !this.props.currentPage.section10Visible &&
                                  isAdminAndEditing
                                    ? "admin-editing-blurred"
                                    : ""
                                }`}
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "30px",
                                }}
                              >
                                <div
                                  className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                  style={{
                                    backgroundPosition: "left top",
                                    backgroundRepeat: "no-repeat",
                                    WebkitBackgroundSize: "cover",
                                    MozBackgroundSize: "cover",
                                    OBackgroundSize: "cover",
                                    backgroundSize: "cover",
                                    padding: "15px 0px 0px 0px",
                                  }}
                                >
                                  <div className="fusion-column-content-centered">
                                    <div className="fusion-column-content">
                                      <div
                                        style={{
                                          textAlign: "center",
                                          marginTop: "24px",
                                        }}
                                        className="fusion-text fusion-text-36"
                                      >
                                        <p className="m-5481381658492837685p3">
                                          <strong>
                                            <span
                                              className="m-5481381658492837685s2"
                                              style={{
                                                fontSize: "25px",
                                                letterSpacing: "4px",
                                                color: "#b18b5e",
                                              }}
                                            >
                                              {
                                                this.props.currentPage
                                                  .section10Title
                                              }
                                            </span>
                                          </strong>
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        className="fusion-text fusion-text-34"
                                      >
                                        <div>
                                          <h4
                                            style={{
                                              fontSize: "25px",
                                              fontWeight: 400,
                                              letterSpacing: "4px",
                                            }}
                                          >
                                            {this.props.currentPage.section10Items.map(
                                              (bulletPointListItem, index) => {
                                                return (
                                                  <React.Fragment key={index}>
                                                    <span
                                                      style={{
                                                        fontSize: "17.6px",
                                                        height: "34px",
                                                        width: "34px",
                                                        marginRight: "14px",
                                                      }}
                                                      className="icon-wrapper heart"
                                                    >
                                                      <i
                                                        className="fusion-li-icon fa-hand-point-right fas"
                                                        style={{
                                                          color: "#b18b5e",
                                                        }}
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                    {bulletPointListItem.text}
                                                    {index <
                                                      this.props.currentPage!!
                                                        .section10Items.length -
                                                        1 && <br />}
                                                  </React.Fragment>
                                                );
                                              }
                                            )}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fusion-clearfix" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundImage:
                              'url("/wp-content/uploads/new/hifu-img-2-test.png")',
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            paddingTop: "450px",
                            paddingRight: "30px",
                            paddingBottom: "100px",
                            paddingLeft: "30px",
                            marginBottom: "0px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                            WebkitBackgroundSize: "cover",
                            MozBackgroundSize: "cover",
                            OBackgroundSize: "cover",
                            backgroundSize: "cover",
                            marginTop: "0",
                          }}
                        >
                          <div
                            className="fusion-builder-row fusion-row"
                            style={{ marginTop: "-190px" }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{ padding: "0px 0px 0px 0px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  className="fusion-animated shake"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: isMobileOnly
                                      ? "column"
                                      : "row",
                                    backgroundColor:
                                      "rgba(215, 188, 178, 0.47)",
                                    padding: "16px",
                                    animationDuration: "1.6s",
                                  }}
                                  data-animationoffset="100%"
                                  data-animationtype="shake"
                                  data-animationduration="1.6"
                                >
                                  <div
                                    className="fusion-button-wrapper"
                                    style={{
                                      marginRight: isMobileOnly ? "0" : "60px",
                                      marginBottom: isMobileOnly ? "20px" : "0",
                                    }}
                                  >
                                    <style
                                      type="text/css"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                      }}
                                    />
                                    <a
                                      className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no "
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href="/shop"
                                      style={{
                                        width: isMobileOnly ? "100%" : "unset",
                                      }}
                                    >
                                      <span className="fusion-button-text">
                                        Vásárláshoz kattints ide!
                                      </span>
                                    </a>
                                  </div>
                                  <div className="fusion-button-wrapper">
                                    <style
                                      type="text/css"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1 {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder-radius: 25px;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.fusion-button.button-1.button-3d {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.button-1.button-3d:active {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-webkit-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t-moz-box-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbox-shadow: inset 0px 1px 0px #fff, 0px 3px 0px rgba(142, 94, 54, 0.68), 1px 5px 5px 3px rgba(0, 0, 0, 0.3);\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                      }}
                                    />
                                    <a
                                      className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no "
                                      href={
                                        this.props.currentPage.pricingPage ==
                                        null
                                          ? "/"
                                          : `/arak/${this.props.currentPage.pricingPage.path}`
                                      }
                                    >
                                      <span className="fusion-button-text">
                                        Árak megtekintéséhez kattints ide!
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <strong>
                          <div
                            className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              backgroundColor: "rgba(232,232,232,0.2)",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              paddingTop: "0px",
                              paddingRight: "30px",
                              paddingBottom: "0px",
                              paddingLeft: "30px",
                              marginBottom: "0px",
                              marginTop: "32px",
                              borderWidth: "0px 0px 0px 0px",
                              borderColor: "#f7f7f7",
                              borderStyle: "solid",
                            }}
                          >
                            <div className="fusion-builder-row fusion-row">
                              <div
                                className="fusion-fullwidth fullwidth-box fusion-builder-row-14 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                                style={{
                                  backgroundColor: "rgba(188,151,120,0.1)",
                                  backgroundPosition: "left top",
                                  backgroundRepeat: "no-repeat",
                                  paddingTop: "0px",
                                  paddingRight: "30px",
                                  paddingBottom: "0px",
                                  paddingLeft: "30px",
                                  marginBottom: "30px",
                                  borderWidth: "0px 0px 0px 0px",
                                  borderColor: "#f7f7f7",
                                  borderStyle: "solid",
                                }}
                              >
                                <div className="fusion-builder-row fusion-row">
                                  <div
                                    className="fusion-layout-column fusion_builder_column fusion-builder-column-40 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                                    style={{
                                      marginTop: "30px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <div
                                      className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                      style={{
                                        backgroundPosition: "left top",
                                        backgroundRepeat: "no-repeat",
                                        WebkitBackgroundSize: "cover",
                                        MozBackgroundSize: "cover",
                                        OBackgroundSize: "cover",
                                        backgroundSize: "cover",
                                        padding: "0px 0px 0px 0px",
                                      }}
                                    >
                                      <div
                                        className="fusion-text fusion-text-33 fusion-animated"
                                        style={{
                                          textAlign: "center",
                                          animationDuration: "2.5s",
                                        }}
                                        data-animationoffset="100%"
                                        data-animationtype="bounce"
                                        data-animationduration="2.5"
                                      >
                                        <h2 style={{ textAlign: "center" }}>
                                          <strong>
                                            Keress minket bizalommal:
                                          </strong>
                                          <br />
                                          Szépítészeid, az Oxy-s lányok
                                        </h2>
                                      </div>
                                      <div className="fusion-aligncenter">
                                        <a
                                          className="fusion-button button-3d fusion-button-default-size button-default button-1 fusion-button-span-no"
                                          href="https://oxy-esztetikai-studio.salonic.hu"
                                        >
                                          <span className="fusion-button-text">
                                            IDŐPONTFOGLALÁS
                                          </span>
                                        </a>
                                      </div>
                                      <div className="fusion-sep-clear" />
                                      <div
                                        className="fusion-separator"
                                        style={{
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          marginTop: "0px",
                                          marginBottom: "17px",
                                          width: "100%",
                                          maxWidth: "280px",
                                        }}
                                      ></div>
                                      <div className="fusion-sep-clear" />
                                      <div className="fusion-text fusion-text-34">
                                        <h4 style={{ textAlign: "center" }}>
                                          A szépség belülről fakad, de a
                                          gondoskodás rajtad múlik. Mi azért
                                          dolgozunk minden nap, hogy ebben a
                                          gondoskodásban segítsünk.
                                        </h4>
                                      </div>
                                      <div className="fusion-clearfix" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="fusion-fullwidth fullwidth-box fusion-builder-row-15 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                                style={{
                                  backgroundColor: "#ffffff",
                                  backgroundPosition: "center center",
                                  backgroundRepeat: "no-repeat",
                                  paddingTop: "0px",
                                  paddingRight: "30px",
                                  paddingBottom: "0px",
                                  paddingLeft: "30px",
                                  borderWidth: "0px 0px 0px 0px",
                                  borderColor: "#f7f7f7",
                                  borderStyle: "solid",
                                }}
                              >
                                <div className="fusion-builder-row fusion-row">
                                  <div
                                    className="fusion-layout-column fusion_builder_column fusion-builder-column-41 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-first"
                                    style={{
                                      width:
                                        "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                      marginRight: "4%",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <div
                                      className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                      style={{
                                        backgroundPosition: "left top",
                                        backgroundRepeat: "no-repeat",
                                        WebkitBackgroundSize: "cover",
                                        MozBackgroundSize: "cover",
                                        OBackgroundSize: "cover",
                                        backgroundSize: "cover",
                                        padding: "0px 0px 0px 0px",
                                      }}
                                    >
                                      <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                        <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                          <div
                                            className="fusion-flip-box flip-up"
                                            tabIndex={0}
                                          >
                                            <div className="flip-box-inner-wrapper">
                                              <div
                                                className="flip-box-front"
                                                style={{
                                                  marginLeft: isMobileOnly
                                                    ? "unset"
                                                    : "180px",
                                                  marginRight: isMobileOnly
                                                    ? "unset"
                                                    : "-190px",
                                                  backgroundColor: "#ffffff",
                                                  borderColor:
                                                    "rgba(255,255,255,0)",
                                                  borderRadius: "4px",
                                                  borderStyle: "solid",
                                                  borderWidth: "1px",
                                                  color: "#747474",
                                                  transitionDuration: "1.6s",
                                                }}
                                              >
                                                <div className="flip-box-front-inner">
                                                  <div
                                                    className="flip-box-grafix flip-box-circle"
                                                    style={{
                                                      backgroundColor:
                                                        "#b18b5e",
                                                      borderColor: "#ebe1e1",
                                                    }}
                                                  >
                                                    <i
                                                      aria-hidden="true"
                                                      className="fa-envelope far"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    />
                                                  </div>
                                                  <h2
                                                    className="flip-box-heading"
                                                    style={{ color: "#333333" }}
                                                  >
                                                    Írj nekünk!
                                                  </h2>
                                                  oxyesztetika@gmail.com
                                                  <br />
                                                  &nbsp;
                                                </div>
                                              </div>
                                              <div
                                                className="flip-box-back"
                                                style={{
                                                  marginLeft: isMobileOnly
                                                    ? "unset"
                                                    : "180px",
                                                  marginRight: isMobileOnly
                                                    ? "unset"
                                                    : "-190px",
                                                  backgroundColor: "#b18b5e",
                                                  borderColor:
                                                    "rgba(255,255,255,0)",
                                                  borderRadius: "4px",
                                                  borderStyle: "solid",
                                                  borderWidth: "1px",
                                                  color: "#ffffff",
                                                  transitionDuration: "1.6s",
                                                }}
                                              >
                                                <div className="flip-box-back-inner">
                                                  <h3
                                                    className="flip-box-heading-back"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    24 órán belül válaszolunk
                                                  </h3>
                                                  <p>
                                                    Amennyiben bármilyen
                                                    kérdésed felmerül,
                                                    készséggel állunk
                                                    rendelkezésedre!
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearfix" />
                                      <div className="fusion-clearfix" />
                                    </div>
                                  </div>
                                  <div
                                    className="fusion-layout-column fusion_builder_column fusion-builder-column-42 fusion_builder_column_1_3 1_3 fusion-one-third"
                                    style={{
                                      width:
                                        "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                                      marginRight: "4%",
                                      marginTop: "0px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <div
                                      className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                      style={{
                                        backgroundPosition: "left top",
                                        backgroundRepeat: "no-repeat",
                                        WebkitBackgroundSize: "cover",
                                        MozBackgroundSize: "cover",
                                        OBackgroundSize: "cover",
                                        backgroundSize: "cover",
                                        padding: "0px 0px 0px 0px",
                                      }}
                                    >
                                      <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                        <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                          <div
                                            className="fusion-flip-box flip-up"
                                            tabIndex={0}
                                          >
                                            <div className="flip-box-inner-wrapper">
                                              <div
                                                className="flip-box-front"
                                                style={{
                                                  marginBottom: "-20px",
                                                  marginLeft: isMobileOnly
                                                    ? "unset"
                                                    : "200px",
                                                  marginRight: isMobileOnly
                                                    ? "unset"
                                                    : "-210px",
                                                  backgroundColor: "#ffffff",
                                                  borderColor:
                                                    "rgba(255,255,255,0)",
                                                  borderRadius: "4px",
                                                  borderStyle: "solid",
                                                  borderWidth: "1px",
                                                  color: "#747474",
                                                  transitionDuration: "1.6s",
                                                }}
                                              >
                                                <div className="flip-box-front-inner">
                                                  <div
                                                    className="flip-box-grafix flip-box-circle"
                                                    style={{
                                                      backgroundColor:
                                                        "#b18b5e",
                                                      borderColor: "#ebe1e1",
                                                    }}
                                                  >
                                                    <i
                                                      aria-hidden="true"
                                                      className="fa-phone fas"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    />
                                                  </div>
                                                  <h2
                                                    className="flip-box-heading"
                                                    style={{ color: "#333333" }}
                                                  >
                                                    Hívj minket!
                                                  </h2>
                                                  06 30 464 2297
                                                </div>
                                              </div>
                                              <div
                                                className="flip-box-back"
                                                style={{
                                                  marginBottom: "-20px",
                                                  marginLeft: isMobileOnly
                                                    ? "unset"
                                                    : "200px",
                                                  marginRight: isMobileOnly
                                                    ? "unset"
                                                    : "-210px",
                                                  backgroundColor: "#b18b5e",
                                                  borderColor:
                                                    "rgba(255,255,255,0)",
                                                  borderRadius: "4px",
                                                  borderStyle: "solid",
                                                  borderWidth: "1px",
                                                  color: "#ffffff",
                                                  transitionDuration: "1.6s",
                                                }}
                                              >
                                                <div className="flip-box-back-inner">
                                                  <h3
                                                    className="flip-box-heading-back"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    Szeretettel várjuk a
                                                    hívásodat <br /> kedd-péntek
                                                    9-18 óráig
                                                    <br />
                                                    hétfő, szombat 8-14 óráig
                                                  </h3>
                                                  <p>
                                                    Időpontfoglalásban és
                                                    teljeskörű tájékoztatásban
                                                    bármikor segítséget
                                                    nyújtunk.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearfix" />
                                      <div className="fusion-clearfix" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </strong>
                      </div>
                    </div>
                  </section>
                </div>
              </main>
            )}

            <Footer />
          </div>
        </div>

        {isAdminAndEditing && this.props.currentPage != null && (
          <React.Fragment>
            <Modal
              isOpen={this.state.section1EditorOpen}
              toggle={this.toggleSection1Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection1Editor}>
                Szekció 1 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection1TitleAlignedCenter"
                      checked={this.state.section1Payload.isTitleAlignedCenter}
                      onChange={(e: any) =>
                        this.handleSection1PayloadChange({
                          ...this.state.section1Payload,
                          isTitleAlignedCenter: e.target.checked,
                        })
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection1TitleAlignedCenter"
                    >
                      A cím legyen középre igazítva
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="inputPageSection1Title">Szalagcím</label>
                  <textarea
                    rows={3}
                    className="form-control"
                    id="inputPageSection1Title"
                    autoComplete="off"
                    placeholder="Írd be a szalagcímet."
                    value={this.state.section1Payload.title}
                    onChange={(e: any) =>
                      this.handleSection1PayloadChange({
                        ...this.state.section1Payload,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Alcím</label>
                  <ReactQuill
                    theme="snow"
                    value={this.state.section1Payload.subTitle}
                    onChange={(text) =>
                      this.handleSection1PayloadChange({
                        ...this.state.section1Payload,
                        subTitle: text,
                      })
                    }
                    modules={QuillEditorUtils.modules}
                    formats={QuillEditorUtils.formats}
                  />
                </div>
                <div className="form-group">
                  <label>Címszöveg</label>
                  <ReactQuill
                    theme="snow"
                    value={this.state.section1Payload.subText}
                    onChange={(text) =>
                      this.handleSection1PayloadChange({
                        ...this.state.section1Payload,
                        subText: text,
                      })
                    }
                    modules={QuillEditorUtils.modules}
                    formats={QuillEditorUtils.formats}
                  />
                </div>

                {this.state.section1ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section1ValidationErrors.map((error, index) => {
                      return (
                        <div key={index} className="form-invalid">
                          {error}
                        </div>
                      );
                    })}
                  </Card>
                )}

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection1SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection1()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection1Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <GalleryEditorModal
              title="Szekció 2 szerkesztése"
              gallery={this.props.currentPage.section2Gallery}
              images={this.props.images}
              addImage={(galleryId, imageId, crop) => {
                this.props.addImageToGallery(galleryId, imageId, crop);
                this.props.getImages();
              }}
              removeImage={this.props.removeImageFromGallery}
              reorder={this.props.reorderImagesForGallery}
              isOpen={this.state.section2EditorOpen}
              toggle={this.toggleSection2Editor}
              uploadImage={(file) => {
                this.props.uploadImage(file);
                this.props.getImages();
              }}
            />

            <Modal
              isOpen={this.state.section3EditorOpen}
              toggle={this.toggleSection3Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection3Editor}>
                Szekció 3 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection3SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection3()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection3Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>

                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection3Visible"
                      checked={this.state.section3IsVisible}
                      onChange={this.handleSection3IsVisibleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection3Visible"
                    >
                      Szekció megjelenik
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="inputPageSection3Title">Cím</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPageSection3Title"
                    autoComplete="off"
                    placeholder="Írd be a címet."
                    value={this.state.section3Payload.title}
                    onChange={(e: any) =>
                      this.handleSection3PayloadChange({
                        ...this.state.section3Payload,
                        title: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="d-flex justify-content-end mb-2">
                  <Button
                    color="primary"
                    onClick={(e: any) =>
                      this.handleSection3PayloadChange({
                        ...this.state.section3Payload,
                        items: this.state.section3Payload.items
                          .slice()
                          .concat([""]),
                      })
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                    Új hozzáadása
                  </Button>
                </div>

                {this.state.section3Payload.items.map((item, index) => (
                  <div key={index} className="form-group">
                    <label htmlFor={`inputPageSection3ItemText$${index + 1}`}>
                      Elem {index + 1}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`inputPageSection3ItemText$${index + 1}`}
                      autoComplete="off"
                      placeholder="Írd be a szöveget."
                      value={item}
                      onChange={(e: any) =>
                        this.handleSection3PayloadChange({
                          ...this.state.section3Payload,
                          items: this.state.section3Payload.items
                            .slice()
                            .map((innerItem, innerIndex) =>
                              innerIndex === index ? e.target.value : innerItem
                            ),
                        })
                      }
                    />
                    <br />
                    <Button
                      color="danger"
                      onClick={(e: any) => {
                        const newArray =
                          this.state.section3Payload.items.slice();
                        newArray.splice(index, 1);
                        this.handleSection3PayloadChange({
                          ...this.state.section3Payload,
                          items: newArray,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "trash-alt"]}
                        className="mr-1"
                      />{" "}
                      Törlés
                    </Button>
                    <hr />
                  </div>
                ))}

                {this.state.section3ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section3ValidationErrors.map((error, index) => {
                      return (
                        <div key={index} className="form-invalid">
                          {error}
                        </div>
                      );
                    })}
                  </Card>
                )}

                <div className="d-flex justify-content-end mt-4">
                  <Button
                    color="primary"
                    onClick={(e: any) =>
                      this.handleSection3PayloadChange({
                        ...this.state.section3Payload,
                        items: this.state.section3Payload.items
                          .slice()
                          .concat([""]),
                      })
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                    Új hozzáadása
                  </Button>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection3SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection3()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection3Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.section4EditorOpen}
              toggle={this.toggleSection4Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection4Editor}>
                Szekció 4 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection4Visible"
                      checked={this.state.section4IsVisible}
                      onChange={this.handleSection4IsVisibleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection4Visible"
                    >
                      Szekció megjelenik
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-end mb-2">
                  <Button
                    color="primary"
                    onClick={(e: any) =>
                      this.handleSection4PayloadChange({
                        ...this.state.section4Payload,
                        items: this.state.section4Payload.items
                          .slice()
                          .concat({
                            imageId: NaN,
                            text: "",
                            crop: { x: 0, y: 0, width: 100, height: 100 },
                          }),
                      })
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                    Új hozzáadása
                  </Button>
                </div>

                {this.state.section4Payload.items.map((item, index) => (
                  <div key={index} className="form-group">
                    <label>Kép {index + 1}</label>
                    <div className="d-flex mt-2">
                      <div
                        style={{
                          height: "200px",
                          minHeight: "200px",
                          maxHeight: "200px",
                        }}
                      >
                        {!isNaN(item.imageId) &&
                          this.props.images.find(
                            (x) => x.id === item.imageId
                          ) != null && (
                            <img
                              alt=""
                              onClick={() => {}}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                overflow: "hidden",
                              }}
                              src={`/wp-content/uploads/new/${
                                this.props.images.find(
                                  (x) => x.id === item.imageId
                                )!!.name
                              }`}
                            />
                          )}
                      </div>
                      <div>
                        <Button
                          color="primary"
                          onClick={() => this.toggleSection4ImagePicker(index)}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="mr-1"
                          />{" "}
                          Kép kiválasztása
                        </Button>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Szöveg {index + 1}</label>
                      <ReactQuill
                        theme="snow"
                        value={item.text}
                        onChange={(text, delta, source, editor) =>
                          this.handleSection4PayloadChange(
                            {
                              ...this.state.section4Payload,
                              items: this.state.section4Payload.items.map(
                                (innerItem, innerIndex) =>
                                  innerIndex === index
                                    ? { ...innerItem, text: text }
                                    : innerItem
                              ),
                            },
                            editor.getText()
                          )
                        }
                        modules={QuillEditorUtils.modules}
                        formats={QuillEditorUtils.formats}
                      />
                    </div>

                    <div className="dropdown mt-2">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id={`dropdownMenuPageTreatmentPage-${index}`}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Kezelés oldal{" "}
                        {isNaN(item.scrollingPageId!!)
                          ? "(üres)"
                          : `(${
                              this.props.scrollingPages.length === 0
                                ? ""
                                : this.props.scrollingPages.find(
                                    (x) => x.id === item.scrollingPageId
                                  )!!.name
                            })`}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby={`dropdownMenuPageTreatmentPage-${index}`}
                      >
                        <div
                          className="dropdown-item cursor-pointer"
                          onClick={() =>
                            this.handleSection4PayloadChange({
                              ...this.state.section4Payload,
                              items: this.state.section4Payload.items.map(
                                (innerItem, innerInnerIndex) =>
                                  innerInnerIndex === index
                                    ? {
                                        ...innerItem,
                                        scrollingPageId: undefined,
                                      }
                                    : innerItem
                              ),
                            })
                          }
                        >
                          (üres)
                        </div>
                        {this.props.scrollingPages.map(
                          (scrollingPage, innerIndex) => {
                            return (
                              <div
                                key={innerIndex}
                                className="dropdown-item cursor-pointer"
                                onClick={() =>
                                  this.handleSection4PayloadChange({
                                    ...this.state.section4Payload,
                                    items: this.state.section4Payload.items.map(
                                      (innerItem, innerInnerIndex) =>
                                        innerInnerIndex === index
                                          ? {
                                              ...innerItem,
                                              scrollingPageId: scrollingPage.id,
                                            }
                                          : innerItem
                                    ),
                                  })
                                }
                              >
                                {scrollingPage.name}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>

                    <br />
                    <Button
                      color="danger"
                      onClick={(e: any) => {
                        const newArray =
                          this.state.section4Payload.items.slice();
                        newArray.splice(index, 1);
                        this.handleSection4PayloadChange({
                          ...this.state.section4Payload,
                          items: newArray,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "trash-alt"]}
                        className="mr-1"
                      />{" "}
                      Törlés
                    </Button>
                    <hr />

                    <ImagePickerModal
                      isOpen={this.state.section4ImagePickerOpen === index}
                      toggle={() => this.toggleSection4ImagePicker(NaN)}
                      images={this.props.images}
                      onImageSelected={(
                        id: number,
                        x: number,
                        y: number,
                        width: number,
                        height: number
                      ) =>
                        this.handleSection4ImageSelected(
                          index,
                          id,
                          x,
                          y,
                          width,
                          height
                        )
                      }
                      aspectRatio={1420 / 946}
                      uploadImage={this.props.uploadImage}
                    />
                  </div>
                ))}

                {this.state.section4ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section4ValidationErrors.map((error, index) => {
                      return (
                        <div key={index} className="form-invalid">
                          {error}
                        </div>
                      );
                    })}
                  </Card>
                )}

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection4SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection4()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection4Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.section5EditorOpen}
              toggle={this.toggleSection5Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection5Editor}>
                Szekció 5 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection5Visible"
                      checked={this.state.section5IsVisible}
                      onChange={this.handleSection5IsVisibleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection5Visible"
                    >
                      Szekció megjelenik
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="inputPageSection5Text">Szöveg</label>
                  <textarea
                    rows={5}
                    className="form-control"
                    id="inputPageSection5Text"
                    autoComplete="off"
                    placeholder="Írd be a szöveget."
                    value={this.state.section5Text}
                    onChange={(e: any) =>
                      this.handleSection5TextChange(e.target.value)
                    }
                  />
                </div>

                {this.state.section5ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section5ValidationErrors.map((error, index) => {
                      return (
                        <div key={index} className="form-invalid">
                          {error}
                        </div>
                      );
                    })}
                  </Card>
                )}

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection5SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection5()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection5Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <GalleryEditorModal
              title="Szekció 6 szerkesztése"
              gallery={this.props.currentPage.section6Gallery}
              images={this.props.images}
              addImage={(galleryId, imageId, crop) => {
                this.props.addImageToGallery(galleryId, imageId, crop);
                this.props.getImages();
              }}
              removeImage={this.props.removeImageFromGallery}
              reorder={this.props.reorderImagesForGallery}
              isOpen={this.state.section6EditorOpen}
              toggle={this.toggleSection6Editor}
              uploadImage={(file) => {
                this.props.uploadImage(file);
                this.props.getImages();
              }}
            />

            <Modal
              isOpen={this.state.section7EditorOpen}
              toggle={this.toggleSection7Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection7Editor}>
                Szekció 7 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection7Visible"
                      checked={this.state.section7IsVisible}
                      onChange={this.handleSection7IsVisibleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection7Visible"
                    >
                      Szekció megjelenik
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="inputPageSection7Text">Szöveg</label>
                  <textarea
                    rows={5}
                    className="form-control"
                    id="inputPageSection7Text"
                    autoComplete="off"
                    placeholder="Írd be a szöveget."
                    value={this.state.section7Text}
                    onChange={(e: any) =>
                      this.handleSection7TextChange(e.target.value)
                    }
                  />
                </div>

                {this.state.section7ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section7ValidationErrors.map((error, index) => {
                      return (
                        <div key={index} className="form-invalid">
                          {error}
                        </div>
                      );
                    })}
                  </Card>
                )}

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection7SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection7()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection7Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.section8EditorOpen}
              toggle={this.toggleSection8Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection8Editor}>
                Szekció 8 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection8Visible"
                      checked={this.state.section8IsVisible}
                      onChange={this.handleSection8IsVisibleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection8Visible"
                    >
                      Szekció megjelenik
                    </label>
                  </div>
                </div>

                {[0, 1, 2].map((index) => {
                  return (
                    <div key={index} className="form-group">
                      <label>Kép {index + 1}</label>
                      <div className="d-flex mt-2">
                        <div
                          style={{
                            height: "200px",
                            minHeight: "200px",
                            maxHeight: "200px",
                          }}
                        >
                          {!isNaN(this.state.section8Payload[index].imageId) &&
                            this.props.images.find(
                              (x) =>
                                x.id ===
                                this.state.section8Payload[index].imageId
                            ) != null && (
                              <img
                                alt=""
                                onClick={() => {}}
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                  overflow: "hidden",
                                }}
                                src={`/wp-content/uploads/new/${
                                  this.props.images.find(
                                    (x) =>
                                      x.id ===
                                      this.state.section8Payload[index].imageId
                                  )!!.name
                                }`}
                              />
                            )}
                        </div>
                        <div>
                          <Button
                            color="primary"
                            onClick={() =>
                              this.toggleSection8ImagePicker(index)
                            }
                          >
                            <FontAwesomeIcon
                              icon={["fas", "plus"]}
                              className="mr-1"
                            />{" "}
                            Kép kiválasztása
                          </Button>
                        </div>
                      </div>

                      <ImagePickerModal
                        isOpen={this.state.section8ImagePickerOpen === index}
                        toggle={() => this.toggleSection8ImagePicker(NaN)}
                        images={this.props.images}
                        onImageSelected={(
                          id: number,
                          x: number,
                          y: number,
                          width: number,
                          height: number
                        ) =>
                          this.handleSection8ImageSelected(
                            index,
                            id,
                            x,
                            y,
                            width,
                            height
                          )
                        }
                        aspectRatio={1420 / 946}
                        uploadImage={this.props.uploadImage}
                      />
                    </div>
                  );
                })}

                {this.state.section8ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section8ValidationErrors.map((error, index) => {
                      return (
                        <div key={index} className="form-invalid">
                          {error}
                        </div>
                      );
                    })}
                  </Card>
                )}

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection8SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection8()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection8Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.section9EditorOpen}
              toggle={this.toggleSection9Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection9Editor}>
                Szekció 9 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection9Visible"
                      checked={this.state.section9IsVisible}
                      onChange={this.handleSection9IsVisibleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection9Visible"
                    >
                      Szekció megjelenik
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label>Szöveg</label>
                  <ReactQuill
                    theme="snow"
                    value={this.state.section9Text}
                    onChange={this.handleSection9TextChange}
                    modules={QuillEditorUtils.modules}
                    formats={QuillEditorUtils.formats}
                  />
                </div>

                {this.state.section9ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section9ValidationErrors.map((error, index) => {
                      return (
                        <div key={index} className="form-invalid">
                          {error}
                        </div>
                      );
                    })}
                  </Card>
                )}

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection9SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection9()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection9Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.section10EditorOpen}
              toggle={this.toggleSection10Editor}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={this.toggleSection10Editor}>
                Szekció 10 szerkesztése
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    onClick={this.handleSection10SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection10()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection10Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>

                <div className="form-check mt-2">
                  <div className="d-flex justify-content-start">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="inputPageSection10Visible"
                      checked={this.state.section10IsVisible}
                      onChange={this.handleSection10IsVisibleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inputPageSection10Visible"
                    >
                      Szekció megjelenik
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="inputPageSection10Title">Cím</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPageSection10Title"
                    autoComplete="off"
                    placeholder="Írd be a címet."
                    value={this.state.section10Payload.title}
                    onChange={(e: any) =>
                      this.handleSection10PayloadChange({
                        ...this.state.section10Payload,
                        title: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="d-flex justify-content-end mb-2">
                  <Button
                    color="primary"
                    onClick={(e: any) =>
                      this.handleSection10PayloadChange({
                        ...this.state.section10Payload,
                        items: this.state.section10Payload.items
                          .slice()
                          .concat([""]),
                      })
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                    Új hozzáadása
                  </Button>
                </div>

                {this.state.section10Payload.items.map((item, index) => (
                  <div key={index} className="form-group">
                    <label htmlFor={`inputPageSection10ItemText$${index + 1}`}>
                      Elem {index + 1}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`inputPageSection10ItemText$${index + 1}`}
                      autoComplete="off"
                      placeholder="Írd be a szöveget."
                      value={item}
                      onChange={(e: any) =>
                        this.handleSection10PayloadChange({
                          ...this.state.section10Payload,
                          items: this.state.section10Payload.items
                            .slice()
                            .map((innerItem, innerIndex) =>
                              innerIndex === index ? e.target.value : innerItem
                            ),
                        })
                      }
                    />
                    <br />
                    <Button
                      color="danger"
                      onClick={(e: any) => {
                        const newArray =
                          this.state.section10Payload.items.slice();
                        newArray.splice(index, 1);
                        this.handleSection10PayloadChange({
                          ...this.state.section10Payload,
                          items: newArray,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "trash-alt"]}
                        className="mr-1"
                      />{" "}
                      Törlés
                    </Button>
                    <hr />
                  </div>
                ))}

                {this.state.section10ValidationErrors.length > 0 && (
                  <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                    {this.state.section10ValidationErrors.map(
                      (error, index) => {
                        return (
                          <div key={index} className="form-invalid">
                            {error}
                          </div>
                        );
                      }
                    )}
                  </Card>
                )}

                <div className="d-flex justify-content-end mt-4">
                  <Button
                    color="primary"
                    onClick={(e: any) =>
                      this.handleSection10PayloadChange({
                        ...this.state.section10Payload,
                        items: this.state.section10Payload.items
                          .slice()
                          .concat([""]),
                      })
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                    Új hozzáadása
                  </Button>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <button
                    onClick={this.handleSection10SaveClick}
                    type="button"
                    className="btn btn-primary"
                    disabled={!this.isValidSection10()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
                    Mentés
                  </button>

                  <button
                    className="btn btn-secondary"
                    onClick={this.toggleSection10Editor}
                  >
                    <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </React.Fragment>
        )}
      </div>
    );
  }

  private handleSection3IsVisibleChange = (e: any) => {
    this.setState({
      section3IsVisible: e.target.checked,
    });
  };

  private handleSection4IsVisibleChange = (e: any) => {
    this.setState(
      {
        section4IsVisible: e.target.checked,
      },
      () => this.validateSection4(true)
    );
  };

  private handleSection5IsVisibleChange = (e: any) => {
    this.setState({
      section5IsVisible: e.target.checked,
    });
  };

  private handleSection7IsVisibleChange = (e: any) => {
    this.setState({
      section7IsVisible: e.target.checked,
    });
  };

  private handleSection8IsVisibleChange = (e: any) => {
    this.setState({
      section8IsVisible: e.target.checked,
    });
  };

  private handleSection9IsVisibleChange = (e: any) => {
    this.setState({
      section9IsVisible: e.target.checked,
    });
  };

  private handleSection10IsVisibleChange = (e: any) => {
    this.setState({
      section10IsVisible: e.target.checked,
    });
  };

  private handleSection4ImageSelected = (
    index: number,
    id: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    this.setState(
      {
        section4Payload: {
          ...this.state.section4Payload,
          items: this.state.section4Payload.items.map((item, innerIndex) => {
            return index === innerIndex
              ? {
                  ...item,
                  imageId: id,
                  crop: {
                    x,
                    y,
                    width,
                    height,
                  },
                }
              : {
                  ...item,
                };
          }),
        },
      },
      () => {
        this.validateSection4(true);
      }
    );

    this.toggleSection4ImagePicker(NaN);
  };

  private handleSection8ImageSelected = (
    index: number,
    id: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    this.setState(
      {
        section8Payload: this.state.section8Payload.map((item, innerIndex) => {
          return index === innerIndex
            ? {
                ...item,
                imageId: id,
                crop: {
                  x,
                  y,
                  width,
                  height,
                },
              }
            : {
                ...item,
              };
        }),
      },
      () => {
        this.validateSection8(true);
      }
    );

    this.toggleSection8ImagePicker(NaN);
  };

  private handleSection1PayloadChange = (
    payload: PageTitleWithTextSectionPayload
  ) => {
    this.setState(
      {
        section1Payload: {
          ...payload,
        },
      },
      () => this.validateSection1(true)
    );
  };

  private handleSection3PayloadChange = (
    payload: PageBulletPointListWithTitlePayload
  ) => {
    this.setState({
      section3Payload: {
        ...payload,
      },
    });
  };

  private handleSection4PayloadChange = (
    payload: PageImageWithTextSideBySidePayload,
    editorText?: string
  ) => {
    this.setState(
      {
        section4Payload: {
          ...payload,
        },
      },
      () => this.validateSection4(true, editorText)
    );
  };

  private handleSection5TextChange = (text: string) => {
    this.setState({
      section5Text: text,
    });
  };

  private handleSection7TextChange = (text: string) => {
    this.setState({
      section7Text: text,
    });
  };

  private handleSection9TextChange = (text: string) => {
    this.setState({
      section9Text: text,
    });
  };

  private handleSection10PayloadChange = (
    payload: PageBulletPointListWithTitlePayload
  ) => {
    this.setState({
      section10Payload: {
        ...payload,
      },
    });
  };

  private toggleSection1Editor = () => {
    this.setState({
      section1EditorOpen: !this.state.section1EditorOpen,
    });
  };

  private toggleSection2Editor = () => {
    this.setState({
      section2EditorOpen: !this.state.section2EditorOpen,
    });
  };

  private toggleSection4ImagePicker = (index: number) => {
    this.setState({
      section4ImagePickerOpen: index,
    });
  };

  private toggleSection3Editor = () => {
    this.setState({
      section3EditorOpen: !this.state.section3EditorOpen,
    });
  };

  private toggleSection4Editor = () => {
    this.setState({
      section4EditorOpen: !this.state.section4EditorOpen,
    });
  };

  private toggleSection5Editor = () => {
    this.setState({
      section5EditorOpen: !this.state.section5EditorOpen,
    });
  };

  private toggleSection6Editor = () => {
    this.setState({
      section6EditorOpen: !this.state.section6EditorOpen,
    });
  };

  private toggleSection7Editor = () => {
    this.setState({
      section7EditorOpen: !this.state.section7EditorOpen,
    });
  };

  private toggleSection8Editor = () => {
    this.setState({
      section8EditorOpen: !this.state.section8EditorOpen,
    });
  };

  private toggleSection8ImagePicker = (index: number) => {
    this.setState({
      section8ImagePickerOpen: index,
    });
  };

  private toggleSection9Editor = () => {
    this.setState({
      section9EditorOpen: !this.state.section9EditorOpen,
    });
  };

  private toggleSection10Editor = () => {
    this.setState({
      section10EditorOpen: !this.state.section10EditorOpen,
    });
  };

  private isValidSection1 = () => {
    return this.validateSection1();
  };

  private validateSection1 = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.section1Payload.title.length === 0) {
      validationErrors.push("A cím kitöltése kötelező.");
    }

    if (shouldSetState) {
      this.setState({
        section1ValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private isValidSection3 = () => {
    return this.validateSection3();
  };

  private validateSection3 = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (shouldSetState) {
      this.setState({
        section3ValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private isValidSection4 = () => {
    return this.state.section4ValidationErrors.length === 0;
  };

  private validateSection4 = (
    shouldSetState: boolean = false,
    editorText?: string
  ) => {
    const validationErrors: string[] = [];

    if (
      this.state.section4IsVisible &&
      this.state.section4Payload.items.length < 1
    ) {
      validationErrors.push(
        "Ha a szekciót megjelenítjük, legalább egy képet és szöveget tartalmaznia kell."
      );
    }

    if (editorText != null) {
      if (editorText.length > 690) {
        validationErrors.push("Legalább az egyik szöveg túl hosszú.");
      }
    }

    if (this.state)
      if (shouldSetState) {
        this.setState({
          section4ValidationErrors: validationErrors,
        });
      }

    return validationErrors.length === 0;
  };

  private isValidSection5 = () => {
    return this.validateSection5();
  };

  private validateSection5 = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (shouldSetState) {
      this.setState({
        section5ValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private isValidSection7 = () => {
    return this.validateSection7();
  };

  private validateSection7 = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (shouldSetState) {
      this.setState({
        section7ValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private isValidSection8 = () => {
    return this.state.section8ValidationErrors.length === 0;
  };

  private validateSection8 = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.section8Payload.length != 3) {
      validationErrors.push(
        "A szekciónak pontosan három képet kell tartalmaznia."
      );
    }

    if (this.state)
      if (shouldSetState) {
        this.setState({
          section8ValidationErrors: validationErrors,
        });
      }

    return validationErrors.length === 0;
  };

  private isValidSection9 = () => {
    return this.validateSection9();
  };

  private validateSection9 = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (shouldSetState) {
      this.setState({
        section9ValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private isValidSection10 = () => {
    return this.validateSection10();
  };

  private validateSection10 = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (shouldSetState) {
      this.setState({
        section10ValidationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };

  private handleSection1SaveClick = () => {
    this.props.updatePageSection1(
      this.props.currentPage!!.id,
      this.state.section1Payload
    );
    this.toggleSection1Editor();
  };

  private handleSection3SaveClick = () => {
    this.props.updatePageSection3(
      this.props.currentPage!!.id,
      this.state.section3IsVisible,
      this.state.section3Payload
    );
    this.toggleSection3Editor();
  };

  private handleSection4SaveClick = () => {
    this.props.updatePageSection4(
      this.props.currentPage!!.id,
      this.state.section4IsVisible,
      this.state.section4Payload
    );
    this.toggleSection4Editor();
    this.props.getImages();
  };

  private handleSection5SaveClick = () => {
    this.props.updatePageSection5(
      this.props.currentPage!!.id,
      this.state.section5IsVisible,
      this.state.section5Text
    );
    this.toggleSection5Editor();
  };

  private handleSection7SaveClick = () => {
    this.props.updatePageSection7(
      this.props.currentPage!!.id,
      this.state.section7IsVisible,
      this.state.section7Text
    );
    this.toggleSection7Editor();
  };

  private handleSection8SaveClick = () => {
    this.props.updatePageSection8(
      this.props.currentPage!!.id,
      this.state.section8IsVisible,
      this.state.section8Payload
    );
    this.toggleSection8Editor();
    this.props.getImages();
  };

  private handleSection9SaveClick = () => {
    this.props.updatePageSection9(
      this.props.currentPage!!.id,
      this.state.section9IsVisible,
      this.state.section9Text
    );
    this.toggleSection9Editor();
  };

  private handleSection10SaveClick = () => {
    this.props.updatePageSection10(
      this.props.currentPage!!.id,
      this.state.section10IsVisible,
      this.state.section10Payload
    );
    this.toggleSection10Editor();
  };
}

export default connect(
  (state: ApplicationState) => state.oxy,
  OxyStore.actionCreators
)(OxyPageComponent as any);
