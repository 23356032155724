import * as React from 'react';
import { Button, Card, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from './NavMenuAdmin';
import Reorder from 'react-reorder';
import { BeforeAfterGalleryDto, MenuItemDto } from '../../models';
import { BeforeAfterGalleryItemPayload, CropPayload, ReorderPayload } from '../../payloads';
import { BeforeAfterGalleryEditorCard } from "./BeforeAfterGalleryEditorCard";
import AdminBackButton from "./AdminBackButton";
import ImagePickerModal from './ImagePickerModal';

type BeforeAfterGalleryEditorProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface BeforeAfterGalleryEditorState {
    addGalleryModalOpen: boolean;
    expandedGalleryId: number;
    galleryTitle: string;
    galleryId: number;
    itemDescription: string;
    leftImageId: number;
    leftCrop: CropPayload;
    rightImageId: number;
    rightCrop: CropPayload;
    leftImagePickerOpen: boolean;
    rightImagePickerOpen: boolean;
    validationErrorsGallery: string[];
    validationErrorsGalleryItem: string[];
}

class BeforeAfterGalleryEditor extends React.PureComponent<BeforeAfterGalleryEditorProps, BeforeAfterGalleryEditorState> {
    constructor(props: BeforeAfterGalleryEditorProps) {
        super(props);

        this.state = {
            addGalleryModalOpen: false,
            expandedGalleryId: NaN,
            galleryTitle: "",
            galleryId: NaN,
            itemDescription: "",
            leftImageId: NaN,
            leftCrop: {
                x: 0,
                y: 0,
                width: 100,
                height: 100
            },
            rightImageId: NaN,
            rightCrop: {
                x: 0,
                y: 0,
                width: 100,
                height: 100
            },
            leftImagePickerOpen: false,
            rightImagePickerOpen: false,
            validationErrorsGallery: [],
            validationErrorsGalleryItem: []
        }
    }

    componentDidMount() {
        this.props.getAllBeforeAfterGalleries();
        this.props.getImages();
    }

    public render() {
        return (
            <React.Fragment>
                <NavMenuAdmin />
                <Container>
                    <AdminBackButton />
                    <div>
                        <h1>Galéria kezelése</h1>
                    </div>

                    <div className="d-flex justify-content-end mb-3">
                        <Button onClick={this.toggleAddGalleryModal} color="primary">
                            <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                        </Button>
                    </div>

                    {this.props.beforeAfterGalleries.length === 0 &&
                        <div>
                            <h3>Nem található galéria.</h3>
                        </div>
                    }

                    <div className="p-4">
                        <div className="mb-2">
                            <p>
                                Az elemek sorrendjének módosításához egyszerűen fogd meg az adott elemet, és húzd a kívánt helyre!
                                <br />
                                A galériák sorrendje csak akkor módosítható, ha egyik sincs kinyitva.
                            </p>
                        </div>
                        <Reorder
                            listClass="list-group"
                            itemKey="id"
                            list={this.props.beforeAfterGalleries}
                            sharedProps={{
                                onItemDelete: this.props.removeItemFromBeforeAfterGallery,
                                onAddItemClick: this.handleAddItemClick,
                                images: this.props.images,
                                reorderItems: this.props.reorderItemsForBeforeAfterGallery,
                                expandedId: this.state.expandedGalleryId,
                                toggleExpandedId: this.toggleExpandedGalleryId,
                                updateGallery: this.props.updateBeforeAfterGallery
                            }}
                            holdTime={200}
                            template={BeforeAfterGalleryEditorCard}
                            callback={this.handleGalleryReorder}
                            itemClicked={() => { }}
                            itemClass="list-group-item list-group-item-action"
                            disableReorder={!isNaN(this.state.expandedGalleryId)}
                        />
                    </div>
                    {this.getAddGalleryModal()}
                    {this.getAddGalleryItemModal()}
                    <ImagePickerModal
                        isOpen={this.state.leftImagePickerOpen}
                        toggle={this.toggleLeftImagePicker}
                        images={this.props.images}
                        onImageSelected={this.handleLeftImageSelected}
                        aspectRatio={500 / 500}
                        uploadImage={this.props.uploadImage}
                    />
                    <ImagePickerModal
                        isOpen={this.state.rightImagePickerOpen}
                        toggle={this.toggleRightImagePicker}
                        images={this.props.images}
                        onImageSelected={this.handleRightImageSelected}
                        aspectRatio={500 / 500}
                        uploadImage={this.props.uploadImage}
                    />
                </Container>
            </React.Fragment>
        );
    }

    private getAddGalleryModal = () => {
        return (
            <Modal isOpen={this.state.addGalleryModalOpen} toggle={this.toggleAddGalleryModal} className="modal-dialog-centered">
                <ModalHeader toggle={this.toggleAddGalleryModal}>Új galéria</ModalHeader>
                <ModalBody>
                    <div>
                        <div className="form-group">
                            <label htmlFor="inputBeforeAfterGalleryTitle">Cím</label>
                            <input type="text" className="form-control" id="inputBeforeAfterGalleryTitle" autoComplete="off" placeholder="Írd be a címet." value={this.state.galleryTitle} onChange={this.handleGalleryTitleChange} />
                        </div>
                    </div>

                    {this.state.validationErrorsGallery.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrorsGallery.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleSaveNewGalleryClick} type="button" className="btn btn-primary" disabled={!this.isValidGallery()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelAddGalleryClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private getAddGalleryItemModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.galleryId)} toggle={this.handleCancelAddItemClick} className="modal-dialog-centered">
                <ModalHeader toggle={this.handleCancelAddItemClick}>Új galéria elem</ModalHeader>
                <ModalBody>
                    <div>
                        <div className="form-group">
                            <label htmlFor="inputBeforeAfterGalleryItemDescription">Leírás</label>
                            <input type="text" className="form-control" id="inputBeforeAfterGalleryItemDescription" autoComplete="off" placeholder="Írd be a leírást." value={this.state.itemDescription} onChange={this.handleItemDescriptionChange} />
                        </div>
                        <div className="mt-2">
                            <div style={{ height: "200px", minHeight: "200px", maxHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {!isNaN(this.state.leftImageId) &&
                                    <img alt="" style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "contain", overflow: "hidden" }} src={`/wp-content/uploads/new/${this.props.images.find(x => x.id === this.state.leftImageId)!!.name}`} />
                                }
                                {isNaN(this.state.leftImageId) &&
                                    <h4>Válassz ki egy képet!</h4>
                                }
                            </div>
                            <div>
                                <Button color="primary" onClick={this.toggleLeftImagePicker}>
                                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> &quot;Előtte&quot; kép kiválasztása
                                </Button>
                            </div>
                            <br />
                            <div style={{ height: "200px", minHeight: "200px", maxHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {!isNaN(this.state.rightImageId) &&
                                    <img alt="" style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "contain", overflow: "hidden" }} src={`/wp-content/uploads/new/${this.props.images.find(x => x.id === this.state.rightImageId)!!.name}`} />
                                }
                                {isNaN(this.state.rightImageId) &&
                                    <h4>Válassz ki egy képet!</h4>
                                }
                            </div>
                            <div>
                                <Button color="primary" onClick={this.toggleRightImagePicker}>
                                    <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> &quot; Utána&quot; kép kiválasztása
                                </Button>
                            </div>
                        </div>
                    </div>

                    {this.state.validationErrorsGalleryItem.length > 0 &&
                        <Card className="mt-4 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrorsGalleryItem.map((error, index) => {
                                return (
                                    <div key={index} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleAddGalleryItemClick} type="button" className="btn btn-primary" disabled={!this.isValidGalleryItem()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelAddItemClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleGalleryTitleChange = (e: any) => {
        this.setState({
            galleryTitle: e.target.value
        }, () => this.validateGallery(true));
    }

    private handleItemDescriptionChange = (e: any) => {
        this.setState({
            itemDescription: e.target.value
        }, () => this.validateGalleryItem(true));
    }

    private handleLeftImageSelected = (id: number, x: number, y: number, width: number, height: number) => {
        this.setState({
            leftImageId: id,
            leftCrop: {
                x,
                y,
                width,
                height
            },
            leftImagePickerOpen: false
        }, () => this.validateGalleryItem(true));
    }

    private toggleLeftImagePicker = () => {
        this.setState({
            leftImagePickerOpen: !this.state.leftImagePickerOpen
        });
    }

    private handleRightImageSelected = (id: number, x: number, y: number, width: number, height: number) => {
        this.setState({
            rightImageId: id,
            rightCrop: {
                x,
                y,
                width,
                height
            },
            rightImagePickerOpen: false
        }, () => this.validateGalleryItem(true));
    }

    private toggleRightImagePicker = () => {
        this.setState({
            rightImagePickerOpen: !this.state.rightImagePickerOpen
        });
    }

    private handleSaveNewGalleryClick = () => {
        this.props.addBeforeAfterGallery(this.state.galleryTitle);
        this.setState({
            addGalleryModalOpen: false,
            galleryTitle: ""
        });
    }

    private handleAddGalleryItemClick = () => {
        const payload: BeforeAfterGalleryItemPayload = {
            description: this.state.itemDescription,
            leftImageId: this.state.leftImageId,
            leftCrop: this.state.leftCrop,
            rightImageId: this.state.rightImageId,
            rightCrop: this.state.rightCrop
        };

        this.props.addItemToBeforeAfterGallery(this.state.galleryId, payload);
        this.handleCancelAddItemClick();
    }

    private handleCancelAddGalleryClick = () => {
        this.setState({
            addGalleryModalOpen: false,
            galleryTitle: ""
        });
    }

    private toggleAddGalleryModal = () => {
        this.setState({
            addGalleryModalOpen: !this.state.addGalleryModalOpen
        });
    }

    private toggleExpandedGalleryId = (id: number) => {
        this.setState({
            expandedGalleryId: id === this.state.expandedGalleryId ? NaN : id
        });
    }

    private handleAddItemClick = (id: number) => {
        this.setState({
            galleryId: id
        });
    }

    private handleCancelAddItemClick = () => {
        this.setState({
            galleryId: NaN,
            itemDescription: "",
            leftImageId: NaN,
            leftCrop: {
                x: 0,
                y: 0,
                width: 100,
                height: 100
            },
            rightImageId: NaN,
            rightCrop: {
                x: 0,
                y: 0,
                width: 100,
                height: 100
            }
        });
    }

    private handleGalleryReorder = (event: any,
        itemThatHasBeenMoved: BeforeAfterGalleryDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: BeforeAfterGalleryDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorderBeforeAfterGalleries(payload);
    }

    private isValidGallery = () => {
        return this.validateGallery();
    }

    private validateGallery = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.galleryTitle.length === 0) {
            validationErrors.push("A cím megadása kötelező!");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsGallery: validationErrors
            });
        }

        return validationErrors.length === 0;
    }

    private isValidGalleryItem = () => {
        return this.validateGalleryItem();
    }

    private validateGalleryItem = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (isNaN(this.state.leftImageId) || isNaN(this.state.rightImageId)) {
            validationErrors.push("Mindkét kép kiválasztása kötelező!");
        }

        if (this.state.itemDescription.length === 0) {
            validationErrors.push("A leírás megadása kötelező!");
        }

        if (shouldSetState) {
            this.setState({
                validationErrorsGalleryItem: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(BeforeAfterGalleryEditor as any);
