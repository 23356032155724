import { ProtectedRestApi } from "./";
import { ServiceResult, SubscriptionDto } from "../models";
import { SubscriptionPayload } from "../payloads";

export class SubscriptionApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxysubscription";

    public subscribe(payload: SubscriptionPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${SubscriptionApi.baseUrl}/subscribe`, payload, true, true) as Promise<ServiceResult<boolean>>;
    }

    public subscribeWithCoupon(payload: SubscriptionPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${SubscriptionApi.baseUrl}/subscribe-with-coupon`, payload, true, true) as Promise<ServiceResult<boolean>>;
    }

    public getSubscriptions(): Promise<ServiceResult<SubscriptionDto[]>> {
        return this.httpGet(`${SubscriptionApi.baseUrl}/all`) as Promise<ServiceResult<SubscriptionDto[]>>;
    }
}
