import * as React from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../store/index';
import * as OxyStore from '../../../store/OxyStore';
import NavMenuAdmin from "../../admin/NavMenuAdmin";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type WebshopAdminDashboardProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface WebshopAdminDashboardState {

}

class WebshopAdminDashboard extends React.PureComponent<WebshopAdminDashboardProps, WebshopAdminDashboardState> {
    constructor(props: WebshopAdminDashboardProps) {
        super(props);

        this.state = {
        }
    }

    componentWillMount() {
        this.props.getAllProductCategories();
    }

    public render() {
        return this.props.loggedInUser != null && this.props.loggedInUser.isAdmin ?
            <React.Fragment>
                <NavMenuAdmin />
                <Card>
                    <CardBody className="d-flex flex-column align-items-center">
                        <div className="mb-3">
                            <Link to={this.props.productCategories.length === 0 ? "/shop/admin" : `/shop/admin/category/${this.props.productCategories.find(x => x.name.toLowerCase().indexOf("ajándékutalvány") > -1)!.id}`} className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "table"]} className="fa-fw mr-1" />
                                Ajándékutalványok kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/shop/admin/orders" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "shopping-bag"]} className="fa-fw mr-1" />
                                Rendelések kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/shop/admin/users" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "users"]} className="fa-fw mr-1" />
                                Felhasználók kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/shop/admin/subscriptions" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "newspaper"]} className="fa-fw mr-1" />
                                Feliratkozások kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/shop/admin/coupons" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "percent"]} className="fa-fw mr-1" />
                                Kuponok kezelése
                            </Link>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
            : null
        ;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(WebshopAdminDashboard as any);
