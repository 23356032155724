import * as React from 'react';
import NavMenuShop from './NavMenuShop';
import Footer from '../Footer';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import ScrollToTop from '../ScrollToTop';

class TermsAndConditions extends React.PureComponent {
    public render() {
        require("../../shop.css");
        require("../../DancingScript.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="webshop-main-container">
                    <header className="header mb--30">
                        <img className="header__image" src="/wp-content/uploads/new/webshop2.png" />
                    </header>
                    <div className="container aszf-container">
                        <h1>ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK</h1>
                        <p>
                            Hatályos: 2018. május 25. napjától.
                        </p>
                        <p>
                            Jelen Általános Szerződés Feltételek (ÁSZF) tartalmazza a Szolgáltató tulajdonában lévő www.oxyesztetika.hu (továbbiakban “Honlap vagy Weboldal”) díjmentes és díjköteles szolgáltatásainak igénybevételi feltételeit.
                        </p>
                        <h2>1. Általános adatok és elérhetőségek</h2>
                        <h3>1.1. Szolgáltató neve és címe és egyéb adatai:</h3>
                        <p>
                            Szolgáltató neve: OXY DAY SPA Kft.
                            <br />
                            Szolgáltató székhelye:&nbsp;
                            <a href="https://www.google.com/maps/place/Miskolc,+Arany+J%C3%A1nos+t%C3%A9r+1,+3526/@48.1077192,20.787912,17z/data=!3m1!4b1!4m5!3m4!1s0x47409ffd1c2aa6b3:0xf3bd9d14ffffc462!8m2!3d48.1077192!4d20.787912?hl" target="_blank">
                                3526 Miskolc, Arany János tér 1.
                            </a>
                            <br />
                            Szolgáltató adószáma: 23407068-2-05
                            <br />
                            Szolgáltató nyilvántartási száma: 05 09 022069
                            <br />
                            Szolgáltató nyilvántartó hatósága: Miskolci Törvényszék Cégbírósága
                            <br />
                            Bankszámla szám: Budapest Bank Zrt., 10102770-04962500-02003005
                            <br />
                            Szolgáltató e-mail címe: <a href="mailto:oxyesztetika@gmail.com">oxyesztetika@gmail.com</a>
                            <br />
                            Szolgáltatás weboldalának címe: <a href="https://www.oxyesztetika.hu">www.oxyesztetika.hu</a>
                            <br />
                            <br />
                            Szolgáltató ügyfélszolgálatának elérhetőségei:
                            <br />
                            E-mail cím: <a href="mailto:oxyesztetika@gmail.com">oxyesztetika@gmail.com</a>
                            <br />
                            Telefonszám: <a href="tel:+36304642297">+36/30-464-2297</a>
                        </p>
                        <h3>1.2. Általános Szerződési Feltételek elérhetőségei</h3>
                        <p>
                            A Szolgáltató az Általános Szerződési Feltételeit, valamint annak valamennyi módosítását időrendi sorrendben keltezéssel ellátva a honlapján teszi közé.
                        </p>
                        <h3>1.3. Definíciók</h3>
                        <p>
                            Felhasználó: a Szolgáltatást igénybe vevő természetes vagy jogi személy.
                            <br />
                            Szolgáltatás: a Szolgáltató Honlapján Szolgáltatásokat nyújt. A Szolgáltatások egy részének igénybevételéhez Regisztráció szükséges. A Szolgáltató határozza meg a regisztrációhoz kötött Szolgáltatások körét. Erről a Felhasználókat a Honlapon tájékoztatja a Szolgáltató.
                            <br />
                            Regisztráció: a Felhasználónak bizonyos funkciók használatához (szolgáltatás megrendeléshez) regisztrálnia kell. Ehhez személyes adatait kell megadnia, melyek a következők: név, lakcím, e-mail cím, telefonszám. A Felhasználó személyes adatainak kezeléséről az Adatkezelési Tájékoztató rendelkezik, mely elérhető a weboldalon, illetve mely a jelen ÁSZF mellékletét képezi. Regisztrációnak csak azokat a kitöltött adatlapokat tekintjük, amelyben minden kötelezően megadott személyes adat szerepel.
                        </p>

                        <h2>2. Szolgáltatások igénybevételének feltételei, egyéb fontos tudnivalók</h2>
                        <h3>Megvásárolható termékek, szolgáltatások köre: online ajándékkártya</h3>
                        <p>
                            A termékek kizárólag a webáruházon keresztül rendelhetőek meg. A megvásárolt ajándékkártyát lehetősége van ingyenesen pdf formátumban is letölteni a weboldalról, vagy kérheti tőlünk az ajándékkártya kinyomtatását, melyet ingyenesen székhelyünkön átvehet.
                            <br />
                            A Webáruházban feltüntetett árak magyar forintban (HUF) feltüntetett bruttó árak, így tehát az áfát már tartalmazzák, Önnek ezt az összeget kell a termékért megfizetnie.
                            <br />
                            Tájékoztatjuk, hogy az ajándékkártya átruházható. Amennyiben Ön más személynek ajándékba veszi az ajándékkártyát, akkor kérjük, hogy ezt szíveskedjen megrendeléskor jelölni, és a megajándékozott személy nevét feltüntetni, ez a szerződés létrejöttének és teljesítésének feltétele. Ebben az esetben kizárólag az Ön által megajándékozni kívánt személy használhatja fel az ajándékkártyát.
                            <br />
                            Az Oxy Day Spa Kft. nem vállal felelősséget az elveszett, megrongálódott vagy megsemmisült ajándékkártyákért. Ilyen esetekben a kártya nem pótolható. Az ajándékkártya egyenlegéről a oxyesztetika@gmail.com email címen kérhetsz információt.
                            <br />
                            A Szolgáltató a számlát elektronikus úton megküldi a Felhasználó részére a regisztrációja során megadott e-mail címre.
                        </p>
                        <h3>2.1. Regisztráció</h3>
                        <p>
                            A Felhasználó Regisztrációt követően használhatja a Szolgáltatások egy részét. A szolgáltatások bizonyos részének igénybe vételéhez nem szükséges regisztráció. A Regisztráció véglegesítéséhez a kötelezően kitöltendő személyes adatokat meg kell adni. A Felhasználó a személyes adatok megadásával kifejezetten hozzájárul ahhoz és beleegyezését adja, hogy a Szolgáltató az információkat összesített és feldolgozott formában statisztikai és szolgáltatás fejlesztési célokra felhasználja.
                            <br />
                            A Szolgáltató rögzíti, hogy a jelen szerződés (amennyiben azt a másik fél elfogadja) írásba foglalt szerződésnek minősül, azt a Szolgáltató rögzíti, annak létrejöttét követő 5 (öt) évig őrzi, és utólag hozzáférhető. A felek rögzítik, hogy a jelen szerződés magyar nyelven jön létre a felek között, és arra a magyar jog az irányadó.
                        </p>
                        <h3>2.2. Szolgáltatás igénybevételének feltételei</h3>
                        <p>
                            A Szolgáltatás igénybevételéhez (azaz a megrendelés leadásához és a szerződés megkötéséhez) Regisztráció és a személyes adatok megadása szükséges. A személyes adatok valódiságáért, pontosságáért kizárólag a Felhasználó tartozik felelősséggel, azoknak sem ellenőrzésére, sem felülbírálására a Szolgáltató nem jogosult. Ezt a Felhasználó kifejezetten elfogadja és tudomásul veszi.
                            <br />
                            Tájékoztatjuk, hogy a termékek megrendelésére vonatkozó adásvételi szerződés létrejöttéhez elengedhetetlenül szükséges az Ön adatainak megadása. Amennyiben Ön nem adja meg az általunk megkívánt és előírt személyes adatait, abban az esetben nem jön létre közöttünk a szerződés.
                            <br />
                        </p>
                        <h4>2.2.1. A vásárlás (megrendelés) menete</h4>
                        <p>
                            1. A termék adatlapján válassza ki a vásárolni kívánt termék mennyiségét, majd kattintson a „Kosár” gombra (kosár ikonra), ezáltal a termék a megfelelő mennyiségben belekerül az Ön virtuális bevásárlói kosarába. A kosár tartalma a vásárlás alatt a megrendelés leadásáig bármikor módosítható, illetve törölhető.
                            <br />
                            2. A vásárlás további folytatásához válasszon ki egy újabb terméket, majd miután megadta a vásárolni kívánt mennyiséget, kattintson újra a "Kosár" gombra. Ez a termék is bekerül a virtuális kosárba. Minden egyes újabb megvásárolni kívánt termék esetében a fentiek szerint járjon el.
                            <br />
                            3. Amennyiben nem kíván további termékeket elhelyezni a Kosárban, úgy a „Fizetés / Pénztár” gombra kattintva haladhat tovább a megrendelés folyamatában.
                            <br />
                            4. A megrendelés leadásnak utolsó lépéseként a „Tovább” gomb használatával erősítse meg adatait, majd a „Megrendelés leadása” gombra kattintson, mellyel rendelését elküldi nekünk.
                            <br />
                            5. A megrendelés leadásáról a rendszer egy automatikus e-mailben tájékoztatja.
                            <br />
                            A megrendelés akkor válik elfogadottá, azaz a szerződés akkor jön létre a Felek között, ha azt a Szolgáltató rendszere e-mailben az Ön által megadott e-mail címre visszaigazolja. A Szolgáltató rendszere a megrendelésről egy automatikus levelet küld Önnek, melyben található egy azonosító szám. A rendelési számra hivatkozva érdeklődhet megrendelésével kapcsolatban elérhetőségeink bármelyikén.
                            <br />
                            Fizetési mód:
                            <br />
                            A megrendelt termék ellenértékét a megrendelés leadását követően kizárólag BARION online bankkártyás fizetés alkalmazásával egyenlítheti ki.
                            <br />
                            A feldolgozás és a számlázás automatikusan történik, a megrendeléseket a nap bármely időszakában tudjuk fogadni.
                        </p>
                        <h4>2.2.2. Szavatosság</h4>
                        <p>
                            A szavatossággal kapcsolatos részletszabályokat jelen a jelen ÁSZF elválaszthatatlan részét képező 3. számú melléklet tartalmazza, valamint a jelen ÁSZF speciális ezzel kapcsolatos rendelkezései.
                        </p>
                        <h2>3. Panasz és reklamáció kezelése (díjreklamáció)</h2>
                        <p>
                            Kérjük, hogy panaszát elsődlegesen a Szolgáltató elérhetőségein szíveskedjék közölni, hogy az abban foglaltakat ki tudjuk vizsgálni. A panaszkezelés részletes szabályait a jelen ÁSZF elválaszthatatlan részét képező 2. számú melléklet tartalmazza.
                            <br />
                            Tájékoztatjuk, hogy amennyiben a Szolgáltatóval szemben jelen jogügylettel összefüggésben továbbra is bármilyen panasza van, akkor Önnek lehetősége van a B-A-Z Megyei Békéltető Testülethez (címe: 3525 Miskolc, Vologda utca 3. I. emelet), illetve a B-A-Z Megyei Kormányhivatal Fogyasztóvédelmi Felügyelőséghez (címe: 3530 Miskolc, Meggyesalja u. 12.) fordulni.
                        </p>
                        <h2>4. Regisztráció törlése</h2>
                        <h3>4.1. Felhasználó által kezdeményezett Regisztráció törlése</h3>
                        <p>
                            A Regisztráció törlése a weboldalon érhető el a Felhasználók számára. A Regisztráció törlésével a Felhasználó már nem lesz jogosult a belépésre. A Felhasználó tudomásul veszi, hogy a Regisztráció törlésével minden addig rögzített adata el fog veszni, a Szolgáltató által törlésre kerül és az adatok visszaállítására már egy későbbi Regisztráció során sem lesz lehetőség. Felhasználó által kezdeményezett törlés esetén Felhasználó nem jogosult reklamációra.
                        </p>
                        <h3>4.2. Szolgáltató által kezdeményezett Regisztráció megszüntetése</h3>
                        <p>
                            A Szolgáltató az alábbi esetekben kezdeményezheti az egyoldalú Regisztráció megszüntetést:
                            <ul>
                                <li>a Felhasználó olyan tevékenységet végez, amely sérti a Szolgáltató jogkörét, üzleti tevékenységét,</li>
                                <li>a Szolgáltató észleli, hogy a Felhasználó rendellenesen használja a Szolgáltatást, és/vagy az átlagostól eltérően használja a Szolgáltatást.</li>
                            </ul>
                        </p>
                        <h2>5. Felmondási jog</h2>
                        <p>
                            A Felhasználókat a 45/2014. Kormányrendelet alapján jogszabályon alapuló felmondási jog illeti meg, melynek részletes szabályait, rendelkezéseit a jelen ÁSZF elválaszthatatlan részét képező 2. számú melléklet tartalmazza.
                        </p>
                        <h2>6. Felelősségi körök</h2>
                        <p>
                            Felhasználó a Weboldalt kizárólag a saját felelősségére használhatja, és elfogadja, hogy Szolgáltató semmilyen felelősséget nem vállal a Weboldal funkciói, tartalma vagy az azon hirdetett szolgáltatások/termékek igénybevételéből és/vagy használatából eredő kárért, hátrányért.
                            <br />
                            <br />
                            A Weboldalra feltöltött kapcsolódási pontok (linkek) más szolgáltatók oldalaira irányíthatnak. Ezen oldalak tartalmát a Szolgáltató nem ellenőrzi, azokkal semmilyen kapcsolatban nem áll. A Szolgáltató semmilyen felelősséget nem vállal ezen szolgáltatók oldalainak tartalmáért, ezen oldalak jogszerűségéért valamint adat- és információvédelmi gyakorlatáért, ill. az oldalak látogatásából eredő, vagy azzal összefüggésben keletkezett károkért.
                            <br />
                            <br />
                            Szolgáltató nem vállal garanciát a Weboldalon a nem általa elhelyezett anyagok és információk hitelességéért, hibátlanságáért. A weboldalon közölt adatokért, azok pontosságáért a Szolgáltató felelősséget nem vállal, amennyiben az adott adatokat nem ő helyezte el.
                            <br />
                            <br />
                            A Weboldal látogatásakor közvetlenül, közvetetten, véletlenszerűen felmerülő hibáért, kárért (közvetlen vagy közvetett), adatvesztésért, illetve a Weboldal tartalmi hibáiért vagy hiányosságaiért semmilyen felelősség nem terheli a Szolgáltatót.
                            <br />
                            <br />
                            A Szolgáltató nem vállal felelősséget különösen az egyes Felhasználók által a Szolgáltató közösségi oldalán közzétett valós, vagy valótlan információkért, esetlegesen károkozó vagy jogellenes hivatkozásokért.
                            <br />
                            <br />
                            Felhasználó a teljes felelősséget vállalja mindenféle tevékenységért, ami a felhasználói regisztrációs fiókjában vagy azon keresztül történik. Ha a Felhasználónak tudomására jut a regisztrációs fiókjának bárminemű jogosulatlan használata, köteles a Szolgáltatót azonnal értesíteni. Jogosulatlan hozzáférés révén megrendelt Szolgáltatások esetén Szolgáltató nem köteles megtéríteni a megrendelt és/vagy felhasznált Szolgáltatások díjait.
                            <br />
                            <br />
                            A Szolgáltató nem tartozik kártérítési felelősséggel a Weboldallal kapcsolatos, vagy a Weboldal használatából, vagy használatra képtelen állapotából, vagy a nem megfelelő működésből, hibából, üzemzavarból, szoftverhibából, információ-továbbítási késedelemből vagy számítógépes vírusból, kommunikációs vonal- vagy rendszerhibából bekövetkező károkért.
                        </p>
                        <h2>7. Hírlevél, reklámcélú megkeresések (részletes leírást az adatvédelmi tájékoztatóban találnak)</h2>
                        <p>
                            A Weboldalra látogatók Regisztrációjuk véglegesítésével, vagy külön feliratkozáskor hozzájárulnak ahhoz, hogy feliratkozzanak a Szolgáltató hírlevelére.
                            <br />
                            A Felhasználó a hozzájárulását bármikor korlátozás és indokolás nélkül, díjmentesen visszavonhatja a weboldalon a megfelelő funkció alkalmazásával, vagy a Szolgáltató címére postai úton küldött levélben, vagy a Szolgáltató e-mail címére küldött elektronikus levélben.
                            <br />
                            A fentiek alapján tehát a Szolgáltató jogosult a Felhasználó részére hírlevelet vagy egyéb reklám célú levelet küldeni, illetőleg a Felhasználót direktmarketing céljából megkeresni, amennyiben a Felhasználó a regisztrációkor illetőleg külön feliratkozáskor a megfelelő adatai megadásával ehhez előzetesen, egyértelműen, kifejezetten és önkéntesen hozzájárult. A Szolgáltató nem köteles ellenőrizni, hogy a Felhasználó által a regisztrációkor, illetőleg egyébként a hozzájárulás megadásakor megadott adatok valósak, illetőleg helytállóak.
                            <br />
                            A Felhasználó az önkéntes hozzájárulását bármikor jogosult a fent részletezett módon visszavonni. Ebben az esetben a Szolgáltató a visszavonást követően a Felhasználó részére több hírlevelet, illetőleg egyéb reklámlevelet, illetve direktmarketing célú megkeresést nem küld, továbbá törli a Felhasználó adatait a hírlevélre feliratkozott felhasználók adatai közül.
                            <br />
                            A hírlevélre történő feliratkozással a Felhasználó kifejezetten hozzájárul, hogy a személyes adatait hirdetési és reklámcélokból a Szolgáltató tárolja, ill. folyamatosan felhasználja, tehát pl. hírleveleket, egyéb marketing célú megkereséseket küldjön a Felhasználónak.
                            <br />
                            <br />
                            A Felhasználó hozzájárul ahhoz, hogy megadott adatai alapján személyre szabott hirdetéseket, ill. ajánlatokat küldjünk részére.
                            <br />
                            <br />
                            A Felhasználó által szolgáltatott adatokat a honlapunk, hirdetéseink, marketingünk, piackutatásunk, valamint értékesítési tevékenységünk eredményességének mérésére és javítására is felhasználhatjuk.
                        </p>
                        <h2>8. Szerzői jogok</h2>
                        <p>
                            A Szolgáltató online értékesítési rendszerében megjelenített tartalom (grafika és egyéb anyagok, a honlap felületének elrendezése, szerkesztése, a használt szoftveres és egyéb megoldások, védjegy, cégnév stb.) a Szolgáltató szellemi alkotása; kivételt képeznek ez alól a Weboldalon feltüntetett, a Szolgáltatótól eltérő személyek védjegyei. A szolgáltatás rendeltetésszerű használatával járó megjelenítésen, az ehhez szükséges ideiglenes többszörözésen és a magáncélú másolatkészítésen túl ezen szellemi alkotások a Szolgáltató előzetes írásbeli engedélye nélkül semmilyen egyéb formában nem használhatók fel vagy hasznosíthatók, bármely ilyen jellegű tevékenység polgári és büntetőjogi eljárásokat vonhat maga után.
                        </p>
                        <h2>9. Alkalmazandó jogszabályok</h2>
                        <p>
                            Jelen ÁSZF-ben nem szabályozott kérdésekben a Polgári Törvénykönyv, illetőleg az egyéb vonatkozó jogszabályok rendelkezései az irányadóak. A Felek rögzítik, hogy a jelen szerződésben foglalt feltételek általános szerződési feltételeknek minősülnek, melyet a Felhasználó kifejezetten elfogad és tudomásul vesz. A Szolgáltató kijelenti, hogy a jelen szerződési feltételeket a szerződés megkötését megelőzően megismerhetővé tette a Felhasználó számára, így a szerződés annak elfogadásával a Felek között a fenti tartalommal létrejött, melyet a Felhasználó is kifejezetten tudomásul vesz és elfogad.
                        </p>
                        <h2>10. Adatkezelés, adatbiztonság, egyéb rendelkezések</h2>
                        <p>
                            A Szolgáltató köteles a Felhasználóról az Üzleti kapcsolat során tudomására jutott adatokat és információkat a hatályos adatvédelmi jogszabályi rendelkezések, és az adatvédelmi tájékoztatóban foglaltak szerint kezelni. A Szolgáltató a Felhasználók személyes adatainak kezelésének feltételeiről Adatkezelési Tájékoztatót tart hatályban, amely a jelen ÁSZF 1. számú mellékletét képezi.
                            <br />
                            A jelen ÁSZF 2018. május 25. napjától visszavonásig, illetve a további módosítások hatályba lépéséig marad hatályban.
                            <br />
                            <br />
                            A Szolgáltató bármikor jogosult jelen Általános Szerződési Feltételeket egyoldalúan módosítani. A módosítások a honlapon történő megjelenéssel válnak hatályossá. Amennyiben a módosításra a Felhasználó által megrendelt termék megrendelése után, de a megrendelés visszaigazolása, illetve a teljesítés előtt kerül sor, a Szolgáltató csak a Felhasználó javára térhet el a megrendelés leadásakor a honlapon feltüntetett Általános Szerződési Feltételektől.
                            <br />
                            <br />
                            Felek kijelentik, hogy szerződéses kapcsolatukból fakadó esetleges vitáik során elsődlegesen békésen kívánnak eljárni. Békés úton nem rendezhető viták esetére Felek kikötik értékhatártól függően a Miskolci Járásbíróság, illetve a Miskolci Törvényszék kizárólagos illetékességét.
                            <br />
                            <br />
                            Tevékenységünkkel kapcsolatos esetleges panasz esetén Miskolc Megyei Jogú Város Jegyzőjéhez (3525 Miskolc, Városház tér 8.), illetve az 1. és 2.. számú mellékletekben rögzített hatóságokhoz fordulhat.
                        </p>
                        <p>
                            Kelt és lezárva: Miskolc, 2018. május 25.
                            <br />
                            <br />
                            Mellékletek:
                            <br />
                            <br />
                            1. Számú melléklet: <a target="_blank" href="/wp-content/uploads/docs/Oxy_1sz_melleklet_adatkezelesi_tajekoztato.pdf">Adatkezelési tájékoztató</a>
                            <br />
                            2. Számú melléklet: <a target="_blank" href="/wp-content/uploads/docs/Oxy_2sz_melleklet_tajekoztato.pdf">Általános tájékoztató</a>
                            <br />
                            3. Számú melléklet: <a target="_blank" href="/wp-content/uploads/docs/Oxy_3sz_melleklet_szavatossagi_tajekoztato.pdf">Szavatossági, jótállási tájékoztató</a>
                            <br />
                            4. Számú melléklet: <a target="_blank" href="/wp-content/uploads/docs/Oxy_4sz_melleklet_felmondasi_nyilatkozat.docx">Felmondási nyilatkozat</a>
                            <br />
                            <a target="_blank" href="/wp-content/uploads/docs/Oxy_adatvedelmi_incidens_jegyzokonyv_minta.docx">Adatvédelmi incidens jegyzőkönyv</a>
                            <br />
                            <a target="_blank" href="/wp-content/uploads/docs/Oxy_Panaszkezelesi_jegyzokonyv_minta.docx">Panaszkezelési jegyzőkönyv</a>
                        </p>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(TermsAndConditions as any);
