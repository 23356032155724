import * as React from 'react';
import { Modal, ModalBody, ModalHeader, Toast, ToastBody } from 'reactstrap';
import './toast-container.css';

interface ToastContainerProps {
    open: boolean;
    success: boolean;
    message?: string | null;
    close?: () => void;
    getOperationFinished: boolean;
}

interface ToastContainerState {
    modalOpen: boolean;
}

export class ToastContainer extends React.PureComponent<ToastContainerProps, ToastContainerState> {
    constructor(props: ToastContainerProps) {
        super(props);

        this.state = {
            modalOpen: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ToastContainerProps) {
        if (!nextProps.success && !this.props.open && nextProps.open) {
            this.setState({
                modalOpen: true
            });
        }
    }

    public render() {
        const { open, success, message, getOperationFinished } = this.props;
        return (
            success
                ?
                <div className="toast-container">
                    <Toast isOpen={open && !getOperationFinished} className={`toast-${success ? "success" : "danger"}`}>
                        <ToastBody>
                            {success ? "Sikeres művelet." : "Sikertelen művelet."}
                            {message != null && <div>{message}</div>}
                        </ToastBody>
                    </Toast>
                </div>
                :
                <Modal isOpen={this.state.modalOpen && !getOperationFinished} toggle={this.closeModal} className="modal-dialog-centered">
                    <ModalHeader toggle={this.closeModal}>Hiba</ModalHeader>
                    <ModalBody>
                        <div>
                            <p>A művelet végrehajtása közben hiba történt.</p>
                            <p>{message}</p>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <button onClick={this.closeModal} type="button" className="btn btn-primary">
                                OK
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
        );
    }

    private closeModal = () => {
        this.setState({
            modalOpen: false
        });

        if (this.props.close != null) {
            this.props.close();
        }
    }
}

export default ToastContainer;
