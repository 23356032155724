import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store/index';
import * as OxyStore from '../../../store/OxyStore';
import NavMenuShop from './../NavMenuShop';
import Footer from '../../Footer';
import ScrollToTop from '../../ScrollToTop';
import SubscriptionCard from './SubscriptionCard';
import { SubscriptionDto } from '../../../models';

type SubscriptionsProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface SubscriptionsState {
    search: string;
    filteredSubscriptions: SubscriptionDto[];
}

class Subscriptions extends React.PureComponent<SubscriptionsProps, SubscriptionsState> {
    constructor(props: SubscriptionsProps) {
        super(props);

        this.state = {
            search: "",
            filteredSubscriptions: props.subscriptions
        }
    }

    componentDidMount() {
        if (this.props.subscriptions.length === 0) {
            this.props.getAllSubscriptions();
        }
    }

    componentWillReceiveProps(nextProps: SubscriptionsProps) {
        if (this.props.subscriptions.length !== nextProps.subscriptions.length) {
            this.setState({
                search: "",
                filteredSubscriptions: nextProps.subscriptions
            })
        }
    }

    public render() {
        require("../../../shop.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="container checkout-main-container mb-4">
                    <h1>Feliratkozások</h1>
                    <div className="mt-2 mb-2">
                        <div className="form-group">
                            <input type="text" className="form-control" autoComplete="off" placeholder="Keresés..." value={this.state.search} onChange={this.handleSearchChange} />
                        </div>
                    </div>
                    {this.state.filteredSubscriptions.length === 0 && <div>
                        <h4>Nem található feliratkozás.</h4>
                    </div>}
                    {this.state.filteredSubscriptions.length > 0 && <div className="list-group">
                        {this.state.filteredSubscriptions.map((subscription, index) => {
                            return (
                                <SubscriptionCard
                                    key={index}
                                    subscription={subscription}
                                />
                            );
                        })}
                    </div>}
                </div>
                <Footer />
            </React.Fragment>
        );
    }

    private handleSearchChange = (e: any) => {
        const search = e.target.value;

        this.setState({
            search: search,
            filteredSubscriptions: this.filterSubscriptions(this.props.subscriptions, search)
        });
    }

    private filterSubscriptions = (subscriptions: SubscriptionDto[], search: string): SubscriptionDto[] => {
        search = search.toLowerCase();
        return subscriptions.filter(subscription => {
            if (subscription.firstName.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if (subscription.lastName.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if (subscription.email.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if (subscription.couponCode != null && subscription.couponCode.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            return false;
        });
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(Subscriptions as any);
