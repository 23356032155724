import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { CurrentOfferEditorCard } from "./CurrentOfferEditorCard";
import { ImageDto, PageDto, CurrentOfferDto, ScrollingPageDto } from "../../models";
import { CurrentOfferPayload, ReorderPayload } from "../../payloads";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrentOfferEditor } from "./CurrentOfferEditor";
import Reorder from 'react-reorder';

interface CurrentOfferEditorModalProps {
    currentOffers: CurrentOfferDto[];
    images: ImageDto[];
    pages: PageDto[];
    scrollingPages: ScrollingPageDto[];
    add: (payload: CurrentOfferPayload) => void;
    update: (id: number, payload: CurrentOfferPayload) => void;
    delete: (id: number) => void;
    reorder: (payload: ReorderPayload) => void;
    isOpen: boolean;
    toggle: () => void;
    uploadImage: (file: File) => void;
}

interface CurrentOfferEditorModalState {
    currentOfferIdForEdit: number;
    currentOfferBeingEdited: CurrentOfferPayload;
}

export class CurrentOfferEditorModal extends React.PureComponent<CurrentOfferEditorModalProps, CurrentOfferEditorModalState> {
    constructor(props: CurrentOfferEditorModalProps) {
        super(props);

        this.state = {
            currentOfferIdForEdit: NaN,
            currentOfferBeingEdited: {
                title: "",
                imageId: NaN,
                targetId: null,
                targetScrollingId: null,
                crop: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100
                }
            }
        }
    }

    public render() {
        const noMoreAllowed = this.props.currentOffers.length >= 12;

        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={this.props.toggle}>Bőrproblémák kezelése szerkesztése</ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-end mb-4">
                            <Button color="primary" onClick={() => this.toggleEditor(0)} disabled={noMoreAllowed}>
                                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új hozzáadása
                            </Button>
                        </div>
                        <div className="mt-2 mb-2">
                            <p>
                                A bőrproblémák kezelése sorrendjének módosításához egyszerűen fogd meg az adott bőrproblémák kezelését, és húzd a kívánt helyre!
                            </p>
                        </div>
                        {noMoreAllowed &&
                            <div className="mb-4">
                                <p>
                                    Új bőrproblémák kezelése nem adható hozzá, mert az egységes kinézet miatt legfeljebb 12 bőrproblémák kezelése lehet a rendszerben.
                                </p>
                            </div>
                        }
                        <div>
                            <Reorder
                                listClass="reorder-container"
                                itemKey="id"
                                list={this.props.currentOffers}
                                sharedProps={{
                                    onImageClick: this.onItemImageClick,
                                    onDeleteClick: this.onItemDeleteClick,
                                    onEditClick: this.onItemEditClick
                                }}
                                holdTime={200}
                                template={CurrentOfferEditorCard}
                                callback={this.handleReorder}
                                itemClicked={() => { }}
                                itemClass=""
                            />
                        </div>
                    </ModalBody>
                </Modal>
                <CurrentOfferEditor
                    isCreate={this.state.currentOfferIdForEdit === 0}
                    isOpen={!isNaN(this.state.currentOfferIdForEdit)}
                    toggle={this.toggleEditor}
                    images={this.props.images}
                    pages={this.props.pages}
                    scrollingPages={this.props.scrollingPages}
                    save={this.save}
                    onImageClick={() => { }}
                    payload={this.state.currentOfferBeingEdited}
                    uploadImage={this.props.uploadImage}
                />
            </React.Fragment>
        );
    }

    private save = (payload: CurrentOfferPayload) => {
        if (this.state.currentOfferIdForEdit === 0) {
            this.props.add(payload);
        } else {
            this.props.update(this.state.currentOfferIdForEdit, payload);
        }

        this.toggleEditor();
    }

    private handleReorder = (event: any,
        itemThatHasBeenMoved: ImageDto,
        itemsPreviousIndex: number,
        itemsNewIndex: number,
        reorderedArray: ImageDto[]) => {
        if (itemsPreviousIndex === itemsNewIndex) {
            return;
        }

        const payload: ReorderPayload = {
            idsInOrder: reorderedArray.map(x => x.id)
        };

        this.props.reorder(payload);
    }

    private toggleEditor = (id: number = NaN) => {
        this.setState({
            currentOfferIdForEdit: id,
            currentOfferBeingEdited: isNaN(id)
                ? {
                    title: "",
                    imageId: NaN,
                    targetId: null,
                    targetScrollingId: null,
                    crop: {
                        x: 0,
                        y: 0,
                        width: 100,
                        height: 100
                    }
                }
                : this.state.currentOfferBeingEdited
        });
    }

    private onItemImageClick = (name: string) => {
    }

    private onItemDeleteClick = (id: number) => {
        this.props.delete(id);
    }

    private onItemEditClick = (id: number) => {
        const currentOffer = this.props.currentOffers.find(x => x.id === id)!!;
        const payload: CurrentOfferPayload = {
            title: currentOffer.title,
            imageId: currentOffer.image.id,
            targetId: currentOffer.target != null ? currentOffer.target.id : null,
            targetScrollingId: currentOffer.targetScrolling != null ? currentOffer.targetScrolling.id : null,
            crop: {
                x: 0,
                y: 0,
                width: 100,
                height: 100
            }
        }

        this.setState({
            currentOfferIdForEdit: id,
            currentOfferBeingEdited: payload
        });
    }
}

export default CurrentOfferEditorModal;
