import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ScrollingPageItemDto } from '../models';

interface OxyScrollingPageItemImageLeftProps {
    item: ScrollingPageItemDto;
}

class OxyScrollingPageItemImageLeft extends React.PureComponent<OxyScrollingPageItemImageLeftProps> {
    public render() {
        return (
            <React.Fragment>
                <section className="elementor-element elementor-element-7df7355c elementor-section-stretched elementor-section-full_width elementor-hidden-desktop elementor-hidden-tablet elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="7df7355c" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}" style={{ width: '2543px', left: '0px' }}>
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-row">
                            <div className="elementor-element elementor-element-7e871a7e elementor-column elementor-col-50 elementor-top-column" data-id="7e871a7e" data-element_type="column">
                                <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-cae3a6b elementor-widget elementor-widget-heading" data-id="cae3a6b" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">{this.props.item.title}</h2>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-74c64e1 elementor-widget elementor-widget-text-editor" data-id="74c64e1" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <p>
                                                        <span className="color_15">{this.props.item.subtitle}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-1fb50c2e elementor-widget elementor-widget-text-editor" data-id="1fb50c2e" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <div id="comp-jtmki20t" className="txtNew" data-packed="true">
                                                        <p className="font_9" style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: this.props.item.text.replace(/\n/g, "<br />") }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-5d50ecc elementor-column elementor-col-50 elementor-top-column" data-id="5d50ecc" data-element_type="column">
                                <div className="elementor-column-wrap elementor-element-populated" style={{ backgroundImage: `url('/wp-content/uploads/new/${this.props.item.image.name}')`, backgroundSize: isMobileOnly ? "contain" : "unset", backgroundRepeat: isMobileOnly ? "no-repeat" : "unset" }}>
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-442219c elementor-widget elementor-widget-spacer" data-id="442219c" data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="elementor-element elementor-element-b15480c elementor-section-stretched elementor-section-full_width elementor-hidden-phone elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b15480c" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-row">
                            <div className="elementor-element elementor-element-45fc03d elementor-column elementor-col-50 elementor-top-column" data-id="45fc03d" data-element_type="column">
                                <div className="elementor-column-wrap elementor-element-populated" style={{ backgroundImage: `url('/wp-content/uploads/new/${this.props.item.image.name}')`, backgroundPosition: "center left", backgroundRepeat: "no-repeat", backgroundSize: "auto" }}>
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-2cb4a92 elementor-widget elementor-widget-spacer" data-id="2cb4a92" data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-ca2c67c elementor-column elementor-col-50 elementor-top-column" data-id="ca2c67c" data-element_type="column">
                                <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-9aee49f elementor-widget elementor-widget-heading" data-id="9aee49f" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">{this.props.item.title}</h2>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-d413a5f elementor-widget elementor-widget-text-editor" data-id="d413a5f" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <p>
                                                        <span className="color_15">{this.props.item.subtitle}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-968abc4 elementor-widget elementor-widget-text-editor" data-id="968abc4" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <div id="comp-jtmki20t" className="txtNew" data-packed="true">
                                                        <p className="font_9" style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: this.props.item.text.replace(/\n/g, "<br />") }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default OxyScrollingPageItemImageLeft;
