import * as React from "react";
import {
  Button,
  Card,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../../store/index";
import * as OxyStore from "../../../store/OxyStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavMenuAdmin from "../../admin/NavMenuAdmin";
import Reorder from "react-reorder";
import { AboutUsSectionDto } from "../../../models";
import { ProductPayload, ReorderPayload } from "../../../payloads";
import { ProductEditorCard } from "./ProductEditorCard";
import ImagePickerModal from "../../admin/ImagePickerModal";
import AdminBackButton from "../../admin/AdminBackButton";
import { ProductCategoryDto } from "../../../models/shop";
import { Constants } from "../../../utils";

type ProductEditorProps = OxyStore.OxyState &
  typeof OxyStore.actionCreators &
  RouteComponentProps<{ id?: string }>;

interface ProductEditorState {
  category: ProductCategoryDto | null;
  editId: number;
  imagePickerOpen: boolean;
  payload: ProductPayload;
  validationErrors: string[];
  productShortDescriptionTooLongModalOpen: boolean;
}

class ProductEditor extends React.PureComponent<
  ProductEditorProps,
  ProductEditorState
> {
  constructor(props: ProductEditorProps) {
    super(props);

    this.state = {
      category: null,
      editId: NaN,
      imagePickerOpen: false,
      payload: {
        name: "",
        shortDescription: "",
        longDescription: "",
        hasOptions: false,
        price: 0,
        imageId: NaN,
        imageCrop: {
          x: 0,
          y: 0,
          width: 100,
          height: 100,
        },
        couponRedeemEnabled: false,
      },
      validationErrors: [],
      productShortDescriptionTooLongModalOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.productCategories.length === 0) {
      this.props.getAllProductCategories();
    }

    const id = this.props.match.params.id;
    const idAsNumber = Number(id);
    if (
      !isNaN(idAsNumber) &&
      idAsNumber > 0 &&
      this.props.productCategories.length > 0
    ) {
      const category = this.props.productCategories.find(
        (x) => x.id === idAsNumber
      );

      if (category == null) {
        window.location.href = "/shop/admin";
      } else {
        this.setState({
          category: category,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps: ProductEditorProps) {
    if (
      this.props.productCategories.length !==
        nextProps.productCategories.length ||
      (!this.props.productCategoryOperationFinished &&
        nextProps.productCategoryOperationFinished)
    ) {
      const id = this.props.match.params.id;
      const idAsNumber = Number(id);
      if (
        !isNaN(idAsNumber) &&
        idAsNumber > 0 &&
        nextProps.productCategories.length > 0
      ) {
        const category = nextProps.productCategories.find(
          (x) => x.id === idAsNumber
        );

        if (category == null) {
          window.location.href = "/shop/admin";
        } else {
          this.setState({
            category: category,
          });
        }
      }
    }
  }

  public render() {
    return (
      <React.Fragment>
        <NavMenuAdmin />
        {this.state.category != null && (
          <Container>
            <AdminBackButton to="/shop/admin" />
            <div>
              <h1>Ajándékutalványok kezelése</h1>
            </div>

            <div className="mb-2">
              <p>
                Az elemek sorrendjének módosításához egyszerűen fogd meg az
                adott elemet, és húzd a kívánt helyre!
              </p>
            </div>
            <div className="d-flex justify-content-end mb-3">
              <Button onClick={() => this.toggleEditor(-1)} color="primary">
                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új
                hozzáadása
              </Button>
            </div>
            <Reorder
              listClass="list-group"
              itemKey="id"
              list={this.state.category.products}
              sharedProps={{
                onEditClick: this.toggleEditor,
                onDeleteClick: this.onDeleteClick,
              }}
              holdTime={200}
              template={ProductEditorCard}
              callback={this.handleReorder}
              itemClicked={() => {}}
              itemClass="list-group-item list-group-item-action"
            />
            {this.state.category.products.length === 0 && (
              <div>
                <h3>Nem található termék.</h3>
              </div>
            )}
          </Container>
        )}
        {this.getEditModal()}
        {this.getProductShortDescriptionTooLongModal()}
        <ImagePickerModal
          isOpen={this.state.imagePickerOpen}
          toggle={this.toggleImagePicker}
          images={this.props.imagesPaged.data}
          onImageSelected={this.handleImageSelected}
          aspectRatio={400 / 266}
          uploadImage={this.props.uploadImage}
          isPaged={true}
          getImagesPaged={this.props.getImagesPaged}
          pageCount={this.props.imagesPaged.pageCount}
        />
      </React.Fragment>
    );
  }

  private getEditModal = () => {
    return (
      <Modal
        isOpen={!isNaN(this.state.editId)}
        toggle={() => this.toggleEditor(NaN)}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => this.toggleEditor(NaN)}>
          Termék {this.state.editId > 0 ? "szerkesztése" : "hozzáadása"}
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="inputProductName">Név</label>
            <input
              type="text"
              className="form-control"
              id="inputProductName"
              autoComplete="off"
              placeholder="Írd be a nevet."
              value={this.state.payload.name}
              onChange={this.handleNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputProductShortDescription">Leírás</label>
            <textarea
              rows={5}
              className="form-control"
              id="inputProductShortDescription"
              autoComplete="off"
              placeholder="Írd be a leírást."
              value={
                this.state.payload.shortDescription.length <
                Constants.productShortDescriptionMaxLength
                  ? this.state.payload.shortDescription
                  : this.state.payload.longDescription
              }
              onChange={this.handleShortDescriptionChange}
            />
          </div>
          {this.state.payload.shortDescription.length >=
            Constants.productShortDescriptionMaxLength - 10 &&
            this.state.payload.shortDescription.length <
              Constants.productShortDescriptionMaxLength && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                A felső leírásba még{" "}
                {Constants.productShortDescriptionMaxLength -
                  this.state.payload.shortDescription.length}{" "}
                karakter kerülhet. A további leírás a lenti, hosszú
                termékleírasban fog megjelenni.
              </div>
            )}
          {this.state.payload.shortDescription.length >=
            Constants.productShortDescriptionMaxLength && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              A rövid leírás maximális hosszát elérted, a teljes szöveg a lenti,
              hosszú termékleírásban fog megjelenni.
            </div>
          )}
          <div className="form-check mt-2 mb-2">
            <div className="d-flex justify-content-start">
              <input
                type="checkbox"
                className="form-check-input"
                id="inputCouponRedeemEnabled"
                checked={this.state.payload.couponRedeemEnabled}
                onChange={this.handleCouponRedeemEnabledChange}
              />
              <label
                className="form-check-label"
                htmlFor="inputCouponRedeemEnabled"
              >
                Kupon beváltható ehhez a termékhez
              </label>
            </div>
          </div>
          <div className="form-check mt-2 mb-2">
            <div className="d-flex justify-content-start">
              <input
                type="checkbox"
                className="form-check-input"
                id="inputHasOptions"
                checked={this.state.payload.hasOptions}
                onChange={this.handleHasOptionsChange}
              />
              <label className="form-check-label" htmlFor="inputHasOptions">
                Megadok hossz opciókat a termékhez
              </label>
            </div>
          </div>
          {!this.state.payload.hasOptions && (
            <div className="form-group">
              <label htmlFor="inputProductPrice">Ár (Ft)</label>
              <input
                type="text"
                className="form-control"
                id="inputProductPrice"
                autoComplete="off"
                placeholder="Írd be az árat."
                value={this.state.payload.price || 0}
                onChange={this.handlePriceChange}
              />
            </div>
          )}
          <div className="d-flex mt-2">
            <div
              style={{
                height: "200px",
                minHeight: "200px",
                maxHeight: "200px",
              }}
            >
              {!isNaN(this.state.payload.imageId) &&
                this.props.images.find(
                  (x) => x.id === this.state.payload.imageId
                ) != null && (
                  <img
                    alt=""
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      overflow: "hidden",
                    }}
                    src={`/wp-content/uploads/new/${
                      this.props.images.find(
                        (x) => x.id === this.state.payload.imageId
                      )!!.name
                    }`}
                  />
                )}
            </div>
            <div>
              <Button color="primary" onClick={this.toggleImagePicker}>
                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Kép
                kiválasztása
              </Button>
            </div>
          </div>

          {this.state.validationErrors.length > 0 && (
            <Card className="mt-4 mb-2 p-2 bg-danger text-white">
              {this.state.validationErrors.map((error, index) => {
                return (
                  <div key={index} className="form-invalid">
                    {error}
                  </div>
                );
              })}
            </Card>
          )}

          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              onClick={this.handleSaveClick}
              type="button"
              className="btn btn-primary"
              disabled={!this.isValid()}
            >
              <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
            </button>

            <button
              className="btn btn-secondary"
              onClick={() => this.toggleEditor(NaN)}
            >
              <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private getProductShortDescriptionTooLongModal = () => {
    return (
      <Modal
        isOpen={this.state.productShortDescriptionTooLongModalOpen}
        toggle={this.toggleProductShortDescriptionTooLongModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.toggleProductShortDescriptionTooLongModal}>
          Termékleírás túl hosszú
        </ModalHeader>
        <ModalBody>
          <div>
            <p>
              A beírt szöveg túl hosszú. A fenti, rövid termékleírásban
              rövidítve fog megjelenni, a teljes szöveg a lenti, hosszú
              termékleírásban lesz csak olvasható.
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn btn-secondary"
              onClick={this.toggleProductShortDescriptionTooLongModal}
            >
              <FontAwesomeIcon icon={["fas", "check"]} /> OK
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private toggleProductShortDescriptionTooLongModal = () => {
    this.setState({
      productShortDescriptionTooLongModalOpen:
        !this.state.productShortDescriptionTooLongModalOpen,
    });
  };

  private handleImageSelected = (
    id: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          imageId: id,
          imageCrop: {
            x,
            y,
            width,
            height,
          },
        },
        imagePickerOpen: false,
      },
      () => this.validate(true)
    );
  };

  private toggleImagePicker = () => {
    this.setState({
      imagePickerOpen: !this.state.imagePickerOpen,
    });
  };

  private handleSaveClick = () => {
    if (this.state.editId <= 0) {
      this.props.addProduct(this.state.category!!.id, this.state.payload);
    } else {
      this.props.updateProduct(this.state.editId, this.state.payload);
    }

    this.toggleEditor(NaN);
  };

  private onDeleteClick = (id: number, isVisible: boolean) => {
    this.props.toggleProductVisible(id, isVisible);
  };

  private handleNameChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          name: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private handleHasOptionsChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          hasOptions: e.target.checked,
          price: e.target.checked ? 0 : null,
        },
      },
      () => this.validate(true)
    );
  };

  private handleCouponRedeemEnabledChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          couponRedeemEnabled: e.target.checked,
        },
      },
      () => this.validate(true)
    );
  };

  private handlePriceChange = (e: any) => {
    if (!isNaN(e.target.value)) {
      const targetValue = parseInt(e.target.value.replace(/\D/, ""));
      this.setState(
        {
          payload: {
            ...this.state.payload,
            price: isNaN(targetValue) ? 0 : targetValue,
          },
        },
        () => {
          this.validate(true);
        }
      );
    }
  };

  private handleShortDescriptionChange = (e: any) => {
    const targetShortDescription =
      e.target.value.length > Constants.productShortDescriptionMaxLength
        ? e.target.value.substring(
            0,
            Constants.productShortDescriptionMaxLength
          )
        : e.target.value;
    const modalOpen =
      this.state.payload.shortDescription.length <
        Constants.productShortDescriptionMaxLength &&
      e.target.value.length >= Constants.productShortDescriptionMaxLength;
    this.setState(
      {
        payload: {
          ...this.state.payload,
          shortDescription: targetShortDescription,
          longDescription:
            targetShortDescription.length >=
            Constants.productShortDescriptionMaxLength
              ? e.target.value
              : "",
        },
        productShortDescriptionTooLongModalOpen: modalOpen,
      },
      () => this.validate(true)
    );
  };

  private toggleEditor = (id: number) => {
    const targetId = this.state.editId === id ? NaN : id;
    const productToEdit = this.state.category!!.products.find(
      (x) => x.id === targetId
    );

    this.setState({
      editId: targetId,
      payload: {
        name: productToEdit == null ? "" : productToEdit.name,
        shortDescription:
          productToEdit == null ? "" : productToEdit.shortDescription,
        longDescription:
          productToEdit == null ? "" : productToEdit.longDescription,
        hasOptions: productToEdit == null ? false : productToEdit.hasOptions,
        price: productToEdit == null ? 0 : productToEdit.price,
        imageId: productToEdit == null ? NaN : productToEdit.image.id,
        imageCrop: {
          x: 0,
          y: 0,
          width: 100,
          height: 100,
        },
        couponRedeemEnabled:
          productToEdit == null ? false : productToEdit.couponRedeemEnabled,
      },
    });
  };

  private handleReorder = (
    event: any,
    itemThatHasBeenMoved: AboutUsSectionDto,
    itemsPreviousIndex: number,
    itemsNewIndex: number,
    reorderedArray: AboutUsSectionDto[]
  ) => {
    if (itemsPreviousIndex === itemsNewIndex) {
      return;
    }

    const payload: ReorderPayload = {
      idsInOrder: reorderedArray.map((x) => x.id),
    };

    this.props.reorderProducts(this.state.category!!.id, payload);
  };

  private isValid = () => {
    return this.validate();
  };

  private validate = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.payload.name.length === 0) {
      validationErrors.push("A név megadása kötelező.");
    }

    if (this.state.payload.shortDescription.length === 0) {
      validationErrors.push("A rövid leírás megadása kötelező.");
    }

    if (
      this.state.payload.shortDescription.length >
      Constants.productShortDescriptionMaxLength
    ) {
      validationErrors.push("A rövid leírás túl hosszú.");
    }

    if (isNaN(this.state.payload.imageId)) {
      validationErrors.push("A kép kiválasztása kötelező.");
    }

    if (
      !this.state.payload.hasOptions &&
      (this.state.payload.price == null || this.state.payload.price <= 0)
    ) {
      validationErrors.push(
        "Ha nem adunk meg hossz opciókat a termékhez, az ár megadása kötelező."
      );
    }

    if (shouldSetState) {
      this.setState({
        validationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };
}

export default connect(
  (state: ApplicationState) => state.oxy,
  OxyStore.actionCreators
)(ProductEditor as any);
