import * as React from 'react';
import { AboutUsSectionDto } from "../../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface AboutUsSectionEditorCardProps {
    item: AboutUsSectionDto;
    sharedProps?: {
        onEditClick: (id: number) => void;
        onDeleteClick?: (id: number) => void;
    }
}

interface AboutUsSectionEditorCardState {
    deleteModalOpen: boolean;
}

export class AboutUsSectionEditorCard extends React.PureComponent<AboutUsSectionEditorCardProps, AboutUsSectionEditorCardState> {
    constructor(props: AboutUsSectionEditorCardProps) {
        super(props);

        this.state = {
            deleteModalOpen: false
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-between align-items-center align-content-center cursor-pointer">
                    <span>{this.props.item.title}</span>
                    {this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null && <div>
                        <Button onClick={() => this.props.sharedProps!!.onEditClick(this.props.item.id)} color="primary" className="mr-3">
                            <FontAwesomeIcon icon={["fas", "pencil-alt"]} className="mr-1" /> Szerkesztés
                        </Button>
                        <Button onClick={this.handleDeleteClick} color="danger">
                            <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                        </Button>
                    </div>}
                </div>
                <Modal isOpen={this.state.deleteModalOpen} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                    <ModalHeader toggle={this.handleCancelDeleteClick}>Rólunk szekció törlése</ModalHeader>
                    <ModalBody>
                        <div>
                            <p>
                                Biztosan törölni szeretnéd a szekciót?
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-danger">
                                <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                            </button>

                            <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    private handleDeleteClick = () => {
        this.setState({
            deleteModalOpen: true
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteModalOpen: false
        });
    }

    private handleConfirmDeleteClick = () => {
        if (this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null) {
            this.props.sharedProps.onDeleteClick(this.props.item.id);
            this.setState({
                deleteModalOpen: false
            });
        }
    }
}

export default AboutUsSectionEditorCard;
