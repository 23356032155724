import { ProtectedRestApi } from "./";
import { ServiceResult, AboutUsSectionDto } from "../models";
import { AboutUsSectionPayload, ReorderPayload } from "../payloads";

export class AboutUsApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyaboutus";

    public getAboutUsSections(): Promise<ServiceResult<AboutUsSectionDto[]>> {
        return this.httpGet(`${AboutUsApi.baseUrl}/all`, true) as Promise<ServiceResult<AboutUsSectionDto[]>>;
    }

    public addAboutUsSection(payload: AboutUsSectionPayload): Promise<ServiceResult<AboutUsSectionDto>> {
        return this.httpPut(`${AboutUsApi.baseUrl}/add`, payload) as Promise<ServiceResult<AboutUsSectionDto>>;
    }

    public updateAboutUsSection(id: number, payload: AboutUsSectionPayload): Promise<ServiceResult<AboutUsSectionDto>> {
        return this.httpPost(`${AboutUsApi.baseUrl}/${id}`, payload) as Promise<ServiceResult<AboutUsSectionDto>>;
    }

    public deleteAboutUsSection(id: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${AboutUsApi.baseUrl}/${id}`) as Promise<ServiceResult<boolean>>;
    }

    public reorderAboutUsSections(payload: ReorderPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${AboutUsApi.baseUrl}/reorder`, payload) as Promise<ServiceResult<boolean>>;
    }
}
