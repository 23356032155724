import * as React from 'react';
import { BeforeAfterGalleryItemDto } from "../../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

interface BeforeAfterGalleryItemEditorCardProps {
    item: BeforeAfterGalleryItemDto;
    sharedProps?: {
        onDeleteClick: (id: number) => void;
    }
}

interface BeforeAfterGalleryItemEditorCardState {
}

export class BeforeAfterGalleryItemEditorCard extends React.PureComponent<BeforeAfterGalleryItemEditorCardProps, BeforeAfterGalleryItemEditorCardState> {
    constructor(props: BeforeAfterGalleryItemEditorCardProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-between align-items-center align-content-center cursor-pointer">
                    <span>{this.props.item.description}</span>
                    <div className="d-flex justify-content-between" style={{ gap: "15px" }}>
                        <img style={{ height: "120px" }} src={`/wp-content/uploads/new/${this.props.item.leftImage.name}`} />
                        <img style={{ height: "120px" }} src={`/wp-content/uploads/new/${this.props.item.rightImage.name}`} />
                    </div>
                    <div>
                        <Button onClick={() => this.props.sharedProps?.onDeleteClick(this.props.item.id)} color="danger">
                            <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BeforeAfterGalleryItemEditorCard;
