import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum AdminEditorBadgePosition {
    BottomLeft,
    BottomRight,
    TopLeft,
    TopRight
}

interface AdminEditorBadgeProps {
    position: AdminEditorBadgePosition;
    onClick: () => void;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    title?: string;
}

class AdminEditorBadge extends React.PureComponent<AdminEditorBadgeProps> {
    public render() {
        const buttonStyle: any = {
            position: "absolute",
            fontSize: "32px",
            fontWeight: 900,
            cursor: "pointer",
            color: "salmon",
            zIndex: 3000
        }

        switch (this.props.position) {
            case AdminEditorBadgePosition.BottomLeft:
                buttonStyle.bottom = this.props.bottom != null ? `${this.props.bottom}px` : "50px";
                buttonStyle.left = this.props.left != null ? `${this.props.left}px` : "50px";
                break;
            case AdminEditorBadgePosition.BottomRight:
                buttonStyle.bottom = this.props.bottom != null ? `${this.props.bottom}px` : "50px";
                buttonStyle.right = this.props.right != null ? `${this.props.right}px` : "50px";
                break;
            case AdminEditorBadgePosition.TopLeft:
                buttonStyle.top = this.props.top != null ? `${this.props.top}px` : "50px";
                buttonStyle.left = this.props.left != null ? `${this.props.left}px` : "50px";
                break;
            case AdminEditorBadgePosition.TopRight:
                buttonStyle.top = this.props.top != null ? `${this.props.top}px` : "50px";
                buttonStyle.right = this.props.right != null ? `${this.props.right}px` : "50px";
                break;
        }

        return (
            <React.Fragment>
                <div>
                    <span style={buttonStyle} onClick={this.props.onClick}>
                        <FontAwesomeIcon icon={["fas", "edit"]} />
                    </span>
                </div>
                {this.props.title != null && <div style={{ color: "#b18b5e", textAlign: "right", fontSize: "24px", marginTop: "12px" }}>
                    {this.props.title}
                </div>}
            </React.Fragment>
        );
    }
}

export default AdminEditorBadge;
