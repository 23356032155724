import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import NavMenuShop from './NavMenuShop';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';
import { OrderStatus } from '../../models/shop';
import { Button, Card } from 'reactstrap';
import queryString from 'query-string';

type OrderDetailsProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ id?: string }>;

interface OrderDetailsState {
    orderRequested: number;
    orderPollingInterval: number;
    paymentId: string;
}

class OrderDetails extends React.PureComponent<OrderDetailsProps, OrderDetailsState> {
    constructor(props: OrderDetailsProps) {
        super(props);

        this.state = {
            orderRequested: NaN,
            orderPollingInterval: NaN,
            paymentId: ""
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const idAsNumber = Number(id);
        if (!isNaN(idAsNumber) && idAsNumber > 0) {
            if (this.props.location != null && this.props.location.search != null) {
                const queryParams = queryString.parse(this.props.location.search);
                if (queryParams != null && queryParams.paymentId != null) {
                    this.setState({
                        paymentId: queryParams.paymentId as string
                    });

                    this.props.getOrder(idAsNumber, queryParams.paymentId as string);
                }
                else {
                    this.props.getOrder(idAsNumber, "");
                }
            }
            else {
                this.props.getOrder(idAsNumber, "");
            }
        }

        if (this.props.productCategories.length === 0) {
            this.props.getAllProductCategories();
        }
    }

    componentWillReceiveProps(nextProps: OrderDetailsProps) {
        const id = nextProps.match.params.id;
        const idAsNumber = Number(id);
        if (!isNaN(idAsNumber) && idAsNumber > 0) {
            if (this.props.order != null && (this.props.order.id !== idAsNumber && this.state.orderRequested !== idAsNumber))
                if (nextProps.location != null && nextProps.location.search != null) {
                    const queryParams = queryString.parse(nextProps.location.search);
                    if (queryParams != null && queryParams.paymentId != null) {
                        this.setState({
                            paymentId: queryParams.paymentId as string
                        });

                        this.setState({
                            orderRequested: idAsNumber
                        }, () => {
                            this.props.getOrder(idAsNumber, queryParams.paymentId as string);
                        });
                    }
                    else {
                        this.setState({
                            orderRequested: idAsNumber
                        }, () => {
                            this.props.getOrder(idAsNumber, "");
                        });
                    }
                }
                else {
                    this.setState({
                        orderRequested: idAsNumber
                    }, () => {
                        this.props.getOrder(idAsNumber, "");
                    });
                    this.props.getOrder(idAsNumber, "");
                }


            if (this.props.order == null && nextProps.order != null) {
                if (nextProps.order.status === OrderStatus.Ordered) {
                    this.setState({
                        orderPollingInterval: this.state.orderPollingInterval || window.setInterval(() => {
                            this.props.getOrder(idAsNumber, this.state.paymentId);
                        }, 3000)
                    });
                }
            }

            if (!isNaN(this.state.orderPollingInterval) && this.props.order != null && nextProps.order != null && this.props.order.status === OrderStatus.Ordered && nextProps.order.status !== OrderStatus.Ordered) {
                window.clearInterval(this.state.orderPollingInterval);
                this.setState({
                    orderPollingInterval: NaN
                });
            }
        }

        if (this.props.barionPayment == null && nextProps.barionPayment != null) {
            window.location.href = nextProps.barionPayment.gatewayUrl;
        }

        if (JSON.stringify(this.props.location) !== JSON.stringify(nextProps.location) && nextProps.location != null && nextProps.location.search != null) {
            const queryParams = queryString.parse(nextProps.location.search);
            if (queryParams != null && queryParams.paymentId != null) {
                this.setState({
                    paymentId: queryParams.paymentId as string
                });
            }
        }
    }

    private currencyFormatter = new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    public render() {
        require("../../shop.css");

        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="container checkout-main-container">
                    <h1>Rendelés részletei</h1>
                    {this.props.order != null && <Card className={`mt-3 mb-3 p-2 d-flex align-items-center order-status-message text-white ${this.props.order.status === OrderStatus.Ordered ? "bg-warning" : ((this.props.order.status === OrderStatus.Paid || this.props.order.status === OrderStatus.InvoiceGenerated) ? "bg-success" : "bg-danger")}`}>
                        {this.props.order.status === OrderStatus.Ordered && <p>A fizetési folyamat még nem zárult le.</p>}
                        {(this.props.order.status === OrderStatus.Paid || this.props.order.status === OrderStatus.InvoiceGenerated) && <p>A fizetési folyamat sikeresen lezárult.</p>}
                        {this.props.order.status === OrderStatus.PaymentFailedOrCancelled && <p>
                            <span>A fizetési folyamat megszakadt vagy sikertelen volt.</span>
                            <span className="ml-3">
                                <Button color="success" onClick={() => this.props.restartPayment(this.props.order!!.id)}>
                                    Fizetés újraindítása
                                </Button>
                            </span>
                        </p>}
                    </Card>}
                    {this.props.order != null && <h2 style={{ color: "#747474" }}>Kosár</h2>}
                    {this.props.order != null && this.props.productCategories.length > 0 && <div className="scrollbar-container ps">
                        {this.props.order.items.map((orderItem, index) => {
                            return (
                                <div key={index} className="cart-sidebar-product-container">
                                    <Link to={`/shop/product/${orderItem.product!!.id}`} className="cart-sidebar-product-image-container">
                                        <div style={{ display: 'inline-block', maxWidth: '100%', overflow: 'hidden', position: 'relative', boxSizing: 'border-box', margin: '0px' }}>
                                            <div style={{ boxSizing: 'border-box', display: 'block', maxWidth: '100%' }}>
                                                <img aria-hidden="true" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEwIiBoZWlnaHQ9IjEyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4=" style={{ maxWidth: '100%', display: 'block', margin: '0px', border: 'none', padding: '0px' }} />
                                            </div>
                                            <img src={`/wp-content/uploads/new/${orderItem.product!!.image.name}`} className="cart-sidebar-product-image" />
                                        </div>
                                    </Link>
                                    <div className="cart-sidebar-product-details-container">
                                        <div>
                                            <Link to={`/shop/product/${orderItem.product!!.id}`} className="cart-sidebar-product-details-name">{orderItem.product!!.name}{orderItem.productOption != null && ` - ${orderItem.productOption.name}`}</Link>
                                            <div className="cart-sidebar-product-amount">{orderItem.quantity} x <span className="cart-sidebar-product-price">{this.currencyFormatter.format(orderItem.productOption != null ? orderItem.productOption.price : orderItem.product!!.price!!)}</span></div>
                                            <div className="cart-sidebar-product-amount-buttons-container">
                                                <input
                                                    className="cart-sidebar-product-amount-input"
                                                    type="text"
                                                    size={9}
                                                    readOnly
                                                    value={orderItem.quantity}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                Ajándékozott neve: {orderItem.gifteeName}
                                            </div>
                                            {(this.props.order!!.status === OrderStatus.Paid || this.props.order!!.status === OrderStatus.InvoiceGenerated) && <React.Fragment>
                                                {orderItem.vouchers.map((voucher, index) => {
                                                    return (
                                                        <div key={index} className="mt-2">
                                                            <a target="_blank" href={`/api/oxyorder/voucher?orderId=${this.props.order!!.id}&orderItemId=${orderItem.id}&voucherId=${voucher.id}${this.props.loggedInUser == null ? "&paymentId=" + this.state.paymentId : ""}`}>Voucher{orderItem.vouchers.length === 1 ? "" : ` ${index + 1}`}: {voucher.voucherCode}</a>
                                                        </div>
                                                    );
                                                })}
                                            </React.Fragment>}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>}
                    {this.props.order == null && <div className="empty-cart-message-container">
                        <div className="empty-cart-icon">
                            <FontAwesomeIcon icon={["fas", "question-circle"]} />
                        </div>
                        <div className="empty-cart-text">
                            <h2>
                                A rendelés nem található.
                            </h2>
                        </div>
                    </div>}
                    {this.props.order != null && <h2 style={{ color: "#747474" }} className="mt-4">Rendelés adatai</h2>}
                    {this.props.order != null && <div>
                        <div className="form-group">
                            <label htmlFor="inputOrderDetailsName">Név</label>
                            <input type="text" className="form-control" id="inputOrderDetailsName" value={this.props.order.name} readOnly />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputOrderDetailsEmail">E-mail cím</label>
                            <input type="text" className="form-control" id="inputOrderDetailsEmail" value={this.props.order.email} readOnly />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputOrderDetailsBillingName">Számlázási név</label>
                            <input type="text" className="form-control" id="inputOrderDetailsBillingName" value={this.props.order.billingName} readOnly />
                        </div>
                        <h3 style={{ color: "#747474" }} className="mt-3">Számlázási cím</h3>
                        <div className="form-group">
                            <label htmlFor="inputOrderDetailsZipCode">Irányítószám</label>
                            <input type="text" className="form-control" id="inputOrderDetailsZipCode" value={this.props.order.billingAddress.zipCode || ""} readOnly />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputOrderDetailsCity">Település</label>
                            <input type="text" className="form-control" id="inputOrderDetailsCity" value={this.props.order.billingAddress.city} readOnly />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputOrderDetailsStreetAddress">Cím</label>
                            <input type="text" className="form-control" id="inputOrderDetailsStreetAddress" value={this.props.order.billingAddress.streetAddress} readOnly />
                        </div>
                    </div>}
                    {this.props.order != null && this.props.order.coupon != null && <div className="mt-4 mb-4">
                        Kupon <strong>{this.props.order.coupon.code}</strong>: <strong>-{this.currencyFormatter.format(this.props.order.coupon.value)}</strong>
                    </div>}
                    {this.props.order != null && <div className="mt-4 mb-4">
                        <h3>Összesen: {this.currencyFormatter.format(this.props.order.total)}</h3>
                    </div>}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(OrderDetails as any);
