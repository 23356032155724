import { ProtectedRestApi } from "./";
import { ServiceResult, ScrollingPageToDisplayDto, ScrollingPageDto } from "../models";

export class ScrollingPageApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxyscrollingpage";

    public getScrollingPageByPath(path: string): Promise<ServiceResult<ScrollingPageToDisplayDto>> {
        return this.httpGet(`${ScrollingPageApi.baseUrl}/by-path/${path}`, true) as Promise<ServiceResult<ScrollingPageToDisplayDto>>;
    }

    public getScrollingPages(): Promise<ServiceResult<ScrollingPageDto[]>> {
        return this.httpGet(`${ScrollingPageApi.baseUrl}/all`) as Promise<ServiceResult<ScrollingPageDto[]>>;
    }
}
