import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";
import { ApplicationState } from "../store/index";
import * as OxyStore from "../store/OxyStore";
import { NavMenu } from "./NavMenu";
import { Footer } from "./Footer";
import { ContactUsMessagePayload } from "../payloads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "reactstrap";
import { isMobileOnly } from "react-device-detect";

type ContactUsProps = OxyStore.OxyState &
  typeof OxyStore.actionCreators &
  RouteComponentProps<{}>;

interface ContactUsState {
  payload: ContactUsMessagePayload;
  validationErrors: string[];
}

class ContactUs extends React.PureComponent<ContactUsProps, ContactUsState> {
  constructor(props: ContactUsProps) {
    super(props);

    this.state = {
      payload: {
        senderName: "",
        senderAddress: "",
        message: "",
      },
      validationErrors: [],
    };
  }

  componentDidMount() {
    this.props.getMainPage();
  }

  componentWillReceiveProps(nextProps: ContactUsProps) {
    if (
      !this.props.contactUsMessageOperationFinished &&
      nextProps.contactUsMessageOperationFinished
    ) {
      window.location.replace("/sikeres-kapcsolatfelvetel");
    }
  }

  public render() {
    return (
      <div id="boxed-wrapper">
        <div className="fusion-sides-frame"></div>
        <div id="wrapper" className="fusion-wrapper">
          <div id="home" style={{ position: "relative", top: "-1px" }}></div>
          <div id="sliders-container"></div>

          <NavMenu {...this.props} />

          <div>
            <main id="main" className="clearfix width-100">
              <div className="fusion-row" style={{ maxWidth: "100%" }}>
                <section id="content" className="full-width">
                  <div
                    id="post-10879"
                    className="post-10879 page type-page status-publish hentry"
                  >
                    <div className="post-content">
                      <div
                        className="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-parallax-none nonhundred-percent-fullwidth non-hundred-percent-height-scrolling fusion-fullwidth-custom-background"
                        style={{
                          backgroundColor: "rgba(255,255,255,0.84)",
                          backgroundImage:
                            'url("/wp-content/uploads/new/placeholder-image.png")',
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          backgroundBlendMode: "overlay",
                          paddingTop: "0px",
                          paddingRight: "30px",
                          paddingBottom: "0px",
                          paddingLeft: "30px",
                          marginBottom: "0px",
                          marginTop: "0px",
                          borderWidth: "0px 0px 0px 0px",
                          borderColor: "#f7f7f7",
                          borderStyle: "solid",
                          WebkitBackgroundSize: "cover",
                          MozBackgroundSize: "cover",
                          OBackgroundSize: "cover",
                          backgroundSize: "cover",
                        }}
                      >
                        <div className="fusion-builder-row fusion-row">
                          <div
                            className="fusion-layout-column fusion_builder_column fusion-builder-column-0 fusion_builder_column_1_6 1_6 fusion-one-sixth fusion-column-first"
                            style={{
                              width:
                                "calc(16.666666666667% - ( ( 4% + 4% ) * 0.16666666666667 ) )",
                              marginRight: "4%",
                              marginTop: "0px",
                              marginBottom: "30px",
                            }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                WebkitBackgroundSize: "cover",
                                MozBackgroundSize: "cover",
                                OBackgroundSize: "cover",
                                backgroundSize: "cover",
                                padding: "0px 0px 0px 0px",
                              }}
                            >
                              <div className="fusion-clearfix" />
                            </div>
                          </div>
                          <div
                            className="fusion-layout-column fusion_builder_column fusion-builder-column-1 fusion_builder_column_2_3 2_3 fusion-two-third"
                            style={{
                              width:
                                "calc(66.666666666667% - ( ( 4% + 4% ) * 0.66666666666667 ) )",
                              marginRight: "4%",
                              marginTop: "0px",
                              marginBottom: "30px",
                            }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                WebkitBackgroundSize: "cover",
                                MozBackgroundSize: "cover",
                                OBackgroundSize: "cover",
                                backgroundSize: "cover",
                                padding: "0px 0px 0px 0px",
                              }}
                            >
                              <div
                                className="fusion-text fusion-text-1"
                                style={{
                                  fontSize: "19px",
                                  textAlign: "center",
                                }}
                              >
                                <h2>
                                  <strong>OXY ESZTÉTIKAI STÚDIÓ</strong>
                                </h2>
                                <p>
                                  <strong>
                                    <a
                                      href="https://www.google.com/maps/place/Miskolc,+Arany+J%C3%A1nos+t%C3%A9r+1,+3526/@48.1077192,20.787912,17z/data=!3m1!4b1!4m5!3m4!1s0x47409ffd1c2aa6b3:0xf3bd9d14ffffc462!8m2!3d48.1077192!4d20.787912?hl"
                                      target="_blank"
                                    >
                                      3526 Miskolc, Arany János tér 1.
                                    </a>
                                  </strong>
                                  <br />
                                  <strong>
                                    Telefon:{" "}
                                    <a href="tel:+36304642297">
                                      06 30 464 2297
                                    </a>
                                  </strong>
                                </p>
                                <p>
                                  <strong>
                                    E-mail:{" "}
                                    <a href="mailto:oxyesztetika@gmail.com">
                                      oxyesztetika@gmail.com
                                    </a>
                                  </strong>
                                </p>
                                <div className="d-flex justify-content-center">
                                  <table
                                    style={{ border: "0" }}
                                    className="contact-us-opening-hours-table"
                                  >
                                    <tbody>
                                      <tr
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <td colSpan={2}>
                                          <strong>Nyitvatartásunk:</strong>
                                          <br />
                                          <td colSpan={2}></td>Bejelentkezés
                                          alapján!
                                          <br />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <br />
                                <p>
                                  Bármikor kaphatsz nálunk részletes
                                  tájékoztatást és segítséget{" "}
                                  <strong>személyesen</strong>,{" "}
                                  <strong>e-mailben</strong> és{" "}
                                  <strong>telefonon</strong> egyaránt.
                                </p>
                                <p>
                                  Ha jobban kedveled a személyes ügyintézést, a{" "}
                                  <a href="/shop">webshop</a> mellett
                                  lehetőséged van termékek és ajándékkártyák
                                  megvásárlására is a szalonunkban.{" "}
                                </p>
                                <p>
                                  A szolgáltatások igénybevételéhez
                                  időpontegyeztetés szükséges! Kérlek, keress
                                  bármely fenti elérhetőségen minket vagy
                                  használd az{" "}
                                  <a href="https://oxy-esztetikai-studio.salonic.hu">
                                    online időponfoglalás
                                  </a>{" "}
                                  lehetőséget!{" "}
                                </p>
                                <p>
                                  <strong>
                                    Készséggel állunk rendelkezésedre!
                                  </strong>
                                </p>
                              </div>
                              <div className="fusion-clearfix" />
                            </div>
                          </div>
                          <div
                            className="fusion-layout-column fusion_builder_column fusion-builder-column-2 fusion_builder_column_1_6 1_6 fusion-one-sixth fusion-column-last"
                            style={{
                              width:
                                "calc(16.666666666667% - ( ( 4% + 4% ) * 0.16666666666667 ) )",
                              marginTop: "0px",
                              marginBottom: "30px",
                            }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                WebkitBackgroundSize: "cover",
                                MozBackgroundSize: "cover",
                                OBackgroundSize: "cover",
                                backgroundSize: "cover",
                                padding: "0px 0px 0px 0px",
                              }}
                            >
                              <div className="fusion-clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <strong>
                        <div
                          className="fusion-fullwidth fullwidth-box fusion-builder-row-9 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                          style={{
                            backgroundColor: "rgba(232,232,232,0.2)",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            paddingTop: "0px",
                            paddingRight: "30px",
                            paddingBottom: "0px",
                            paddingLeft: "30px",
                            marginBottom: "0px",
                            marginTop: "32px",
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#f7f7f7",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="fusion-builder-row fusion-row">
                            <div
                              className="fusion-fullwidth fullwidth-box fusion-builder-row-14 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                              style={{
                                backgroundColor: "rgba(188,151,120,0.1)",
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                paddingTop: "0px",
                                paddingRight: "30px",
                                paddingBottom: "0px",
                                paddingLeft: "30px",
                                marginBottom: "30px",
                                borderWidth: "0px 0px 0px 0px",
                                borderColor: "#f7f7f7",
                                borderStyle: "solid",
                              }}
                            >
                              <div className="fusion-builder-row fusion-row">
                                <div
                                  className="fusion-layout-column fusion_builder_column fusion-builder-column-40 fusion_builder_column_1_1 1_1 fusion-one-full fusion-column-first fusion-column-last"
                                  style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div
                                    className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                                    style={{
                                      backgroundPosition: "left top",
                                      backgroundRepeat: "no-repeat",
                                      WebkitBackgroundSize: "cover",
                                      MozBackgroundSize: "cover",
                                      OBackgroundSize: "cover",
                                      backgroundSize: "cover",
                                      padding: "0px 0px 0px 0px",
                                    }}
                                  >
                                    <div
                                      className="fusion-text fusion-text-33"
                                      style={{
                                        textAlign: "center",
                                        animationDuration: "2.5s",
                                      }}
                                      data-animationoffset="100%"
                                      data-animationtype="bounce"
                                      data-animationduration="2.5"
                                    >
                                      <h2 style={{ textAlign: "center" }}>
                                        Itt is tudsz nekünk üzenetet küldeni, ha
                                        kérdésed lenne!
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </strong>
                      <div className="row d-flex justify-content-center mt-3 mb-4 pb-5">
                        <form className="contact-us-form">
                          <div className="form-group">
                            <label htmlFor="inputContactUsSenderName">
                              Név
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputContactUsSenderName"
                              autoComplete="off"
                              placeholder="Írd be a neved."
                              value={this.state.payload.senderName}
                              onChange={this.handleSenderNameChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputContactUsSenderAddress">
                              E-mail cím
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputContactUsSenderName"
                              autoComplete="off"
                              placeholder="Írd be az e-mail címed."
                              value={this.state.payload.senderAddress}
                              onChange={this.handleSenderAddressChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputContactUsMessage">
                              Üzenet
                            </label>
                            <textarea
                              rows={5}
                              className="form-control"
                              id="inputContactUsMessage"
                              autoComplete="off"
                              placeholder="Írd be az üzenetet."
                              value={this.state.payload.message}
                              onChange={this.handleMessageChange}
                            />
                          </div>

                          {this.state.validationErrors.length > 0 && (
                            <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                              {this.state.validationErrors.map(
                                (error, index) => {
                                  return (
                                    <div key={index} className="form-invalid">
                                      {error}
                                    </div>
                                  );
                                }
                              )}
                            </Card>
                          )}

                          <div className="mt-3">
                            <button
                              onClick={this.handleSendMessageClick}
                              type="button"
                              className="btn btn-primary"
                              disabled={!this.isValid()}
                              style={{
                                boxShadow: "0 0 0 0.2rem rgb(177, 139, 94)",
                                borderColor: "rgb(177, 139, 94)",
                                backgroundColor: "rgb(177, 139, 94)",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={["fas", "paper-plane"]}
                                className="mr-1"
                              />{" "}
                              Küldés
                            </button>
                          </div>
                        </form>
                      </div>
                      <div
                        className="fusion-fullwidth fullwidth-box fusion-builder-row-15 nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                        style={{
                          backgroundColor: "#ffffff",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          paddingTop: "0px",
                          paddingRight: "30px",
                          paddingBottom: "0px",
                          paddingLeft: "30px",
                          borderWidth: "0px 0px 0px 0px",
                          borderColor: "#f7f7f7",
                          borderStyle: "solid",
                        }}
                      >
                        <div className="fusion-builder-row fusion-row">
                          <div
                            className="fusion-layout-column fusion_builder_column fusion-builder-column-41 fusion_builder_column_1_3 1_3 fusion-one-third fusion-column-first"
                            style={{
                              width:
                                "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                              marginRight: "4%",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                WebkitBackgroundSize: "cover",
                                MozBackgroundSize: "cover",
                                OBackgroundSize: "cover",
                                backgroundSize: "cover",
                                padding: "0px 0px 0px 0px",
                              }}
                            >
                              <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                  <div
                                    className="fusion-flip-box flip-up"
                                    tabIndex={0}
                                  >
                                    <div className="flip-box-inner-wrapper">
                                      <div
                                        className="flip-box-front"
                                        style={{
                                          marginLeft: isMobileOnly
                                            ? "unset"
                                            : "180px",
                                          marginRight: isMobileOnly
                                            ? "unset"
                                            : "-190px",
                                          backgroundColor: "#ffffff",
                                          borderColor: "rgba(255,255,255,0)",
                                          borderRadius: "4px",
                                          borderStyle: "solid",
                                          borderWidth: "1px",
                                          color: "#747474",
                                          transitionDuration: "1.6s",
                                        }}
                                      >
                                        <div className="flip-box-front-inner">
                                          <div
                                            className="flip-box-grafix flip-box-circle"
                                            style={{
                                              backgroundColor: "#b18b5e",
                                              borderColor: "#ebe1e1",
                                            }}
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="fa-envelope far"
                                              style={{ color: "#ffffff" }}
                                            />
                                          </div>
                                          <h2
                                            className="flip-box-heading"
                                            style={{ color: "#333333" }}
                                          >
                                            Írj nekünk!
                                          </h2>
                                          oxyesztetika@gmail.com
                                          <br />
                                          &nbsp;
                                        </div>
                                      </div>
                                      <div
                                        className="flip-box-back"
                                        style={{
                                          marginLeft: isMobileOnly
                                            ? "unset"
                                            : "180px",
                                          marginRight: isMobileOnly
                                            ? "unset"
                                            : "-190px",
                                          backgroundColor: "#b18b5e",
                                          borderColor: "rgba(255,255,255,0)",
                                          borderRadius: "4px",
                                          borderStyle: "solid",
                                          borderWidth: "1px",
                                          color: "#ffffff",
                                          transitionDuration: "1.6s",
                                        }}
                                      >
                                        <div className="flip-box-back-inner">
                                          <h3
                                            className="flip-box-heading-back"
                                            style={{ color: "#ffffff" }}
                                          >
                                            24 órán belül válaszolunk
                                          </h3>
                                          <p>
                                            Amennyiben bármilyen kérdésed
                                            felmerül, készséggel állunk
                                            rendelkezésedre!
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="clearfix" />
                              <div className="fusion-clearfix" />
                            </div>
                          </div>
                          <div
                            className="fusion-layout-column fusion_builder_column fusion-builder-column-42 fusion_builder_column_1_3 1_3 fusion-one-third"
                            style={{
                              width:
                                "calc(33.333333333333% - ( ( 4% + 4% ) * 0.33333333333333 ) )",
                              marginRight: "4%",
                              marginTop: "0px",
                              marginBottom: "30px",
                            }}
                          >
                            <div
                              className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                              style={{
                                backgroundPosition: "left top",
                                backgroundRepeat: "no-repeat",
                                WebkitBackgroundSize: "cover",
                                MozBackgroundSize: "cover",
                                OBackgroundSize: "cover",
                                backgroundSize: "cover",
                                padding: "0px 0px 0px 0px",
                              }}
                            >
                              <div className="fusion-flip-boxes flip-boxes row fusion-columns-1 flip-effect-classic">
                                <div className="fusion-flip-box-wrapper fusion-column col-lg-12 col-md-12 col-sm-12">
                                  <div
                                    className="fusion-flip-box flip-up"
                                    tabIndex={0}
                                  >
                                    <div className="flip-box-inner-wrapper">
                                      <div
                                        className="flip-box-front"
                                        style={{
                                          marginBottom: "-20px",
                                          marginLeft: "200px",
                                          marginRight: "-210px",
                                          backgroundColor: "#ffffff",
                                          borderColor: "rgba(255,255,255,0)",
                                          borderRadius: "4px",
                                          borderStyle: "solid",
                                          borderWidth: "1px",
                                          color: "#747474",
                                          transitionDuration: "1.6s",
                                        }}
                                      >
                                        <div className="flip-box-front-inner">
                                          <div
                                            className="flip-box-grafix flip-box-circle"
                                            style={{
                                              backgroundColor: "#b18b5e",
                                              borderColor: "#ebe1e1",
                                            }}
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="fa-phone fas"
                                              style={{ color: "#ffffff" }}
                                            />
                                          </div>
                                          <h2
                                            className="flip-box-heading"
                                            style={{ color: "#333333" }}
                                          >
                                            Hívj minket!
                                          </h2>
                                          06 30 464 2297
                                        </div>
                                      </div>
                                      <div
                                        className="flip-box-back"
                                        style={{
                                          marginLeft: isMobileOnly
                                            ? "unset"
                                            : "200px",
                                          marginRight: isMobileOnly
                                            ? "unset"
                                            : "-210px",
                                          backgroundColor: "#b18b5e",
                                          borderColor: "rgba(255,255,255,0)",
                                          borderRadius: "4px",
                                          borderStyle: "solid",
                                          borderWidth: "1px",
                                          color: "#ffffff",
                                          transitionDuration: "1.6s",
                                        }}
                                      >
                                        <div className="flip-box-back-inner">
                                          <h3
                                            className="flip-box-heading-back"
                                            style={{ color: "#ffffff" }}
                                          >
                                            Szeretettel várjuk a hívásodat{" "}
                                            <br /> kedd-péntek 9-18 óráig
                                            <br />
                                            hétfő, szombat 8-14 óráig
                                          </h3>
                                          <p>
                                            Időpontfoglalásban és teljeskörű
                                            tájékoztatásban bármikor segítséget
                                            nyújtunk.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="clearfix" />
                              <div className="fusion-clearfix" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>{" "}
              {/* fusion-row */}
            </main>{" "}
            {/* #main */}
            <Footer />
          </div>
        </div>
      </div>
    );
  }

  private handleSenderNameChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          senderName: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private handleSenderAddressChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          senderAddress: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private handleMessageChange = (e: any) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          message: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private handleSendMessageClick = () => {
    this.props.sendContactUsMessage(this.state.payload);
    this.setState({
      payload: {
        senderAddress: "",
        senderName: "",
        message: "",
      },
      validationErrors: [],
    });
  };

  private isValid = () => {
    return this.validate();
  };

  private validate = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.payload.senderName.length === 0) {
      validationErrors.push("A név megadása kötelező.");
    }

    if (this.state.payload.senderAddress.length === 0) {
      validationErrors.push("Az e-mail cím megadása kötelező.");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.state.payload.senderAddress
      )
    ) {
      validationErrors.push("Az e-mail cím érvénytelen.");
    }

    if (this.state.payload.message.length === 0) {
      validationErrors.push("Az üzenet megadása kötelező.");
    }

    if (shouldSetState) {
      this.setState({
        validationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };
}

export default connect(
  (state: ApplicationState) => state.oxy,
  OxyStore.actionCreators
)(ContactUs as any);
