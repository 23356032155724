import { ProtectedRestApi } from "./";
import { ServiceResult, ImageDto } from "../models";
import { PagedImageResult } from "../models/PagedImageResult";

export class ImageApi extends ProtectedRestApi {
  private static baseUrl = "/api/oxyimage";

  public getImages(): Promise<ServiceResult<ImageDto[]>> {
    return this.httpGet(`${ImageApi.baseUrl}/all`) as Promise<
      ServiceResult<ImageDto[]>
    >;
  }

  public getImagesPaged(
    page: number,
    pageSize: number,
    query: string
  ): Promise<ServiceResult<PagedImageResult>> {
    return this.httpGet(
      `${
        ImageApi.baseUrl
      }/all-paged?page=${page}&pageSize=${pageSize}&query=${encodeURIComponent(
        query
      )}`
    ) as Promise<ServiceResult<PagedImageResult>>;
  }

  public uploadImage(
    file: File,
    autoResize: boolean
  ): Promise<ServiceResult<ImageDto>> {
    const formData = new FormData();
    formData.append("file", file);

    return this.httpPutForm(
      `${ImageApi.baseUrl}/upload?autoresize=${autoResize ? "true" : "false"}`,
      formData
    ) as Promise<ServiceResult<ImageDto>>;
  }

  public deleteImage(id: number): Promise<ServiceResult<boolean>> {
    return this.httpDelete(`${ImageApi.baseUrl}/${id}`) as Promise<
      ServiceResult<boolean>
    >;
  }
}
