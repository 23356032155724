import * as React from 'react';
import { Button, Card, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import ImageCard from "./ImageCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavMenuAdmin from './NavMenuAdmin';
import AdminBackButton from './AdminBackButton';
import { ImageDto } from '../../models';

type ImageEditorProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface ImageEditorState {
    uploadModalOpen: boolean;
    imageFile: File | null;
    validationErrors: string[];
    search: string;
    filteredImages: ImageDto[];
    autoResize: boolean;
}

class ImageEditor extends React.PureComponent<ImageEditorProps, ImageEditorState> {
    constructor(props: ImageEditorProps) {
        super(props);

        this.state = {
            uploadModalOpen: false,
            imageFile: null,
            validationErrors: [],
            search: "",
            filteredImages: props.images,
            autoResize: false
        }
    }

    componentDidMount() {
        this.props.getImages();
    }

    componentWillReceiveProps(nextProps: ImageEditorProps) {
        if (this.props.images.length !== nextProps.images.length) {
            this.setState({
                filteredImages: this.filterImages(nextProps.images, this.state.search)
            });
        }
    }

    public render() {
        return (
            <React.Fragment>
                <NavMenuAdmin />
                <Container>
                    <AdminBackButton />
                    <div className="ml-2">
                        <h1>Képek kezelése</h1>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <div className="form-group">
                                <input type="text" className="form-control" id="inputImageSearch" autoComplete="off" placeholder="Keresés..." value={this.state.search} onChange={this.handleSearchChange} />
                            </div>
                        </div>
                        <div>
                            <Button color="primary" onClick={this.handleUploadClick}>
                                <FontAwesomeIcon icon={["fas", "cloud-upload-alt"]} className="mr-1" /> Feltöltés
                            </Button>
                        </div>
                    </div>
                    {this.state.filteredImages.length === 0 && <div>
                        <h3>Nem található kép.</h3>
                    </div>}
                    {this.state.filteredImages.length > 0 && <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {this.state.filteredImages.map((image, index) => {
                            return (
                                <ImageCard
                                    key={index}
                                    item={image}
                                    sharedProps={{
                                        onDeleteClick: this.handleDeleteImage
                                    }}
                                />
                            );
                        })}
                    </div>}
                    {this.getUploadModal()}
                </Container>
            </React.Fragment>
        );
    }

    private getUploadModal = () => {
        return (
            <Modal isOpen={this.state.uploadModalOpen} toggle={this.handleCancelUploadClick} className="modal-dialog-centered">
                <ModalHeader toggle={this.handleCancelUploadClick}>Kép feltöltése</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="inputNewImage">Kép</label>
                        <input type="file" className="form-control" id="inputNewImage" onChange={this.handleImageFileChange} />
                    </div>

                    <div className="form-check mt-2 mb-2">
                        <div className="d-flex justify-content-start">
                            <input type="checkbox" className="form-check-input" id="inputAutoResize" checked={this.state.autoResize} onChange={this.handleAutoResizeChange} />
                            <label className="form-check-label" htmlFor="inputAutoResize">Automatikus átméretezés</label>
                        </div>
                    </div>

                    {this.state.validationErrors.length > 0 &&
                        <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <div key={`copierModel-form-validation-error-${index}`} className="form-invalid">
                                        {error}
                                    </div>
                                );
                            })}
                        </Card>
                    }

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmUploadClick} type="button" className="btn btn-primary" disabled={!this.isValid()}>
                            <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Feltöltés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelUploadClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private filterImages = (images: ImageDto[], filter: string): ImageDto[] => {
        return filter.length === 0 ? images.slice() : images.slice().filter(x => x.name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
    }

    private handleSearchChange = (e: any) => {
        this.setState({
            search: e.target.value,
            filteredImages: this.filterImages(this.props.images, e.target.value)
        });
    }

    private handleAutoResizeChange = (e: any) => {
        this.setState({
            autoResize: e.target.checked
        });
    }

    private handleImageFileChange = (e: any) => {
        this.setState({
            imageFile: e.target.files[0]
        }, () => this.validate(true));
    }

    private handleConfirmUploadClick = () => {
        this.props.uploadImage(this.state.imageFile!!, this.state.autoResize);
        this.setState({
            imageFile: null,
            uploadModalOpen: false,
            autoResize: false
        });
    }

    private handleUploadClick = () => {
        this.setState({
            uploadModalOpen: true
        });
    }

    private handleCancelUploadClick = () => {
        this.setState({
            uploadModalOpen: false
        });
    }

    private handleDeleteImage = (id: number) => {
        this.props.deleteImage(id);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false) => {
        const validationErrors: string[] = [];

        if (this.state.imageFile == null) {
            validationErrors.push("A fájl megadása kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(ImageEditor as any);
