import * as React from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import NavMenuAdmin from "./NavMenuAdmin";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type AdminDashboardProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface AdminDashboardState {

}

class AdminDashboard extends React.PureComponent<AdminDashboardProps, AdminDashboardState> {
    constructor(props: AdminDashboardProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return this.props.loggedInUser != null && this.props.loggedInUser.isAdmin ?
            <React.Fragment>
                <NavMenuAdmin />
                <Card>
                    <CardBody className="d-flex flex-column align-items-center">
                        <div className="mb-3">
                            <a href="/admin/main" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "home"]} className="fa-fw mr-1" />
                                Főoldal szerkesztése
                            </a>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/pages" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "copy"]} className="fa-fw mr-1" />
                                Oldalak kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/garancia/garancia" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "award"]} className="fa-fw mr-1" />
                                Garancia oldal szerkesztése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/pricing-pages" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "dollar-sign"]} className="fa-fw mr-1" />
                                Árak kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/menu" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "list"]} className="fa-fw mr-1" />
                                Menü kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/about-us" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "id-card"]} className="fa-fw mr-1" />
                                Rólunk oldal szerkesztése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/about-us-gallery" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "id-card"]} className="fa-fw mr-1" />
                                Rólunk oldal galéria szerkesztése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/gallery" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "images"]} className="fa-fw mr-1" />
                                Galéria kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/admin/images" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "image"]} className="fa-fw mr-1" />
                                Képek kezelése
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to="/shop/admin" className="btn btn-lg btn-primary w-200-px">
                                <FontAwesomeIcon icon={["fas", "shopping-cart"]} className="fa-fw mr-1" />
                                Webshop
                            </Link>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
            : null
        ;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(AdminDashboard as any);
