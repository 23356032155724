import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Layout from './components/Layout';
import MainComponent from './components/MainComponent';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import InfraTrainer from './components/InfraTrainer';
import OxyPageComponent from './components/OxyPageComponent';
import OxyGalleryPageComponent from './components/OxyGalleryPageComponent';
import OxyGuaranteePageComponent from './components/OxyGuaranteePageComponent';
import OxyPricingPageComponent from './components/OxyPricingPageComponent';
import AdminDashboard from './components/admin/AdminDashboard';
import WebshopAdminDashboard from './components/shop/admin/WebshopAdminDashboard';
import ManagePages from './components/admin/ManagePages';
import ManagePricingPages from './components/admin/ManagePricingPages';
import MainComponentEditor from './components/admin/MainComponentEditor';
import AboutUsComponentEditor from './components/admin/AboutUsComponentEditor';
import OxyPageEditor from './components/admin/OxyPageEditor';
import OxyGuaranteePageEditor from './components/admin/OxyGuaranteePageEditor';
import PricingPageEditor from './components/admin/PricingPageEditor';
import ImageEditor from './components/admin/ImageEditor';
import MenuEditor from './components/admin/MenuEditor';
import BeforeAfterGalleryEditor from './components/admin/BeforeAfterGalleryEditor';
import AboutUsEditor from './components/admin/AboutUsEditor';
import ProductCategoryEditor from './components/shop/admin/ProductCategoryEditor';
import ProductEditor from './components/shop/admin/ProductEditor';
import ProductOptionEditor from './components/shop/admin/ProductOptionEditor';
import OrdersAdmin from './components/shop/admin/OrdersAdmin';
import Subscriptions from './components/shop/admin/Subscriptions';
import Coupons from './components/shop/admin/Coupons';
import Users from './components/shop/admin/Users';
import WebshopMainPage from './components/shop/WebshopMainPage';
import ProductDetails from './components/shop/ProductDetails';
import TermsAndConditions from './components/shop/TermsAndConditions';
import Checkout from './components/shop/Checkout';
import Profile from './components/shop/Profile';
import OrderDetails from './components/shop/OrderDetails';
import MyOrders from './components/shop/MyOrders';
import ThankYouForAppointment from './components/ThankYouForAppointment';
import ThankYouForContacting from './components/ThankYouForContacting';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import './custom.css';

import 'react-image-crop/dist/ReactCrop.css';

export default () => (
    <Layout>
        <Switch>
            <AuthorizeRoute exact path='/admin' component={AdminDashboard} />
            <AuthorizeRoute exact path='/admin/main' component={MainComponentEditor} />
            <AuthorizeRoute exact path='/admin/pages' component={ManagePages} />
            <AuthorizeRoute exact path='/admin/pricing-pages' component={ManagePricingPages} />
            <AuthorizeRoute exact path='/admin/images' component={ImageEditor} />
            <AuthorizeRoute exact path='/admin/menu' component={MenuEditor} />
            <AuthorizeRoute exact path='/admin/gallery' component={BeforeAfterGalleryEditor} />
            <AuthorizeRoute exact path='/admin/about-us' component={AboutUsEditor} />
            <AuthorizeRoute exact path='/admin/about-us-gallery' component={AboutUsComponentEditor} />
            <AuthorizeRoute exact path='/admin/oldal/:path' component={OxyPageEditor} />
            <AuthorizeRoute exact path='/admin/garancia/:path' component={OxyGuaranteePageEditor} />
            <AuthorizeRoute exact path='/admin/arak/:path' component={PricingPageEditor} />
            <AuthorizeRoute exact path='/shop/admin' component={WebshopAdminDashboard} />
            <AuthorizeRoute exact path='/shop/admin/categories' component={ProductCategoryEditor} />
            <AuthorizeRoute exact path='/shop/admin/category/:id' component={ProductEditor} />
            <AuthorizeRoute exact path='/shop/admin/product/:id' component={ProductOptionEditor} />
            <AuthorizeRoute exact path='/shop/admin/orders' component={OrdersAdmin} />
            <AuthorizeRoute exact path='/shop/admin/subscriptions' component={Subscriptions} />
            <AuthorizeRoute exact path='/shop/admin/coupons' component={Coupons} />
            <AuthorizeRoute exact path='/shop/admin/users' component={Users} />
            <Route exact path='/' component={MainComponent} />
            <Route exact path='/rolunk' component={AboutUs} />
            <Route exact path='/galeria' component={OxyGalleryPageComponent} />
            <Route exact path='/garancia/:path' component={OxyGuaranteePageComponent} />
            <Route exact path='/sikeres-idopontfoglalas' component={ThankYouForAppointment} />
            <Route exact path='/sikeres-kapcsolatfelvetel' component={ThankYouForContacting} />
            <Route exact path='/kapcsolat' component={ContactUs} />
            <Route exact path='/oldal/infratrainer-alakformalas' component={InfraTrainer} />
            <Route exact path='/oldal/:path' component={OxyPageComponent} />
            <Route exact path='/arak/:path' component={OxyPricingPageComponent} />
            <Route exact path='/shop' component={WebshopMainPage} />
            <Route exact path='/shop/product/:id' component={ProductDetails} />
            <Route exact path='/shop/aszf' component={TermsAndConditions} />
            <Route exact path='/shop/checkout' component={Checkout} />
            <Route exact path='/shop/order/:id' component={OrderDetails} />
            <AuthorizeRoute exact path='/shop/profile' component={Profile} />
            <AuthorizeRoute exact path='/shop/my-orders' component={MyOrders} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            <Redirect to='/' />
        </Switch>
    </Layout>
);
