import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store/index';
import * as OxyStore from '../../../store/OxyStore';
import NavMenuShop from './../NavMenuShop';
import Footer from '../../Footer';
import ScrollToTop from '../../ScrollToTop';
import OrderAdminCard from './OrderAdminCard';
import { OrderDto } from '../../../models/shop';

type OrdersAdminProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface OrdersAdminState {
    search: string;
    filteredOrders: OrderDto[];
}

class OrdersAdmin extends React.PureComponent<OrdersAdminProps, OrdersAdminState> {
    constructor(props: OrdersAdminProps) {
        super(props);

        this.state = {
            search: "",
            filteredOrders: props.allOrders
        }
    }

    componentDidMount() {
        if (this.props.allOrders.length === 0) {
            this.props.getAllOrders();
        }
    }

    componentWillReceiveProps(nextProps: OrdersAdminProps) {
        if (this.props.allOrders.length !== nextProps.allOrders.length) {
            this.setState({
                search: "",
                filteredOrders: nextProps.allOrders
            })
        }
    }

    public render() {
        require("../../../shop.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="container checkout-main-container mb-4 orders-list-container">
                    <h1>Rendelések</h1>
                    <div className="mt-2 mb-2">
                        <div className="form-group">
                            <input type="text" className="form-control" autoComplete="off" placeholder="Keresés..." value={this.state.search} onChange={this.handleSearchChange} />
                        </div>
                    </div>
                    {this.state.filteredOrders.length === 0 && <div>
                        <h4>Nem található rendelés.</h4>
                    </div>}
                    {this.state.filteredOrders.length > 0 && <div className="list-group">
                        {this.state.filteredOrders.map((order, index) => {
                            return (
                                <OrderAdminCard
                                    key={index}
                                    order={order}
                                />
                            );
                        })}
                    </div>}
                </div>
                <Footer />
            </React.Fragment>
        );
    }

    private handleSearchChange = (e: any) => {
        const search = e.target.value;

        this.setState({
            search: search,
            filteredOrders: this.filterOrders(this.props.allOrders, search)
        });
    }

    private filterOrders = (orders: OrderDto[], search: string): OrderDto[] => {
        search = search.toLowerCase();
        return orders.filter(order => {
            if (order.name.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if (order.billingName.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if (order.items.find(orderItem => orderItem.vouchers.find(voucher => voucher.voucherCode.toLowerCase().indexOf(search) > -1) != null) != null) {
                return true;
            }

            return false;
        });
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(OrdersAdmin as any);
