import * as React from "react";
import { Button, Card, Modal, ModalHeader, ModalBody } from "reactstrap";
import ImageCard from "./ImageCard";
import { ImageDto } from "../../models";
import { ImageCropperModal } from "./ImageCropperModal";
import { Crop } from "react-image-crop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Constants } from "../../utils";
import ReactPaginate from "react-paginate";

interface ImagePickerModalProps {
  images: ImageDto[];
  onImageSelected: (
    id: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) => void;
  isOpen: boolean;
  toggle: () => void;
  aspectRatio?: number;
  uploadImage: (file: File) => void;
  isPaged?: boolean;
  getImagesPaged?: (page: number, pageSize: number, query: string) => void;
  pageCount?: number;
}

interface ImagePickerModalState {
  uploadModalOpen: boolean;
  imageFile: File | null;
  validationErrors: string[];
  cropperModalOpen: boolean;
  selectedImage: ImageDto | null;
  search: string;
  page: number;
}

export class ImagePickerModal extends React.PureComponent<
  ImagePickerModalProps,
  ImagePickerModalState
> {
  private debounceTimeout: any;

  constructor(props: ImagePickerModalProps) {
    super(props);

    this.state = {
      uploadModalOpen: false,
      imageFile: null,
      validationErrors: [],
      cropperModalOpen: false,
      selectedImage: null,
      search: "",
      page: 0,
    };

    this.debounceTimeout = null;
  }

  componentDidMount(): void {
    if (this.props.images.length === 0 && this.props.getImagesPaged != null) {
      this.props.getImagesPaged(0, Constants.pageSize, "");
    }
  }

  public render() {
    const filteredImages =
      this.props.isPaged || this.state.search.length === 0
        ? this.props.images
        : this.props.images.filter(
            (x) =>
              x.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
          );

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className="modal-dialog-centered modal-lg"
        >
          <ModalHeader toggle={this.props.toggle}>Kép kiválasztása</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputImageSearch"
                    autoComplete="off"
                    placeholder="Keresés..."
                    value={this.state.search}
                    onChange={this.handleSearchChange}
                  />
                </div>
              </div>
              <div>
                <Button color="primary" onClick={this.handleUploadClick}>
                  <FontAwesomeIcon
                    icon={["fas", "cloud-upload-alt"]}
                    className="mr-1"
                  />{" "}
                  Feltöltés
                </Button>
              </div>
            </div>
            {filteredImages.length === 0 && (
              <div>
                <h3>Nem található kép.</h3>
              </div>
            )}
            {filteredImages.length > 0 && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {filteredImages.map((image, index) => {
                  return (
                    <ImageCard
                      key={index}
                      item={image}
                      sharedProps={{
                        onImageClick: () => this.onImageSelected(image.id),
                      }}
                    />
                  );
                })}
              </div>
            )}
            {this.props.isPaged && (
              <ReactPaginate
                className="react-paginate"
                breakLabel="..."
                nextLabel="Következő >"
                onPageChange={this.handlePageClick}
                pageRangeDisplayed={2}
                pageCount={this.props.pageCount!!}
                previousLabel="< Előző"
                renderOnZeroPageCount={null}
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            )}
            {this.getUploadModal()}
            <ImageCropperModal
              isOpen={this.state.cropperModalOpen}
              toggle={this.toggleCropperModal}
              image={this.state.selectedImage}
              aspectRatio={this.props.aspectRatio}
              onConfirmCrop={this.onConfirmCrop}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }

  private handlePageClick = (selectedItem: { selected: number }) => {
    this.setState(
      {
        ...this.state,
        page: selectedItem.selected,
      },
      () => {
        if (this.props.getImagesPaged != null) {
          this.props.getImagesPaged(
            this.state.page,
            Constants.pageSize,
            this.state.search
          );
        }
      }
    );
  };

  private getUploadModal = () => {
    return (
      <Modal
        isOpen={this.state.uploadModalOpen}
        toggle={this.handleCancelUploadClick}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.handleCancelUploadClick}>
          Kép feltöltése
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="inputNewImage">Kép</label>
            <input
              type="file"
              className="form-control"
              id="inputNewImage"
              onChange={this.handleImageFileChange}
            />
          </div>

          {this.state.validationErrors.length > 0 && (
            <Card className="mt-2 mb-2 p-2 bg-danger text-white">
              {this.state.validationErrors.map((error, index) => {
                return (
                  <div
                    key={`copierModel-form-validation-error-${index}`}
                    className="form-invalid"
                  >
                    {error}
                  </div>
                );
              })}
            </Card>
          )}

          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              onClick={this.handleConfirmUploadClick}
              type="button"
              className="btn btn-primary"
              disabled={!this.isValid()}
            >
              <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" />{" "}
              Feltöltés
            </button>

            <button
              className="btn btn-secondary"
              onClick={this.handleCancelUploadClick}
            >
              <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private handleSearchChange = (e: any) => {
    if (this.props.isPaged) {
      if (this.debounceTimeout != null) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (this.props.getImagesPaged != null) {
          this.props.getImagesPaged(
            this.state.page,
            Constants.pageSize,
            this.state.search
          );
        }
      }, Constants.debounceTimeoutMs);
    }

    this.setState({
      search: e.target.value,
    });
  };

  private handleImageFileChange = (e: any) => {
    this.setState(
      {
        imageFile: e.target.files[0],
      },
      () => this.validate(true)
    );
  };

  private handleConfirmUploadClick = () => {
    this.props.uploadImage(this.state.imageFile!!);
    this.setState({
      imageFile: null,
      uploadModalOpen: false,
    });
  };

  private handleUploadClick = () => {
    this.setState({
      uploadModalOpen: true,
    });
  };

  private handleCancelUploadClick = () => {
    this.setState({
      uploadModalOpen: false,
    });
  };

  private toggleCropperModal = () => {
    this.setState({
      cropperModalOpen: !this.state.cropperModalOpen,
    });
  };

  private onImageSelected = (id: number) => {
    this.setState({
      selectedImage: this.props.images.find((x) => x.id === id)!!,
      cropperModalOpen: true,
    });
  };

  private onConfirmCrop = (crop: Partial<Crop>) => {
    if (this.state.selectedImage != null) {
      this.props.onImageSelected(
        this.state.selectedImage.id,
        crop.x!!,
        crop.y!!,
        crop.width!!,
        crop.height!!
      );

      this.setState({
        selectedImage: null,
        cropperModalOpen: false,
      });
    }
  };

  private isValid = () => {
    return this.validate();
  };

  private validate = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (this.state.imageFile == null) {
      validationErrors.push("A fájl megadása kötelező.");
    }

    if (shouldSetState) {
      this.setState({
        validationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };
}

export default ImagePickerModal;
