import { ProtectedRestApi } from "./";
import { ServiceResult } from "../models";
import { ContactUsMessagePayload } from "../payloads";

export class ContactUsApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxycontactus";

    public sendContactUsMessage(payload: ContactUsMessagePayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${ContactUsApi.baseUrl}/message`, payload, true, true) as Promise<ServiceResult<boolean>>;
    }
}
