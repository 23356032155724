import * as OxyStore from './OxyStore';

export interface ApplicationState {
    oxy: OxyStore.OxyState | undefined;
}

export const reducers = {
    oxy: OxyStore.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
