import * as React from 'react';
import { SubscriptionDto } from '../../../models';

interface SubscriptionCardProps {
    subscription: SubscriptionDto;
}

interface SubscriptionCardState {
}

class SubscriptionCard extends React.PureComponent<SubscriptionCardProps, SubscriptionCardState> {
    constructor(props: SubscriptionCardProps) {
        super(props);
    }

    public render() {
        return (
            <React.Fragment>
                <div className="list-group-item d-flex justify-content-between">
                    <div>{this.props.subscription.lastName} {this.props.subscription.firstName}</div>
                    <div>{this.props.subscription.email}</div>
                    <div>{this.props.subscription.couponCode != null ? this.props.subscription.couponCode : "(nincs kuponja)"}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default SubscriptionCard;
