import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { OrderDto, VoucherDto } from '../../../models/shop';

interface OrderAdminCardProps {
    order: OrderDto;
}

interface OrderAdminCardState {
}

class OrderAdminCard extends React.PureComponent<OrderAdminCardProps, OrderAdminCardState> {
    constructor(props: OrderAdminCardProps) {
        super(props);
    }

    private currencyFormatter = new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    private dateFormatter = new Intl.DateTimeFormat("hu-HU", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
    });

    public render() {
        const vouchers: VoucherDto[] = [];

        for (const orderItem of this.props.order.items) {
            if (vouchers.length >= 4) {
                break;
            }

            for (const voucher of orderItem.vouchers) {
                if (vouchers.length >= 4) {
                    break;
                }

                vouchers.push(voucher);
            }
        }

        return (
            <React.Fragment>
                <Link to={`/shop/order/${this.props.order.id}`} className={`list-group-item list-group-item-action d-flex justify-content-between ${isMobileOnly ? " flex-column" : ""}`}>
                    <div className="order-name-date-container">{this.props.order.name} - {this.dateFormatter.format(this.props.order.orderedAt)}</div>
                    {vouchers.length > 0 && <div className="order-voucher-column">Voucher{vouchers.length > 0 ? " 1" : ""}: {vouchers[0].voucherCode}</div>}
                    {vouchers.length > 1 && <div className="order-voucher-column">Voucher 2: {vouchers[1].voucherCode}</div>}
                    {vouchers.length > 2 && <div className="order-voucher-column">Voucher 3: {vouchers.length > 0 ? " 1" : ""}{vouchers[2].voucherCode}</div>}
                    {vouchers.length > 3 && <div className="order-voucher-column">Voucher 4: {vouchers.length > 0 ? " 1" : ""}{vouchers[3].voucherCode}</div>}
                    {vouchers.length === 1 && <React.Fragment><div className="order-voucher-column"></div><div className="order-voucher-column"></div><div className="order-voucher-column"></div></React.Fragment>}
                    {vouchers.length === 2 && <React.Fragment><div className="order-voucher-column"></div><div className="order-voucher-column"></div></React.Fragment>}
                    {vouchers.length === 3 && <React.Fragment><div className="order-voucher-column"></div></React.Fragment>}
                    <div>{this.currencyFormatter.format(this.props.order.total)}</div>
                </Link>
            </React.Fragment>
        );
    }
}

export default OrderAdminCard;
