import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as OxyStore from '../store/OxyStore'
import { NavMenu } from "./NavMenu";
import { Footer } from "./Footer";
import { isMobileOnly } from 'react-device-detect';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Card } from 'reactstrap';

interface OxyGalleryPageComponentOwnProps {
}

type OxyGalleryPageComponentProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{ path: string }>
    & OxyGalleryPageComponentOwnProps;

interface OxyGalleryPageComponentState {
    currentIndex: number;
}

const splideOptions: any = {
    type: "slide",
    perPage: 1,
    perMove: 1,
    start: 0,
    focus: "center",
    updateOnMove: true,
    drag: isMobileOnly
};

const imageStyle = {
    height: isMobileOnly ? "200px" : "400px"
};

class OxyGalleryPageComponent extends React.PureComponent<OxyGalleryPageComponentProps, OxyGalleryPageComponentState> {
    constructor(props: OxyGalleryPageComponentProps) {
        super(props);

        this.state = {
            currentIndex: isMobileOnly ? 0 : 1
        }
    }

    public componentDidMount() {
        this.props.getAllBeforeAfterGalleries();
    }

    public render() {
        return (
            <div id="boxed-wrapper">
                <div className="fusion-sides-frame"></div>
                <div id="wrapper" className="fusion-wrapper" style={{ backgroundColor: "rgb(254, 248, 237)" }}>
                    <div id="home" style={{ position: "relative", top: "-1px" }}></div>
                    <div id="sliders-container">
                    </div>

                    <NavMenu {...this.props} />
                    <div>
                        <main id="main" className="clearfix width-100" style={{ marginBottom: "485px" }}>
                            <div className="d-flex justify-content-center align-items-center before-after-splide-container" style={{ flexDirection: "column", gap: "36px" }}>
                            {this.props.beforeAfterGalleries.filter(x => x.items.length > 0).map((gallery, index) => {
                                return (
                                    <Card key={`before-after-container-${index}`} style={{ width: isMobileOnly ? "unset" : "1000px", backgroundColor: "transparent" }} className="mt-5 pt-5 pb-5">
                                        <div className="d-flex justify-content-center">
                                            <h2>{gallery.title}</h2>
                                        </div>
                                        <Splide options={splideOptions}>
                                            {gallery.items.map((galleryItem, innerIndex) => {
                                                return (
                                                    <SplideSlide key={`before-after-gallery-${index}-splide-${innerIndex}`}>
                                                        <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
                                                            <div>
                                                                <div>
                                                                    <img style={{ ...imageStyle }} src={`/wp-content/uploads/new/${galleryItem.leftImage.name}`} />
                                                                </div>
                                                                <div className="d-flex justify-content-center">
                                                                    Előtte
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <img style={{ ...imageStyle }} src={`/wp-content/uploads/new/${galleryItem.rightImage.name}`} />
                                                                </div>
                                                                <div className="d-flex justify-content-center">
                                                                    Utána
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center mt-2">
                                                            <h4>{galleryItem.description}</h4>
                                                        </div>
                                                    </SplideSlide>
                                                );
                                            })}
                                        </Splide>
                                        {this.props.beforeAfterGalleries.flatMap(x => x.items).map((galleryItem, index) => {
                                            return (
                                                <React.Fragment>
                                                    <link key={index} rel="preload" href={`/wp-content/uploads/new/${galleryItem.leftImage.name}`} as="image" />
                                                    <link key={index} rel="preload" href={`/wp-content/uploads/new/${galleryItem.rightImage.name}`} as="image" />
                                                </React.Fragment>
                                            );
                                        })}
                                    </Card>
                                );
                            })}
                        </div>
                        </main>

                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(OxyGalleryPageComponent as any);
