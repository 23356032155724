import * as React from "react";
import { MenuItemDto, PageDto, ScrollingPageDto } from "../../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ReorderPayload, ServicesMenuItemPayload } from "../../payloads";
import { PagePickerModal } from "./PagePickerModal";
import { ScrollingPagePickerModal } from "./ScrollingPagePickerModal";
import Reorder from "react-reorder";
import { MenuItemEditorCard } from "./MenuItemEditorCard";

interface ServicesParentMenuItemEditorCardProps {
  item: MenuItemDto;
  sharedProps?: {
    onDeleteClick?: (id: number) => void;
    onLeafMenuItemAdd: (payload: ServicesMenuItemPayload) => void;
    pages: PageDto[];
    scrollingPages: ScrollingPageDto[];
    reorderMenuItems: (parentId: number, payload: ReorderPayload) => void;
    expandedId: number;
    toggleExpandedId: (id: number) => void;
  };
}

interface ServicesParentMenuItemEditorCardState {
  deleteModalOpen: boolean;
  addLeafMenuItemModalOpen: boolean;
  pagePickerOpen: boolean;
  scrollingPagePickerOpen: boolean;
  servicesMenuItemPayload: ServicesMenuItemPayload;
  validationErrors: string[];
}

export class ServicesParentMenuItemEditorCard extends React.PureComponent<
  ServicesParentMenuItemEditorCardProps,
  ServicesParentMenuItemEditorCardState
> {
  constructor(props: ServicesParentMenuItemEditorCardProps) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      addLeafMenuItemModalOpen: false,
      pagePickerOpen: false,
      scrollingPagePickerOpen: false,
      servicesMenuItemPayload: {
        title: "",
        parentMenuItemId: NaN,
        targetPageId: null,
        targetScrollingPageId: null,
      },
      validationErrors: [],
    };
  }

  public render() {
    const isExpanded =
      this.props.sharedProps!!.expandedId === this.props.item.id;

    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center align-content-center cursor-pointer">
          <span>{this.props.item.title}</span>
          {this.props.sharedProps != null &&
            this.props.sharedProps.onDeleteClick != null && (
              <div>
                <Button onClick={this.handleDeleteClick} color="danger">
                  <FontAwesomeIcon
                    icon={["fas", "trash-alt"]}
                    className="mr-1"
                  />{" "}
                  Törlés
                </Button>
                <span
                  className="ml-4 cursor-pointer"
                  onClick={this.toggleExpanded}
                >
                  <FontAwesomeIcon
                    icon={["fas", isExpanded ? "chevron-up" : "chevron-down"]}
                    className="mr-1"
                  />
                </span>
              </div>
            )}
        </div>
        {isExpanded && (
          <div className="px-4">
            <div className="mt-2 mb-2">
              <p>
                Az elemek sorrendjének módosításához egyszerűen fogd meg az
                adott elemet, és húzd a kívánt helyre!
              </p>
            </div>
            <div className="d-flex justify-content-end mb-3">
              <Button onClick={this.toggleAddLeafMenuItemModal} color="primary">
                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új
                hozzáadása
              </Button>
            </div>
            <Reorder
              listClass="list-group"
              itemKey="id"
              list={this.props.item.children}
              sharedProps={{
                onDeleteClick: this.onMenuItemDeleteClick,
              }}
              holdTime={200}
              template={MenuItemEditorCard}
              callback={this.handleMenuReorder}
              itemClicked={() => {}}
              itemClass="list-group-item list-group-item-action"
            />
          </div>
        )}
        <Modal
          isOpen={this.state.deleteModalOpen}
          toggle={this.handleCancelDeleteClick}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={this.handleCancelDeleteClick}>
            Menüelem törlése
          </ModalHeader>
          <ModalBody>
            <div>
              <p>Biztosan törölni szeretnéd a menüelemet?</p>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <button
                onClick={this.handleConfirmDeleteClick}
                type="button"
                className="btn btn-danger"
              >
                <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" />{" "}
                Törlés
              </button>

              <button
                className="btn btn-secondary"
                onClick={this.handleCancelDeleteClick}
              >
                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
              </button>
            </div>
          </ModalBody>
        </Modal>
        {this.getAddLeafMenuItemModal()}
        <PagePickerModal
          isOpen={this.state.pagePickerOpen}
          toggle={this.togglePagePicker}
          pages={
            this.props.sharedProps != null ? this.props.sharedProps.pages : []
          }
          onPageSelected={this.handlePageSelected}
        />
        <ScrollingPagePickerModal
          isOpen={this.state.scrollingPagePickerOpen}
          toggle={this.toggleScrollingPagePicker}
          scrollingPages={
            this.props.sharedProps != null
              ? this.props.sharedProps.scrollingPages
              : []
          }
          onScrollingPageSelected={this.handleScrollingPageSelected}
        />
      </React.Fragment>
    );
  }

  private getAddLeafMenuItemModal = () => {
    return (
      <Modal
        isOpen={this.state.addLeafMenuItemModalOpen}
        toggle={this.toggleAddLeafMenuItemModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.toggleAddLeafMenuItemModal}>
          Új menüelem hozzáadása
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="inputNewPricingMenuItemTitle">
              Új menüelem neve
            </label>
            <input
              type="text"
              className="form-control"
              id="inputNewPricingMenuItemTitle"
              autoComplete="off"
              placeholder="Írd be a nevet."
              value={this.state.servicesMenuItemPayload.title}
              onChange={this.handleMenuItemTitleChange}
            />
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div>
              Céloldal:{" "}
              {this.state.servicesMenuItemPayload.targetScrollingPageId == null
                ? " - "
                : this.props.sharedProps!!.scrollingPages.find(
                    (x) =>
                      x.id ===
                      this.state.servicesMenuItemPayload.targetScrollingPageId
                  )!!.name}
            </div>
            <div>
              <Button color="primary" onClick={this.toggleScrollingPagePicker}>
                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />{" "}
                Hivatkozás kiválasztása
              </Button>
            </div>
          </div>

          {this.state.validationErrors.length > 0 && (
            <Card className="mt-4 mb-2 p-2 bg-danger text-white">
              {this.state.validationErrors.map((error, index) => {
                return (
                  <div key={index} className="form-invalid">
                    {error}
                  </div>
                );
              })}
            </Card>
          )}

          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              onClick={this.handleNewMenuItemSaveClick}
              type="button"
              className="btn btn-primary"
              disabled={!this.isValid()}
            >
              <FontAwesomeIcon icon={["fas", "save"]} className="mr-1" /> Mentés
            </button>

            <button
              className="btn btn-secondary"
              onClick={this.toggleAddLeafMenuItemModal}
            >
              <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  private handleNewMenuItemSaveClick = () => {
    this.props.sharedProps?.onLeafMenuItemAdd(
      this.state.servicesMenuItemPayload
    );
    this.toggleAddLeafMenuItemModal();
  };

  private handleMenuItemTitleChange = (e: any) => {
    this.setState(
      {
        servicesMenuItemPayload: {
          ...this.state.servicesMenuItemPayload,
          title: e.target.value,
        },
      },
      () => this.validate(true)
    );
  };

  private onMenuItemDeleteClick = (id: number) => {
    this.props.sharedProps!!.onDeleteClick!!(id);
  };

  private handleMenuReorder = (
    event: any,
    itemThatHasBeenMoved: MenuItemDto,
    itemsPreviousIndex: number,
    itemsNewIndex: number,
    reorderedArray: MenuItemDto[]
  ) => {
    if (itemsPreviousIndex === itemsNewIndex) {
      return;
    }

    const payload: ReorderPayload = {
      idsInOrder: reorderedArray.map((x) => x.id),
    };

    this.props.sharedProps!!.reorderMenuItems(this.props.item.id, payload);
  };

  private toggleAddLeafMenuItemModal = () => {
    this.setState({
      addLeafMenuItemModalOpen: !this.state.addLeafMenuItemModalOpen,
      servicesMenuItemPayload: {
        title: "",
        parentMenuItemId: this.props.item.id,
        targetPageId: null,
        targetScrollingPageId: null,
      },
    });
  };

  private toggleExpanded = () => {
    this.props.sharedProps!!.toggleExpandedId(this.props.item.id);
  };

  private handlePageSelected = (id: number) => {
    this.setState(
      {
        servicesMenuItemPayload: {
          ...this.state.servicesMenuItemPayload,
          targetPageId: id,
          targetScrollingPageId: null,
        },
      },
      () => this.validate(true)
    );

    this.togglePagePicker();
  };

  private togglePagePicker = () => {
    this.setState({
      pagePickerOpen: !this.state.pagePickerOpen,
    });
  };

  private handleScrollingPageSelected = (id: number) => {
    this.setState(
      {
        servicesMenuItemPayload: {
          ...this.state.servicesMenuItemPayload,
          targetScrollingPageId: id,
          targetPageId: null,
        },
      },
      () => this.validate(true)
    );

    this.toggleScrollingPagePicker();
  };

  private toggleScrollingPagePicker = () => {
    this.setState({
      scrollingPagePickerOpen: !this.state.scrollingPagePickerOpen,
    });
  };

  private handleDeleteClick = () => {
    this.setState({
      deleteModalOpen: true,
    });
  };

  private handleCancelDeleteClick = () => {
    this.setState({
      deleteModalOpen: false,
    });
  };

  private handleConfirmDeleteClick = () => {
    if (
      this.props.sharedProps != null &&
      this.props.sharedProps.onDeleteClick != null
    ) {
      this.props.sharedProps.onDeleteClick(this.props.item.id);
      this.setState({
        deleteModalOpen: false,
      });
    }
  };

  private isValid = () => {
    return this.validate();
  };

  private validate = (shouldSetState: boolean = false) => {
    const validationErrors: string[] = [];

    if (
      this.state.servicesMenuItemPayload.targetPageId == null &&
      this.state.servicesMenuItemPayload.targetScrollingPageId == null
    ) {
      validationErrors.push(
        "A hivatkozni kívánt céloldal kötelezően választandó!"
      );
    }

    if (
      this.state.servicesMenuItemPayload.targetPageId != null &&
      this.state.servicesMenuItemPayload.targetScrollingPageId != null
    ) {
      validationErrors.push(
        "A hivatkozni kívánt céloldal kötelezően választandó!"
      );
    }

    if (shouldSetState) {
      this.setState({
        validationErrors: validationErrors,
      });
    }

    return validationErrors.length === 0;
  };
}

export default ServicesParentMenuItemEditorCard;
