import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as OxyStore from '../store/OxyStore';
import './NavMenu.css';

type NavMenuProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuState {
    mobileMenuOpen: boolean;
    mobileExpandedIndex: number;
    mobileSubExpandedIndex: number;
}

enum MobileMenuCategory {
    AboutUs,
    Services,
    Pricing,
    Webshop,
    SkinProblems
}

export class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
            mobileMenuOpen: false,
            mobileExpandedIndex: NaN,
            mobileSubExpandedIndex: NaN
        };
    }

    componentDidMount() {
        this.props.getServicesMenu();
        this.props.getPricingMenu();
        //this.props.getWebshopMenu();
        this.props.getSkinProblemsMenu();
    }

    public render() {
        return (
            <header className={`fusion-header-wrapper ${isMobileOnly ? "mobile" : ""}`}>
                <div className="fusion-header-v3 fusion-logo-alignment fusion-logo-left fusion-sticky-menu-1 fusion-sticky-logo-1 fusion-mobile-logo-  fusion-mobile-menu-design-modern">
                    <div className="fusion-secondary-header">
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '1500px', alignItems: 'center', margin: '0 auto' }}>
                            <div style={{ marginLeft: isMobileOnly ? 'unset' : '165px' }}>
                                <div className="fusion-contact-info">
                                    <span className="fusion-contact-info-phone-number">
                                        Várjuk hívásodat:&nbsp;<span className="fusion-contact-info-email-address"></span>
                                        <span className="fusion-contact-info-email-address">
                                            <a className="white-link" href="tel:06304642297">&nbsp;06&nbsp;30&nbsp;464&nbsp;2297</a>
                                        </span>
                                    </span>
                                    <span className="fusion-header-separator">|</span>
                                    <span className="fusion-contact-info-email-address">
                                        <a className="white-link" href="mailto:oxyesztetika@gmail.com">E‑mail:&nbsp;oxyesztetika@gmail.com</a>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className="fusion-social-links-header">
                                    <div className="fusion-social-networks boxed-icons">
                                        <div className="fusion-social-networks-wrapper">
                                            <a className="fusion-social-network-icon fusion-tooltip fusion-facebook fusion-icon-facebook" data-placement="bottom" data-title="Facebook" data-toggle="tooltip" title="Facebook" href="https://www.facebook.com/oxyesztetika" target="_blank" rel="noopener noreferrer"><span className="screen-reader-text">Facebook</span></a><a className="fusion-social-network-icon fusion-tooltip fusion-instagram fusion-icon-instagram" style={{}} data-placement="bottom" data-title="Instagram" data-toggle="tooltip" title="Instagram" href="https://www.instagram.com/oxyesztetikaistudio/" target="_blank" rel="noopener noreferrer"><span className="screen-reader-text">Instagram</span></a><a className="fusion-social-network-icon fusion-tooltip fusion-youtube fusion-icon-youtube" style={{}} data-placement="bottom" data-title="YouTube" data-toggle="tooltip" title="YouTube" href="https://www.youtube.com/channel/UCsHqPQEY9K1-LK2d0_H1IVA" target="_blank" rel="noopener noreferrer"><span className="screen-reader-text">YouTube</span></a><a className="fusion-social-network-icon fusion-tooltip fusion-mail fusion-icon-mail" style={{}} data-placement="bottom" data-title="Email" data-toggle="tooltip" title="Email" href="mailto: oxyesztetika@gmail.com" target="_self" rel="noopener noreferrer">
                                                <span className="screen-reader-text">Email</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fusion-header-sticky-height" />
                    <div className="fusion-header">
                        <div className="fusion-row menu-flex-row-not-sticky" style={{ display: 'flex', maxWidth: '1545px', justifyContent: isMobileOnly ? 'space-between' : 'flex-end', margin: '0 auto', padding: 0 }}>
                            <div className="fusion-logo" data-margin-top="31px" data-margin-bottom="31px" data-margin-left="0px" data-margin-right="0px" style={{ marginRight: isMobileOnly ? 'unset' : '65px' }}>
                                <a className="fusion-logo-link" style={{ maxHeight: "100px" }} href="/">
                                    {/* standard logo */}
                                    <img src="/wp-content/uploads/new/OXY_LOGO_PNG_vektoros_arany_feher_200.png" width={498} style={{ maxHeight: isMobileOnly ? '100px' : '200px', height: 'auto', marginTop: isMobileOnly ? '0' : "-50px" }} alt="Oxy Esztétikai Szépségszalon Logo" className={isMobileOnly ? "" : "fusion-standard-logo"} />
                                    {/* sticky header logo */}
                                    {!isMobileOnly && <img src="/wp-content/uploads/new/OXY_LOGO_PNG_vektoros_arany_feher.png" width={498} style={{ maxHeight: '100px', height: 'auto', marginTop: "-10px", marginBottom: "-10px", marginLeft: '80px', marginRight: '-80px' }} alt="Oxy Esztétikai Szépségszalon Logo" className="fusion-sticky-logo" />}
                                </a>
                            </div>
                            <nav className="fusion-main-menu" aria-label="Main Menu">
                                <ul id="menu-fomenu" className="fusion-menu">
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item">
                                        <a href="/" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Kezdőlap
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu">
                                        <a href="/rolunk" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Rólunk
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                            <span className="menu-text">
                                                Szolgáltatások
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.servicesMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                                            <span style={{ marginRight: '16px', fontSize: '16px' }}><i aria-hidden="true" className="fa-angle-left fas" style={{ color: '#b18b5e' }} /></span>
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                        {menuItem.children.length > 0 && <ul className="sub-menu">
                                                            {menuItem.children.map((subMenuItem, subIndex) => {
                                                                return (
                                                                    <li key={subIndex} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                                        <a href={`/oldal/${subMenuItem.targetPage != null ? subMenuItem.targetPage!!.path : subMenuItem.targetScrollingPage!!.path}`} className="fusion-bar-highlight">
                                                                            <span>{subMenuItem.title}</span>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-has-children fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                            <span className="menu-text">
                                                Bőrproblémák kezelése
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.skinProblemsMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a href={`/oldal/${menuItem.targetPage!!.path}`} className="fusion-bar-highlight">
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item">
                                        <a href="https://oxy-esztetikai-studio.salonic.hu/" className="fusion-bar-highlight" target="_blank">
                                            <span className="menu-text">
                                                Időpontfoglalás
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-has-children fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                            <span className="menu-text">
                                                Árak
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.pricingMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a href={`/arak/${menuItem.targetPricingPage!!.path}`} className="fusion-bar-highlight">
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/shop" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                WEBSHOP
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/galeria" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Galéria
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/kapcsolat" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Kapcsolat
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <nav className="fusion-main-menu fusion-sticky-menu" aria-label="Main Menu Sticky">
                                <ul id="menu-fomenu-1" className="fusion-menu">
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item">
                                        <a href="/" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Kezdőlap
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu">
                                        <a href="/rolunk" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Rólunk
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                            <span className="menu-text">
                                                Szolgáltatások
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.servicesMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a className="fusion-bar-highlight" style={{ cursor: "default" }}>
                                                            <span style={{ marginRight: '16px', fontSize: '16px' }}><i aria-hidden="true" className="fa-angle-left fas" style={{ color: '#b18b5e' }} /></span>
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                        {menuItem.children.length > 0 && <ul className="sub-menu">
                                                            {menuItem.children.map((subMenuItem, subIndex) => {
                                                                return (
                                                                    <li key={subIndex} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                                        <a href={`/oldal/${subMenuItem.targetPage != null ? subMenuItem.targetPage!!.path : subMenuItem.targetScrollingPage!!.path}`} className="fusion-bar-highlight">
                                                                            <span>{subMenuItem.title}</span>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-has-children menu-item-11951 fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                            <span className="menu-text">
                                                Bőrproblémák kezelése
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.skinProblemsMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a href={`/oldal/${menuItem.targetPage!!.path}`} className="fusion-bar-highlight">
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="https://oxy-esztetikai-studio.salonic.hu/" className="fusion-bar-highlight" target="_blank">
                                            <span className="menu-text">
                                                Időpontfoglalás
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-has-children menu-item-11951 fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                            <span className="menu-text">
                                                Árak
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.pricingMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a href={`/arak/${menuItem.targetPricingPage!!.path}`} className="fusion-bar-highlight">
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/shop" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                WEBSHOP
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/galeria" className="fusion-bar-highlight">
                                            <span className="menu-text">Galéria</span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/kapcsolat" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Kapcsolat
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="fusion-mobile-navigation">
                                <ul id="menu-fomenu-2" className="fusion-mobile-menu">
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item">
                                        <a href="/" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Kezdőlap
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu">
                                        <a href="/rolunk" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Rólunk
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight" style={{ display: 'flex', cursor: "default" }}>
                                            <span className="menu-text">
                                                Szolgáltatások
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.servicesMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a className="fusion-bar-highlight" style={{ cursor: "default" }}>
                                                            <span style={{ marginRight: '16px', fontSize: '16px' }}><i aria-hidden="true" className="fa-angle-left fas" style={{ color: '#b18b5e' }} /></span>
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                        {menuItem.children.length > 0 && <ul className="sub-menu">
                                                            {menuItem.children.map((subMenuItem, subIndex) => {
                                                                return (
                                                                    <li key={subIndex} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                                        <a href={`/oldal/${subMenuItem.targetPage != null ? subMenuItem.targetPage!!.path : subMenuItem.targetScrollingPage!!.path}`} className="fusion-bar-highlight">
                                                                            <span>{subMenuItem.title}</span>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-has-children fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Bőrproblémák kezelése
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.skinProblemsMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a href={`/oldal/${menuItem.targetPage!!.path}`} className="fusion-bar-highlight">
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item">
                                        <a href="https://oxy-esztetikai-studio.salonic.hu/" className="fusion-bar-highlight" target="_blank">
                                            <span className="menu-text">
                                                Időpontfoglalás
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-has-children fusion-dropdown-menu">
                                        <a className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Árak
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            {this.props.pricingMenu.map((menuItem, index) => {
                                                return (
                                                    <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu">
                                                        <a href={`/arak/${menuItem.targetPricingPage!!.path}`} className="fusion-bar-highlight">
                                                            <span>{menuItem.title}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/shop" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                WEBSHOP
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/galeria" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Galéria
                                            </span>
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/kapcsolat" className="fusion-bar-highlight">
                                            <span className="menu-text">
                                                Kapcsolat
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={`oxy-navbar-mobile-wrapper ${this.state.mobileMenuOpen ? "open" : ""}`} onClick={e => { if (this.state.mobileMenuOpen) this.toggleMobileMenu(e, false); }} style={{ pointerEvents: this.state.mobileMenuOpen ? "all" : "none", height: "100px" }}>
                                <nav className="navbar navbar-default oxy-navbar-mobile w-100" role="navigation" style={{ position: "absolute", zIndex: 2 }}>
                                    <div className="container-fluid">
                                        <div className="navbar-header d-flex justify-content-end w-100">
                                            <div className="navbar-toggle collapsed mr-4" onClick={e => this.toggleMobileMenu(e)} style={{ pointerEvents: "all" }}>
                                                <div>
                                                    <FontAwesomeIcon icon={["fas", "bars"]} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`collapse navbar-collapse ${this.state.mobileMenuOpen ? "show in" : ""}`} id="oxy-mobile-navbar-collapse">
                                            <ul className="nav navbar-nav">
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                    <a href="/" className="fusion-bar-highlight">
                                                        <span className="menu-text">
                                                            Kezdőlap
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu dropdown text-center">
                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page text-center">
                                                        <a href="/rolunk" className="fusion-bar-highlight">
                                                            <span className="menu-text">
                                                                Rólunk
                                                            </span>
                                                        </a>
                                                    </li>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu dropdown text-center">
                                                    <a href="#" onClick={e => this.toggleMobileExpandedIndex(e, this.state.mobileExpandedIndex === MobileMenuCategory.Services ? NaN : MobileMenuCategory.Services, NaN)}>
                                                        Szolgáltatások
                                                        <FontAwesomeIcon icon={["fas", this.state.mobileExpandedIndex === MobileMenuCategory.Services ? "angle-up" : "angle-down"]} className="ml-2" />
                                                    </a>
                                                    <ul className={`dropdown-menu ${this.state.mobileExpandedIndex === MobileMenuCategory.Services ? "show" : ""}`} role="menu">
                                                        {this.props.servicesMenu.map((menuItem, index) => {
                                                            return (
                                                                <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu text-center">
                                                                    <a className="fusion-bar-highlight" href="#" style={{ cursor: "default" }} onClick={(e) => this.toggleMobileExpandedIndex(e, MobileMenuCategory.Services, index)}>
                                                                        {menuItem.title}
                                                                        <FontAwesomeIcon icon={["fas", this.state.mobileSubExpandedIndex === index ? "angle-up" : "angle-down"]} className="ml-2" />
                                                                    </a>
                                                                    {menuItem.children.length > 0 && <ul className={`sub-menu dropdown-menu dropdown-submenu ${this.state.mobileSubExpandedIndex === index ? "show" : ""}`}>
                                                                        {menuItem.children.map((subMenuItem, subIndex) => {
                                                                            return (
                                                                                <li key={subIndex} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu text-center">
                                                                                    <a href={`/oldal/${subMenuItem.targetPage != null ? subMenuItem.targetPage!!.path : subMenuItem.targetScrollingPage!!.path}`} className="fusion-bar-highlight">
                                                                                        <span className="menu-text">{subMenuItem.title}</span>
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu dropdown text-center">
                                                    <a href="#" onClick={e => this.toggleMobileExpandedIndex(e, this.state.mobileExpandedIndex === MobileMenuCategory.SkinProblems ? NaN : MobileMenuCategory.SkinProblems, NaN)}>
                                                        Bőrproblémák kezelése
                                                        <FontAwesomeIcon icon={["fas", this.state.mobileExpandedIndex === MobileMenuCategory.SkinProblems ? "angle-up" : "angle-down"]} className="ml-2" />
                                                    </a>
                                                    <ul className={`dropdown-menu ${this.state.mobileExpandedIndex === MobileMenuCategory.SkinProblems ? "show" : ""}`} role="menu">
                                                        {this.props.skinProblemsMenu.map((menuItem, index) => {
                                                            return (
                                                                <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu text-center">
                                                                    <a href={`/arak/${menuItem.targetPage!!.path}`} className="fusion-bar-highlight">
                                                                        <span className="menu-text">{menuItem.title}</span>
                                                                    </a>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item text-center">
                                                    <a href="https://oxy-esztetikai-studio.salonic.hu/" className="fusion-bar-highlight" target="_blank">
                                                        <span className="menu-text">
                                                            Időpontfoglalás
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children fusion-dropdown-menu dropdown text-center">
                                                    <a href="#" onClick={e => this.toggleMobileExpandedIndex(e, this.state.mobileExpandedIndex === MobileMenuCategory.Pricing ? NaN : MobileMenuCategory.Pricing, NaN)}>
                                                        Árak
                                                        <FontAwesomeIcon icon={["fas", this.state.mobileExpandedIndex === MobileMenuCategory.Pricing ? "angle-up" : "angle-down"]} className="ml-2" />
                                                    </a>
                                                    <ul className={`dropdown-menu ${this.state.mobileExpandedIndex === MobileMenuCategory.Pricing ? "show" : ""}`} role="menu">
                                                        {this.props.pricingMenu.map((menuItem, index) => {
                                                            return (
                                                                <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page fusion-dropdown-submenu text-center">
                                                                    <a href={`/arak/${menuItem.targetPricingPage!!.path}`} className="fusion-bar-highlight">
                                                                        <span className="menu-text">{menuItem.title}</span>
                                                                    </a>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page text-center">
                                                    <a href="/shop" className="fusion-bar-highlight">
                                                        <span className="menu-text">
                                                            WEBSHOP
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page text-center">
                                                    <a href="/galeria" className="fusion-bar-highlight">
                                                        <span className="menu-text">
                                                            Galéria
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page text-center">
                                                    <a href="/kapcsolat" className="fusion-bar-highlight">
                                                        <span className="menu-text">
                                                            Kapcsolat
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <nav className="fusion-mobile-nav-holder fusion-mobile-menu-text-align-center fusion-mobile-sticky-nav-holder" aria-label="Main Menu Mobile Sticky" />
                        </div>
                    </div>
                </div>
                <div className="fusion-clearfix" />
            </header>
        );
    }

    private toggleMobileMenu = (e: any, value: boolean = !this.state.mobileMenuOpen) => {
        if (e != null && e.target != null && e.target.className != null && e.target.className.indexOf != null && e.target.className.indexOf("menu-text") !== -1) {
            return;
        }

        if (e.nativeEvent != null) {
            e.nativeEvent.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            e.stopPropagation();
        }

        this.setState({
            mobileMenuOpen: value
        });
    }

    private toggleMobileExpandedIndex = (e: any, index: number, subIndex: number) => {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();

        this.setState({
            mobileExpandedIndex: index,
            mobileSubExpandedIndex: this.state.mobileSubExpandedIndex === subIndex ? NaN : subIndex
        });
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(NavMenu as any);
