import * as React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { PageDto } from "../../models";

interface PagePickerModalProps {
    pages: PageDto[];
    onPageSelected: (id: number) => void;
    isOpen: boolean;
    toggle: () => void;
}

interface PagePickerModalState {
}

export class PagePickerModal extends React.PureComponent<PagePickerModalProps, PagePickerModalState> {
    constructor(props: PagePickerModalProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-lg">
                    <ModalHeader toggle={this.props.toggle}>Hivatkozás kiválasztása</ModalHeader>
                    <ModalBody>
                        <div className="list-group">
                            {this.props.pages.map((page, index) => {
                                return (
                                    <div key={index} className="list-group-item list-group-item-action" style={{ cursor: "pointer" }} onClick={() => this.props.onPageSelected(page.id)}>
                                        {page.name}
                                    </div>
                                );
                            })}
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default PagePickerModal;
