import * as React from 'react';
import './loader-heart.css';

interface LoadingIndicatorProps {
    show: boolean;
}

export const LoadingIndicator = ({ show }: LoadingIndicatorProps) => (
    <div className={`loader ${show ? "show" : ""}`}>
        <div className="lds-heart">
            <div></div>
        </div>
    </div>
);
