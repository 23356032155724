import * as React from 'react';
import { CurrentOfferDto } from '../models/CurrentOfferDto';

interface CurrentOfferCardProps {
    currentOffer: CurrentOfferDto | null | undefined;
    isPlaceholder: boolean;
}

class CurrentOfferCard extends React.PureComponent<CurrentOfferCardProps> {
    public render() {
        const currentOffer: CurrentOfferDto = this.props.isPlaceholder ? {
            id: 0,
            title: "Webshop",
            image: {
                id: 0,
                name: "webshop-img-v1.png"
            },
            target: {
                id: 0,
                name: "Webshop",
                path: "/shop",
                pricingPage: null
            },
            targetScrolling: null,
            order: 0
        } : this.props.currentOffer!!;

        return (
            <div className="fusion-column content-box-column content-box-column content-box-column-1 col-lg-3 col-md-3 col-sm-3 fusion-content-box-hover content-box-column-first-in-row" style={{ visibility: this.props.currentOffer === undefined ? "hidden" : "visible" }}>
                <div className="col content-box-wrapper content-wrapper link-area-box link-type-text content-icon-wrapper-yes icon-hover-animation-fade" style={{ backgroundColor: 'transparent' }} data-link={this.props.isPlaceholder ? currentOffer.target!!.path : `/oldal/${currentOffer.target != null ? currentOffer.target.path : currentOffer.targetScrolling!!.path}`} data-animationoffset="100%">
                    <div className="heading heading-with-icon icon-left">
                        <a className="heading-link">
                            <div aria-hidden="true" className="image">
                                <img src={`/wp-content/uploads/new/${currentOffer.image.name}`} width={300} height={407} alt="" />
                            </div>
                            <h2 className="content-box-heading" style={{ fontSize: '22px', lineHeight: '27px', height: "54px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {currentOffer.title}
                            </h2>
                        </a>
                    </div>
                    <div className="fusion-clearfix" />
                    <a className="fusion-read-more">
                        {this.props.isPlaceholder ? "Irány a webshop" : "Bővebben a kezelésről"}
                    </a>
                    <div className="fusion-clearfix" />
                </div>
            </div>
        );
    }
}

export default CurrentOfferCard;
