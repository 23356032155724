import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface AdminBackButtonProps {
    to?: string;
}

class AdminBackButton extends React.PureComponent<AdminBackButtonProps> {
    public render() {
        return (
            <div className="d-flex justify-content-start mb-2">
                <Link to={this.props.to != null ? this.props.to : "/admin"} className="btn btn-outline btn-primary">
                    <FontAwesomeIcon icon={["fas", "caret-left"]} className="fa-fw mr-1" />
                    Vissza
                </Link>
            </div>
        );
    }
}

export default AdminBackButton;
