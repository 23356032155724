import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ScrollingPageItemDto } from '../models';

interface OxyScrollingPageItemImageRightProps {
    item: ScrollingPageItemDto;
}

class OxyScrollingPageItemImageRight extends React.PureComponent<OxyScrollingPageItemImageRightProps> {
    public render() {
        return (
            <React.Fragment>
                <section className="elementor-element elementor-element-4fda6416 elementor-section-stretched elementor-section-full_width elementor-hidden-desktop elementor-hidden-tablet elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="4fda6416" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-row">
                            <div className="elementor-element elementor-element-55b7be72 elementor-column elementor-col-50 elementor-top-column" data-id="55b7be72" data-element_type="column">
                                <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-77473a67 elementor-widget elementor-widget-heading" data-id="77473a67" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">{this.props.item.title}</h2>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-e2e7bc2 elementor-widget elementor-widget-text-editor" data-id="e2e7bc2" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <p>
                                                        <span className="color_15">{this.props.item.subtitle}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-1b48d097 elementor-widget elementor-widget-text-editor" data-id="1b48d097" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <p style={{ textAlign: "justify" }}>
                                                        <span className="color_15" dangerouslySetInnerHTML={{ __html: this.props.item.text.replace(/\n/g, "<br />") }}></span>
                                                        <span style={{ fontSize: '16.8px' }}>&nbsp;</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-56d0a6b5 elementor-column elementor-col-50 elementor-top-column" data-id="56d0a6b5" data-element_type="column">
                                <div className="elementor-column-wrap elementor-element-populated" style={{ backgroundImage: `url('/wp-content/uploads/new/${this.props.item.image.name}')`, backgroundSize: isMobileOnly ? "contain" : "unset", backgroundRepeat: isMobileOnly ? "no-repeat" : "unset" }}>
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-a2323b8 elementor-widget elementor-widget-spacer" data-id="a2323b8" data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="elementor-element elementor-element-46159c4 elementor-section-stretched elementor-section-full_width elementor-hidden-phone elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="46159c4" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                    <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-row">
                            <div className="elementor-element elementor-element-6da2935 elementor-column elementor-col-50 elementor-top-column" data-id="6da2935" data-element_type="column">
                                <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-576b3d6 elementor-widget elementor-widget-heading" data-id="576b3d6" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">{this.props.item.title}</h2>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-c15b407 elementor-widget elementor-widget-text-editor" data-id="c15b407" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <p>
                                                        <span className="color_15">{this.props.item.subtitle}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-fc6d14d elementor-widget elementor-widget-text-editor" data-id="fc6d14d" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                    <p style={{ textAlign: "justify" }}>
                                                        <span className="color_15" dangerouslySetInnerHTML={{ __html: this.props.item.text.replace(/\n/g, "<br />") }}></span>
                                                        <span style={{ fontSize: '16.8px' }}>&nbsp;</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-b8005af elementor-column elementor-col-50 elementor-top-column" data-id="b8005af" data-element_type="column">
                                <div className="elementor-column-wrap elementor-element-populated" style={{ backgroundImage: `url('/wp-content/uploads/new/${this.props.item.image.name}')` }}>
                                    <div className="elementor-widget-wrap">
                                        <div className="elementor-element elementor-element-6e3a796 elementor-widget elementor-widget-spacer" data-id="6e3a796" data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default OxyScrollingPageItemImageRight;
