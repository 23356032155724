import { ProtectedRestApi } from "./";
import {
    ServiceResult,
    MainPageDto,
    PageDto,
    PageToDisplayDto,
    InfraTrainerPageDto
} from "../models";
import {
    AboutUsTextsPayload,
    PageBulletPointListWithTitlePayload,
    PageImageWithTextSideBySidePayload,
    PageTitleWithTextSectionPayload,
    Section8ImagePayload,
    VideoOverlayOpeningHoursPayload,
    VideoOverlayTitlesPayload
} from "../payloads";

export class PageApi extends ProtectedRestApi {
    private static baseUrl = "/api/oxypage";

    public getMainPage(): Promise<ServiceResult<MainPageDto>> {
        return this.httpGet(`${PageApi.baseUrl}/main`, true) as Promise<ServiceResult<MainPageDto>>;
    }

    public getInfraTrainerPage(): Promise<ServiceResult<InfraTrainerPageDto>> {
        return this.httpGet(`${PageApi.baseUrl}/infratrainer`, true) as Promise<ServiceResult<InfraTrainerPageDto>>;
    }

    public getPages(): Promise<ServiceResult<PageDto[]>> {
        return this.httpGet(`${PageApi.baseUrl}/all`) as Promise<ServiceResult<PageDto[]>>;
    }

    public getPageByPath(path: string): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpGet(`${PageApi.baseUrl}/by-path/${path}`, true) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updateMainPageAboutUsTexts(payload: AboutUsTextsPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${PageApi.baseUrl}/main/about-us`, payload) as Promise<ServiceResult<boolean>>;
    }

    public updateMainPageYoutubeVideoId(videoId: string): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${PageApi.baseUrl}/main/youtube-video-id`, videoId) as Promise<ServiceResult<boolean>>;
    }

    public updateMainPageVideoOverlayTitles(payload: VideoOverlayTitlesPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${PageApi.baseUrl}/main/video-overlay-titles`, payload) as Promise<ServiceResult<boolean>>;
    }

    public updateMainPageVideoOverlayDetails(text: string): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${PageApi.baseUrl}/main/video-overlay-details`, text) as Promise<ServiceResult<boolean>>;
    }

    public updateMainPageVideoOverlayOpeningHours(payload: VideoOverlayOpeningHoursPayload): Promise<ServiceResult<boolean>> {
        return this.httpPost(`${PageApi.baseUrl}/main/video-overlay-opening-hours`, payload) as Promise<ServiceResult<boolean>>;
    }

    public updatePageSection1(pageId: number, payload: PageTitleWithTextSectionPayload): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-1`, payload) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePageSection3(pageId: number, isVisible: boolean, payload: PageBulletPointListWithTitlePayload): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-3?isVisible=${isVisible}`, payload) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePageSection4(pageId: number, isVisible: boolean, payload: PageImageWithTextSideBySidePayload): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-4?isVisible=${isVisible}`, payload) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePageSection5(pageId: number, isVisible: boolean, text: string): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-5?isVisible=${isVisible}`, text) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePageSection7(pageId: number, isVisible: boolean, text: string): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-7?isVisible=${isVisible}`, text) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePageSection8(pageId: number, isVisible: boolean, payload: Section8ImagePayload[]): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-8?isVisible=${isVisible}`, payload) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePageSection9(pageId: number, isVisible: boolean, text: string): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-9?isVisible=${isVisible}`, text) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePageSection10(pageId: number, isVisible: boolean, payload: PageBulletPointListWithTitlePayload): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/section-10?isVisible=${isVisible}`, payload) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public copyPage(pageId: number, name: string, path: string): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}/copy?name=${name}&path=${path}`) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public updatePage(pageId: number, name: string, path: string, pricingPageId: number): Promise<ServiceResult<PageToDisplayDto>> {
        return this.httpPost(`${PageApi.baseUrl}/${pageId}?name=${name}&path=${path}&pricingPageId=${pricingPageId}`) as Promise<ServiceResult<PageToDisplayDto>>;
    }

    public deletePage(pageId: number): Promise<ServiceResult<boolean>> {
        return this.httpDelete(`${PageApi.baseUrl}/${pageId}`) as Promise<ServiceResult<boolean>>;
    }
}
