import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as OxyStore from '../../store/OxyStore';
import NavMenuShop from './NavMenuShop';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';
import { AddressDto } from '../../models';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { UpdateProfilePayload } from '../../payloads';

type ProfileProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface ProfileState {
    validationErrors: string[];
    name: string;
    billingName: string;
    billingAddress: AddressDto;
}

class Profile extends React.PureComponent<ProfileProps, ProfileState> {
    constructor(props: ProfileProps) {
        super(props);

        this.state = {
            validationErrors: [],
            name: props.loggedInUser == null ? "" : props.loggedInUser.name,
            billingName: props.loggedInUser == null ? "" : props.loggedInUser.billingName,
            billingAddress: props.loggedInUser == null || props.loggedInUser.billingAddress == null ? {
                id: 0,
                zipCode: 0,
                city: "",
                streetAddress: ""
            } : { ...props.loggedInUser.billingAddress }
        };

        this.validate(true);
    }

    componentWillReceiveProps(nextProps: ProfileProps) {
        if (this.props.loggedInUser == null && nextProps.loggedInUser != null) {
            this.setState({
                name: nextProps.loggedInUser.name,
                billingName: nextProps.loggedInUser.billingName,
                billingAddress: { ...nextProps.loggedInUser.billingAddress }
            }, () => this.validate(true));
        }
    }

    public render() {
        require("../../shop.css");

        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="container checkout-main-container">
                    <h1>Profil</h1>
                    <div>
                        <div className="form-group">
                            <label htmlFor="inputProfileName">Név</label>
                            <input type="text" className="form-control" id="inputProfileName" autoComplete="off" placeholder="Írd be a nevet." value={this.state.name} onChange={this.handleNameChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputProfileBillingName">Számlázási név</label>
                            <input type="text" className="form-control" id="inputProfileBillingName" autoComplete="off" placeholder="Írd be a számlázási nevet." value={this.state.billingName} onChange={this.handleBillingNameChange} />
                        </div>
                        <h3 style={{ color: "#747474" }} className="mt-3">Számlázási cím</h3>
                        <div className="form-group">
                            <label htmlFor="inputProfileZipCode">Irányítószám</label>
                            <input type="text" className="form-control" id="inputProfileZipCode" autoComplete="off" placeholder="Írd be az irányítószámot." value={this.state.billingAddress.zipCode || ""} onChange={(e: any) => this.handleBillingAddressChange({ ...this.state.billingAddress, zipCode: (!isNaN(parseInt(e.target.value.replace(/\D/, ""))) ? parseInt(e.target.value.replace(/\D/, "")) : 0) })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputProfileCity">Település</label>
                            <input type="text" className="form-control" id="inputProfileCity" autoComplete="off" placeholder="Írd be a település nevét." value={this.state.billingAddress.city} onChange={(e: any) => this.handleBillingAddressChange({ ...this.state.billingAddress, city: e.target.value })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputProfileStreetAddress">Cím</label>
                            <input type="text" className="form-control" id="inputProfileStreetAddress" autoComplete="off" placeholder="Írd be a címet." value={this.state.billingAddress.streetAddress} onChange={(e: any) => this.handleBillingAddressChange({ ...this.state.billingAddress, streetAddress: e.target.value })} />
                        </div>
                    </div>
                    <div className="mt-4 mb-4">
                        <div id="checkout-order-button-container">
                            <Button id="checkout-order-button" color="success" className="d-flex" onClick={this.handleSaveClick} disabled={!this.isValid()}>
                                <div>Mentés</div>
                            </Button>
                        </div>
                        {this.state.validationErrors.length > 0 && <UncontrolledTooltip placement='top' target="checkout-order-button-container">
                            {this.state.validationErrors.map((error, index) => {
                                return (
                                    <React.Fragment>
                                        {error}
                                        {index < this.state.validationErrors.length - 1 && <br />}
                                    </React.Fragment>
                                );
                            })}
                        </UncontrolledTooltip>}
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }

    private handleNameChange = (e: any) => {
        this.setState({
            name: e.target.value
        }, () => this.validate(true));
    }

    private handleBillingNameChange = (e: any) => {
        this.setState({
            billingName: e.target.value
        }, () => this.validate(true));
    }

    private handleBillingAddressChange = (billingAddress: AddressDto) => {
        this.setState({
            billingAddress: {
                ...billingAddress
            }
        }, () => this.validate(true));
    }

    private handleSaveClick = () => {
        const payload: UpdateProfilePayload = {
            name: this.state.name,
            billingName: this.state.billingName,
            billingAddress: {
                ...this.state.billingAddress
            }
        };

        this.props.updateProfile(payload);
    }

    private isValid = () => {
        return this.validate();
    }

    private validate = (shouldSetState: boolean = false, props: ProfileProps = this.props) => {
        const validationErrors: string[] = [];

        if (this.state.name.length === 0) {
            validationErrors.push("A név kitöltése kötelező.");
        }

        if (this.state.billingName.length === 0) {
            validationErrors.push("A számlázási név kitöltése kötelező.");
        }

        if (this.state.billingAddress.zipCode < 1000 || this.state.billingAddress.zipCode > 9999) {
            validationErrors.push("Az irányítószám érvénytelen.")
        }

        if (this.state.billingAddress.city.length === 0) {
            validationErrors.push("A település kitöltése kötelező.");
        }

        if (this.state.billingAddress.streetAddress.length === 0) {
            validationErrors.push("A cím kitöltése kötelező.");
        }

        if (shouldSetState) {
            this.setState({
                validationErrors: validationErrors
            });
        }

        return validationErrors.length === 0;
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(Profile as any);
