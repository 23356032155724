import * as React from 'react';
import { ProductOptionDto } from '../../../models/shop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface ProductOptionEditorCardProps {
    item: ProductOptionDto;
    sharedProps?: {
        onEditClick: (id: number) => void;
        onDeleteClick?: (id: number, isVisible: boolean) => void;
    }
}

interface ProductOptionEditorCardState {
    deleteModalOpen: boolean;
}

export class ProductOptionEditorCard extends React.PureComponent<ProductOptionEditorCardProps, ProductOptionEditorCardState> {
    constructor(props: ProductOptionEditorCardProps) {
        super(props);

        this.state = {
            deleteModalOpen: false
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className={`d-flex justify-content-between align-items-center align-content-center cursor-pointer ${this.props.item.isVisible ? "" : "text-muted admin-item-invisible"}`}>
                    <span>{this.props.item.name}</span>
                    {this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null && <div>
                        <Button onClick={() => this.props.sharedProps!!.onEditClick(this.props.item.id)} color="primary" className="mr-3">
                            <FontAwesomeIcon icon={["fas", "pencil-alt"]} className="mr-1" /> Szerkesztés
                        </Button>
                        <Button onClick={this.handleDeleteClick} color={this.props.item.isVisible ? "danger" : "success"}>
                            <FontAwesomeIcon icon={["fas", "eye"]} className="mr-1" /> {this.props.item.isVisible ? "Elrejtés" : "Megjelenítés"}
                        </Button>
                    </div>}
                </div>
                <Modal isOpen={this.state.deleteModalOpen} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                    <ModalHeader toggle={this.handleCancelDeleteClick}>Opció {this.props.item.isVisible ? "elrejtése" : "megjelenítése"}</ModalHeader>
                    <ModalBody>
                        <div>
                            <p>
                                Biztosan {this.props.item.isVisible ? "el szeretnéd rejteni" : "meg szeretnéd jeleníteni"} az opciót?
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-warning">
                                <FontAwesomeIcon icon={["fas", "eye"]} className="mr-1" /> {this.props.item.isVisible ? "Elrejtés" : "Megjelenítés"}
                            </button>

                            <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                                <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    private handleDeleteClick = () => {
        this.setState({
            deleteModalOpen: true
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            deleteModalOpen: false
        });
    }

    private handleConfirmDeleteClick = () => {
        if (this.props.sharedProps != null && this.props.sharedProps.onDeleteClick != null) {
            this.props.sharedProps.onDeleteClick(this.props.item.id, !this.props.item.isVisible);
            this.setState({
                deleteModalOpen: false
            });
        }
    }
}

export default ProductOptionEditorCard;
