import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store/index';
import * as OxyStore from '../../../store/OxyStore';
import NavMenuShop from './../NavMenuShop';
import Footer from '../../Footer';
import ScrollToTop from '../../ScrollToTop';
import UserCard from './UserCard';
import { OxyUser } from '../../../models';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type UsersProps = OxyStore.OxyState
    & typeof OxyStore.actionCreators
    & RouteComponentProps<{}>;

interface UsersState {
    search: string;
    filteredUsers: OxyUser[];
    userIdToBeDeleted: number;
}

class Users extends React.PureComponent<UsersProps, UsersState> {
    constructor(props: UsersProps) {
        super(props);

        this.state = {
            search: "",
            filteredUsers: props.users,
            userIdToBeDeleted: NaN
        }
    }

    componentDidMount() {
        if (this.props.users.length === 0) {
            this.props.getAllUsers();
        }
    }

    componentWillReceiveProps(nextProps: UsersProps) {
        if (this.props.users.length !== nextProps.users.length) {
            this.setState({
                search: "",
                filteredUsers: nextProps.users
            })
        }
    }

    public render() {
        require("../../../shop.css");
        return (
            <React.Fragment>
                <NavMenuShop />
                <ScrollToTop />
                <div className="container checkout-main-container mb-4">
                    <h1>Felhasználók</h1>
                    <div className="mt-2 mb-2">
                        <div className="form-group">
                            <input type="text" className="form-control" autoComplete="off" placeholder="Keresés..." value={this.state.search} onChange={this.handleSearchChange} />
                        </div>
                    </div>
                    {this.state.filteredUsers.length === 0 && <div>
                        <h4>Nem található felhasználó.</h4>
                    </div>}
                    {this.state.filteredUsers.length > 0 && <div className="list-group">
                        {this.state.filteredUsers.map((user, index) => {
                            return (
                                <UserCard
                                    key={index}
                                    user={user}
                                    delete={this.handleDeleteClick}
                                />
                            );
                        })}
                    </div>}
                </div>
                {this.getDeleteConfirmModal()}
                <Footer />
            </React.Fragment>
        );
    }

    private getDeleteConfirmModal = () => {
        return (
            <Modal isOpen={!isNaN(this.state.userIdToBeDeleted)} toggle={this.handleCancelDeleteClick} className="modal-dialog-centered">
                <ModalHeader toggle={this.handleCancelDeleteClick}>Felhasználó törlése</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Biztosan törölni szeretnéd a felhasználót?
                        </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleConfirmDeleteClick} type="button" className="btn btn-danger">
                            <FontAwesomeIcon icon={["fas", "trash-alt"]} className="mr-1" /> Törlés
                        </button>

                        <button className="btn btn-secondary" onClick={this.handleCancelDeleteClick}>
                            <FontAwesomeIcon icon={["fas", "ban"]} /> Mégse
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private handleSearchChange = (e: any) => {
        const search = e.target.value;

        this.setState({
            search: search,
            filteredUsers: this.filterUsers(this.props.users, search)
        });
    }

    private filterUsers = (users: OxyUser[], search: string): OxyUser[] => {
        search = search.toLowerCase();
        return users.filter(user => {
            if (user.name != null && user.name.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if (user.email.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            return false;
        });
    }

    private handleDeleteClick = (id: number) => {
        this.setState({
            userIdToBeDeleted: id
        });
    }

    private handleCancelDeleteClick = () => {
        this.setState({
            userIdToBeDeleted: NaN
        });
    }

    private handleConfirmDeleteClick = () => {
        if (!isNaN(this.state.userIdToBeDeleted)) {
            this.props.deleteUser(this.state.userIdToBeDeleted);
            this.setState({
                userIdToBeDeleted: NaN
            });
        }
    }
}

export default connect(
    (state: ApplicationState) => state.oxy,
    OxyStore.actionCreators
)(Users as any);
