import * as React from 'react';
import { ConnectedComponent } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as OxyGuaranteePageComponent from '../OxyGuaranteePageComponent';

export class OxyGuaranteePageEditor extends React.PureComponent<RouteComponentProps<{ path: string }>> {
    public render() {
        const Hack = OxyGuaranteePageComponent.default as ConnectedComponent<any, any>;

        return (
            <Hack match={this.props.match} editing={true} />
        );
    }
}

export default OxyGuaranteePageEditor;
